/**
 * Checks if there are any active filters in the selected filters object
 * @param selectedFilters Object containing arrays of selected filter values
 * @returns boolean indicating if any filters are active
 */
export const hasActiveFilters = (
  selectedFilters: Record<string, string[]>,
): boolean => {
  return Object.values(selectedFilters).some((filters) => filters.length > 0);
};
