import { z } from "zod";
import { formatDateForInput } from "../utils/dateUtils";

export const sessionSchema = z.object({
  id: z.number().optional(),
  eventId: z.number().optional(),
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required"),
  maxEnrollments: z.coerce
    .number()
    .min(1, "Maximum enrollments must be at least 1"),
  virtualLink: z.string().optional().nullable(),
  notes: z.string().optional().nullable(),
  status: z.string().min(1, "Status is required"),
});

export type SessionFormData = z.infer<typeof sessionSchema>;

export const DEFAULT_SESSION_FORM_VALUES: SessionFormData = {
  startDate: formatDateForInput(new Date().toISOString()),
  endDate: formatDateForInput(new Date().toISOString()),
  maxEnrollments: 1,
  virtualLink: "",
  notes: "",
  status: "SCHEDULED",
};
