import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/app/hooks";
import {
  editEventCategoryAction,
  createEventCategoryAction,
} from "@/modules/eventRegistration/features/EventDirectory/thunks/eventThunks";
import {
  EventCategoryFormData,
  eventCategorySchema,
} from "../schemas/eventSchema";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";

interface UseEventCategoryDialogFormProps {
  eventCategory: ApiEventCategory | null;
  onSuccess?: () => void;
  onOpenChange?: (open: boolean) => void;
}

export const useEventCategoryDialogForm = ({
  eventCategory,
  onSuccess,
  onOpenChange,
}: UseEventCategoryDialogFormProps) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<EventCategoryFormData>({
    resolver: zodResolver(eventCategorySchema),
    defaultValues: {
      name: eventCategory?.name ?? "",
      description: eventCategory?.description ?? null,
      isActive: eventCategory?.isActive ?? true,
    },
  });

  const handleSubmit = async (
    data: EventCategoryFormData,
  ): Promise<boolean> => {
    setIsSubmitting(true);
    try {
      if (eventCategory) {
        await dispatch(
          editEventCategoryAction({
            eventCategoryId: eventCategory.id,
            editEventCategoryDTO: data,
          }),
        ).unwrap();
      } else {
        await dispatch(
          createEventCategoryAction({
            createEventCategoryDTO: data,
          }),
        ).unwrap();
      }

      // Reset form and close dialog on success
      form.reset();
      onSuccess?.();
      onOpenChange?.(false);
      return true;
    } catch (error) {
      console.error("Failed to save event category:", error);
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    form,
    handleSubmit,
    isSubmitting,
    isDirty: form.formState.isDirty,
  };
};
