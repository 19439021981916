import React from "react";
import SessionManagementTable from "../SessionManagementTable/SessionManagementTable";
import { SortingParams } from "../../hooks/useSessionManagementActions";
import {
  ApiEventSession,
  FetchEventSessionsRequest,
} from "@/api/fetchEventSessions/types";

export type SortOrder = "ASC" | "DESC";

export interface SessionManagementTableSectionProps {
  sessions: ApiEventSession[];
  isLoading: boolean;
  currentRequest: Omit<FetchEventSessionsRequest, "sortOrder"> & {
    sortOrder?: SortOrder;
  };
  totalCount: number;
  onEditSession: (session: ApiEventSession) => void;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
  onSortingChange: (params: SortingParams) => Promise<void>;
  defaultPageSize: number;
}

export const SessionManagementTableSection: React.FC<
  SessionManagementTableSectionProps
> = ({
  sessions,
  isLoading,
  currentRequest,
  totalCount,
  onEditSession,
  onPaginationChange,
  onSortingChange,
  defaultPageSize,
}) => {
  return (
    <SessionManagementTable
      data={sessions}
      isLoading={isLoading}
      onEditSession={onEditSession}
      onPaginationChange={onPaginationChange}
      onSortingChange={(sortBy, sortOrder) => {
        onSortingChange({ sortBy, sortOrder });
      }}
      pagination={{
        pageIndex: (currentRequest.page ?? 1) - 1,
        pageSize: currentRequest.pageSize ?? defaultPageSize,
        totalCount: totalCount ?? 0,
      }}
      sorting={{
        sortBy: currentRequest.sortBy,
        sortOrder: currentRequest.sortOrder,
      }}
    />
  );
};

export default React.memo(SessionManagementTableSection);
