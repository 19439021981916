import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Search, ChevronLeft, Star } from "lucide-react";
import EventDirectoryGrid from "../EventDirectoryGrid/EventDirectoryGrid";
import { useFavoritedEventsData } from "../../hooks/useFavoritedEventsData";

const FavoritedEvents: React.FC = () => {
  const { favoritedEvents, favorites, loading, error } =
    useFavoritedEventsData();

  if (favorites.length === 0) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-gradient-to-b from-background to-muted/20">
        <div className="space-y-4 max-w-md">
          <h2 className="text-2xl font-semibold">No favorited events yet</h2>
          <p className="text-muted-foreground">
            Start exploring our events and favorite the ones that interest you.
            They&apos;ll appear here for easy access.
          </p>
          <Button asChild className="mt-4" size="lg">
            <Link
              className="flex items-center gap-2 text-white"
              to="/event-registration/event-directory"
            >
              <Search className="h-4 w-4" />
              Browse Events
            </Link>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8">
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Button asChild className="mr-2" size="sm" variant="ghost">
            <Link to="/event-registration/event-directory">
              <ChevronLeft className="h-4 w-4" />
              Back to All Events
            </Link>
          </Button>
        </div>
      </div>

      <div className="mb-6 flex items-center">
        <Star className="mr-2 h-5 w-5 text-yellow-500" fill="currentColor" />
        <h1 className="text-2xl font-bold">Favorited Events</h1>
      </div>

      {loading ? (
        <div className="flex h-64 items-center justify-center">
          <p>Loading your favorited events...</p>
        </div>
      ) : error ? (
        <div className="rounded-lg border border-red-200 bg-red-50 p-4 text-red-800">
          <p>{error}</p>
          <Button
            className="mt-2"
            onClick={() => window.location.reload()}
            size="sm"
            variant="outline"
          >
            Try Again
          </Button>
        </div>
      ) : (
        <EventDirectoryGrid events={favoritedEvents} />
      )}
    </div>
  );
};

export default FavoritedEvents;
