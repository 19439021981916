import { SessionFormData } from "../schemas/sessionSchema";
import { EventSessionFormData } from "../schemas/eventSessionSchema";
import { SESSION_STATUS_API_MAPPING } from "../constants/sessionManagementConstants";

/**
 * Maps API session data (EventSessionFormData) to the form data structure (SessionFormData)
 */
export const mapApiSessionToFormData = (
  apiSession: EventSessionFormData,
): SessionFormData => {
  return {
    id: apiSession.id,
    eventId: apiSession.eventId,
    startDate: apiSession.startDate,
    endDate: apiSession.endDate,
    maxEnrollments: apiSession.maxEnrollments || 1,
    virtualLink: apiSession.virtualLink || "",
    notes: apiSession.notes || "",
    status: apiSession.status || "SCHEDULED",
  };
};

/**
 * Maps form data (SessionFormData) back to the API session structure (EventSessionFormData)
 */
export const mapFormDataToApiSession = (
  formData: SessionFormData,
): EventSessionFormData => {
  return {
    id: formData.id,
    eventId: formData.eventId,
    startDate: formData.startDate,
    endDate: formData.endDate,
    maxEnrollments: formData.maxEnrollments,
    virtualLink: formData.virtualLink || null,
    notes: formData.notes || null,
    status:
      SESSION_STATUS_API_MAPPING[
        formData.status as keyof typeof SESSION_STATUS_API_MAPPING
      ] || formData.status,
  };
};
