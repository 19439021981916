import axios from "../axiosInstance";
import { CreateEventRequest, CreateEventResponse } from "./types";

export const createEvent = async (
  requestData: CreateEventRequest,
): Promise<CreateEventResponse> => {
  const response = await axios.post<CreateEventResponse>(
    `/api/private/events/create`,
    requestData,
  );
  return response.data;
};
