import { HomeIcon } from "@heroicons/react/24/outline";
import { NavigationItem } from "./types";
import { BookOpenIcon, UserRoundPlus } from "lucide-react";

const navigation: NavigationItem[] = [
  {
    name: "Event Directory",
    href: "/event-registration/event-directory",
    icon: BookOpenIcon,
    current: false,
    internalName: "event-directory",
    permissions: [],
  },
  {
    name: "Admin",
    href: "/event-registration/admin",
    icon: HomeIcon,
    current: false,
    internalName: "admin",
    permissions: [],
    isCertainPathOnly: true,
    children: [
      {
        name: "Event Management",
        href: "/event-registration/admin/event-management",
        icon: UserRoundPlus,
        current: false,
        internalName: "event-management",
        permissions: [],
      },
    ],
  },
];

const eventRegistrationConfig = {
  sectionName: "event-registration",
  defaultRoute: "/event-registration",
  navigation,
};

export default eventRegistrationConfig;
