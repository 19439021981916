import { FC } from "react";
import ERSearch from "../../../shared/components/ERSearch";
import { useSessionManagementSearch } from "../../hooks/useSessionManagementSearch";

export interface SessionManagementSearchProps {
  onSearch: (query: string) => Promise<void> | void;
}

export const SessionManagementSearch: FC<SessionManagementSearchProps> = ({
  onSearch,
}) => {
  const { searchQuery, handleSearchChange } =
    useSessionManagementSearch(onSearch);

  return (
    <ERSearch
      onSearchChange={handleSearchChange}
      placeholder="Search sessions..."
      searchQuery={searchQuery}
    />
  );
};
