import { FileText, Download, Loader2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import React, { useMemo } from "react";

export interface EventDetailsFilesProps {
  files: ApiEventFile[];
  onFileDownload: (file: ApiEventFile) => void;
  downloadingFileId: number | null;
  isLoading?: boolean;
}

export const EventDetailsFiles: React.FC<EventDetailsFilesProps> = React.memo(
  ({ files, onFileDownload, downloadingFileId, isLoading = false }) => {
    const filesList = useMemo(() => {
      if (isLoading) {
        return (
          <div className="py-4 flex items-center justify-center">
            <Loader2 className="h-6 w-6 text-primary animate-spin mr-2" />
            <span>Loading files...</span>
          </div>
        );
      }

      return files?.length ? (
        files.map((file: ApiEventFile) => (
          <li
            className="flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50"
            key={file.id}
          >
            <div className="flex items-center space-x-3">
              <FileText className="h-5 w-5 text-gray-400" />
              <span>{file.fileName}</span>
            </div>
            <Button
              className="hover:text-primary"
              disabled={downloadingFileId === file.id}
              onClick={() => onFileDownload(file)}
              size="icon"
              variant="ghost"
            >
              {downloadingFileId === file.id ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Download className="h-4 w-4" />
              )}
            </Button>
          </li>
        ))
      ) : (
        <li className="text-gray-500 p-3">No files available for this event</li>
      );
    }, [files, onFileDownload, downloadingFileId, isLoading]);

    return (
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Event Files</h2>
        {isLoading ? (
          <div className="space-y-3">{filesList}</div>
        ) : (
          <ul className="space-y-3 list-none p-0">{filesList}</ul>
        )}
      </div>
    );
  },
);

EventDetailsFiles.displayName = "EventDetailsFiles";
