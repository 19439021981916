import { FetchEventTypesRequest, FetchEventTypesResponse } from "./types";
import { axiosInstance } from "../axiosInstance";

export const fetchEventTypes = async (
  requestData: FetchEventTypesRequest,
): Promise<FetchEventTypesResponse> => {
  const params = {
    ...requestData,
  };

  const response = await axiosInstance.get<FetchEventTypesResponse>(
    "/api/private/event-types",
    {
      params,
    },
  );
  return response.data;
};
