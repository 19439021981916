import React from "react";

// Shared Components
import { EntityDialog } from "@/modules/eventRegistration/features/shared/components/EntityDialog";

// Feature imports
import { SessionFormField } from "../SessionFormField/SessionFormField";
import {
  SessionFormData,
  DEFAULT_SESSION_FORM_VALUES,
} from "../../schemas/sessionSchema";
import { SESSION_FORM } from "../../constants/sessionManagementConstants";

import { SessionDialogAlerts } from "../SessionDialogAlerts/SessionDialogAlerts";
import { useSessionDialogForm } from "../../hooks/useSessionDialogForm";
import { useFormReset } from "../../hooks/useFormReset";

export interface SessionDialogProps {
  initialData?: SessionFormData;
  mode: "create" | "edit";
  onOpenChange: (open: boolean) => void;
  onSubmit: (
    data: SessionFormData,
    initialData?: SessionFormData,
  ) => Promise<void>;
  open: boolean;
  isSubmitting?: boolean;
  onSuccess?: () => void;
}

const SessionDialog: React.FC<SessionDialogProps> = ({
  initialData,
  mode,
  onOpenChange,
  onSubmit: propOnSubmit,
  open,
  isSubmitting = false,
  onSuccess,
}) => {
  const handleDialogClose = () => {
    onOpenChange(false);
  };

  const { form, handleClose, handleReset, confirmReset, confirmClose } =
    useSessionDialogForm({
      onClose: handleDialogClose,
      initialValues:
        mode === "create" ? DEFAULT_SESSION_FORM_VALUES : initialData,
    });

  // Use the form reset hook to handle form reset logic
  useFormReset({
    form,
    open,
    initialData,
    mode,
    defaultValues: DEFAULT_SESSION_FORM_VALUES,
  });

  const isDirty = form.formState.isDirty;

  return (
    <>
      <EntityDialog
        cancelButtonText={SESSION_FORM.BUTTON_TEXT.cancel}
        errorTitle="Error"
        form={form}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        mode={mode}
        onClose={handleClose}
        onOpenChange={onOpenChange}
        onReset={handleReset}
        onSubmit={(data) => {
          try {
            propOnSubmit(data as SessionFormData, initialData);
            handleDialogClose();
            if (onSuccess) {
              onSuccess();
            }
          } catch (error) {
            console.error("Error submitting form:", error);
          }
        }}
        open={open}
        resetButtonText={SESSION_FORM.BUTTON_TEXT.reset}
        submitButtonText={{
          create: SESSION_FORM.BUTTON_TEXT.create,
          edit: SESSION_FORM.BUTTON_TEXT.update,
          creating: SESSION_FORM.BUTTON_TEXT.creating,
          updating: SESSION_FORM.BUTTON_TEXT.updating,
        }}
        title={SESSION_FORM.TITLES[mode]}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <SessionFormField
            control={form.control}
            label={SESSION_FORM.LABELS.startDate}
            name="startDate"
            placeholder={SESSION_FORM.PLACEHOLDERS.startDate}
            type="datetime-local"
          />

          <SessionFormField
            control={form.control}
            label={SESSION_FORM.LABELS.endDate}
            name="endDate"
            placeholder={SESSION_FORM.PLACEHOLDERS.endDate}
            type="datetime-local"
          />

          <SessionFormField
            control={form.control}
            label={SESSION_FORM.LABELS.maxEnrollments}
            name="maxEnrollments"
            placeholder={SESSION_FORM.PLACEHOLDERS.maxEnrollments}
            type="number"
          />

          <SessionFormField
            control={form.control}
            label={SESSION_FORM.LABELS.virtualLink}
            name="virtualLink"
            placeholder={SESSION_FORM.PLACEHOLDERS.virtualLink}
            type="text"
          />

          <SessionFormField
            control={form.control}
            label={SESSION_FORM.LABELS.status}
            name="status"
            options={SESSION_FORM.STATUS_OPTIONS}
            placeholder={SESSION_FORM.PLACEHOLDERS.status}
            type="select"
          />

          <SessionFormField
            control={form.control}
            label={SESSION_FORM.LABELS.notes}
            name="notes"
            placeholder={SESSION_FORM.PLACEHOLDERS.notes}
            type="textarea"
          />
        </div>
      </EntityDialog>

      <SessionDialogAlerts
        onResetConfirm={confirmReset}
        onUnsavedChangesConfirm={confirmClose}
      />
    </>
  );
};

export default SessionDialog;
