import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@/hooks/useToast";
import { CreateEventRequest } from "@/api/createEvent/types";
import { EditEventDTO } from "@/api/editEvent/types";
import { ApiEvent } from "@/api/fetchEvents/types";
import {
  createEventWithCompanyCheckAction,
  editEventAction,
  fetchEventsAction,
} from "@/modules/eventRegistration/features/EventDirectory/thunks/eventThunks";
import { setCurrentRequest } from "@/modules/eventRegistration/features/EventDirectory/slices/eventUISlice";
import { RootState } from "@/app/rootReducer";
import { EventFormData } from "../schemas/eventSchema";
import { DEFAULT_PAGE_SIZE } from "../constants/eventManagementConstants";
import { AppDispatch } from "@/app/store";
import { useForm } from "react-hook-form";

export const useEventForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { totalCount } = useSelector((state: RootState) => state.events);
  const currentRequest = useSelector(
    (state: RootState) => state.eventUI.currentRequest,
  );

  // Create a form instance to expose to components
  const form = useForm<EventFormData>();
  const formState = form.formState;

  const handleSubmit = async (
    data: EventFormData,
    eventToEdit: ApiEvent | null,
  ): Promise<void> => {
    setIsSubmitting(true);

    if (eventToEdit) {
      const editEventDTO: EditEventDTO = {
        ...data,
        eventPrice: parseFloat(data.eventPrice.toFixed(2)),
        imageUrl: eventToEdit.imageUrl,
        isPublished: true,
        eventTypeId: eventToEdit.eventTypeId || 0,
        eventInstructorId: eventToEdit.eventInstructorId || null,
      };

      await dispatch(
        editEventAction({
          eventId: eventToEdit.id,
          editEventDTO,
        }),
      ).unwrap();

      toast({
        title: "Success",
        description: "Event updated successfully",
      });
    } else {
      const createEventRequest: CreateEventRequest = {
        ...data,
        eventPrice: parseFloat(data.eventPrice.toFixed(2)),
        isPublished: true,
        companyUuid: data.companyUuid || "",
        hideFromCalendar: data.hideFromCalendar || false,
        hideFromCatalog: data.hideFromCatalog || false,
      };

      await dispatch(
        createEventWithCompanyCheckAction(createEventRequest),
      ).unwrap();

      toast({
        title: "Success",
        description: "Event created successfully",
      });

      // Refresh the events list after creating a new event
      // If we're on the last page and the new event would create a new page, go to that page
      const currentPage = (currentRequest.page || 1) - 1; // Convert to 0-indexed
      const pageSize = currentRequest.pageSize || DEFAULT_PAGE_SIZE;
      const totalPages = Math.ceil(totalCount / pageSize);
      const isLastPage = currentPage === totalPages - 1;
      const wouldCreateNewPage = totalCount + 1 > totalPages * pageSize;

      if (isLastPage && wouldCreateNewPage) {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            page: totalPages + 1, // Convert to 1-indexed for API
          }),
        );
      } else {
        dispatch(fetchEventsAction());
      }
    }

    setIsSubmitting(false);
  };

  return {
    handleSubmit,
    isSubmitting,
    form,
    formState,
  };
};
