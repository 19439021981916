import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/rootReducer";
import { ApiEventSession } from "@/api/fetchEventSessions/types";
import { SessionManagementTableHeader } from "../SessionManagementTableHeader/SessionManagementTableHeader";
import { SessionManagementPagination } from "../SessionManagementPagination/SessionManagementPagination";
import { useParams } from "react-router-dom";
import {
  selectSessionsByEventId,
  selectIsLoading,
  selectSessionPagination,
} from "../../selectors/sessionManagementSelectors";
import { Column } from "../../hooks/useTableColumns";
import { useSessionManagementTableColumns } from "../../hooks/useSessionManagementTableColumns";
import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator";

export interface SessionManagementTableProps {
  data: ApiEventSession[];
  isLoading: boolean;
  onEditSession: (session: ApiEventSession) => void;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
  onSortingChange: (sortBy?: string, sortOrder?: "ASC" | "DESC") => void;
  pagination: {
    pageIndex: number;
    pageSize: number;
    totalCount: number;
  };
  sorting: {
    sortBy?: string;
    sortOrder?: "ASC" | "DESC";
  };
}

const SessionManagementTable: React.FC<SessionManagementTableProps> = ({
  onEditSession,
  onPaginationChange,
}) => {
  const { eventId } = useParams<{ eventId: string }>();

  const sessions = useSelector((state: RootState) =>
    selectSessionsByEventId(state, parseInt(eventId!, 10)),
  );
  const isLoading = useSelector(selectIsLoading);
  const pagination = useSelector(selectSessionPagination);

  const columns = useSessionManagementTableColumns({
    sessions,
    onEditSession,
    eventId: eventId!,
  });

  if (isLoading && sessions.length === 0) {
    return <LoadingIndicator />;
  }

  return (
    <div className="relative space-y-4">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
          <LoadingIndicator />
        </div>
      )}
      <div className="rounded-md border shadow-sm">
        <div className="relative w-full overflow-auto">
          <table className="w-full caption-bottom text-sm">
            <SessionManagementTableHeader columns={columns} />
            <tbody className="[&_tr:last-child]:border-0">
              {sessions.length === 0 ? (
                <tr className="border-b transition-colors hover:bg-muted/50">
                  <td
                    className="p-10 text-center text-muted-foreground"
                    colSpan={columns.length + 1}
                  >
                    No sessions found
                  </td>
                </tr>
              ) : (
                <>
                  {sessions.map((session: ApiEventSession) => (
                    <tr
                      className="border-b transition-colors hover:bg-muted/50"
                      key={session.id}
                    >
                      {columns.map((column: Column, index: number) => (
                        <td
                          className="p-4 align-middle [&:has([role=checkbox])]:pr-0"
                          key={`${session.id}-${column.id || index}`}
                        >
                          {column.cell
                            ? column.cell({ row: { original: session } })
                            : ""}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <SessionManagementPagination
        onPageChange={onPaginationChange}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        totalCount={pagination.totalCount}
      />
    </div>
  );
};

export default SessionManagementTable;
