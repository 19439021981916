import { FetchEventSessionsRequest, FetchEventSessionsResponse } from "./types";
import { axiosInstance } from "../axiosInstance";

export const fetchEventSessions = async (
  requestData: FetchEventSessionsRequest,
): Promise<FetchEventSessionsResponse> => {
  const params = {
    ...requestData,
  };

  const response = await axiosInstance.get("/api/private/event-sessions", {
    params,
  });

  return response.data;
};
