import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/app/store";
import { setCurrentRequest } from "../slices/sessionUISlice";
import { fetchSessionsAction } from "../thunks/sessionThunks";
import { DEFAULTS } from "../constants/sessionManagementConstants";

/**
 * Custom hook to initialize sessions data for a specific event
 * Sets up pagination and fetches initial session data
 */
export const useSessionInitialization = (
  eventId: string | number | undefined,
) => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!eventId) return;

    // Convert eventId to number if it's a string
    const numericEventId =
      typeof eventId === "string" ? Number(eventId) : eventId;

    // Initialize pagination request
    dispatch(
      setCurrentRequest({
        page: 1,
        pageSize: DEFAULTS.PAGE_SIZE,
        eventId: numericEventId,
      }),
    );

    // Fetch sessions for the event
    dispatch(
      fetchSessionsAction({
        eventId: numericEventId,
        page: 1,
        pageSize: DEFAULTS.PAGE_SIZE,
        searchTerm: "",
      }),
    );
  }, [dispatch, eventId]);
};
