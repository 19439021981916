import { useEffect, useCallback } from "react";
import { useAppDispatch } from "@/app/hooks";
import { setCurrentRequest } from "../../EventDirectory/slices/eventUISlice";
import { fetchEventsAction } from "../../EventDirectory/thunks/eventThunks";
import { useToast } from "@/hooks/useToast";

interface InitializeEventsOptions {
  pageSize: number;
  showErrorToast?: boolean;
  onError?: (error: unknown) => void;
}

export const useInitializeEvents = ({
  pageSize,
  showErrorToast = false,
  onError,
}: InitializeEventsOptions) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  // Memoize the fetch function to prevent recreating it on every render
  const fetchEvents = useCallback(async () => {
    try {
      dispatch(
        setCurrentRequest({
          page: 1,
          pageSize,
        }),
      );
      await dispatch(fetchEventsAction()).unwrap();
    } catch (error) {
      if (showErrorToast) {
        toast({
          title: "Error",
          description: "Failed to fetch events. Please try again.",
          variant: "destructive",
        });
      }
      onError?.(error);
    }
  }, [dispatch, pageSize, showErrorToast, onError, toast]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);
};
