/**
 * Maps API status values to UI status values
 * @param apiStatus - The status value from the API
 * @returns The corresponding UI status value
 */
export const mapApiStatusToUiStatus = (apiStatus: string): string => {
  const statusMap: Record<string, string> = {
    scheduled: "SCHEDULED",
    "in-progress": "IN_PROGRESS",
    completed: "COMPLETED",
    cancelled: "CANCELLED",
  };

  // First try to find a direct match in the map
  if (apiStatus in statusMap) {
    return statusMap[apiStatus];
  }

  // If not found, try to normalize the status and find a match
  const normalizedStatus = apiStatus.replace(/-/g, "_").toUpperCase();

  // Return the normalized status or default to SCHEDULED if not recognized
  return Object.values(statusMap).includes(normalizedStatus)
    ? normalizedStatus
    : "SCHEDULED";
};

/**
 * Maps UI status values to API status values
 * @param uiStatus - The status value from the UI
 * @returns The corresponding API status value
 */
export const mapUiStatusToApiStatus = (uiStatus: string): string => {
  const statusMap: Record<string, string> = {
    SCHEDULED: "scheduled",
    IN_PROGRESS: "in-progress",
    COMPLETED: "completed",
    CANCELLED: "cancelled",
  };

  // Try to find a direct match in the map
  if (uiStatus in statusMap) {
    return statusMap[uiStatus];
  }

  // If not found, try to normalize the status and find a match
  const normalizedStatus = uiStatus.replace(/_/g, "-").toLowerCase();

  // Return the normalized status or default to "scheduled" if not recognized
  return Object.values(statusMap).includes(normalizedStatus)
    ? normalizedStatus
    : "scheduled";
};
