import { ChangeEvent, useCallback } from "react";

interface UseERSearchActionsProps {
  onSearchChange: (value: string) => void;
}

/**
 * Hook for handling ERSearch component actions
 * @param onSearchChange Callback function to handle search value changes
 * @returns Object containing search action handlers
 */
export const useERSearchActions = ({
  onSearchChange,
}: UseERSearchActionsProps) => {
  /**
   * Handles input change events and calls the onSearchChange callback with the new value
   * @param event The change event from the input element
   */
  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onSearchChange(event.target.value);
    },
    [onSearchChange],
  );

  return {
    handleSearchChange,
  };
};
