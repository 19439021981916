export const DEFAULTS = {
  PAGE_SIZE: 12,
} as const;

export const getStarStyle = (isFavorited: boolean) => ({
  color: isFavorited ? "rgb(250 204 21)" : "currentColor",
});

export const COLORS = {
  STAR: {
    ACTIVE: "rgb(250 204 21)",
    DEFAULT: "currentColor",
  },
} as const;

export const TOAST_MESSAGES = {
  FETCH_ERROR: {
    title: "Error",
    description: "Failed to fetch events",
  },
  SUBMIT_ERROR: {
    title: "Error",
    description: "Failed to submit event",
  },
} as const;

export const PRICE_RANGE = {
  MIN: 0,
  MAX: 1000,
} as const;

export const FILTER_SECTIONS = [
  {
    title: "Categories",
    type: "categories",
    options: [
      { value: "Programming" },
      { value: "Design" },
      { value: "Business" },
      { value: "Marketing" },
      { value: "Music" },
    ],
  },
  {
    title: "Duration",
    type: "durations",
    options: [
      { value: "1-4 weeks" },
      { value: "1-3 months" },
      { value: "3-6 months" },
      { value: "6+ months" },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [12, 24, 36, 48, 60] as const;
