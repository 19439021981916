import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { SESSION_FORM } from "../../constants/sessionManagementConstants";
import { useSessionDialogAlerts } from "../../hooks/useSessionDialogAlerts";

interface SessionDialogAlertsProps {
  onResetConfirm: () => void;
  onUnsavedChangesConfirm: () => void;
}

export const SessionDialogAlerts: React.FC<SessionDialogAlertsProps> = ({
  onResetConfirm,
  onUnsavedChangesConfirm,
}) => {
  const {
    showUnsavedChangesDialog,
    showResetDialog,
    handleCancelClose,
    handleConfirmClose,
    handleCancelReset,
    handleConfirmReset,
    setUnsavedChangesDialog,
    setResetDialog,
  } = useSessionDialogAlerts({
    onResetConfirm,
    onUnsavedChangesConfirm,
  });

  return (
    <>
      <AlertDialog
        onOpenChange={(open) => setUnsavedChangesDialog(open)}
        open={!!showUnsavedChangesDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {SESSION_FORM.TITLES.unsavedChanges}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {SESSION_FORM.MESSAGES.unsavedChanges}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelClose}>
              {SESSION_FORM.BUTTON_TEXT.cancel}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmClose}>
              {SESSION_FORM.BUTTON_TEXT.discard}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        onOpenChange={(open) => setResetDialog(open)}
        open={!!showResetDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{SESSION_FORM.TITLES.resetForm}</AlertDialogTitle>
            <AlertDialogDescription>
              {SESSION_FORM.MESSAGES.resetForm}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelReset}>
              {SESSION_FORM.BUTTON_TEXT.cancel}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmReset}>
              {SESSION_FORM.BUTTON_TEXT.reset}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
