import React from "react";
import { Button } from "../../../../../components/ui/button";
import { Badge } from "../../../../../components/ui/badge";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover";
import { Filter, X } from "lucide-react";
import { hasActiveFilters } from "../utils/filterUtils";

export interface FilterOption {
  value: string;
  label?: string;
}

export interface FilterSection {
  title: string;
  type: string;
  options: FilterOption[];
}

export interface ERFiltersProps {
  sections: FilterSection[];
  selectedFilters: Record<string, string[]>;
  onToggleFilter: (type: string, value: string) => void;
  onClearFilters: () => void;
  buttonClassName?: string;
  disabled?: boolean;
}

const ERFilters: React.FC<ERFiltersProps> = ({
  sections,
  selectedFilters,
  onToggleFilter,
  onClearFilters,
  buttonClassName,
  disabled,
}) => {
  const activeFilters = hasActiveFilters(selectedFilters);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={buttonClassName}
          disabled={disabled}
          size="default"
          variant="outline"
        >
          <Filter className="mr-2 h-4 w-4" />
          Filters
          {activeFilters && (
            <Badge
              className="ml-2 rounded-sm px-1 font-normal lg:hidden"
              variant="secondary"
            >
              {Object.values(selectedFilters).reduce(
                (acc, curr) => acc + curr.length,
                0,
              )}
            </Badge>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80 bg-white">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">Filters</h3>
            {activeFilters && (
              <Button
                className="h-8 px-2 lg:px-3"
                onClick={onClearFilters}
                size="sm"
                variant="ghost"
              >
                Clear
              </Button>
            )}
          </div>

          <div className="space-y-4">
            {sections.map((section) => (
              <div className="space-y-2" key={section.type}>
                <h4 className="font-medium">{section.title}</h4>
                <div className="flex flex-wrap gap-2">
                  {section.options.map((option) => (
                    <Badge
                      className="capitalize cursor-pointer"
                      key={option.value}
                      onClick={() => onToggleFilter(section.type, option.value)}
                      variant={
                        selectedFilters[section.type]?.includes(option.value)
                          ? "default"
                          : "outline"
                      }
                    >
                      {option.label || option.value}
                      {selectedFilters[section.type]?.includes(
                        option.value,
                      ) && <X className="ml-1 h-3 w-3" />}
                    </Badge>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ERFilters;
