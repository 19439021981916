export type FilterType = "status";

export type StatusValue =
  | "SCHEDULED"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "CANCELLED";

/**
 * Type guard to check if a string is a valid FilterType
 * @param type - The string to check
 * @returns True if the string is a valid FilterType
 */
export const isFilterType = (type: string): type is FilterType => {
  return ["instructor", "status"].includes(type);
};

/**
 * Type guard to check if a string is a valid StatusValue
 * @param value - The string to check
 * @returns True if the string is a valid StatusValue
 */
export const isStatusValue = (value: string): value is StatusValue => {
  return ["SCHEDULED", "IN_PROGRESS", "COMPLETED", "CANCELLED"].includes(value);
};
