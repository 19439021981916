import { useAppSelector } from "@/app/hooks";
import {
  selectSelectedCategories,
  selectSelectedDurations,
} from "../../selectors/eventDirectorySelectors";
import ERFilters from "../../../shared/components/ERFilters";
import {
  FILTER_SECTIONS,
  PRICE_RANGE,
} from "../../constants/eventDirectoryConstants";
import { useEventDirectoryActions } from "../../hooks/useEventDirectoryActions";

export interface EventFilters {
  categories: string[];
  durations: string[];
  minPrice: number;
  maxPrice: number;
}

export interface EventDirectoryFiltersProps {
  onFilterChange: (filters: EventFilters) => void;
}

const EventDirectoryFilters: React.FC<EventDirectoryFiltersProps> = ({
  onFilterChange,
}) => {
  const selectedCategories = useAppSelector(selectSelectedCategories);
  const selectedDurations = useAppSelector(selectSelectedDurations);
  const { handleToggleFilter, handleClearFilters } = useEventDirectoryActions({
    page: 1,
    pageSize: 10,
  });

  return (
    <ERFilters
      onClearFilters={handleClearFilters((categories, durations) => {
        onFilterChange({
          categories,
          durations,
          minPrice: PRICE_RANGE.MIN,
          maxPrice: PRICE_RANGE.MAX,
        });
      })}
      onToggleFilter={handleToggleFilter(
        selectedCategories,
        selectedDurations,
        (categories, durations) => {
          onFilterChange({
            categories,
            durations,
            minPrice: PRICE_RANGE.MIN,
            maxPrice: PRICE_RANGE.MAX,
          });
        },
      )}
      sections={FILTER_SECTIONS}
      selectedFilters={{
        categories: selectedCategories,
        durations: selectedDurations,
      }}
    />
  );
};

export default EventDirectoryFilters;
