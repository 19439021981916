import axios from "../axiosInstance";
import { DeleteEventInstructorResponse } from "./types";

export const deleteEventInstructor = async (
  id: number,
): Promise<DeleteEventInstructorResponse> => {
  const response = await axios.delete<DeleteEventInstructorResponse>(
    `/api/private/event-instructors/${id}`,
  );
  return response.data;
};
