import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignProductsAction } from "../slices/stochasticCampaignProductsSlice";
import {
  SortingState,
  PaginationState,
  OnChangeFn,
  VisibilityState,
} from "@tanstack/react-table";
import { RootState } from "@/app/rootReducer";
import { CampaignProduct } from "@/api/fetchCampaignProducts/types";

export function useCampaignProducts() {
  const dispatch = useDispatch();
  const { campaignProducts, totalCount, loading, error } = useSelector(
    (state: RootState) => state.stochasticCampaignProducts,
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const [filters, setFilters] = useState<{ searchTerm?: string }>({});

  useEffect(() => {
    dispatch(fetchCampaignProductsAction());
  }, [dispatch]);

  const filteredData = useMemo(() => {
    if (!filters.searchTerm) return campaignProducts;

    const searchTerm = filters.searchTerm.toLowerCase();
    return campaignProducts.filter((product: CampaignProduct) => {
      return (
        product.name?.toLowerCase().includes(searchTerm) ||
        product.description?.toLowerCase().includes(searchTerm)
      );
    });
  }, [campaignProducts, filters.searchTerm]);

  const filteredCount = filteredData.length;

  const handlePaginationChange: OnChangeFn<PaginationState> = (
    updaterOrValue,
  ) => {
    setPagination((old) =>
      typeof updaterOrValue === "function"
        ? updaterOrValue(old)
        : updaterOrValue,
    );
  };

  const handleSortingChange: OnChangeFn<SortingState> = (updaterOrValue) => {
    setSorting((old) =>
      typeof updaterOrValue === "function"
        ? updaterOrValue(old)
        : updaterOrValue,
    );
  };

  const handleColumnVisibilityChange: OnChangeFn<VisibilityState> = (
    updaterOrValue,
  ) => {
    setColumnVisibility((old) =>
      typeof updaterOrValue === "function"
        ? updaterOrValue(old)
        : updaterOrValue,
    );
  };

  const handleFilterChange = (searchTerm: string) => {
    setFilters({ searchTerm });
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  };

  const paginatedData = useMemo(() => {
    const { pageIndex, pageSize } = pagination;
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return filteredData.slice(start, end);
  }, [filteredData, pagination]);

  return {
    campaignProducts: paginatedData,
    totalCount: filteredCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    filters,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleFilterChange,
  };
}
