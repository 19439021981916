import axios from "../axiosInstance";

export const fetchFavorites = async (): Promise<number[]> => {
  const response = await axios.get<{ data: { favorites: number[] } }>(
    "/api/private/events/favorites",
  );
  return response.data.data.favorites;
};

export const toggleFavorite = async (eventId: number): Promise<boolean> => {
  const response = await axios.post<{ data: { isFavorited: boolean } }>(
    `/api/private/events/${eventId}/favorite`,
  );
  return response.data.data.isFavorited;
};
