import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FetchCampaignProductsResponse,
  CampaignProduct,
} from "@/api/fetchCampaignProducts/types";
import { AppDispatch, AppThunk } from "@/app/store";
import { fetchCampaignProducts } from "@/api/fetchCampaignProducts/fetchCampaignProducts";

interface CampaignProductsState {
  campaignProducts: CampaignProduct[];
  totalCount: number;
  loading: boolean;
  error: string | null;
}

const initialState: CampaignProductsState = {
  campaignProducts: [],
  totalCount: 0,
  loading: false,
  error: null,
};

const stochasticCampaignProductsSlice = createSlice({
  name: "stochasticCampaignProducts",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setCampaignProductsData: (
      state,
      action: PayloadAction<{
        campaignProducts: CampaignProduct[];
        totalCount: number;
      }>,
    ) => {
      state.campaignProducts = action.payload.campaignProducts;
      state.totalCount = action.payload.totalCount;
    },
  },
});

export const { setLoading, setError, setCampaignProductsData } =
  stochasticCampaignProductsSlice.actions;

export const fetchCampaignProductsAction =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: FetchCampaignProductsResponse =
        await fetchCampaignProducts();

      const campaignProducts = response.data.campaignProducts || [];
      const totalCount = response.meta?.totalCount || 0;

      dispatch(
        setCampaignProductsData({
          campaignProducts,
          totalCount,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Failed to fetch stochastic campaignProducts"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export default stochasticCampaignProductsSlice.reducer;
