import { useAppSelector } from "@/app/hooks";
import { selectFavoritedEventsData } from "../selectors/eventDirectorySelectors";

/**
 * Custom hook to access favorited events data
 * @returns Object containing favoritedEvents, favorites, loading, and error
 */
export const useFavoritedEventsData = () => {
  return useAppSelector(selectFavoritedEventsData);
};
