"use client";

import React, { useState, useMemo, useEffect } from "react";
import { X } from "lucide-react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/components/ui/lib/utils";
import EntityPickerModal, {
  Entity,
  FetchEntitiesFn,
  CreateEntityFn,
} from "@/components/EntityPickerModal/EntityPickerModal";

interface EntityMultiSelectProps {
  /**
   * An array of entity objects currently selected: [{ id, name }, ...].
   */
  value: Entity[];

  /**
   * Called when user changes selection (either removing or picking items),
   * passing an updated array of entity objects.
   */
  onChange: (newValue: Entity[]) => void;

  /** Fetch function for searching entities. */
  fetchEntities: FetchEntitiesFn;

  /** Optional. If provided, user can create a new entity in the drawer. */
  createEntity?: CreateEntityFn;

  /** Labels used in the UI */
  entityNameSingular?: string;
  entityNamePlural?: string;
}

export function EntityMultiSelect({
  value,
  onChange,
  fetchEntities,
  createEntity,
  entityNameSingular,
  entityNamePlural,
}: EntityMultiSelectProps) {
  const [selectedEntities, setSelectedEntities] = useState<Entity[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  /**
   * Keep local `selectedEntities` in sync with the parent-supplied `value`.
   * If the parent form changes, we update accordingly.
   */
  useEffect(() => {
    setSelectedEntities(value);
  }, [value]);

  /**
   * Remove a single entity by ID and propagate the change up
   */
  const handleRemoveItem = (id: number) => {
    const newEntities = selectedEntities.filter((x) => x.id !== id);
    setSelectedEntities(newEntities);
    onChange(newEntities);
  };

  /**
   * Called when the user presses Confirm in the EntityPickerModal.
   * We update local state and call onChange(...) with the full array of objects.
   */
  const handleConfirm = (newSelected: Entity[]) => {
    setSelectedEntities(newSelected);
    onChange(newSelected);
    setModalOpen(false);
  };

  /**
   * For display: prepare an array of chip objects to show in badges
   */
  const chips = useMemo(
    () =>
      selectedEntities.map((ent) => ({
        id: ent.id,
        displayName: ent.name,
      })),
    [selectedEntities],
  );

  return (
    <div className="space-y-2">
      <div className="flex flex-wrap gap-2">
        {chips.map((chip) => (
          <Badge className="truncate flex items-center space-x-1" key={chip.id}>
            <span className="text-white">{chip.displayName}</span>
            <X
              className="h-3 w-3 cursor-pointer text-white"
              onClick={() => handleRemoveItem(chip.id)}
            />
          </Badge>
        ))}
      </div>

      <Button
        className={cn(
          "relative text-left justify-between px-3 py-2 rounded-md",
        )}
        onClick={() => setModalOpen(true)}
        type="button"
        variant="outline"
      >
        <div className="flex items-center space-x-2">
          <PlusIcon className="w-4 h-4" />
          <span>Select {entityNamePlural}...</span>
        </div>
      </Button>

      <EntityPickerModal
        createEntity={createEntity}
        entityNamePlural={entityNamePlural}
        entityNameSingular={entityNameSingular}
        fetchEntities={fetchEntities}
        /* We initialize the modal with the same array of entities that
           are currently selected in the form. */
        initialSelectedEntities={selectedEntities}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
      />
    </div>
  );
}
