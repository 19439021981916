import axios from "../axiosInstance";
import { CreateEventTypeRequest, CreateEventTypeResponse } from "./types";

export const createEventType = async (
  requestData: CreateEventTypeRequest,
): Promise<CreateEventTypeResponse> => {
  const response = await axios.post<CreateEventTypeResponse>(
    `/api/private/event-types/create`,
    requestData,
  );
  return response.data;
};
