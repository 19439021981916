import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchEvents } from "@/api/fetchEvents/fetchEventsApi";
import { FetchEventsResponse, ApiEvent } from "@/api/fetchEvents/types";
import { RootState } from "@/app/rootReducer";
import { AppDispatch } from "@/app/store";
import { EventFormData } from "../schemas/eventSchema";
import { createEvent } from "@/api/createEvent/createEventApi";
import { editEvent } from "@/api/editEvent/editEventApi";
import { copyEvent } from "@/api/copyEvent/copyEventApi";
import { deleteEvent } from "@/api/deleteEvent/deleteEventApi";
import { uploadEventFiles } from "@/api/uploadEventFiles/uploadEventFilesApi";
import { fetchEventFiles } from "@/api/fetchEventFiles/fetchEventFilesApi";
import { deleteEventFile } from "@/api/deleteEventFile/deleteEventFileApi";
import {
  setEvents,
  setLoading,
  setError,
  setTotalCount,
} from "../slices/eventManagementEventsSlice";
import {
  setEvents as setEventsInEventsSlice,
  setTotalCount as setTotalCountInEventsSlice,
} from "@/modules/eventRegistration/features/EventDirectory/slices/eventsSlice";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { CreateEventRequest } from "@/api/createEvent/types";
import { EditEventDTO } from "@/api/editEvent/types";
import { fetchEventTypes } from "@/api/fetchEventTypes/fetchEventTypesApi";
import { fetchEventInstructors } from "@/api/fetchEventInstructors/fetchEventInstructorsApi";
import { fetchEventCategories } from "@/api/fetchEventCategories/fetchEventCategoriesApi";
import { ApiEventType } from "@/api/fetchEventTypes/types";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";
import {
  setEventTypes,
  setLoadingEventTypes,
  setEventInstructors,
  setLoadingEventInstructors,
  setEventCategories,
  setLoadingEventCategories,
} from "../slices/eventManagementUISlice";
import { deleteEventType } from "@/api/deleteEventType/deleteEventTypeApi";
import { deleteEventInstructor } from "@/api/deleteEventInstructor/deleteEventInstructorApi";
import { deleteEventCategory } from "@/api/deleteEventCategory/deleteEventCategoryApi";
import { setShowDeleteTypeDialog } from "../slices/eventManagementUISlice";
import { setShowDeleteInstructorDialog } from "../slices/eventManagementUISlice";
import { setShowDeleteCategoryDialog } from "../slices/eventManagementUISlice";

// Fetch events thunk
export const fetchEventsAction = createAsyncThunk<
  FetchEventsResponse,
  void,
  { state: RootState; rejectValue: string }
>(
  "eventManagement/fetchEvents",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const state = getState() as RootState;
      const currentRequest = state.eventManagementUI.currentRequest;
      const response = await fetchEvents(currentRequest);

      // Update both the eventManagementEvents slice and the events slice
      dispatch(setEvents(response.data.items));

      // Also update the events slice to keep both slices in sync
      // This ensures that both EventManagement and EventDirectory components show the same data
      dispatch(setEventsInEventsSlice(response.data.items));

      // Update total count in both slices
      const totalCount = response.meta?.totalCount || 0;
      dispatch(setTotalCount(totalCount));
      dispatch(setTotalCountInEventsSlice(totalCount));

      dispatch(setLoading(false));
      return response;
    } catch (error) {
      dispatch(setLoading(false));
      if (error instanceof Error) {
        dispatch(setError(error.message));
        return rejectWithValue(error.message);
      }
      dispatch(setError("An unexpected error occurred"));
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

// Create event thunk
export const createEventAction = createAsyncThunk<
  ApiEvent,
  CreateEventRequest,
  { state: RootState; rejectValue: string }
>("eventManagement/createEvent", async (eventData, { rejectWithValue }) => {
  try {
    const requestData: CreateEventRequest = {
      ...eventData,
      eventTypeId: eventData.eventTypeId,
      eventInstructorId: eventData.eventInstructorId,
    };

    const response = await createEvent(requestData);

    // Convert the response data to ApiEvent format
    const apiEvent: ApiEvent = {
      id: response.data.id || 0,
      eventCode: eventData.eventCode,
      eventName: response.data.eventName || "",
      eventDescription: response.data.eventDescription || "",
      eventPrice: response.data.eventPrice || 0,
      imageUrl: response.data.imageUrl,
      eventTypeId: response.data.eventTypeId,
      eventCategoryId: response.data.eventCategoryId,
      eventInstructorId: response.data.eventInstructorId,
      eventType: eventData.eventTypeId ? undefined : undefined,
      eventInstructor: response.data.eventInstructor,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    return apiEvent;
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An unexpected error occurred",
    );
  }
});

// Edit event thunk
interface EditEventActionPayload {
  eventId: number;
  editEventDTO: EditEventDTO;
  callback?: (updatedEvent: ApiEvent) => void;
}

export const editEventAction = createAsyncThunk<
  ApiEvent,
  EditEventActionPayload,
  { state: RootState; rejectValue: string }
>(
  "eventManagement/editEvent",
  async ({ eventId, editEventDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await editEvent(eventId, editEventDTO);
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);

// Duplicate event thunk
interface DuplicateEventActionPayload {
  eventId: number;
  eventCode: string;
  eventName: string;
  eventTypeId: number | null;
  eventInstructorId: number | null;
  eventCategoryId: number | null;
  callback?: (newEvent: ApiEvent) => void;
}

export const duplicateEventAction = createAsyncThunk<
  ApiEvent,
  DuplicateEventActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/duplicateEvent",
  async (
    {
      eventId,
      eventCode,
      eventName,
      eventTypeId,
      eventInstructorId,
      eventCategoryId,
      callback,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await copyEvent(eventId, {
        eventCode,
        eventName,
        eventTypeId,
        eventInstructorId,
        eventCategoryId,
      });

      if (callback && response.data) {
        callback(response.data);
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);

// Delete event thunk
export const deleteEventAction = createAsyncThunk<
  number,
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("eventManagement/deleteEvent", async (eventId, { rejectWithValue }) => {
  try {
    await deleteEvent(eventId);
    return eventId;
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An unexpected error occurred",
    );
  }
});

// Upload event files thunk
interface UploadEventFilesActionPayload {
  eventId: number;
  files: File[];
  fileType?: "other" | string;
}

export const uploadEventFilesAction = createAsyncThunk<
  ApiEventFile[],
  UploadEventFilesActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/uploadEventFiles",
  async ({ eventId, files, fileType = "other" }, { rejectWithValue }) => {
    try {
      const response = await uploadEventFiles(eventId, files, fileType);

      // Convert EventFile[] to ApiEventFile[]
      const apiEventFiles: ApiEventFile[] = response.data.map((file) => ({
        id: file.id,
        fileName: file.fileName,
        fileType: file.fileType,
        fileSize: file.fileSize || 0,
        eventId: eventId,
        fileUrl: file.fileUrl,
        createdAt: file.createdAt,
        updatedAt: file.updatedAt,
      }));

      return apiEventFiles;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);

// Fetch event files thunk
export const fetchEventFilesAction = createAsyncThunk<
  ApiEventFile[],
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("eventManagement/fetchEventFiles", async (eventId, { rejectWithValue }) => {
  try {
    const response = await fetchEventFiles({ eventId });
    return response.data.items;
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An unexpected error occurred",
    );
  }
});

// Delete event file thunk
export const deleteEventFileAction = createAsyncThunk<
  number,
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("eventManagement/deleteEventFile", async (fileId, { rejectWithValue }) => {
  try {
    await deleteEventFile(fileId);
    return fileId;
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An unexpected error occurred",
    );
  }
});

// Fetch event types thunk
export const fetchEventTypesAction = createAsyncThunk<
  ApiEventType[],
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/fetchEventTypes",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingEventTypes(true));
      const response = await fetchEventTypes({});

      // Check if the response has the expected structure
      if (!response.data || !Array.isArray(response.data.items)) {
        console.error("Unexpected response structure:", response);
        return rejectWithValue("Unexpected response structure");
      }

      // Dispatch the action to update the state
      dispatch(setEventTypes(response.data.items));

      return response.data.items;
    } catch (error) {
      console.error("Error fetching event types:", error);
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    } finally {
      dispatch(setLoadingEventTypes(false));
    }
  },
);

// Fetch event instructors thunk
export const fetchEventInstructorsAction = createAsyncThunk<
  ApiEventInstructor[],
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/fetchEventInstructors",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingEventInstructors(true));
      const response = await fetchEventInstructors({});

      // Check if the response has the expected structure
      if (!response.data || !Array.isArray(response.data.items)) {
        console.error("Unexpected response structure:", response);
        return rejectWithValue("Unexpected response structure");
      }

      // Dispatch the action to update the state
      dispatch(setEventInstructors(response.data.items));

      return response.data.items;
    } catch (error) {
      console.error("Error fetching event instructors:", error);
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    } finally {
      dispatch(setLoadingEventInstructors(false));
    }
  },
);

// Fetch event categories thunk
export const fetchEventCategoriesAction = createAsyncThunk<
  ApiEventCategory[],
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/fetchEventCategories",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingEventCategories(true));
      const response = await fetchEventCategories({});

      // Check if the response has the expected structure
      if (!response.data || !Array.isArray(response.data.items)) {
        console.error("Unexpected response structure:", response);
        return rejectWithValue("Unexpected response structure");
      }

      // Dispatch the action to update the state
      dispatch(setEventCategories(response.data.items));

      return response.data.items;

      return response.data.items;
    } catch (error) {
      console.error("Error fetching event categories:", error);
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    } finally {
      dispatch(setLoadingEventCategories(false));
    }
  },
);

// Delete event type thunk
export const deleteEventTypeAction = createAsyncThunk<
  { id: number },
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/deleteEventType",
  async (eventTypeId, { dispatch, rejectWithValue }) => {
    try {
      await deleteEventType(eventTypeId);
      dispatch(setShowDeleteTypeDialog(false));
      return { id: eventTypeId };
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);

// Delete event instructor thunk
export const deleteEventInstructorAction = createAsyncThunk<
  { id: number },
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/deleteEventInstructor",
  async (instructorId, { dispatch, rejectWithValue }) => {
    try {
      await deleteEventInstructor(instructorId);
      dispatch(setShowDeleteInstructorDialog(false));
      return { id: instructorId };
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);

// Delete event category thunk
export const deleteEventCategoryAction = createAsyncThunk<
  { id: number },
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/deleteEventCategory",
  async (categoryId, { dispatch, rejectWithValue }) => {
    try {
      await deleteEventCategory(categoryId);
      dispatch(setShowDeleteCategoryDialog(false));
      return { id: categoryId };
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);

// Handle event submission (create or edit)
interface HandleEventSubmissionPayload {
  mode: "create" | "edit";
  data: EventFormData;
  eventId?: number;
  selectedFiles: File[];
}

export const handleEventSubmission = createAsyncThunk<
  ApiEvent,
  HandleEventSubmissionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/handleEventSubmission",
  async (
    { mode, data, eventId, selectedFiles },
    { dispatch, rejectWithValue },
  ) => {
    try {
      let event: ApiEvent;

      // Create or edit the event
      if (mode === "create") {
        const createResponse = await dispatch(
          createEventAction({
            eventCode: data.eventCode,
            eventName: data.eventName,
            eventDescription: data.eventDescription,
            eventPrice: data.eventPrice,
            eventTypeId: data.eventTypeId,
            eventInstructorId: data.eventInstructorId,
            eventCategoryId: data.eventCategoryId,
            isPublished: data.isPublished || false,
            hideFromCalendar: data.hideFromCalendar || false,
            hideFromCatalog: data.hideFromCatalog || false,
            companyUuid: data.companyUuid || "",
            imageUrl: data.imageUrl || null,
          }),
        ).unwrap();
        event = createResponse;
      } else if (mode === "edit" && eventId) {
        const editResponse = await dispatch(
          editEventAction({
            eventId,
            editEventDTO: {
              eventCode: data.eventCode,
              eventName: data.eventName,
              eventDescription: data.eventDescription,
              eventPrice: data.eventPrice,
              eventTypeId: data.eventTypeId,
              eventInstructorId: data.eventInstructorId,
              eventCategoryId: data.eventCategoryId,
              isPublished: data.isPublished || false,
              imageUrl: data.imageUrl || null,
            },
          }),
        ).unwrap();
        event = editResponse;
      } else {
        throw new Error("Invalid mode or missing eventId for edit");
      }

      // Upload files if there are any
      if (selectedFiles.length > 0) {
        await dispatch(
          uploadEventFilesAction({
            eventId: event.id,
            files: selectedFiles,
          }),
        ).unwrap();
      }

      // Refresh the events list
      await dispatch(fetchEventsAction()).unwrap();

      return event;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);
