/**
 * Utility functions for event-related operations
 */

/**
 * Converts an event ID string to a number, returning 0 if the ID is falsy
 * @param eventId The event ID string to convert
 * @returns The event ID as a number, or 0 if the ID is falsy
 */
export const getEventIdNumber = (eventId?: string): number => {
  return eventId ? Number(eventId) : 0;
};
