import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@/app/rootReducer";
import { AppDispatch } from "@/app/store";
import { ApiEventSession } from "@/api/fetchEventSessions/types";
import { fetchEventSessions } from "@/api/fetchEventSessions/fetchEventSessionsApi";
import { createEventSession } from "@/api/createEventSession/createEventSessionApi";
import { editEventSession } from "@/api/editEventSession/editEventSessionApi";
import { deleteEventSession } from "@/api/deleteEventSession/deleteEventSessionApi";
import { CreateEventSessionResponse } from "@/api/createEventSession/types";
import { DeleteEventSessionResponse } from "@/api/deleteEventSession/types";
import { EventSessionFormData } from "../schemas/eventSessionSchema";
import {
  SESSION_FORM,
  TOAST_MESSAGES,
  SESSION_STATUS_API_MAPPING,
  DEFAULTS,
} from "../constants/sessionManagementConstants";
import { formatDateForInput } from "../utils/dateUtils";

// Fetch sessions thunk with pagination
export const fetchSessionsAction = createAsyncThunk<
  { items: ApiEventSession[]; total: number },
  {
    eventId: number;
    page?: number;
    pageSize?: number;
    searchTerm?: string;
    sortBy?: string;
    sortOrder?: "ASC" | "DESC";
    status?: string;
  },
  { state: RootState; rejectValue: { title: string; description: string } }
>(
  "sessions/fetchSessions",
  async (
    {
      eventId,
      page = 1,
      pageSize = DEFAULTS.PAGE_SIZE,
      searchTerm,
      sortBy,
      sortOrder,
      status,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await fetchEventSessions({
        eventId,
        page,
        pageSize,
        searchTerm,
        sortBy,
        sortOrder,
        status,
      });

      // Check if the response has the expected structure
      if (!response || !response.data || !response.data.items) {
        return rejectWithValue({
          title: "API Error",
          description: "Unexpected response format from API",
        });
      }

      // Transform the response to match what our Redux store expects
      return {
        items: response.data.items,
        total: response.data.meta.totalCount,
      };
    } catch (error) {
      console.error("Error in fetchSessionsAction:", error);
      return rejectWithValue({
        title: TOAST_MESSAGES.FETCH_ERROR.title,
        description: TOAST_MESSAGES.FETCH_ERROR.description,
      });
    }
  },
);

// Edit session thunk
interface EditSessionActionPayload {
  sessionId: number;
  editSessionDTO: {
    eventId: number;
    eventName: string;
    startDate: string;
    endDate: string;
    maxEnrollments: number;
    virtualLink: string | null;
    notes: string | null;
    status: string;
  };
  callback?: (updatedSession: ApiEventSession) => void;
}

export const editSessionAction = createAsyncThunk<
  ApiEventSession,
  EditSessionActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "sessions/editSession",
  async ({ sessionId, editSessionDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await editEventSession(sessionId, editSessionDTO);
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

// Create session thunk
interface EventSessionDTO {
  eventId: number;
  eventName: string;
  startDate: string;
  endDate: string;
  maxEnrollments: number;
  virtualLink: string | null;
  notes: string | null;
  status: string;
}

interface CreateSessionActionPayload {
  createSessionDTO: EventSessionDTO;
  callback?: (newSession: ApiEventSession) => void;
}

export const createSessionAction = createAsyncThunk<
  CreateEventSessionResponse,
  CreateSessionActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "sessions/createSession",
  async ({ createSessionDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await createEventSession(
        createSessionDTO.eventId,
        createSessionDTO,
      );
      if (callback) {
        callback(response.data);
      }
      return response;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Failed to create session",
      );
    }
  },
);

// Delete session thunk
export const deleteSessionAction = createAsyncThunk<
  DeleteEventSessionResponse["data"],
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("sessions/deleteSession", async (sessionId, { rejectWithValue }) => {
  try {
    const response = await deleteEventSession(sessionId);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Duplicate session thunk
interface DuplicateSessionActionPayload {
  sessionId: number;
  eventId: number;
  eventName: string;
  startDate: string;
  endDate: string;
  maxEnrollments: number;
  virtualLink: string | null;
  notes: string | null;
  status: string;
  callback?: (newSession: ApiEventSession) => void;
}

export const duplicateSessionAction = createAsyncThunk<
  ApiEventSession,
  DuplicateSessionActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "sessions/duplicateSession",
  async (
    {
      sessionId,
      eventId,
      eventName,
      startDate,
      endDate,
      maxEnrollments,
      virtualLink,
      notes,
      status,
      callback,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await createEventSession(sessionId, {
        eventId,
        eventName,
        startDate,
        endDate,
        maxEnrollments,
        virtualLink,
        notes,
        status,
      });
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch {
      return rejectWithValue("Failed to duplicate session");
    }
  },
);

export const handleSessionSubmission = createAsyncThunk<
  ApiEventSession,
  { mode: "create" | "edit"; data: EventSessionFormData },
  { state: RootState; dispatch: AppDispatch; rejectValue: string }
>("sessions/handleSubmission", async ({ mode, data }, { dispatch }) => {
  if (!data.eventId) throw new Error("Event ID is required");

  if (mode === "create") {
    const createDTO = {
      eventId: data.eventId,
      eventName: data.eventName || "",
      startDate: data.startDate,
      endDate: data.endDate,
      maxEnrollments: data.maxEnrollments,
      virtualLink: data.virtualLink,
      notes: data.notes,
      status:
        SESSION_STATUS_API_MAPPING[
          data.status as keyof typeof SESSION_STATUS_API_MAPPING
        ] || data.status,
    };

    const response = await dispatch(
      createSessionAction({
        createSessionDTO: createDTO,
      }),
    ).unwrap();
    return response.data;
  }

  if (mode === "edit" && data.id) {
    const editDTO = {
      eventId: data.eventId,
      eventName: data.eventName || "",
      startDate: data.startDate,
      endDate: data.endDate,
      maxEnrollments: data.maxEnrollments,
      virtualLink: data.virtualLink,
      notes: data.notes,
      status:
        SESSION_STATUS_API_MAPPING[
          data.status as keyof typeof SESSION_STATUS_API_MAPPING
        ] || data.status,
    };

    const response = await dispatch(
      editSessionAction({
        sessionId: data.id,
        editSessionDTO: editDTO,
      }),
    ).unwrap();
    return response;
  }

  throw new Error("Invalid mode or missing session ID");
});

export const initializeSessionDialog = createAsyncThunk<
  EventSessionFormData | typeof SESSION_FORM.DEFAULT_VALUES,
  { mode: "create" | "edit"; initialData?: EventSessionFormData },
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/initializeDialog",
  async ({ mode, initialData }, { rejectWithValue }) => {
    try {
      if (mode === "edit" && initialData) {
        // Format dates for datetime-local input
        return {
          ...initialData,
          startDate: formatDateForInput(initialData.startDate),
          endDate: formatDateForInput(initialData.endDate),
        };
      }

      return SESSION_FORM.DEFAULT_VALUES;
    } catch (error) {
      console.error("Dialog initialization failed:", error);
      return rejectWithValue("Failed to initialize dialog");
    }
  },
);
