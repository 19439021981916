import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchEventsRequest } from "@/api/fetchEvents/types";
import { ApiEventType } from "@/api/fetchEventTypes/types";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { ApiEvent } from "@/api/fetchEvents/types"; // Added import statement for ApiEvent

// Define the state shape for UI-related state specific to Event Management
interface EventManagementUIState {
  currentRequest: FetchEventsRequest;
  showDeleteTypeDialog: boolean;
  showAddEventType: boolean;
  editingEventType: ApiEventType | null;
  showAddEventInstructor: boolean;
  editingEventInstructor: ApiEventInstructor | null;
  showDeleteInstructorDialog: boolean;
  showAddEventCategory: boolean;
  editingEventCategory: ApiEventCategory | null;
  showDeleteCategoryDialog: boolean;
  isSubmitting: boolean;
  submitError: string | null;
  showUnsavedChangesDialog: boolean;
  showResetDialog: boolean;
  eventTypes: ApiEventType[];
  isLoadingEventTypes: boolean;
  eventInstructors: ApiEventInstructor[];
  isLoadingEventInstructors: boolean;
  eventCategories: ApiEventCategory[];
  isLoadingEventCategories: boolean;
  selectedFiles: File[];
  existingFiles: ApiEventFile[];
  showEventDialog: boolean;
  eventToEdit: ApiEvent | null;
}

const initialState: EventManagementUIState = {
  currentRequest: {
    page: 1,
    pageSize: 10,
    searchTerm: "",
    sortBy: undefined,
    sortOrder: undefined,
  },
  showDeleteTypeDialog: false,
  showAddEventType: false,
  editingEventType: null,
  showAddEventInstructor: false,
  editingEventInstructor: null,
  showDeleteInstructorDialog: false,
  showAddEventCategory: false,
  editingEventCategory: null,
  showDeleteCategoryDialog: false,
  isSubmitting: false,
  submitError: null,
  showUnsavedChangesDialog: false,
  showResetDialog: false,
  eventTypes: [],
  isLoadingEventTypes: false,
  eventInstructors: [],
  isLoadingEventInstructors: false,
  eventCategories: [],
  isLoadingEventCategories: false,
  selectedFiles: [],
  existingFiles: [],
  showEventDialog: false, // Added initial value for showEventDialog
  eventToEdit: null, // Added initial value for eventToEdit
};

const eventManagementUISlice = createSlice({
  name: "eventManagementUI",
  initialState,
  reducers: {
    setCurrentRequest(
      state,
      action: PayloadAction<Partial<FetchEventsRequest>>,
    ) {
      state.currentRequest = {
        ...state.currentRequest,
        ...action.payload,
      };
    },
    resetPagination(state) {
      state.currentRequest.page = 1;
    },
    setShowDeleteTypeDialog(state, action: PayloadAction<boolean>) {
      state.showDeleteTypeDialog = action.payload;
    },
    setShowAddEventType(state, action: PayloadAction<boolean>) {
      state.showAddEventType = action.payload;
    },
    setEditingEventType(state, action: PayloadAction<ApiEventType | null>) {
      state.editingEventType = action.payload;
    },
    setShowAddEventInstructor(state, action: PayloadAction<boolean>) {
      state.showAddEventInstructor = action.payload;
    },
    setEditingEventInstructor(
      state,
      action: PayloadAction<ApiEventInstructor | null>,
    ) {
      state.editingEventInstructor = action.payload;
    },
    setShowDeleteInstructorDialog(state, action: PayloadAction<boolean>) {
      state.showDeleteInstructorDialog = action.payload;
    },
    setShowAddEventCategory(state, action: PayloadAction<boolean>) {
      state.showAddEventCategory = action.payload;
    },
    setEditingEventCategory(
      state,
      action: PayloadAction<ApiEventCategory | null>,
    ) {
      state.editingEventCategory = action.payload;
    },
    setShowDeleteCategoryDialog(state, action: PayloadAction<boolean>) {
      state.showDeleteCategoryDialog = action.payload;
    },
    setSubmitting(state, action: PayloadAction<boolean>) {
      state.isSubmitting = action.payload;
    },
    setSubmitError(state, action: PayloadAction<string | null>) {
      state.submitError = action.payload;
    },
    setUnsavedChangesDialog(state, action: PayloadAction<boolean>) {
      state.showUnsavedChangesDialog = action.payload;
    },
    setResetDialog(state, action: PayloadAction<boolean>) {
      state.showResetDialog = action.payload;
    },
    setEventTypes(state, action: PayloadAction<ApiEventType[]>) {
      state.eventTypes = action.payload;
    },
    setLoadingEventTypes(state, action: PayloadAction<boolean>) {
      state.isLoadingEventTypes = action.payload;
    },
    setEventInstructors(state, action: PayloadAction<ApiEventInstructor[]>) {
      state.eventInstructors = action.payload;
    },
    setLoadingEventInstructors(state, action: PayloadAction<boolean>) {
      state.isLoadingEventInstructors = action.payload;
    },
    setEventCategories(state, action: PayloadAction<ApiEventCategory[]>) {
      state.eventCategories = action.payload;
    },
    setLoadingEventCategories(state, action: PayloadAction<boolean>) {
      state.isLoadingEventCategories = action.payload;
    },
    setSelectedFiles(state, action: PayloadAction<File[]>) {
      state.selectedFiles = action.payload;
    },
    setExistingFiles(state, action: PayloadAction<ApiEventFile[]>) {
      state.existingFiles = action.payload;
    },
    setShowEventDialog(state, action: PayloadAction<boolean>) {
      state.showEventDialog = action.payload;
    },
    setEventToEdit(state, action: PayloadAction<ApiEvent | null>) {
      state.eventToEdit = action.payload;
    },
    updateEventDialog(
      state,
      action: PayloadAction<{ show: boolean; event: ApiEvent | null }>,
    ) {
      state.showEventDialog = action.payload.show;
      state.eventToEdit = action.payload.event;
    },
  },
});

// Export actions
export const {
  setCurrentRequest,
  resetPagination,
  setShowDeleteTypeDialog,
  setShowAddEventType,
  setEditingEventType,
  setShowAddEventInstructor,
  setEditingEventInstructor,
  setShowDeleteInstructorDialog,
  setShowAddEventCategory,
  setEditingEventCategory,
  setShowDeleteCategoryDialog,
  setSubmitting,
  setSubmitError,
  setUnsavedChangesDialog,
  setResetDialog,
  setEventTypes,
  setLoadingEventTypes,
  setEventInstructors,
  setLoadingEventInstructors,
  setEventCategories,
  setLoadingEventCategories,
  setSelectedFiles,
  setExistingFiles,
  setShowEventDialog,
  setEventToEdit,
  updateEventDialog,
} = eventManagementUISlice.actions;

export default eventManagementUISlice.reducer;
