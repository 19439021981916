import { createEvent } from "@/api/createEvent/createEventApi";
import { editEvent } from "@/api/editEvent/editEventApi";
import { copyEvent } from "@/api/copyEvent/copyEventApi";
import { editEventType } from "@/api/editEventType/editEventTypeApi";
import { deleteEvent } from "@/api/deleteEvent/deleteEventApi";
import { fetchEvents } from "@/api/fetchEvents/fetchEventsApi";
import {
  CreateEventRequest,
  CreateEventResponse,
} from "@/api/createEvent/types";
import { EditEventDTO } from "@/api/editEvent/types";
import { DeleteEventResponse } from "@/api/deleteEvent/types";
import { FetchEventsResponse, ApiEvent } from "@/api/fetchEvents/types";
import { RootState } from "@/app/rootReducer";
import { AppDispatch } from "@/app/store";
import { fetchEventTypes } from "@/api/fetchEventTypes/fetchEventTypesApi";
import {
  setDialogEventTypes,
  setDialogLoadingEventInstructors,
  setDialogLoadingEventTypes,
  setDialogEventInstructors,
  setDialogLoadingEventCategories,
  setDialogEventCategories,
  setDialogSubmitting,
  setDialogFormData,
} from "../slices/eventsSlice";
import { setCurrentRequest } from "../slices/eventUISlice";
import { ApiEventType } from "@/api/fetchEventTypes/types";
import { createEventType } from "@/api/createEventType/createEventTypeApi";
import { deleteEventType } from "@/api/deleteEventType/deleteEventTypeApi";
import { DeleteEventTypeResponse } from "@/api/deleteEventType/types";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";
import { createEventInstructor } from "@/api/createEventInstructor/createEventInstructorApi";
import { deleteEventInstructor } from "@/api/deleteEventInstructor/deleteEventInstructorApi";
import { DeleteEventInstructorResponse } from "@/api/deleteEventInstructor/types";
import { editEventInstructor } from "@/api/editEventInstructor/editEventInstructorApi";
import { fetchEventInstructors } from "@/api/fetchEventInstructors/fetchEventInstructorsApi";
import {
  UploadEventFilesResponse,
  FileType,
} from "@/api/uploadEventFiles/types";
import { uploadEventFiles } from "@/api/uploadEventFiles/uploadEventFilesApi";
import { fetchEventFiles } from "@/api/fetchEventFiles/fetchEventFilesApi";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { DeleteEventFileResponse } from "@/api/deleteEventFile/types";
import { deleteEventFile } from "@/api/deleteEventFile/deleteEventFileApi";
import { EventFormData } from "@/modules/eventRegistration/features/EventManagement/schemas/eventSchema";
import { EVENT_FORM } from "@/modules/eventRegistration/features/EventManagement/constants/eventManagementConstants";
import { DEFAULTS } from "../constants/eventDirectoryConstants";
import { removeDialogExistingFile } from "../slices/eventsSlice";
import { fetchEventCategories } from "@/api/fetchEventCategories/fetchEventCategoriesApi";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";
import { editEventCategory } from "@/api/editEventCategory/editEventCategoryApi";
import { createEventCategory } from "@/api/createEventCategory/createEventCategoryApi";
import { deleteEventCategory } from "@/api/deleteEventCategory/deleteEventCategoryApi";
import { setShowDeleteCategoryDialog } from "../slices/eventsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Fetch events thunk
export const fetchEventsAction = createAsyncThunk<
  FetchEventsResponse,
  void,
  { state: RootState; rejectValue: string }
>("events/fetchEvents", async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const currentRequest = state.eventUI.currentRequest;
    const response = await fetchEvents(currentRequest);

    return response;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Create event thunk
export const createEventAction = createAsyncThunk<
  CreateEventResponse["data"],
  CreateEventRequest,
  { rejectValue: string }
>("events/createEvent", async (eventData, { rejectWithValue }) => {
  try {
    // Keep eventTypeId as is without any transformation
    const requestData: CreateEventRequest = {
      ...eventData,
      eventTypeId: eventData.eventTypeId,
      eventInstructorId: eventData.eventInstructorId,
    };

    const response = await createEvent(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An unexpected error occurred",
    );
  }
});

// Edit event thunk
interface EditEventActionPayload {
  eventId: number;
  editEventDTO: EditEventDTO;
  callback?: (updatedEvent: ApiEvent) => void;
}

export const editEventAction = createAsyncThunk<
  ApiEvent,
  EditEventActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/editEvent",
  async ({ eventId, editEventDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await editEvent(eventId, editEventDTO);
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

// Duplicate event thunk
interface DuplicateEventActionPayload {
  eventId: number;
  eventCode: string;
  eventName: string;
  eventTypeId: number | null;
  eventInstructorId: number | null;
  eventCategoryId: number | null;
  callback?: (newEvent: ApiEvent) => void;
}

export const duplicateEventAction = createAsyncThunk<
  ApiEvent,
  DuplicateEventActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/duplicateEvent",
  async (
    {
      eventId,
      eventCode,
      eventName,
      eventTypeId,
      eventInstructorId,
      eventCategoryId,
      callback,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await copyEvent(eventId, {
        eventCode,
        eventName,
        eventTypeId: eventTypeId ?? null,
        eventInstructorId: eventInstructorId ?? null,
        eventCategoryId: eventCategoryId ?? null,
      });
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

// Delete event thunk
export const deleteEventAction = createAsyncThunk<
  DeleteEventResponse["data"],
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("events/deleteEvent", async (eventId, { rejectWithValue }) => {
  try {
    const response = await deleteEvent(eventId);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Create event with company check thunk
export const createEventWithCompanyCheckAction = createAsyncThunk<
  CreateEventResponse["data"],
  Omit<
    CreateEventRequest,
    "companyUuid" | "hideFromCalendar" | "hideFromCatalog"
  >,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/createEventWithCompany",
  async (eventData, { dispatch, rejectWithValue }) => {
    try {
      const companyUuid = localStorage.getItem("selectedCompanyUuid");
      if (!companyUuid) {
        return rejectWithValue("No company selected");
      }

      // Keep the original eventTypeId without transformation
      const fullEventData: CreateEventRequest = {
        ...eventData,
        companyUuid,
        eventTypeId: eventData.eventTypeId,
        eventInstructorId: eventData.eventInstructorId,
        hideFromCalendar: false,
        hideFromCatalog: false,
        isPublished: true,
      };

      const result = await dispatch(createEventAction(fullEventData)).unwrap();
      return result;
    } catch (error) {
      console.error("createEventWithCompanyCheckAction - Error:", error);
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);

export const fetchEventTypesAction = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("events/fetchEventTypes", async (_, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setDialogLoadingEventTypes(true));
    const response = await fetchEventTypes({});
    dispatch(setDialogEventTypes(response.data.items));
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  } finally {
    dispatch(setDialogLoadingEventTypes(false));
  }
});

interface EditEventTypeActionPayload {
  eventTypeId: number;
  editEventTypeDTO: {
    name: string;
    description: string | null;
    isActive: boolean;
  };
  callback?: (updatedEventType: ApiEventType) => void;
}

export const editEventTypeAction = createAsyncThunk<
  ApiEventType,
  EditEventTypeActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/editEventType",
  async ({ eventTypeId, editEventTypeDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await editEventType(eventTypeId, editEventTypeDTO);
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

interface CreateEventTypeDTO {
  name: string;
  description: string | null;
  isActive: boolean;
}

interface CreateEventTypeActionPayload {
  createEventTypeDTO: CreateEventTypeDTO;
  callback?: (newEventType: ApiEventType) => void;
}

export const createEventTypeAction = createAsyncThunk<
  ApiEventType,
  CreateEventTypeActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/createEventType",
  async ({ createEventTypeDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await createEventType(createEventTypeDTO);
      if (callback) {
        callback(response.data.eventType);
      }
      return response.data.eventType;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

export const deleteEventTypeAction = createAsyncThunk<
  DeleteEventTypeResponse["data"],
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("events/deleteEventType", async (eventTypeId, { rejectWithValue }) => {
  try {
    const response = await deleteEventType(eventTypeId);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Add these interfaces
interface CreateEventInstructorDTO {
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
}

interface CreateEventInstructorActionPayload {
  createEventInstructorDTO: CreateEventInstructorDTO;
  callback?: (newInstructor: ApiEventInstructor) => void;
}

export const createEventInstructorAction = createAsyncThunk<
  ApiEventInstructor,
  CreateEventInstructorActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/createEventInstructor",
  async ({ createEventInstructorDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await createEventInstructor(createEventInstructorDTO);
      if (callback) {
        callback(response.data.eventInstructor);
      }
      return response.data.eventInstructor;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

export const deleteEventInstructorAction = createAsyncThunk<
  DeleteEventInstructorResponse["data"],
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("events/deleteEventInstructor", async (instructorId, { rejectWithValue }) => {
  try {
    const response = await deleteEventInstructor(instructorId);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

interface EditEventInstructorActionPayload {
  instructorId: number;
  editEventInstructorDTO: {
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
  };
  callback?: (updatedInstructor: ApiEventInstructor) => void;
}

export const editEventInstructorAction = createAsyncThunk<
  ApiEventInstructor,
  EditEventInstructorActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/editEventInstructor",
  async (
    { instructorId, editEventInstructorDTO, callback },
    { rejectWithValue },
  ) => {
    try {
      const response = await editEventInstructor(
        instructorId,
        editEventInstructorDTO,
      );
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

export const fetchEventInstructorsAction = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("events/fetchEventInstructors", async (_, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setDialogLoadingEventInstructors(true));
    const response = await fetchEventInstructors({});
    dispatch(setDialogEventInstructors(response.data.items));
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  } finally {
    dispatch(setDialogLoadingEventInstructors(false));
  }
});

export const uploadEventFilesAction = createAsyncThunk<
  UploadEventFilesResponse["data"],
  { eventId: number; files: File[]; fileType?: FileType },
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/uploadEventFiles",
  async ({ eventId, files, fileType }, { rejectWithValue }) => {
    try {
      const response = await uploadEventFiles(eventId, files, fileType);
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

export const fetchEventFilesAction = createAsyncThunk<
  { items: ApiEventFile[] },
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("events/fetchEventFiles", async (eventId, { rejectWithValue }) => {
  try {
    const response = await fetchEventFiles({ eventId });
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

export const deleteEventFileAction = createAsyncThunk<
  DeleteEventFileResponse["data"],
  ApiEventFile,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("events/deleteEventFile", async (file, { dispatch, rejectWithValue }) => {
  try {
    const response = await deleteEventFile(file.id);
    // Handle UI state update within the thunk
    dispatch(removeDialogExistingFile(file));
    return response.data;
  } catch (error) {
    console.error("Failed to delete file:", error);
    return rejectWithValue(
      error instanceof Error ? error.message : "An unexpected error occurred",
    );
  }
});

export const handleEventSubmission = createAsyncThunk<
  ApiEvent,
  {
    mode: "create" | "edit";
    data: EventFormData;
    eventId?: number;
    selectedFiles: File[];
  },
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/handleEventSubmission",
  async (
    { mode, data, eventId, selectedFiles },
    { dispatch, rejectWithValue },
  ) => {
    try {
      dispatch(setDialogSubmitting(true));

      let result: ApiEvent;
      if (mode === "create") {
        const newEvent = await dispatch(
          createEventWithCompanyCheckAction(data),
        ).unwrap();

        if (selectedFiles.length > 0) {
          await dispatch(
            uploadEventFilesAction({
              eventId: newEvent.id ?? 0,
              files: selectedFiles,
            }),
          ).unwrap();
        }

        result = newEvent as unknown as ApiEvent;
      } else {
        result = await dispatch(
          editEventAction({
            eventId: eventId ?? 0,
            editEventDTO: {
              eventTypeId: data.eventTypeId || null,
              eventName: data.eventName,
              eventCode: data.eventCode,
              eventDescription: data.eventDescription,
              eventPrice: data.eventPrice,
              eventInstructorId: data.eventInstructorId || null,
              eventCategoryId: data.eventCategoryId || null,
              isPublished: true,
            },
          }),
        ).unwrap();

        if (selectedFiles.length > 0) {
          await dispatch(
            uploadEventFilesAction({
              eventId: eventId ?? 0,
              files: selectedFiles,
            }),
          ).unwrap();
        }
      }
      return result;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    } finally {
      dispatch(setDialogSubmitting(false));
    }
  },
);

export const initializeEventDialog = createAsyncThunk<
  EventFormData | typeof EVENT_FORM.DEFAULT_VALUES,
  { mode: "create" | "edit"; initialData?: EventFormData },
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/initializeDialog",
  async ({ mode, initialData }, { dispatch, rejectWithValue }) => {
    try {
      await (dispatch(fetchEventTypesAction()) as unknown as Promise<void>);
      await (dispatch(
        fetchEventInstructorsAction(),
      ) as unknown as Promise<void>);
      await (dispatch(
        fetchEventCategoriesAction(),
      ) as unknown as Promise<void>);

      if (mode === "edit") {
        if (!initialData) {
          return rejectWithValue("Initial data is required for edit mode");
        }
        if (initialData.id) {
          await dispatch(fetchEventFilesAction(initialData.id));
        }
        return initialData;
      }

      return EVENT_FORM.DEFAULT_VALUES;
    } catch (error) {
      console.error("Dialog initialization failed:", error);
      return rejectWithValue("Failed to initialize dialog");
    }
  },
);

export const prepareEditEventDialog = createAsyncThunk<
  void,
  { eventId: number; initialData: EventFormData },
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "eventManagement/prepareEditDialog",
  async ({ eventId, initialData }, { dispatch }) => {
    try {
      // Load all required data in parallel
      await Promise.all([
        dispatch(fetchEventTypesAction()),
        dispatch(fetchEventInstructorsAction()),
        dispatch(fetchEventFilesAction(eventId)),
      ]);

      // Pre-populate the form
      dispatch(setDialogFormData(initialData));
    } catch (error) {
      console.error("Failed to prepare edit dialog:", error);
      throw error;
    }
  },
);

export const initializeEventsAction = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("events/initializeEvents", async (_, { dispatch, rejectWithValue }) => {
  try {
    dispatch(
      setCurrentRequest({
        page: 1,
        pageSize: DEFAULTS.PAGE_SIZE,
      }),
    );
    await dispatch(fetchEventsAction()).unwrap();
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An unexpected error occurred",
    );
  }
});

export const fetchEventCategoriesAction = createAsyncThunk(
  "events/fetchEventCategories",
  async (_, { dispatch }) => {
    try {
      dispatch(setDialogLoadingEventCategories(true));
      const response = await fetchEventCategories({});
      dispatch(setDialogEventCategories(response.data.items));
      return response;
    } finally {
      dispatch(setDialogLoadingEventCategories(false));
    }
  },
);

interface EditEventCategoryActionPayload {
  eventCategoryId: number;
  editEventCategoryDTO: {
    name: string;
    description: string | null;
    isActive: boolean;
  };
  callback?: (updatedEventCategory: ApiEventCategory) => void;
}

export const editEventCategoryAction = createAsyncThunk<
  ApiEventCategory,
  EditEventCategoryActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/editEventCategory",
  async (
    { eventCategoryId, editEventCategoryDTO, callback },
    { rejectWithValue },
  ) => {
    try {
      const response = await editEventCategory(
        eventCategoryId,
        editEventCategoryDTO,
      );
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

interface CreateEventCategoryDTO {
  name: string;
  description: string | null;
  isActive: boolean;
}

interface CreateEventCategoryActionPayload {
  createEventCategoryDTO: CreateEventCategoryDTO;
  callback?: (newEventCategory: ApiEventCategory) => void;
}

export const createEventCategoryAction = createAsyncThunk<
  ApiEventCategory,
  CreateEventCategoryActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "events/createEventCategory",
  async ({ createEventCategoryDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await createEventCategory(createEventCategoryDTO);
      if (callback) {
        callback(response.data.eventCategory);
      }
      return response.data.eventCategory;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

export const deleteEventCategoryAction = createAsyncThunk(
  "events/deleteEventCategory",
  async (categoryId: number, { dispatch }) => {
    try {
      await deleteEventCategory(categoryId);
      dispatch(setShowDeleteCategoryDialog(false));
      return { id: categoryId };
    } catch (error) {
      dispatch(setShowDeleteCategoryDialog(false));
      throw error;
    }
  },
);
