import axios from "../axiosInstance";
import { CreateEventSessionRequest, CreateEventSessionResponse } from "./types";
import { mapUiStatusToApiStatus } from "../../modules/eventRegistration/features/SessionManagement/utils/statusUtils";

export const createEventSession = async (
  eventId: number,
  requestData: CreateEventSessionRequest,
): Promise<CreateEventSessionResponse> => {
  // Convert status value using the utility function
  const modifiedRequestData = {
    ...requestData,
    status: mapUiStatusToApiStatus(requestData.status),
  };

  const response = await axios.post<CreateEventSessionResponse>(
    `/api/private/events/${eventId}/sessions/create`,
    modifiedRequestData,
  );
  return response.data;
};
