import { EntityModal } from "@/modules/eventRegistration/features/shared/components/EntityModal";
import { useEditEventTypeForm } from "../../hooks/useEditEventTypeForm";
import { EventTypeFormData } from "../../schemas/eventSchema";
import { eventTypeFields } from "../../constants/eventManagementConstants";
import { ApiEventType } from "@/api/fetchEventTypes/types";

export interface EditEventTypeDialogProps {
  eventType: ApiEventType | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
}

export function EventTypeDialog({
  eventType,
  open,
  onOpenChange,
  onSuccess,
}: EditEventTypeDialogProps): JSX.Element {
  const { form, handleSubmit, isSubmitting } = useEditEventTypeForm(eventType);

  return (
    <EntityModal<EventTypeFormData>
      entity={eventType ?? undefined}
      fields={eventTypeFields}
      form={form}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      onOpenChange={onOpenChange}
      onSuccess={onSuccess}
      open={open}
      title="Event Type"
    />
  );
}
