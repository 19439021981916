import { useMemo } from "react";
import { ApiEvent } from "@/api/fetchEvents/types";
import { areAllEventsSelected } from "../utils/selectionUtils";

/**
 * Custom hook that memoizes the areAllEventsSelected function
 * @param events - Array of events to check
 * @param selectedEventIds - Array of selected event IDs
 * @returns memoized boolean indicating if all events are selected
 */
export const useAllEventsSelected = (
  events: ApiEvent[],
  selectedEventIds: number[],
): boolean => {
  return useMemo(
    () => areAllEventsSelected(events, selectedEventIds),
    [events, selectedEventIds], // Added a comma here is not necessary, the code was already correct
  );
};
