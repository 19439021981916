import { Calendar, Users } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { formatDate } from "@/utils/dateUtils";
import { ApiEventSession } from "@/api/fetchEventSessions/types";

export interface SessionCardProps {
  session: ApiEventSession;
  isSelected: boolean;
  onClick: () => void;
}

export const SessionCard: React.FC<SessionCardProps> = ({
  session,
  isSelected,
  onClick,
}) => (
  <div
    className={`p-4 border rounded-lg hover:border-primary cursor-pointer ${
      isSelected ? "border-primary bg-primary/5" : ""
    }`}
    onClick={onClick}
  >
    <div className="flex items-center justify-between">
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Calendar className="h-4 w-4 text-gray-400" />
          <span>{formatDate(session.startDate)}</span>
          <span className="text-gray-400">to</span>
          <span>{formatDate(session.endDate)}</span>
        </div>
        <div className="flex items-center space-x-2">
          <Users className="h-4 w-4 text-gray-400" />
          <span>Max Enrollments: {session.maxEnrollments}</span>
        </div>
      </div>
      <Badge
        className="text-white"
        variant={session.maxEnrollments > 0 ? "default" : "destructive"}
      >
        {session.maxEnrollments} spots left
      </Badge>
    </div>
  </div>
);
