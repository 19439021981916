"use client";

import * as React from "react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import { Calendar as CalendarIcon, Clock } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/components/ui/lib/utils";

interface DatePickerProps {
  /** Current date value in the picker */
  value?: Date | null;

  /** Called when a new date is selected */
  onChange?: (date: Date | null) => void;

  /** Whether to show time selection */
  showTimeSelect?: boolean;

  /** Placeholder text if no date is selected */
  placeholder?: string;

  /** The earliest date selectable */
  minDate?: Date | null;

  /** Disable user interaction? */
  disabled?: boolean;
}

export function DatePicker({
  value,
  onChange,
  showTimeSelect = false,
  placeholder = "Pick a date",
  minDate,
  disabled = false,
}: DatePickerProps) {
  // If `showTimeSelect` is true, store the time in "HH:mm" format
  const [time, setTime] = React.useState(
    value ? format(value, "HH:mm") : "00:00",
  );

  // Handle date selection from DayPicker
  const handleDateSelect = (selectedDate?: Date) => {
    // If user clears the date
    if (!selectedDate) {
      onChange?.(null);
      return;
    }

    // If showTimeSelect is enabled, merge time into chosen date
    if (showTimeSelect && time) {
      const [hours, minutes] = time.split(":").map(Number);
      selectedDate.setHours(hours);
      selectedDate.setMinutes(minutes);
    }

    onChange?.(selectedDate);
  };

  // Handle typing in the time input
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = e.target.value;
    setTime(newTime);

    // If there's already a date chosen, update its time
    if (value) {
      const [hours, minutes] = newTime.split(":").map(Number);
      const newDate = new Date(value);
      newDate.setHours(hours);
      newDate.setMinutes(minutes);
      onChange?.(newDate);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn(
            "w-full justify-start text-left font-normal",
            !value && "text-muted-foreground",
            disabled && "opacity-50 cursor-not-allowed",
          )}
          disabled={disabled}
          variant="outline"
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? (
            // Show "Aug 30, 2025 10:15" if showTimeSelect, else "Aug 30, 2025"
            format(value, showTimeSelect ? "PPP HH:mm" : "PPP")
          ) : (
            <span>{placeholder}</span>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent align="start" className="w-auto p-0 bg-white">
        <div className="p-3">
          <DayPicker
            disabled={minDate ? [{ before: minDate }] : undefined}
            mode="single"
            onSelect={handleDateSelect}
            selected={value ?? undefined}
            showOutsideDays
          />

          {showTimeSelect && (
            <div className="flex items-center gap-2 px-3 py-2 border-t">
              <Clock className="h-4 w-4" />
              <Input
                className="w-24"
                disabled={disabled}
                onChange={handleTimeChange}
                type="time"
                value={time}
              />
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
