/**
 * Calculates the total number of pages based on total count and page size
 * @param totalCount Total number of items
 * @param pageSize Number of items per page
 * @returns Total number of pages
 */
export const calculateTotalPages = (
  totalCount: number,
  pageSize: number,
): number => {
  return Math.ceil(totalCount / pageSize);
};
