import React from "react";
import { Button } from "@/components/ui/button";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Select,
} from "@/components/ui/select";
import { calculateTotalPages } from "../utils/paginationUtils";

export interface ERPaginationProps {
  itemName?: string;
  onPageChange: (pageIndex: number, pageSize: number) => void;
  pageSize: number;
  pageSizeOptions?: number[];
  pageIndex: number;
  totalCount: number;
}

export const ERPagination: React.FC<ERPaginationProps> = ({
  totalCount,
  pageIndex,
  pageSize,
  onPageChange,
  itemName = "items",
  pageSizeOptions = [5, 10, 20, 30, 50],
}) => {
  const totalPages = calculateTotalPages(totalCount, pageSize);

  return (
    <div className="flex flex-col sm:flex-row items-center justify-between gap-4 px-2 py-2">
      <div className="text-sm text-muted-foreground text-center sm:text-left w-full sm:w-auto">
        <span className="font-medium">{totalCount}</span> {itemName} total, page{" "}
        <span className="font-medium">{pageIndex + 1}</span> of{" "}
        <span className="font-medium">{totalPages}</span>
      </div>
      <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-6 w-full sm:w-auto">
        <div className="flex items-center gap-2 w-full sm:w-auto justify-center sm:justify-start">
          <p className="text-sm font-medium whitespace-nowrap">
            {itemName.charAt(0).toUpperCase() + itemName.slice(1)} per page
          </p>
          <Select
            defaultValue={pageSize.toString()}
            onValueChange={(value) => onPageChange(0, Number(value))}
          >
            <SelectTrigger className="w-[70px]">
              <SelectValue>{pageSize}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              {pageSizeOptions.map((size) => (
                <SelectItem key={size} value={size.toString()}>
                  {size}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center gap-2 w-full sm:w-auto justify-center sm:justify-start">
          <Button
            className="h-9 px-4 w-full sm:w-auto"
            disabled={pageIndex === 0}
            onClick={() => onPageChange(pageIndex - 1, pageSize)}
            size="sm"
            variant="outline"
          >
            Previous
          </Button>
          <Button
            className="h-9 px-4 w-full sm:w-auto"
            disabled={pageIndex >= totalPages - 1}
            onClick={() => onPageChange(pageIndex + 1, pageSize)}
            size="sm"
            variant="outline"
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
