import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityState,
} from "@reduxjs/toolkit";
import { ApiEvent } from "@/api/fetchEvents/types";
import { ApiEventType } from "@/api/fetchEventTypes/types";
import { RootState } from "@/app/rootReducer";
import {
  fetchEventsAction,
  createEventAction,
  editEventAction,
  duplicateEventAction,
  deleteEventAction,
  createEventTypeAction,
  editEventTypeAction,
  deleteEventTypeAction,
  createEventInstructorAction,
  editEventInstructorAction,
  deleteEventInstructorAction,
  uploadEventFilesAction,
  deleteEventFileAction,
  fetchEventFilesAction,
  createEventCategoryAction,
  editEventCategoryAction,
  deleteEventCategoryAction,
  fetchEventCategoriesAction,
} from "../thunks/eventThunks";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";
import { EventFormData } from "@/modules/eventRegistration/features/EventManagement/schemas/eventSchema";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";

// Create entity adapter for events
const eventsAdapter = createEntityAdapter<ApiEvent>();

// create entity adapter for event types
const eventTypesAdapter = createEntityAdapter<ApiEventType>();

// create entity adapter for event instructors
const eventInstructorsAdapter = createEntityAdapter<ApiEventInstructor>();

// create entity adapter for event categories
const eventCategoriesAdapter = createEntityAdapter<ApiEventCategory>();

// Get the selectors
const selectors = eventsAdapter.getSelectors<RootState>(
  (state) => state.events,
);

// Export selectors
export const {
  selectAll: selectAllEvents,
  selectById: selectEventById,
  selectIds: selectEventIds,
} = selectors;

// Define the state shape for event data
interface EventsState {
  loading: boolean;
  error: string | null;
  totalCount: number;
  selectedIds: number[];
  dialog: {
    isSubmitting: boolean;
    submitError: string | null;
    showUnsavedChangesDialog: boolean;
    showResetDialog: boolean;
    eventTypes: ApiEventType[];
    isLoadingEventTypes: boolean;
    eventInstructors: ApiEventInstructor[];
    isLoadingEventInstructors: boolean;
    eventCategories: ApiEventCategory[];
    isLoadingEventCategories: boolean;
    selectedFiles: File[];
    existingFiles: ApiEventFile[];
    formData?: EventFormData;
  };
  eventTypes: EntityState<ApiEventType, number>;
  eventInstructors: EntityState<ApiEventInstructor, number>;
  eventCategories: EntityState<ApiEventCategory, number>;
  isLoadingEventCategories: boolean;
  showDeleteCategoryDialog: boolean;
  eventDetails: {
    isLoading: boolean;
    error: string | null;
  };
}

// Initial state using the adapter
const initialState = eventsAdapter.getInitialState<EventsState>({
  loading: false,
  error: null,
  totalCount: 0,
  selectedIds: [],
  dialog: {
    isSubmitting: false,
    submitError: null,
    showUnsavedChangesDialog: false,
    showResetDialog: false,
    eventTypes: [],
    isLoadingEventTypes: false,
    eventInstructors: [],
    isLoadingEventInstructors: false,
    eventCategories: [],
    isLoadingEventCategories: false,
    selectedFiles: [],
    existingFiles: [],
    formData: undefined,
  },
  eventTypes: eventTypesAdapter.getInitialState(),
  eventInstructors: eventInstructorsAdapter.getInitialState(),
  eventCategories: eventCategoriesAdapter.getInitialState(),
  isLoadingEventCategories: false,
  showDeleteCategoryDialog: false,
  eventDetails: {
    isLoading: false,
    error: null,
  },
});

// Create the slice
const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    toggleEventSelection: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const index = state.selectedIds.indexOf(id);
      if (index === -1) {
        state.selectedIds.push(id);
      } else {
        state.selectedIds.splice(index, 1);
      }
    },
    setSelectedEvents: (state, action: PayloadAction<number[]>) => {
      state.selectedIds = action.payload;
    },
    clearSelectedEvents: (state) => {
      state.selectedIds = [];
    },
    setEvents: (state, action: PayloadAction<ApiEvent[]>) => {
      eventsAdapter.setAll(state, action.payload);
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
    setDialogSubmitting: (state, action: PayloadAction<boolean>) => {
      state.dialog.isSubmitting = action.payload;
    },
    setDialogSubmitError: (state, action: PayloadAction<string | null>) => {
      state.dialog.submitError = action.payload;
    },
    setDialogUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.dialog.showUnsavedChangesDialog = action.payload;
    },
    setDialogReset: (state, action: PayloadAction<boolean>) => {
      state.dialog.showResetDialog = action.payload;
    },
    setDialogEventTypes: (state, action: PayloadAction<ApiEventType[]>) => {
      state.dialog.eventTypes = action.payload;
    },
    setDialogLoadingEventTypes: (state, action: PayloadAction<boolean>) => {
      state.dialog.isLoadingEventTypes = action.payload;
    },
    setDialogEventInstructors: (
      state,
      action: PayloadAction<ApiEventInstructor[]>,
    ) => {
      state.dialog.eventInstructors = action.payload;
    },
    setDialogLoadingEventInstructors: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.dialog.isLoadingEventInstructors = action.payload;
    },
    setDialogEventCategories: (
      state,
      action: PayloadAction<ApiEventCategory[]>,
    ) => {
      state.dialog.eventCategories = action.payload;
    },
    setDialogLoadingEventCategories: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.dialog.isLoadingEventCategories = action.payload;
    },
    setDialogSelectedFiles: (state, action: PayloadAction<File[]>) => {
      state.dialog.selectedFiles = action.payload;
    },
    setDialogExistingFiles: (state, action: PayloadAction<ApiEventFile[]>) => {
      state.dialog.existingFiles = action.payload;
    },
    addDialogSelectedFiles: (state, action: PayloadAction<File[]>) => {
      state.dialog.selectedFiles = [
        ...state.dialog.selectedFiles,
        ...action.payload,
      ];
    },
    removeDialogSelectedFile: (state, action: PayloadAction<File>) => {
      state.dialog.selectedFiles = state.dialog.selectedFiles.filter(
        (file) => file.name !== action.payload.name,
      );
    },
    removeDialogExistingFile: (state, action: PayloadAction<ApiEventFile>) => {
      state.dialog.existingFiles = state.dialog.existingFiles.filter(
        (file) => file.id !== action.payload.id,
      );
    },
    clearDialogFiles: (state) => {
      state.dialog.selectedFiles = [];
      state.dialog.existingFiles = [];
    },
    setDialogFormData: (state, action: PayloadAction<EventFormData>) => {
      state.dialog.formData = action.payload;
    },
    setShowDeleteCategoryDialog: (state, action: PayloadAction<boolean>) => {
      state.showDeleteCategoryDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch events
      .addCase(fetchEventsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEventsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.totalCount = action.payload.meta?.totalCount || 0;
        eventsAdapter.setAll(state, action.payload.data.items);
      })
      .addCase(fetchEventsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch events";
      })
      // Create event
      .addCase(createEventAction.fulfilled, (state, action) => {
        if (!action.payload.id) {
          return; // Skip adding if id is null
        }

        // Get the original request from meta arg
        const request = action.meta.arg;

        const event: ApiEvent = {
          id: action.payload.id,
          eventCode: request.eventCode,
          eventName: action.payload.eventName || "",
          eventDescription: action.payload.eventDescription || "",
          eventPrice: action.payload.eventPrice || 0,
          imageUrl: action.payload.imageUrl || null,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          eventCategoryId: action.payload.eventCategoryId || null,
          eventInstructorId: action.payload.eventInstructorId || null,
          eventInstructor: action.payload.eventInstructor || null,
        };
        eventsAdapter.addOne(state, event);
      })
      // Edit event
      .addCase(editEventAction.fulfilled, (state, action) => {
        eventsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      // Duplicate event
      .addCase(duplicateEventAction.fulfilled, (state, action) => {
        if (action.payload && action.payload.id) {
          eventsAdapter.addOne(state, action.payload);
        }
      })
      // Delete event
      .addCase(deleteEventAction.fulfilled, (state, action) => {
        eventsAdapter.removeOne(state, action.payload.eventId);
      })
      // Create event type
      .addCase(createEventTypeAction.fulfilled, (state, action) => {
        if (!action.payload.id) return;
        eventTypesAdapter.addOne(state.eventTypes, action.payload);
      })
      // edit event type
      .addCase(editEventTypeAction.fulfilled, (state, action) => {
        eventTypesAdapter.updateOne(state.eventTypes, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      // delete event type
      .addCase(deleteEventTypeAction.fulfilled, (state, action) => {
        eventTypesAdapter.removeOne(state.eventTypes, action.payload.id);
      })
      // create event instructor
      .addCase(createEventInstructorAction.fulfilled, (state, action) => {
        eventInstructorsAdapter.addOne(state.eventInstructors, action.payload);
      })
      // edit event instructor
      .addCase(editEventInstructorAction.fulfilled, (state, action) => {
        eventInstructorsAdapter.updateOne(state.eventInstructors, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      // delete event instructor
      .addCase(deleteEventInstructorAction.fulfilled, (state, action) => {
        eventInstructorsAdapter.removeOne(
          state.eventInstructors,
          action.payload.id,
        );
      })
      // create event category
      .addCase(createEventCategoryAction.fulfilled, (state, action) => {
        eventCategoriesAdapter.addOne(state.eventCategories, action.payload);
      })
      // edit event category
      .addCase(editEventCategoryAction.fulfilled, (state, action) => {
        eventCategoriesAdapter.updateOne(state.eventCategories, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      // delete event category
      .addCase(deleteEventCategoryAction.fulfilled, (state, action) => {
        eventCategoriesAdapter.removeOne(
          state.eventCategories,
          action.payload.id,
        );
      })
      // upload event files
      .addCase(uploadEventFilesAction.fulfilled, (state) => {
        state.dialog.selectedFiles = []; // Clear selected files after successful upload
      })
      // delete event file
      .addCase(deleteEventFileAction.fulfilled, (state, action) => {
        state.dialog.existingFiles = state.dialog.existingFiles.filter(
          (file: ApiEventFile) => file.id !== action.payload.eventFileId,
        );
      })
      // fetch event files
      .addCase(fetchEventFilesAction.fulfilled, (state, action) => {
        state.dialog.existingFiles = action.payload.items;
      })
      // fetch event categories
      .addCase(fetchEventCategoriesAction.fulfilled, (state, action) => {
        eventCategoriesAdapter.setAll(
          state.eventCategories,
          action.payload.data.items,
        );
        state.isLoadingEventCategories = false;
      })
      // Handle event details loading states
      .addMatcher(
        (action) =>
          [
            fetchEventsAction.pending.type,
            fetchEventFilesAction.pending.type,
            fetchEventCategoriesAction.pending.type,
          ].includes(action.type),
        (state) => {
          state.eventDetails.isLoading = true;
          state.eventDetails.error = null;
        },
      )
      .addMatcher(
        (action) =>
          [
            fetchEventsAction.fulfilled.type,
            fetchEventFilesAction.fulfilled.type,
            fetchEventCategoriesAction.fulfilled.type,
          ].includes(action.type),
        (state) => {
          state.eventDetails.isLoading = false;
        },
      )
      .addMatcher(
        (action) =>
          [
            fetchEventsAction.rejected.type,
            fetchEventFilesAction.rejected.type,
            fetchEventCategoriesAction.rejected.type,
          ].includes(action.type),
        (
          state,
          action: PayloadAction<unknown, string, never, { message?: string }>,
        ) => {
          state.eventDetails.isLoading = false;
          state.eventDetails.error =
            action.error?.message || "Failed to load event details";
        },
      );
  },
});

// Export actions
export const {
  toggleEventSelection,
  setSelectedEvents,
  clearSelectedEvents,
  setEvents,
  setTotalCount,
  setDialogSubmitting,
  setDialogSubmitError,
  setDialogUnsavedChanges,
  setDialogReset,
  setDialogEventTypes,
  setDialogLoadingEventTypes,
  setDialogEventInstructors,
  setDialogLoadingEventInstructors,
  setDialogEventCategories,
  setDialogLoadingEventCategories,
  setDialogSelectedFiles,
  setDialogExistingFiles,
  addDialogSelectedFiles,
  removeDialogSelectedFile,
  removeDialogExistingFile,
  clearDialogFiles,
  setDialogFormData,
  setShowDeleteCategoryDialog,
} = eventsSlice.actions;

export default eventsSlice.reducer;
