import { Badge } from "@/components/ui/badge";
import { Calendar, UserCircle2, DollarSign } from "lucide-react";
import React from "react";
import { ApiEvent } from "@/api/fetchEvents/types";

export interface EventHeaderProps {
  event: ApiEvent;
}

export const EventHeader = React.memo<EventHeaderProps>(({ event }) => (
  <div>
    <h1 className="text-3xl font-bold mb-2">{event.eventName}</h1>
    <div className="mb-4 flex gap-1">
      <Badge className="text-xs bg-primary text-white" variant="outline">
        {event.eventCode}
      </Badge>
      <Badge className="text-xs bg-primary text-white" variant="outline">
        {event.eventCategory}
      </Badge>
      <Badge className="text-xs bg-primary text-white" variant="outline">
        {event.eventType}
      </Badge>
    </div>
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
      <div className="flex items-center space-x-2">
        <Calendar className="h-5 w-5 text-gray-400" />
        <span>
          {event.createdAt
            ? new Date(event.createdAt).toLocaleDateString()
            : "No date available"}
        </span>
      </div>
      <div className="flex items-center space-x-2">
        <UserCircle2 className="h-5 w-5 text-gray-400" />
        <span>{event.eventInstructor || "No instructor assigned"}</span>
      </div>
      <div className="flex items-center space-x-2">
        <DollarSign className="h-5 w-5 text-gray-400" />
        <span>${event.eventPrice}</span>
      </div>
    </div>
  </div>
));

EventHeader.displayName = "EventHeader";
