import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { EVENT_FORM } from "../../constants/eventManagementConstants";
import {
  setUnsavedChangesDialog,
  setResetDialog,
  setShowDeleteTypeDialog,
  setShowDeleteInstructorDialog,
  setShowDeleteCategoryDialog,
} from "../../slices/eventManagementUISlice";
import { selectDialogAlertsState } from "../../selectors/eventManagementSelectors";
import { useEventManagementActions } from "../../hooks/useEventManagementActions";
import { AlertDialogComponent } from "../../../shared/components/AlertDialogComponent";

interface EventDialogAlertsProps {
  onDeleteCategoryConfirm: () => void;
  onDeleteInstructorConfirm: () => void;
  onDeleteTypeConfirm: () => void;
  onResetConfirm: () => void;
  onUnsavedChangesConfirm: () => void;
}

export const EventDialogAlerts = ({
  onDeleteCategoryConfirm,
  onDeleteInstructorConfirm,
  onDeleteTypeConfirm,
  onResetConfirm,
  onUnsavedChangesConfirm,
}: EventDialogAlertsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { handleDialogCloseAction } = useEventManagementActions({});

  const {
    showUnsavedChangesDialog,
    showResetDialog,
    showDeleteTypeDialog,
    showDeleteInstructorDialog,
    showDeleteCategoryDialog,
  } = useAppSelector(selectDialogAlertsState);

  return (
    <>
      <AlertDialogComponent
        cancelText={EVENT_FORM.BUTTON_TEXT.cancel}
        confirmText={EVENT_FORM.BUTTON_TEXT.discard}
        description={EVENT_FORM.MESSAGES.unsavedChanges}
        isOpen={showUnsavedChangesDialog}
        onCancel={() => {
          dispatch(setUnsavedChangesDialog(false));
        }}
        onConfirm={() => {
          onUnsavedChangesConfirm();
          dispatch(setUnsavedChangesDialog(false));
        }}
        onOpenChange={(open) => dispatch(setUnsavedChangesDialog(open))}
        title={EVENT_FORM.TITLES.unsavedChanges}
      />

      <AlertDialogComponent
        cancelText={EVENT_FORM.BUTTON_TEXT.cancel}
        confirmText={EVENT_FORM.BUTTON_TEXT.reset}
        description={EVENT_FORM.MESSAGES.resetForm}
        isOpen={showResetDialog}
        onCancel={() => {
          dispatch(setResetDialog(false));
        }}
        onConfirm={() => {
          onResetConfirm();
          dispatch(setResetDialog(false));
        }}
        onOpenChange={(open) => dispatch(setResetDialog(open))}
        title={EVENT_FORM.TITLES.resetForm}
      />

      <AlertDialogComponent
        cancelText={EVENT_FORM.BUTTON_TEXT.cancel}
        confirmText={EVENT_FORM.BUTTON_TEXT.delete}
        description={EVENT_FORM.MESSAGES.deleteType}
        isDestructive={true}
        isOpen={showDeleteTypeDialog}
        onCancel={handleDialogCloseAction(setShowDeleteTypeDialog)}
        onConfirm={onDeleteTypeConfirm}
        onOpenChange={(open) => dispatch(setShowDeleteTypeDialog(open))}
        title={EVENT_FORM.TITLES.deleteType}
      />

      <AlertDialogComponent
        cancelText={EVENT_FORM.BUTTON_TEXT.cancel}
        confirmText={EVENT_FORM.BUTTON_TEXT.delete}
        description={EVENT_FORM.MESSAGES.deleteInstructor}
        isDestructive={true}
        isOpen={showDeleteInstructorDialog}
        onCancel={handleDialogCloseAction(setShowDeleteInstructorDialog)}
        onConfirm={onDeleteInstructorConfirm}
        onOpenChange={(open) => dispatch(setShowDeleteInstructorDialog(open))}
        title={EVENT_FORM.TITLES.deleteInstructor}
      />

      <AlertDialogComponent
        cancelText={EVENT_FORM.BUTTON_TEXT.cancel}
        confirmText={EVENT_FORM.BUTTON_TEXT.delete}
        description={EVENT_FORM.MESSAGES.deleteCategory}
        isDestructive={true}
        isOpen={showDeleteCategoryDialog}
        onCancel={handleDialogCloseAction(setShowDeleteCategoryDialog)}
        onConfirm={onDeleteCategoryConfirm}
        onOpenChange={(open) => dispatch(setShowDeleteCategoryDialog(open))}
        title={EVENT_FORM.TITLES.deleteCategory}
      />
    </>
  );
};
