import React from "react";
import { Button } from "@/components/ui/button";
import { PlusCircle, Loader2 } from "lucide-react";
import { SessionManagementSearch } from "../SessionManagementSearch/SessionManagementSearch";
import { SessionManagementFilters } from "../SessionManagementFilters/SessionManagementFilters";
import { SessionFilters } from "../../hooks/useSessionManagementActions";

export interface SessionManagementToolbarProps {
  isLoading: boolean;
  onSearch: (query: string) => void;
  onFilterChange: (filters: SessionFilters) => void;
  onAddSession: () => void;
}

export const SessionManagementToolbar: React.FC<
  SessionManagementToolbarProps
> = ({ isLoading, onSearch, onFilterChange, onAddSession }) => (
  <div className="flex flex-col gap-4 md:flex-row md:items-center">
    <div className="w-full md:flex-1">
      <SessionManagementSearch onSearch={onSearch} />
    </div>
    <div className="flex flex-row gap-4 w-full md:w-auto">
      <div className="flex-1 md:flex-initial">
        <SessionManagementFilters onFilterChange={onFilterChange} />
      </div>
      <Button
        className="flex-1 md:flex-initial h-10 sm:min-w-[120px] px-4 py-2 bg-primary text-white hover:bg-primary/90"
        disabled={isLoading}
        onClick={onAddSession}
      >
        {isLoading ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <PlusCircle className="mr-2 h-4 w-4" />
        )}
        Create Session
      </Button>
    </div>
  </div>
);
