import {
  FetchEventInstructorsRequest,
  FetchEventInstructorsResponse,
} from "./types";
import { axiosInstance } from "../axiosInstance";

export const fetchEventInstructors = async (
  requestData: FetchEventInstructorsRequest,
): Promise<FetchEventInstructorsResponse> => {
  const params = {
    ...requestData,
  };

  const response = await axiosInstance.get<FetchEventInstructorsResponse>(
    "/api/private/event-instructors",
    {
      params,
    },
  );
  return response.data;
};
