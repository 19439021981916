// Form imports
import { Control, FieldValues, Path, RegisterOptions } from "react-hook-form";

// UI Components
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/utils/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";

// Hooks
import { useEventFormField } from "../../hooks/useEventFormField";
import { useEventFormFieldHandlers } from "../../hooks/useEventFormFieldHandlers";
import { EventFormFieldType } from "../../schemas/eventFormFieldSchema";

interface EventFormFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  placeholder?: string;
  type: EventFormFieldType;
  id?: string;
  disabled?: boolean;
  readonly?: boolean;
  validation?: RegisterOptions<T, Path<T>>;
  description?: string;
  options?: Array<{
    value: string;
    label: string | JSX.Element;
  }>;
  onValueChange?: (value: string) => void;
}

export const EventFormField = <T extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  type,
  id = `event-field-${String(name)}`,
  disabled = false,
  readonly = false,
  validation,
  description,
  options,
  onValueChange,
}: EventFormFieldProps<T>): JSX.Element => {
  const { validatedType } = useEventFormField<T>({
    control,
    name,
    type: type === "select" ? "text" : type,
  });

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }): JSX.Element => {
        const { isFocused, handleFocus, handleBlur, handleChange } =
          useEventFormFieldHandlers({
            field,
            disabled,
            readonly,
          });

        switch (type) {
          case "checkbox":
            return (
              <FormItem>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    checked={Boolean(field.value)}
                    disabled={disabled}
                    id={id}
                    onCheckedChange={(checked) => field.onChange(checked)}
                  />
                  <FormLabel
                    className={cn(
                      "mb-0",
                      disabled && "cursor-not-allowed opacity-50",
                      isFocused && "text-primary",
                    )}
                    htmlFor={id}
                  >
                    {label}
                  </FormLabel>
                </div>
              </FormItem>
            );
          case "select":
            return (
              <FormItem>
                <FormLabel
                  className={cn(
                    disabled && "cursor-not-allowed opacity-50",
                    isFocused && "text-primary",
                  )}
                  htmlFor={id}
                >
                  {label}
                </FormLabel>
                <FormControl>
                  <Select
                    disabled={disabled}
                    onValueChange={(value) => {
                      if (value === "add-new") {
                        onValueChange?.(value);
                        return;
                      }
                      // Only convert to number if it's not a status field
                      const processedValue =
                        name === "status" ? value : Number(value);
                      field.onChange(processedValue);
                      onValueChange?.(value);
                    }}
                    value={field.value?.toString() || ""}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={placeholder} />
                    </SelectTrigger>
                    <SelectContent>
                      {options?.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                {description && (
                  <FormDescription className={cn(isFocused && "text-primary")}>
                    {description}
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case "textarea":
            return (
              <FormItem>
                <FormLabel
                  className={cn(
                    disabled && "cursor-not-allowed opacity-50",
                    isFocused && "text-primary",
                  )}
                  htmlFor={id}
                >
                  {label}
                </FormLabel>
                <FormControl>
                  <Textarea
                    className={cn(
                      disabled && "cursor-not-allowed",
                      readonly &&
                        "bg-muted focus-visible:ring-0 focus-visible:ring-offset-0",
                      isFocused && "border-secondary",
                    )}
                    disabled={disabled}
                    id={id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder={placeholder}
                    readOnly={readonly}
                    value={String(field.value || "")}
                  />
                </FormControl>
                {description && (
                  <FormDescription className={cn(isFocused && "text-primary")}>
                    {description}
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
          case "datetime-local":
            return (
              <FormItem>
                <FormLabel>{label}</FormLabel>
                <FormControl>
                  <Input type="datetime-local" {...field} disabled={disabled} />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          default:
            return (
              <FormItem>
                <FormLabel
                  className={cn(
                    disabled && "cursor-not-allowed opacity-50",
                    isFocused && "text-primary",
                  )}
                  htmlFor={id}
                >
                  {label}
                </FormLabel>
                <FormControl>
                  <Input
                    className={cn(
                      disabled && "cursor-not-allowed",
                      readonly &&
                        "bg-muted focus-visible:ring-0 focus-visible:ring-offset-0",
                      isFocused && "border-secondary",
                    )}
                    disabled={disabled}
                    id={id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder={placeholder}
                    readOnly={readonly}
                    type={validatedType}
                    value={String(field.value || "")}
                  />
                </FormControl>
                {description && (
                  <FormDescription className={cn(isFocused && "text-primary")}>
                    {description}
                  </FormDescription>
                )}
                <FormMessage />
              </FormItem>
            );
        }
      }}
      rules={validation}
    />
  );
};
