import { UseFormReturn, FieldValues } from "react-hook-form";
import { useCallback } from "react";

interface UseEntityDialogActionsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  onOpenChange: (open: boolean) => void;
}

export const useEntityDialogActions = <T extends FieldValues>({
  form,
  onOpenChange,
}: UseEntityDialogActionsProps<T>) => {
  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        form.reset();
      }
      onOpenChange(open);
    },
    [onOpenChange, form],
  );

  return {
    handleOpenChange,
  };
};
