import { axiosInstance } from "../axiosInstance";
import { EditEventResponse, EditEventDTO } from "./types";

export const editEvent = async (
  id: number,
  editEventDTO: EditEventDTO,
): Promise<EditEventResponse> => {
  const response = await axiosInstance.put<EditEventResponse>(
    `/api/private/events/${id}/edit`,
    editEventDTO,
  );
  return response.data;
};
