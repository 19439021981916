import { useMemo } from "react";
import { useSelector } from "react-redux";
import ERFilters from "@/modules/eventRegistration/features/shared/components/ERFilters";
import {
  selectFilterOptions,
  selectEventManagementCurrentRequest,
  selectSelectedFilters,
} from "../../selectors/eventManagementSelectors";
import { createFilterSections } from "../../utils/filterUtils";
import { useEventManagementActions } from "../../hooks/useEventManagementActions";

export interface EventManagementFiltersProps {
  onFilterChange: (filters: FilterChangeParams) => void;
}

export type FilterChangeParams = {
  eventCategoryId: number | null;
  isPublished: boolean | null;
};

const EventManagementFilters: React.FC<EventManagementFiltersProps> = ({
  onFilterChange,
}) => {
  const filterOptions = useSelector(selectFilterOptions);
  const currentRequest = useSelector(selectEventManagementCurrentRequest);
  const selectedFilters = useSelector(selectSelectedFilters);
  const { handleFilterToggle, handleClear } =
    useEventManagementActions(currentRequest);

  const sections = useMemo(
    () => createFilterSections(filterOptions.categories),
    [filterOptions.categories],
  );

  return (
    <ERFilters
      buttonClassName="h-10 flex-1 sm:flex-none sm:min-w-[120px] px-4 py-2 hover:bg-gray-200"
      onClearFilters={() => handleClear(onFilterChange)}
      onToggleFilter={(type, value) =>
        handleFilterToggle(type, value, onFilterChange)
      }
      sections={sections}
      selectedFilters={selectedFilters}
    />
  );
};

export default EventManagementFilters;
