import axios from "../axiosInstance";
import { AxiosProgressEvent } from "axios";
import { UploadTmpFileApiResponse } from "./types";

export const uploadTmpFile = async (
  file: File,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
): Promise<UploadTmpFileApiResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post<UploadTmpFileApiResponse>(
    "/api/private/tmp/file-upload",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    },
  );

  return response.data;
};
