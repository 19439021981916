import React from "react";
import { Control } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SessionDateTimePicker } from "../SessionDateTimePicker/SessionDateTimePicker";

// Define SessionFormData interface to match the schema
export interface SessionFormData {
  id?: number;
  eventId?: number;
  startDate: string;
  endDate: string;
  maxEnrollments: number;
  virtualLink?: string | null;
  notes?: string | null;
  status: string;
}

interface Option {
  value: string;
  label: string;
}

interface SessionFormFieldProps {
  control: Control<SessionFormData>;
  disabled?: boolean;
  label: string;
  name: keyof SessionFormData;
  options?: Option[];
  placeholder: string;
  type:
    | "text"
    | "textarea"
    | "date"
    | "time"
    | "select"
    | "number"
    | "datetime-local";
}

export const SessionFormField: React.FC<SessionFormFieldProps> = ({
  control,
  disabled = false,
  label,
  name,
  options,
  placeholder,
  type,
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            {type === "textarea" ? (
              <Textarea
                {...field}
                className="resize-none"
                disabled={disabled}
                placeholder={placeholder}
                value={
                  typeof field.value === "number"
                    ? String(field.value)
                    : field.value || ""
                }
              />
            ) : type === "select" && options ? (
              <>
                <Select
                  disabled={disabled}
                  name={field.name}
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value ? String(field.value) : ""}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={placeholder} />
                  </SelectTrigger>
                  <SelectContent>
                    {options.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </>
            ) : type === "date" ? (
              <Input
                {...field}
                disabled={disabled}
                placeholder={placeholder}
                type="date"
                value={
                  typeof field.value === "number"
                    ? String(field.value)
                    : field.value || ""
                }
              />
            ) : type === "time" ? (
              <Input
                {...field}
                disabled={disabled}
                placeholder={placeholder}
                type="time"
                value={
                  typeof field.value === "number"
                    ? String(field.value)
                    : field.value || ""
                }
              />
            ) : type === "number" ? (
              <Input
                {...field}
                disabled={disabled}
                placeholder={placeholder}
                type="number"
                value={
                  typeof field.value === "number"
                    ? String(field.value)
                    : field.value || ""
                }
              />
            ) : type === "datetime-local" ? (
              <>
                <SessionDateTimePicker
                  className="w-full"
                  disabled={disabled}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  placeholder={placeholder}
                  value={
                    typeof field.value === "number"
                      ? String(field.value)
                      : field.value
                  }
                />
              </>
            ) : (
              <Input
                {...field}
                disabled={disabled}
                placeholder={placeholder}
                type="text"
                value={
                  typeof field.value === "number"
                    ? String(field.value)
                    : field.value || ""
                }
              />
            )}
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
