import { FC } from "react";
import ERSearch from "../../../shared/components/ERSearch";
import { useEventManagementSearch } from "../../hooks/useEventManagementSearch";

export interface EventManagementSearchProps {
  onSearch: (query: string) => Promise<void> | void;
}

const EventManagementSearch: FC<EventManagementSearchProps> = ({
  onSearch,
}) => {
  const { searchQuery, handleSearchChange } =
    useEventManagementSearch(onSearch);

  return (
    <ERSearch
      className="w-full"
      onSearchChange={handleSearchChange}
      placeholder="Search events..."
      searchQuery={searchQuery}
    />
  );
};

export default EventManagementSearch;
