import { useSelector } from "react-redux";
import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { ArrowLeft } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

// Feature imports
import { DEFAULTS } from "../../constants/sessionManagementConstants";
import SessionDialog from "../SessionDialog/SessionDialog";
import { SessionManagementToolbar } from "../SessionManagementToolbar/SessionManagementToolbar";
import { SessionManagementTableSection } from "../SessionManagementTableSection/SessionManagementTableSection";
import { useSessionForm } from "../../hooks/useSessionForm";
import { useSessionManagementActions } from "../../hooks/useSessionManagementActions";
import { useSessionInitialization } from "../../hooks/useSessionInitialization";
import {
  selectSessionsWithLoadingState,
  selectSessionDialogState,
  selectCurrentRequestState,
} from "../../selectors/sessionManagementSelectors";
import { SessionManagementContext } from "../../context/SessionManagementContext";
import { mapFormDataToApiSession } from "../../utils/sessionDataMappers";
import { formatLocalDateTime } from "../../utils/dateUtils";
import { mapApiStatusToUiStatus } from "../../utils/statusUtils";

export const SessionManagement = (): JSX.Element => {
  const { eventId } = useParams<{ eventId: string }>();
  const { handleSubmit, isSubmitting } = useSessionForm();
  const navigate = useNavigate();
  const { sessions, isLoadingSessions, sessionsError, totalCount } =
    useSelector(selectSessionsWithLoadingState);
  const { showSessionDialog, sessionToEdit } = useSelector(
    selectSessionDialogState,
  );
  const currentRequest = useSelector(selectCurrentRequestState);

  // Initialize sessions data using the custom hook
  useSessionInitialization(eventId);

  // Get all action handlers from the enhanced custom hook
  const {
    handlePaginationChange,
    handleSortingChange,
    handleSearchChange,
    handleFiltersChange,
    handleDialogSubmit,
    handleAddSession,
    handleEditSession,
    handleDialogClose,
    handleDialogSuccess,
  } = useSessionManagementActions({
    currentRequest,
    eventId,
    sessionToEdit,
    handleSubmit,
  });

  const contextValue = {
    currentRequest,
    isLoadingSessions,
    handlePaginationChange,
    handleSortingChange,
    handleSearchChange,
    handleFiltersChange,
    handleEditSession,
  };

  if (sessionsError) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{sessionsError.description}</AlertDescription>
      </Alert>
    );
  }

  return (
    <ErrorBoundary>
      <SessionManagementContext.Provider value={contextValue}>
        <div className="space-y-4">
          <div className="flex items-center gap-4">
            <Button
              className="flex items-center gap-2"
              onClick={() =>
                navigate("/event-registration/admin/event-management")
              }
              variant="ghost"
            >
              <ArrowLeft className="h-4 w-4" />
              Back
            </Button>
          </div>
          <SessionManagementToolbar
            isLoading={isLoadingSessions}
            onAddSession={handleAddSession}
            onFilterChange={handleFiltersChange}
            onSearch={handleSearchChange}
          />
          <SessionManagementTableSection
            currentRequest={currentRequest}
            defaultPageSize={DEFAULTS.PAGE_SIZE}
            isLoading={isLoadingSessions}
            onEditSession={handleEditSession}
            onPaginationChange={handlePaginationChange}
            onSortingChange={handleSortingChange}
            sessions={sessions}
            totalCount={totalCount}
          />
        </div>
        <SessionDialog
          initialData={
            sessionToEdit
              ? {
                  id: sessionToEdit.id,
                  eventId: Number(eventId),
                  startDate: formatLocalDateTime(sessionToEdit.startDate),
                  endDate: formatLocalDateTime(sessionToEdit.endDate),
                  maxEnrollments: sessionToEdit.maxEnrollments,
                  virtualLink: sessionToEdit.virtualLink,
                  notes: sessionToEdit.notes,
                  // Map API status values to UI status values
                  status: mapApiStatusToUiStatus(sessionToEdit.status),
                }
              : undefined
          }
          isSubmitting={isSubmitting}
          mode={sessionToEdit ? "edit" : "create"}
          onOpenChange={handleDialogClose}
          onSubmit={(data) => {
            // Convert form data to API format
            const apiData = mapFormDataToApiSession(data);
            const mode = sessionToEdit ? "edit" : "create";

            // Call handleDialogSubmit with all required parameters
            return handleDialogSubmit(
              apiData,
              null, // We don't have access to the form here
              mode,
              handleDialogSuccess,
              handleDialogClose,
            );
          }}
          onSuccess={handleDialogSuccess}
          open={showSessionDialog}
        />
      </SessionManagementContext.Provider>
    </ErrorBoundary>
  );
};
