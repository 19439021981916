import { FetchEventFilesRequest, FetchEventFilesResponse } from "./types";
import { axiosInstance } from "../axiosInstance";

export const fetchEventFiles = async (
  requestData: FetchEventFilesRequest,
): Promise<FetchEventFilesResponse> => {
  const params = {
    ...requestData,
  };

  const response = await axiosInstance.get<FetchEventFilesResponse>(
    "/api/private/event-files",
    {
      params,
    },
  );
  return response.data;
};
