import { axiosInstance } from "../axiosInstance";
import { EditEventSessionResponse, EditEventSessionDTO } from "./types";
import { mapUiStatusToApiStatus } from "../../modules/eventRegistration/features/SessionManagement/utils/statusUtils";

export const editEventSession = async (
  id: number,
  editEventSessionDTO: EditEventSessionDTO,
): Promise<EditEventSessionResponse> => {
  // Convert status value using the utility function
  const modifiedRequestData = {
    ...editEventSessionDTO,
    status: mapUiStatusToApiStatus(editEventSessionDTO.status),
  };

  const response = await axiosInstance.put<EditEventSessionResponse>(
    `/api/private/event-sessions/${id}/edit`,
    modifiedRequestData,
  );
  return response.data;
};
