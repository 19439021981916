import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import { createCampaignFileColumns } from "../CampaignFileColumns/CampaignFileColumns";
import { CampaignFile } from "../../../../../../api/fetchCampaignFiles/types";
import { useCampaignFiles } from "../../hooks/useCampaignFile";
import { downloadCampaignFile } from "../../../../../../api/downloadCampaignFile/downloadCampaignFileApi";
import DataTable from "@/components/Datatable/Datatable";

const CampaignFilesList: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const numericCampaignId = Number(campaignId);

  const {
    files,
    totalCount,
    loading,
    error,
    pagination,
    handlePaginationChange,
  } = useCampaignFiles(numericCampaignId);

  const handleDownload = useCallback(async (file: CampaignFile) => {
    try {
      const requestData = {
        bucketName: file.bucketName,
        objectKey: file.objectKey,
      };
      const fileBlob = await downloadCampaignFile(requestData);

      const url = window.URL.createObjectURL(fileBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.originalFilename || "downloaded-file";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file:", error);
      alert("An error occurred while downloading the file.");
    }
  }, []);

  const columns = useMemo(
    () => createCampaignFileColumns({ handleDownload }),
    [handleDownload],
  );

  return (
    <MainPageWrapper error={error} loading={loading} title="Campaign Files">
      <DataTable<CampaignFile>
        columns={columns}
        data={files}
        error={error}
        loading={loading}
        noDataMessage="No campaign files found"
        onPageChange={(newPageIndex, newPageSize) =>
          handlePaginationChange({
            pageIndex: newPageIndex,
            pageSize: newPageSize,
          })
        }
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        rowKeyExtractor={(file) => file.id}
        totalCount={totalCount}
      />
    </MainPageWrapper>
  );
};

export default CampaignFilesList;
