import React, { useCallback, useMemo } from "react";
import { useStochasticCustomers } from "../../hooks/useStochasticCustomers";
import { createCustomersColumns } from "../CustomersColumns/CustomersColumns";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import CustomerListFilters from "../CustomerListFilters/CustomerListFilters";
import { StochasticCustomer } from "../../../../../../api/fetchStochasticCustomers/types";
import DataTable from "@/components/Datatable/Datatable";

const CustomerList: React.FC = () => {
  const {
    customers,
    totalCount,
    loading,
    error,
    pagination,
    handlePaginationChange,
    handleFilterChange,
    filters,
  } = useStochasticCustomers();

  const columns = useMemo(() => createCustomersColumns(), []);

  const onFilterChange = useCallback(
    (searchTerm: string, isActive: number) => {
      handleFilterChange(searchTerm, isActive);
      handlePaginationChange({
        pageIndex: 0,
        pageSize: pagination.pageSize,
      });
    },
    [handleFilterChange, handlePaginationChange, pagination.pageSize],
  );

  return (
    <MainPageWrapper error={error} loading={loading} title="Customers">
      <CustomerListFilters filters={filters} onFilterChange={onFilterChange} />

      <div className="relative">
        <DataTable<StochasticCustomer>
          columns={columns}
          data={customers}
          error={error}
          loading={loading}
          noDataMessage="No customers found"
          onPageChange={(newPageIndex, newPageSize) =>
            handlePaginationChange({
              pageIndex: newPageIndex,
              pageSize: newPageSize,
            })
          }
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          rowKeyExtractor={(item) => item.id}
          totalCount={totalCount}
        />
      </div>
    </MainPageWrapper>
  );
};

export default CustomerList;
