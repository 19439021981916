import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "@/app/rootReducer";
import {
  fetchSessionsAction,
  createSessionAction,
  editSessionAction,
  duplicateSessionAction,
  deleteSessionAction,
} from "../thunks/sessionThunks";
import { ApiEventSession } from "@/api/fetchEventSessions/types";
import { EventSessionFormData } from "../schemas/eventSessionSchema";

const sessionsAdapter = createEntityAdapter<ApiEventSession>();

// Define the error state shape
interface ErrorState {
  title: string;
  description: string;
}

// Define the state shape for session data
interface SessionsState {
  loading: boolean;
  error: ErrorState | null;
  totalCount: number;
  selectedIds: number[];
  dialog: {
    isSubmitting: boolean;
    submitError: ErrorState | null;
    showUnsavedChangesDialog: boolean;
    showResetDialog: boolean;
    formData?: EventSessionFormData;
  };
}

const initialState = sessionsAdapter.getInitialState<SessionsState>({
  loading: false,
  error: null,
  totalCount: 0,
  selectedIds: [],
  dialog: {
    isSubmitting: false,
    submitError: null,
    showUnsavedChangesDialog: false,
    showResetDialog: false,
    formData: undefined,
  },
});

// Create the slice
const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    toggleSessionSelection: (state, action: PayloadAction<number>) => {
      const sessionId = action.payload;
      const index = state.selectedIds.indexOf(sessionId);
      if (index === -1) {
        state.selectedIds.push(sessionId);
      } else {
        state.selectedIds.splice(index, 1);
      }
    },
    setSelectedSessions: (state, action: PayloadAction<number[]>) => {
      state.selectedIds = action.payload;
    },
    clearSelectedSessions: (state) => {
      state.selectedIds = [];
    },
    setDialogSubmitting: (state, action: PayloadAction<boolean>) => {
      state.dialog.isSubmitting = action.payload;
    },
    setDialogSubmitError: (state, action: PayloadAction<ErrorState | null>) => {
      state.dialog.submitError = action.payload;
    },
    setDialogUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.dialog.showUnsavedChangesDialog = action.payload;
    },
    setDialogReset: (state, action: PayloadAction<boolean>) => {
      state.dialog.showResetDialog = action.payload;
    },
    setDialogFormData: (state, action: PayloadAction<EventSessionFormData>) => {
      state.dialog.formData = action.payload;
    },
    resetDialogState: (state) => {
      state.dialog = initialState.dialog;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessionsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSessionsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.totalCount = action.payload.total;
        sessionsAdapter.setAll(state, action.payload.items);
      })
      .addCase(fetchSessionsAction.rejected, (state) => {
        state.loading = false;
        state.error = {
          title: "Error",
          description: "Failed to fetch sessions",
        };
      })
      .addCase(createSessionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSessionAction.fulfilled, (state, action) => {
        state.loading = false;
        sessionsAdapter.addOne(state, action.payload.data);
      })
      .addCase(createSessionAction.rejected, (state) => {
        state.loading = false;
        state.error = {
          title: "Error",
          description: "Failed to create session",
        };
      })
      // Edit session
      .addCase(editSessionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(editSessionAction.fulfilled, (state, action) => {
        state.loading = false;
        sessionsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(editSessionAction.rejected, (state) => {
        state.loading = false;
        state.error = {
          title: "Error",
          description: "Failed to edit session",
        };
      })
      // Duplicate session
      .addCase(duplicateSessionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(duplicateSessionAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          sessionsAdapter.addOne(state, action.payload);
        }
      })
      .addCase(duplicateSessionAction.rejected, (state) => {
        state.loading = false;
        state.error = {
          title: "Error",
          description: "Failed to duplicate session",
        };
      })
      // Delete session
      .addCase(deleteSessionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSessionAction.fulfilled, (state, action) => {
        state.loading = false;
        sessionsAdapter.removeOne(state, action.payload.id);
      })
      .addCase(deleteSessionAction.rejected, (state) => {
        state.loading = false;
        state.error = {
          title: "Error",
          description: "Failed to delete session",
        };
      });
  },
});

export const {
  toggleSessionSelection,
  setSelectedSessions,
  clearSelectedSessions,
  setDialogSubmitting,
  setDialogSubmitError,
  setDialogUnsavedChanges,
  setDialogReset,
  setDialogFormData,
  resetDialogState,
} = sessionsSlice.actions;

// Export selectors
export const {
  selectAll: selectAllSessions,
  selectById: selectSessionById,
  selectIds: selectSessionIds,
} = sessionsAdapter.getSelectors<RootState>((state) => state.sessions);

export default sessionsSlice.reducer;
