import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleUserSelection } from "../../../../hub/features/UserManagement/slices/usersSlice";
import { User } from "../../../../hub/features/UserManagement/slices/usersSlice";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { useNavigate } from "react-router-dom";
import {
  setSelectedSession,
  setPageSize,
  setPageIndex,
} from "../../../features/SessionManagement/slices/sessionUISlice";
import {
  useEventDetailsPageIndex,
  useEventDetailsPageSize,
} from "../selectors/eventDetailsSelectors";

export const useEventDetailsActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageIndex = useEventDetailsPageIndex();
  const pageSize = useEventDetailsPageSize();

  const [downloadingFileId, setDownloadingFileId] = useState<number | null>(
    null,
  );

  const handleUserSelection = useCallback(
    (user: User) => {
      dispatch(toggleUserSelection(user));
    },
    [dispatch],
  );

  const handleEnroll = (
    selectedSession: string | null,
    selectedUsers: User[],
  ) => {
    if (!selectedSession) {
      alert("Please select a session before enrolling.");
      return;
    }
    if (selectedUsers.length === 0) {
      alert("Please select at least one user to enroll.");
      return;
    }
    // Enrollment logic here with selectedUsers
  };

  const handleSessionClick = useCallback(
    (sessionId: number): void => {
      dispatch(setSelectedSession(sessionId.toString()));
    },
    [dispatch],
  );

  const handlePageChange = useCallback(
    (newPageIndex: number, newPageSize: number): void => {
      if (newPageSize !== pageSize) {
        dispatch(setPageSize(newPageSize));
      }
      if (newPageIndex !== pageIndex) {
        dispatch(setPageIndex(newPageIndex));
      }
    },
    [dispatch, pageIndex, pageSize],
  );

  const handleBackNavigation = useCallback((): void => {
    navigate("/event-registration/event-directory");
  }, [navigate]);

  const handleAttendeesNavigation = useCallback(
    (eventId: string | undefined): void => {
      if (eventId) {
        navigate(`/event-registration/events/${eventId}/attendees`);
      }
    },
    [navigate],
  );

  const handleFileDownload = async (file: ApiEventFile) => {
    try {
      setDownloadingFileId(file.id);
      const response = await fetch(file.fileUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("File download failed");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = file.fileName;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      // Add error notification here
    } finally {
      setDownloadingFileId(null);
    }
  };

  return {
    handleUserSelection,
    handleEnroll,
    handleFileDownload,
    downloadingFileId,
    handleSessionClick,
    handlePageChange,
    handleBackNavigation,
    handleAttendeesNavigation,
  };
};
