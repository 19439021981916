import { useState, useEffect, Dispatch, SetStateAction, useRef } from "react";
import { parseInputValue } from "../utils/dateUtils";
import { TimeOption } from "../utils/timeUtils";

interface UseDateTimeSyncProps {
  value: string | null | undefined;
  initialDate?: Date | null;
  initialTime?: string;
  onChange?: (value: string | null) => void;
  timeOptions: TimeOption[];
}

interface UseDateTimeSyncReturn {
  date: Date | null;
  time: string;
  setDate: Dispatch<SetStateAction<Date | null>>;
  setTime: Dispatch<SetStateAction<string>>;
  handleDateSelect: (selectedDate: Date | undefined) => void;
  handleTimeSelect: (newTime: string) => void;
}

/**
 * Custom hook to synchronize date and time state with an external value
 * Updates local date and time state when the external value changes
 * and calls onChange when local state changes
 */
export const useDateTimeSync = ({
  value,
  initialDate = null,
  initialTime = "00:00",
  onChange,
  timeOptions,
}: UseDateTimeSyncProps): UseDateTimeSyncReturn => {
  // Create a ref to track if this is the first render
  const skipInitialRender = useRef(true);

  // Initialize state with the provided initial values
  const [date, setDate] = useState<Date | null>(initialDate);
  const [time, setTime] = useState<string>(initialTime);

  // Update the date and time when the value prop changes
  useEffect(() => {
    // If the value prop changes, update our local state
    if (value !== undefined) {
      const newDateValue = parseInputValue(value);
      if (newDateValue) {
        // Only update if the date has actually changed to avoid infinite loops
        const currentDateTimestamp = date ? date.getTime() : null;
        const newDateTimestamp = newDateValue.getTime();

        if (currentDateTimestamp !== newDateTimestamp) {
          setDate(newDateValue);

          // Update the time to match the new date
          const hours = String(newDateValue.getHours()).padStart(2, "0");
          const minutes = String(newDateValue.getMinutes()).padStart(2, "0");
          const newTime = `${hours}:${minutes}`;
          setTime(newTime);
        }
      } else {
        // If value is empty or invalid, reset the date and time
        setDate(null);
        setTime("00:00");
      }
    }
  }, [value]); // Only depend on value changes

  // Adjust time if the selected date is today and the time is in the past
  useEffect(() => {
    if (date) {
      // Only update if the date has changed, not on every render
      const now = new Date();
      const isToday =
        date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear();

      if (isToday) {
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        // Check if the current time is in the past
        const [hours, minutes] = time.split(":").map(Number);
        if (
          hours < currentHour ||
          (hours === currentHour && minutes <= currentMinute)
        ) {
          // Find the next available time
          const availableOptions = timeOptions.filter((option) => {
            const [optHours, optMinutes] = option.value.split(":").map(Number);
            // If hour is greater, it's definitely in the future
            if (optHours > currentHour) return true;
            // If hour is the same, check minutes
            if (optHours === currentHour) return optMinutes > currentMinute;
            // If hour is less, it's definitely in the past
            return false;
          });

          if (availableOptions.length > 0) {
            const newTimeValue = availableOptions[0].value;

            // Only update if the time is actually different
            if (newTimeValue !== time) {
              setTime(newTimeValue);
            }
          }
        }
      }
    }
  }, [date, time, timeOptions]); // Only depend on date and timeOptions

  // Update the value when date or time changes
  useEffect(() => {
    // Skip if we're in the initial render or if date is null
    if (skipInitialRender.current || !date) {
      skipInitialRender.current = false;
      return;
    }

    // Only update if both date and time are set
    if (date && time) {
      // Create a new date with the selected date and time
      const [hours, minutes] = time.split(":").map(Number);
      const newDate = new Date(date);
      newDate.setHours(hours);
      newDate.setMinutes(minutes);

      // Format the date as ISO string
      const isoString = newDate.toISOString();

      // Only call onChange if the value has actually changed
      if (value !== isoString && onChange) {
        onChange(isoString);
      }
    } else if (date === null && value !== null && onChange) {
      // If date is cleared but value is not null, update value to null
      onChange(null);
    }
  }, [date, time, onChange, value]); // Include all dependencies for correctness

  const handleDateSelect = (selectedDate: Date | undefined) => {
    if (!selectedDate) {
      return;
    }
    setDate(selectedDate);
  };

  const handleTimeSelect = (newTime: string) => {
    setTime(newTime);
  };

  return {
    date,
    time,
    setDate,
    setTime,
    handleDateSelect,
    handleTimeSelect,
  };
};
