import axios from "../axiosInstance";
import { FetchCampaignProductsResponse } from "@/api/fetchCampaignProducts/types";

export const fetchCampaignProducts =
  async (): Promise<FetchCampaignProductsResponse> => {
    const response = await axios.get<FetchCampaignProductsResponse>(
      "/api/private/products/campaign",
    );
    return response.data;
  };
