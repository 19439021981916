import { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { MoreHorizontal, Edit, Trash2 } from "lucide-react";
import { ApiEventSession } from "@/api/fetchEventSessions/types";
import { DeleteConfirmationDialog } from "@/modules/eventRegistration/features/shared/components/DeleteConfirmationDialog";

export interface ActionMenuProps {
  session: ApiEventSession;
  onEdit: (session: ApiEventSession) => void;
  onDuplicate: (session: ApiEventSession) => void;
  onDelete: (sessionId: number) => void;
}

export const SessionManagementActionMenu: React.FC<ActionMenuProps> = ({
  session,
  onEdit,
  onDelete,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="h-8 w-8 p-0 hover:bg-gray-200 transition-colors"
            variant="ghost"
          >
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px] p-2 bg-white">
          <DropdownMenuItem
            className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
            onClick={() => onEdit(session)}
          >
            <Edit className="mr-2 h-4 w-4" />
            <span>Edit</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center px-3 py-2 text-sm cursor-pointer text-red-600 hover:bg-gray-100 transition-colors"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <Trash2 className="mr-2 h-4 w-4" />
            <span>Delete</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteConfirmationDialog
        description="Are you sure you want to delete this session? This action cannot be undone."
        isOpen={showDeleteConfirmation}
        onConfirm={() => {
          onDelete(session.id);
          setShowDeleteConfirmation(false);
        }}
        onOpenChange={setShowDeleteConfirmation}
        title="Delete Session"
      />
    </>
  );
};
