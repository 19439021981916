import { FilterOption } from "../hooks/useSessionManagementActions";
import { formatDateForInput } from "../utils/dateUtils";

export const DEFAULTS = {
  PAGE_SIZE: 5,
  INITIAL_PAGE: 1,
  SORT_BY: "startDate",
  SORT_ORDER: "desc" as const,
};

export const SESSION_FORM = {
  LABELS: {
    startDate: "Start Date",
    endDate: "End Date",
    maxEnrollments: "Maximum Enrollments",
    virtualLink: "Virtual Link",
    notes: "Notes",
    status: "Status",
  },
  DEFAULT_VALUES: {
    eventId: undefined as number | undefined,
    startDate: formatDateForInput(new Date().toISOString()),
    endDate: formatDateForInput(new Date().toISOString()),
    maxEnrollments: 1,
    virtualLink: "",
    notes: "",
    status: "SCHEDULED",
  },
  BUTTON_TEXT: {
    add: "Create Session",
    edit: "Save Changes",
    adding: "Adding...",
    saving: "Saving...",
    cancel: "Cancel",
    discard: "Discard Changes",
    reset: "Reset Form",
    create: "Create Session",
    update: "Update Session",
    creating: "Creating...",
    updating: "Updating...",
  },
  TITLES: {
    create: "Add New Session",
    edit: "Edit Session",
    unsavedChanges: "Unsaved Changes",
    resetForm: "Reset Form",
  },
  PLACEHOLDERS: {
    startDate: "Select start date",
    endDate: "Select end date",
    maxEnrollments: "Enter maximum enrollments",
    virtualLink: "Enter virtual link",
    notes: "Enter notes",
    status: "Select status",
  },
  MESSAGES: {
    unsavedChanges:
      "You have unsaved changes. Are you sure you want to discard them?",
    resetForm:
      "Are you sure you want to reset the form? All changes will be lost.",
  },
  VALIDATION: {
    REQUIRED: "This field is required",
    MAX_CAPACITY: {
      MIN: "Maximum capacity must be at least 1",
      MAX: "Maximum capacity cannot exceed 100",
    },
    DATE: {
      INVALID: "Invalid date",
      END_AFTER_START: "End date must be after start date",
    },
  },
  STATUS_OPTIONS: [
    { label: "Scheduled", value: "SCHEDULED" },
    { label: "In Progress", value: "IN_PROGRESS" },
    { label: "Completed", value: "COMPLETED" },
    { label: "Cancelled", value: "CANCELLED" },
  ],
};

export const TOAST_MESSAGES = {
  FETCH_ERROR: {
    title: "Error",
    description: "Failed to fetch sessions",
  },
  SUBMIT_ERROR: {
    title: "Error",
    description: "Failed to save session",
  },
  DELETE_ERROR: {
    title: "Error",
    description: "Failed to delete session",
  },
  DUPLICATE_ERROR: {
    title: "Error",
    description: "Failed to duplicate session",
  },
  CREATE_SUCCESS: {
    title: "Success",
    description: "Session created successfully",
  },
  UPDATE_SUCCESS: {
    title: "Success",
    description: "Session updated successfully",
  },
  DELETE_SUCCESS: {
    title: "Success",
    description: "Session deleted successfully",
  },
  DUPLICATE_SUCCESS: {
    title: "Success",
    description: "Session duplicated successfully",
  },
};

export const SESSION_STATUS_OPTIONS: FilterOption[] = [
  { label: "Scheduled", value: "SCHEDULED" },
  { label: "In Progress", value: "IN_PROGRESS" },
  { label: "Completed", value: "COMPLETED" },
  { label: "Cancelled", value: "CANCELLED" },
];

// Map UI status values to API status values
export const SESSION_STATUS_API_MAPPING = {
  SCHEDULED: "scheduled",
  IN_PROGRESS: "in-progress",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
};

export const FILTER_TYPES = {
  INSTRUCTOR: "INSTRUCTOR",
  STATUS: "STATUS",
} as const;

export const FILTER_LABELS = {
  [FILTER_TYPES.INSTRUCTOR]: "Instructor",
  [FILTER_TYPES.STATUS]: "Status",
} as const;

export const COLUMN_HEADERS = {
  EVENT_NAME: "Event Name",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  VIRTUAL_LINK: "Virtual Link",
  MAX_ENROLLMENTS: "Maximum Enrollments",
  INSTRUCTOR: "Instructor",
  STATUS: "Status",
  ACTIONS: "Actions",
};

export const TABLE_MESSAGES = {
  NO_SESSIONS: "No sessions found",
  LOADING: "Loading sessions...",
};

export const DIALOG_TITLES = {
  CREATE: "Create Session",
  EDIT: "Edit Session",
  DELETE: "Delete Session",
  DUPLICATE: "Duplicate Session",
};

export const DIALOG_MESSAGES = {
  DELETE_CONFIRMATION: "Are you sure you want to delete this session?",
  UNSAVED_CHANGES: "You have unsaved changes. Are you sure you want to close?",
};

export const BUTTON_LABELS = {
  CREATE: "Create Session",
  EDIT: "Edit Session",
  DELETE: "Delete Session",
  DUPLICATE: "Duplicate Session",
  CANCEL: "Cancel",
  SAVE: "Save",
  CONFIRM: "Confirm",
};

export const FILTER_SECTIONS = {
  STATUS: {
    title: "Status",
    options: SESSION_STATUS_OPTIONS,
  },
};
