export type DateObject = {
  date: string;
  timezone_type: number;
  timezone: string;
};

/**
 * Formats a date string or Date object into a format suitable for datetime-local input
 * @param date - The date to format (string or Date object)
 * @returns A string in the format YYYY-MM-DDTHH:MM
 */
export const formatDateForInput = (
  date: string | Date | DateObject | undefined,
): string => {
  if (!date) {
    return "";
  }

  try {
    if (
      typeof date === "string" &&
      date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)
    ) {
      return date;
    }

    let dateObj: Date;

    if (typeof date === "string") {
      dateObj = new Date(date);
    } else if (date instanceof Date) {
      dateObj = date;
    } else if (typeof date === "object" && "date" in date) {
      dateObj = new Date(date.date);
    } else {
      return "";
    }

    if (isNaN(dateObj.getTime())) {
      return "";
    }

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    const formatted = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formatted;
  } catch (error) {
    console.error("Error formatting date for input:", error);
    return "";
  }
};

export const formatLocalDateTime = (
  dateString: string | null | undefined,
): string => {
  if (!dateString) return "";

  try {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return "";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

export const formatDateForDisplay = (
  dateValue: string | DateObject,
): string => {
  try {
    if (!dateValue) return "";

    const dateString =
      typeof dateValue === "object" && "date" in dateValue
        ? dateValue.date
        : dateValue;

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateValue);
      return "";
    }

    return new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
      .format(date)
      .replace(",", "");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

export const parseInputValue = (
  value: string | null | undefined,
): Date | null => {
  if (!value) {
    return null;
  }

  try {
    const date = new Date(value);

    if (isNaN(date.getTime())) {
      return null;
    }

    return date;
  } catch {
    return null;
  }
};
