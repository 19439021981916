import { useSelector } from "react-redux";
import { ApiEventSession } from "@/api/fetchEventSessions/types";
import { selectSelectedSessionIds } from "../selectors/sessionManagementSelectors";
import { useTableColumns } from "./useTableColumns";
import { useSessionManagementActions } from "./useSessionManagementActions";

interface UseSessionManagementTableColumnsProps {
  sessions: ApiEventSession[];
  onEditSession: (session: ApiEventSession) => void;
  eventId: string | number;
}

export const useSessionManagementTableColumns = ({
  sessions,
  onEditSession,
  eventId,
}: UseSessionManagementTableColumnsProps) => {
  const selectedSessions = useSelector(selectSelectedSessionIds);

  const {
    handleSelectSession,
    handleSelectAll,
    handleDuplicateSession,
    handleDeleteSession,
  } = useSessionManagementActions({
    currentRequest: {
      eventId: typeof eventId === "string" ? parseInt(eventId, 10) : eventId,
    },
    eventId,
    sessionToEdit: null,
    sessions,
  });

  const columns = useTableColumns({
    selectedSessions,
    onSelectSession: handleSelectSession,
    onEditSession,
    onDuplicateSession: handleDuplicateSession,
    onDeleteSession: handleDeleteSession,
    onSelectAll: handleSelectAll,
    isAllSelected:
      selectedSessions.length > 0 &&
      selectedSessions.length === sessions.length,
  });

  return columns;
};
