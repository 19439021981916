import React from "react";
import { Button } from "@/components/ui/button";
import { PlusCircle, Loader2 } from "lucide-react";
import EventManagementSearch from "../EventManagementSearch/EventManagementSearch";
import EventManagementFilters from "../EventManagementFilters/EventManagementFilters";
import { EventFilters } from "../../hooks/useEventManagementActions";

export interface EventManagementToolbarProps {
  isLoading: boolean;
  onSearch: (query: string) => void;
  onFilterChange: (filters: EventFilters) => void;
  onAddEvent: () => void;
}

export const EventManagementToolbar: React.FC<EventManagementToolbarProps> = ({
  isLoading,
  onSearch,
  onFilterChange,
  onAddEvent,
}) => (
  <div className="flex flex-col gap-4 md:flex-row md:items-center">
    <div className="w-full md:flex-1">
      <EventManagementSearch onSearch={onSearch} />
    </div>
    <div className="flex flex-row gap-4 w-full md:w-auto">
      <div className="flex-1 md:flex-initial">
        <EventManagementFilters onFilterChange={onFilterChange} />
      </div>
      <Button
        className="flex-1 md:flex-initial h-10 sm:min-w-[120px] px-4 py-2 bg-primary text-white hover:bg-primary/90"
        disabled={isLoading}
        onClick={onAddEvent}
      >
        {isLoading ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <PlusCircle className="mr-2 h-4 w-4" />
        )}
        Create Event
      </Button>
    </div>
  </div>
);
