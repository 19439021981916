import { axiosInstance } from "../axiosInstance";
import { FileType, UploadEventFilesResponse } from "./types";

export const uploadEventFiles = async (
  eventId: number,
  files: File[],
  fileType: FileType = "other",
): Promise<UploadEventFilesResponse> => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files[]", file);
  });
  formData.append("fileType", fileType);

  const { data } = await axiosInstance.post<UploadEventFilesResponse>(
    `/api/private/events/${eventId}/upload-files`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return data;
};
