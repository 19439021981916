import { useMemo } from "react";
import { Calendar as CalendarIcon } from "lucide-react";
import { DayPicker } from "react-day-picker";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  TIME_OPTIONS,
  getAvailableTimeOptions,
  getTimeDisplayLabel,
} from "../../utils/timeUtils";
import { formatDateForDisplay, parseInputValue } from "../../utils/dateUtils";
import { useDateTimeSync } from "../../hooks/useDateTimeSync";

interface SessionDateTimePickerProps {
  className?: string;
  disabled?: boolean;
  minDate?: Date;
  onChange?: (value: string | null) => void;
  placeholder?: string;
  value?: string | null;
}

export function SessionDateTimePicker({
  value,
  onChange,
  disabled,
  className,
  minDate,
  placeholder = "Pick a date",
}: SessionDateTimePickerProps) {
  // Use current date as minimum date if not provided
  const today = new Date();
  const currentMinDate = minDate || today;

  // Parse the input value into a Date object and memoize it to prevent unnecessary re-renders
  const dateValue = useMemo(() => parseInputValue(value), [value]);

  // Use the custom hook to sync date and time with the value prop
  const { date, time, handleDateSelect, handleTimeSelect } = useDateTimeSync({
    value,
    initialDate: dateValue,
    initialTime: dateValue
      ? `${String(dateValue.getHours()).padStart(2, "0")}:${String(
          dateValue.getMinutes(),
        ).padStart(2, "0")}`
      : "00:00",
    onChange,
    timeOptions: TIME_OPTIONS,
  });

  // Get available time options based on the selected date
  const availableTimeOptions = getAvailableTimeOptions(date, TIME_OPTIONS);

  return (
    <div className="flex flex-col space-y-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            className={cn(
              "w-full justify-start text-left font-normal",
              !value && "text-muted-foreground",
              disabled && "opacity-50 cursor-not-allowed",
              className,
            )}
            disabled={disabled}
            variant="outline"
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {value
              ? date
                ? formatDateForDisplay(value)
                : value
              : placeholder || <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-auto p-2 bg-white">
          <div className="space-y-2 p-3">
            <div className="flex items-center space-x-2">
              <Select
                disabled={disabled || availableTimeOptions.length === 0}
                onValueChange={handleTimeSelect}
                value={time}
              >
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder="Select time">
                    {getTimeDisplayLabel(time, TIME_OPTIONS)}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {availableTimeOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <DayPicker
            disabled={[{ before: currentMinDate }]}
            mode="single"
            onSelect={handleDateSelect}
            selected={date ?? undefined}
            showOutsideDays
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
