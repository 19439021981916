import React from "react";
import { Column } from "../../hooks/useTableColumns";

export interface TableHeaderProps {
  columns: Column[];
}

export const EventManagementTableHeader: React.FC<TableHeaderProps> = ({
  columns,
}) => (
  <thead className="[&_tr]:border-b">
    <tr className="bg-gray-50/75">
      {columns.map((column) => (
        <th
          className="h-11 px-4 text-left align-middle font-semibold text-muted-foreground"
          key={column.id || column.accessorKey}
        >
          {typeof column.header === "function"
            ? column.header()
            : column.header || column.accessorKey}
        </th>
      ))}
    </tr>
  </thead>
);
