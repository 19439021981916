import React from "react";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { MultiSelect } from "@/components/MultiSelect/MultiSelect";
import { DatePicker } from "@/components/DatePicker/DatePicker";
import { SelectWithOptions } from "@/components/SelectWithOptions/SelectWithOptions";
import { ContentBlockEditor } from "@/modules/hub/features/ResourceLibrary/components/ContentBlockEditor/ContentBlockEditor";
import { ImageUpload } from "@/components/ImageUpload/ImageUpload";
import MainPageWrapper from "@/components/MainPageWrapper/MainPageWrapper";
import { useCreateResource } from "@/modules/hub/features/ResourceLibrary/hooks/useCreateResource/useCreateResource";
import { uploadTmpFile } from "@/api/uploadTmpFile/uploadTmpFileApi";
import { getResourceCategories } from "@/api/getResourceCategories/getResourceCategoriesApi";
import { createResourceCategory } from "@/api/createResourceCategory/createResourceCategoryApi";
import { EntityMultiSelect } from "@/components/EntityMultiSelect/EntityMultiSelect";
import { getResourceTags } from "@/api/getResourceTags/getResourceTagsApi";
import { createResourceTag } from "@/api/createResourceTag/createResourceTagApi";

export function CreateResource() {
  const {
    form,
    isLoading,
    requiresContentUrl,
    submitForm,
    handleCancel,
    trades,
    roles,
    resourceTypes,
  } = useCreateResource();

  const { control, handleSubmit, formState, setValue, getValues } = form;
  const { isSubmitting } = formState;

  return (
    <MainPageWrapper title="Create Resource">
      <Form {...form}>
        <form
          className="space-y-8 pb-24 bg-white"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="text-sm text-muted-foreground mb-2">
            Fields marked with an asterisk (*) are required.
          </div>

          <FormField
            control={control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Title *</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="tagline"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Tagline</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description *</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Type *</FormLabel>
                <FormControl>
                  <SelectWithOptions
                    onValueChange={(val) => field.onChange(Number(val))}
                    options={resourceTypes.map((rt) => ({
                      label: rt.name.toUpperCase(),
                      value: String(rt.id),
                    }))}
                    value={field.value ? String(field.value) : ""}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {requiresContentUrl && (
            <FormField
              control={control}
              name="content_url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Content URL *</FormLabel>
                  <FormControl>
                    <Input {...field} type="url" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <FormField
            control={control}
            name="thumbnail_url"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Thumbnail</FormLabel>
                <FormControl>
                  <ImageUpload
                    label="Upload thumbnail"
                    onChange={(val) => {
                      if (typeof val === "string") {
                        field.onChange(val);
                      } else {
                        field.onChange(val.fileUrl);
                        setValue("thumbnailFileId", val.fileId, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: false,
                        });
                      }
                    }}
                    onUpload={async (file) => {
                      const response = await uploadTmpFile(file);
                      return {
                        path: response.data.fileUrl,
                        url: response.data.fileUrl,
                        fileId: response.data.fileId,
                      };
                    }}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid grid-cols-2 gap-6">
            <FormField
              control={control}
              name="publish_start_date"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Publish Start Date</FormLabel>
                  <FormControl>
                    <DatePicker onChange={field.onChange} value={field.value} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={control}
              name="publish_end_date"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Publish End Date</FormLabel>
                  <FormControl>
                    <DatePicker
                      minDate={getValues("publish_start_date") || null}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={control}
            name="is_published"
            render={({ field }) => (
              <FormItem className="flex items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Published</FormLabel>
                  <FormDescription>
                    Make this resource available to users
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FormField
              control={control}
              name="categories"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Categories</FormLabel>
                  <FormControl>
                    <EntityMultiSelect
                      createEntity={async ({ name }) => {
                        const res = await createResourceCategory({ name });
                        return {
                          id: res.data.id ?? 0,
                          name: res.data.name ?? "",
                        };
                      }}
                      entityNamePlural="Categories"
                      entityNameSingular="Category"
                      fetchEntities={async ({ searchTerm, page, pageSize }) => {
                        const response = await getResourceCategories({
                          name: searchTerm,
                          page,
                          pageSize,
                          sortBy: "name",
                          sortOrder: "ASC",
                        });
                        const { categories } = response.data;
                        const totalCount =
                          response.meta?.totalCount ?? categories.length;

                        return {
                          data: categories.map((c) => ({
                            id: c.id,
                            name: c.name,
                          })),
                          totalCount,
                        };
                      }}
                      onChange={field.onChange}
                      value={field.value || []}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={control}
              name="tags"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tags</FormLabel>
                  <FormControl>
                    <EntityMultiSelect
                      createEntity={async ({ name }) => {
                        const res = await createResourceTag({ name });
                        return {
                          id: res.data.id ?? 0,
                          name: res.data.name ?? "",
                        };
                      }}
                      entityNamePlural="Tags"
                      entityNameSingular="Tag"
                      fetchEntities={async ({ searchTerm, page, pageSize }) => {
                        const response = await getResourceTags({
                          name: searchTerm,
                          page,
                          pageSize,
                          sortBy: "name",
                          sortOrder: "ASC",
                        });
                        const { tags } = response.data;
                        const totalCount =
                          response.meta?.totalCount ?? tags.length;

                        return {
                          data: tags.map((t) => ({
                            id: t.id,
                            name: t.name,
                          })),
                          totalCount,
                        };
                      }}
                      onChange={field.onChange}
                      value={field.value || []}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FormField
              control={control}
              name="tradeIds"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Trades</FormLabel>
                  <FormControl>
                    <MultiSelect
                      onChange={field.onChange}
                      options={trades.map((tr) => ({
                        label: tr.name,
                        value: String(tr.id),
                      }))}
                      placeholder="Select trades..."
                      value={field.value?.map(String) ?? []}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={control}
              name="roleIds"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Roles</FormLabel>
                  <FormControl>
                    <MultiSelect
                      onChange={field.onChange}
                      options={roles.map((r) => ({
                        label: r.name,
                        value: String(r.id),
                      }))}
                      placeholder="Select roles..."
                      value={field.value?.map(String) ?? []}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={control}
            name="contentBlocks"
            render={({ field }) => (
              <FormItem className="space-y-4">
                <div className="flex flex-col space-y-2">
                  <FormLabel className="text-lg font-semibold">
                    Content Blocks
                  </FormLabel>
                  <FormDescription>
                    Add content blocks to structure your resource.
                  </FormDescription>
                </div>
                <FormControl>
                  <ContentBlockEditor
                    blocks={field.value || []}
                    onChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="sticky bottom-0 flex justify-end gap-4 px-4 py-4 border-t bg-white mt-8">
            <Button onClick={handleCancel} type="button" variant="outline">
              Cancel
            </Button>
            <Button type="submit">
              {isSubmitting || isLoading ? "Saving..." : "Save"}
            </Button>
          </div>
        </form>
      </Form>
    </MainPageWrapper>
  );
}

export default CreateResource;
