import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "@/components/Button/Button";
import { useDebouncedValue } from "@/hooks/useDebouncedValue";
import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator";
import CreateEntityDrawer from "@/components/CreateEntityDrawer/CreateEntityDrawer";
import { CheckIcon } from "@heroicons/react/24/outline";

export interface Entity {
  id: number;
  name: string;
}

export type FetchEntitiesFn = (params: {
  searchTerm: string;
  page: number;
  pageSize: number;
}) => Promise<{
  data: Entity[];
  totalCount: number;
}>;

export type CreateEntityFn = (params: { name: string }) => Promise<Entity>;

interface EntityPickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  fetchEntities: FetchEntitiesFn;
  createEntity?: CreateEntityFn;
  initialSelectedEntities: Entity[];
  onConfirm: (selectedEntities: Entity[]) => void;
  entityNameSingular?: string;
  entityNamePlural?: string;
}

export default function EntityPickerModal({
  isOpen,
  onClose,
  fetchEntities,
  createEntity,
  initialSelectedEntities,
  onConfirm,
  entityNameSingular = "Entity",
  entityNamePlural = "Entities",
}: EntityPickerModalProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [entities, setEntities] = useState<Entity[]>([]);
  const [selectedEntities, setSelectedEntities] = useState<Entity[]>(
    initialSelectedEntities,
  );
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);
  const [isLoadingInitial, setIsLoadingInitial] = useState(false);
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);

  const headingText = `Please Select ${entityNamePlural}`;

  useEffect(() => {
    if (isOpen) {
      setSelectedEntities(initialSelectedEntities);
      setSearchTerm("");
      setEntities([]);
      setPage(1);
      setHasMore(true);
      setIsLoadingInitial(false);
      setShowCreateDrawer(false);
    }
  }, [isOpen, initialSelectedEntities]);

  useEffect(() => {
    if (!isOpen) return;
    setEntities([]);
    setPage(1);
    setHasMore(true);
    loadEntities(1, debouncedSearchTerm);
  }, [isOpen, debouncedSearchTerm]);

  const loadEntities = (pageNumber: number, sTerm: string) => {
    if (pageNumber === 1) setIsLoadingInitial(true);
    fetchEntities({
      searchTerm: sTerm,
      page: pageNumber,
      pageSize: 10,
    })
      .then((res) => {
        const newData = res.data;
        setEntities((prev) =>
          pageNumber === 1 ? newData : [...prev, ...newData],
        );
        if (newData.length < 10) {
          setHasMore(false);
        }
      })
      .catch(() => {
        setHasMore(false);
      })
      .finally(() => setIsLoadingInitial(false));
  };

  const fetchNext = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    loadEntities(nextPage, debouncedSearchTerm);
  };

  /**
   * Toggle an item in or out of the local "selectedEntities".
   */
  const toggleSelection = (ent: Entity) => {
    setSelectedEntities((prev) => {
      const alreadySelected = prev.some((x) => x.id === ent.id);
      if (alreadySelected) {
        return prev.filter((x) => x.id !== ent.id);
      } else {
        return [...prev, ent];
      }
    });
  };

  /**
   * Called from the CreateEntityDrawer after a new entity is created.
   * We add it to the top of the "entities" list and mark it as selected.
   */
  const handleEntityCreated = (newEntity: Entity) => {
    setEntities((prev) => [newEntity, ...prev]);
    setSelectedEntities((prev) => [...prev, newEntity]);
  };

  const renderEntityRow = (ent: Entity) => {
    const isSelected = selectedEntities.some((x) => x.id === ent.id);
    return (
      <button
        className={`block w-full text-left px-4 py-2 rounded-md mb-1 flex items-center justify-between ${
          isSelected ? "border border-primary" : "bg-gray-50 hover:bg-gray-100"
        }`}
        key={ent.id}
        onClick={() => toggleSelection(ent)}
      >
        <span>{ent.name}</span>
        {isSelected && (
          <CheckIcon aria-hidden="true" className="h-5 w-5 text-primary-dark" />
        )}
      </button>
    );
  };

  return (
    <Modal
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80vw] md:w-[40vw] h-[80vh] flex flex-col rounded-lg p-0 bg-white shadow-xl"
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="fixed inset-0 bg-black bg-opacity-30 z-[9999]"
    >
      <div className="flex-shrink-0 p-4 border-b flex items-center justify-between">
        <h2 className="text-lg font-semibold text-secondary">{headingText}</h2>
        {createEntity && (
          <Button
            className="bg-primary text-white hover:bg-primary-dark"
            onClick={() => setShowCreateDrawer(true)}
          >
            {`New ${entityNameSingular}`}
          </Button>
        )}
      </div>

      <div className="flex-shrink-0 p-4 border-b">
        <input
          className="border border-gray-300 rounded px-2 py-1 w-full"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={`Search ${entityNamePlural}...`}
          type="text"
          value={searchTerm}
        />
      </div>

      <div className="flex-1 overflow-auto p-4" id="infinite-scroller-parent">
        {isLoadingInitial ? (
          <div className="flex items-center justify-center w-full h-full">
            <LoadingIndicator isFullScreen={false} />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={entities.length}
            endMessage={
              <p className="text-center mt-2">{`No more ${entityNamePlural}.`}</p>
            }
            hasMore={hasMore}
            loader={<p className="text-center mt-2">Loading more...</p>}
            next={fetchNext}
            scrollableTarget="infinite-scroller-parent"
            style={{ overflow: "visible" }}
          >
            <div className="space-y-2">
              {entities.map(renderEntityRow)}
              {!isLoadingInitial && entities.length === 0 && (
                <p className="text-center text-gray-500">
                  {`No ${entityNamePlural} found.`}
                </p>
              )}
            </div>
          </InfiniteScroll>
        )}
      </div>

      <div className="flex-shrink-0 p-4 border-t flex justify-end space-x-3">
        <Button onClick={onClose} type="button" variant="outline">
          Cancel
        </Button>
        <Button
          className="bg-primary text-white hover:bg-primary-dark"
          onClick={() => onConfirm(selectedEntities)}
          type="button"
        >
          Confirm
        </Button>
      </div>

      {createEntity && (
        <CreateEntityDrawer
          createEntity={createEntity}
          entityNameSingular={entityNameSingular}
          isOpen={showCreateDrawer}
          onClose={() => setShowCreateDrawer(false)}
          onEntityCreated={handleEntityCreated}
        />
      )}
    </Modal>
  );
}
