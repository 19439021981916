import axios from "../axiosInstance";
import { DeleteEventFileResponse } from "./types";

export const deleteEventFile = async (
  eventFileId: number,
): Promise<DeleteEventFileResponse> => {
  const response = await axios.delete<DeleteEventFileResponse>(
    `/api/private/event-files/${eventFileId}`,
  );
  return response.data;
};
