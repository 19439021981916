import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/app/hooks";
import {
  editEventTypeAction,
  createEventTypeAction,
} from "@/modules/eventRegistration/features/EventDirectory/thunks/eventThunks";
import { EventTypeFormData, eventTypeSchema } from "../schemas/eventSchema";
import { ApiEventType } from "@/api/fetchEventTypes/types";

export const useEditEventTypeForm = (eventType: ApiEventType | null) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<EventTypeFormData>({
    resolver: zodResolver(eventTypeSchema),
    defaultValues: {
      name: eventType?.name ?? "",
      description: eventType?.description ?? null,
      isActive: eventType?.isActive ?? true,
    },
  });

  const handleSubmit = async (data: EventTypeFormData): Promise<boolean> => {
    setIsSubmitting(true);
    try {
      if (eventType) {
        await dispatch(
          editEventTypeAction({
            eventTypeId: eventType.id,
            editEventTypeDTO: data,
          }),
        ).unwrap();
      } else {
        await dispatch(
          createEventTypeAction({
            createEventTypeDTO: data,
          }),
        ).unwrap();
      }
      return true;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    form,
    handleSubmit,
    isSubmitting,
  };
};
