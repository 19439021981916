import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchEventsAction,
  fetchEventFilesAction,
} from "../../EventDirectory/thunks/eventThunks";
import { fetchSessionsAction } from "../../SessionManagement/thunks/sessionThunks";

export const useLoadEventDetails = (eventId: string | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (eventId) {
      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(fetchEventsAction()),
            dispatch(fetchSessionsAction({ eventId: Number(eventId) })),
            dispatch(fetchEventFilesAction(Number(eventId))),
          ]);
        } catch (error) {
          console.error("Error loading event details:", error);
        }
      };
      fetchData();
    }
  }, [eventId, dispatch]);
};
