import React from "react";
import { StochasticClientMailDataRow } from "../../../../../../api/fetchStochasticClientMailData/types";
import { Column } from "@/components/Datatable/Datatable";

export function createStochasticMailingColumns(): Column<StochasticClientMailDataRow>[] {
  return [
    {
      header: "Intacct ID",
      accessorKey: "intacctId",
      cell: ({ row }) => {
        const { intacctId } = row.original;
        return (
          <div className="cursor-pointer hover:text-blue-600">
            {intacctId || "N/A"}
          </div>
        );
      },
    },
    {
      header: "Client Name",
      accessorKey: "clientName",
      cell: ({ row }) => {
        const { clientName } = row.original;
        return (
          <div className="cursor-pointer hover:text-blue-600">
            {clientName || "N/A"}
          </div>
        );
      },
    },
    {
      header: "Campaign ID",
      accessorKey: "campaign_id",
      cell: ({ row }) => {
        const { campaignId } = row.original;
        return (
          <div className="cursor-pointer hover:text-blue-600">
            {campaignId || "N/A"}
          </div>
        );
      },
    },
    {
      header: "Campaign Name",
      accessorKey: "campaign_name",
      cell: ({ row }) => {
        const { campaignName } = row.original;
        return (
          <div className="cursor-pointer hover:text-blue-600">
            {campaignName || "N/A"}
          </div>
        );
      },
    },
    {
      header: "Batch Number",
      accessorKey: "batch_number",
      cell: ({ row }) => {
        const { batchNumber } = row.original;
        return (
          <div className="cursor-pointer hover:text-blue-600">
            {batchNumber}
          </div>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "batch_status",
      cell: ({ row }) => {
        const { batchStatus } = row.original;
        return (
          <div className="cursor-pointer hover:text-blue-600">
            {batchStatus || "N/A"}
          </div>
        );
      },
    },
    {
      header: "Prospect Count",
      accessorKey: "prospect_count",
      cell: ({ row }) => {
        const { prospectCount } = row.original;
        return (
          <div className="cursor-pointer hover:text-blue-600">
            {prospectCount ?? 0}
          </div>
        );
      },
    },
  ];
}
