import React from "react";
import { User } from "../../slices/usersSlice";
import UserManagementActionMenu from "../UserManagementActionMenu/UserManagementActionMenu";
import { Column } from "@/components/Datatable/Datatable";

interface CreateUsersColumnsProps {
  handleEdit: (uuid: string) => void;
  handleImpersonateUser: (uuid: string) => void;
}

export function createUsersColumns({
  handleEdit,
  handleImpersonateUser,
}: CreateUsersColumnsProps): Column<User>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "First Name",
      accessorKey: "firstName",
    },
    {
      header: "Last Name",
      accessorKey: "lastName",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Salesforce ID",
      accessorKey: "salesforceId",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const userItem = row.original;
        return (
          <UserManagementActionMenu
            employeeUuid={userItem.employeeUuid || ""}
            onEdit={handleEdit}
            onImpersonate={handleImpersonateUser}
            userUuid={userItem.uuid || ""}
          />
        );
      },
    },
  ];
}
