import axios from "../axiosInstance";
import { DeleteEventSessionResponse } from "./types";

export const deleteEventSession = async (
  id: number,
): Promise<DeleteEventSessionResponse> => {
  const response = await axios.delete<DeleteEventSessionResponse>(
    `/api/private/event-sessions/${id}`,
  );
  return response.data;
};
