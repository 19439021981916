import { useEffect, useRef } from "react";
import { useAppDispatch } from "@/app/hooks";
import {
  setDialogSubmitting,
  setDialogSubmitError,
} from "@/modules/eventRegistration/features/EventDirectory/slices/eventsSlice";

/**
 * Hook to reset dialog state when it opens
 * @param open - Whether the dialog is open
 */
export const useDialogReset = (open: boolean): void => {
  const dispatch = useAppDispatch();
  const openRef = useRef(open);

  useEffect(() => {
    // Only run this effect if the open state has actually changed
    if (openRef.current !== open) {
      openRef.current = open;
      if (open) {
        dispatch(setDialogSubmitting(false));
        dispatch(setDialogSubmitError(null));
      }
    }
  }, [open, dispatch]);
};
