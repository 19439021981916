import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/rootReducer";
import { selectAllSessions } from "../slices/sessionsSlice";
import { DEFAULTS } from "../constants/sessionManagementConstants";

export const selectSessionDirectoryState = (state: RootState) => state.sessions;
export const selectSessionUIState = (state: RootState) => state.sessionUI;

// Define the selectSessionsByEventId selector directly in this file
export const selectSessionsByEventId = createSelector(
  [selectAllSessions, (state: RootState, eventId: number) => eventId],
  (sessions, eventId) =>
    sessions.filter((session) => session.eventId === eventId),
);

export const selectSessionsWithLoadingState = createSelector(
  [selectAllSessions, selectSessionDirectoryState],
  (sessions, sessionState) => ({
    sessions,
    isLoadingSessions: sessionState.loading,
    sessionsError: sessionState.error,
    totalCount: sessionState.totalCount,
  }),
);

export const selectCurrentRequestState = (state: RootState) =>
  state.sessionUI.currentRequest;

export const selectSelectedFilters = createSelector(
  [selectCurrentRequestState],
  (currentRequest): Record<string, string[]> => ({
    status: currentRequest.status ? [currentRequest.status] : [],
  }),
);

export const selectCurrentPage = (state: RootState) =>
  state.sessionUI.currentRequest.page || 1;

export const selectIsLoading = (state: RootState) => state.sessions.loading;

export const selectTotalSessionCount = (state: RootState) =>
  state.sessions.totalCount;

export const selectPageSize = (state: RootState) =>
  state.sessionUI.currentRequest.pageSize ?? DEFAULTS.PAGE_SIZE;

export const selectTotalPages = createSelector(
  [selectTotalSessionCount, selectPageSize],
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize),
);

// Using createSelector to memoize the result object
// This prevents unnecessary re-renders when the selector is called with the same state
export const selectSessionDialogState = createSelector(
  [
    (state: RootState) => state.sessionUI.showSessionDialog,
    (state: RootState) => state.sessionUI.sessionToEdit,
  ],
  (showSessionDialog, sessionToEdit) => ({
    showSessionDialog,
    sessionToEdit,
  }),
);

// Selectors moved from SessionManagementTable
export const selectSessionPagination = createSelector(
  [selectCurrentRequestState, selectTotalSessionCount],
  (currentRequest, totalCount) => ({
    pageIndex: (currentRequest?.page ?? 1) - 1,
    pageSize: currentRequest?.pageSize ?? DEFAULTS.PAGE_SIZE,
    totalCount: totalCount,
  }),
);

export const selectSelectedSessionIds = (state: RootState) =>
  state.sessions.selectedIds;

// Dialog state selectors
export const selectSessionDialogStateFromSlice = (state: RootState) =>
  state.sessions.dialog;

export const selectShowUnsavedChangesDialog = (state: RootState) =>
  state.sessions.dialog.showUnsavedChangesDialog;

export const selectShowResetDialog = (state: RootState) =>
  state.sessions.dialog.showResetDialog;

// UI state selectors moved from sessionUISlice.ts
export const selectShowSessionDialog = (state: RootState) =>
  state.sessionUI.showSessionDialog;

export const selectSessionToEdit = (state: RootState) =>
  state.sessionUI.sessionToEdit;

export const selectShowDeleteSessionDialog = (state: RootState) =>
  state.sessionUI.showDeleteSessionDialog;

export const selectIsSubmitting = (state: RootState) =>
  state.sessionUI.isSubmitting;

export const selectSubmitError = (state: RootState) =>
  state.sessionUI.submitError;

export const selectSelectedSession = (state: RootState) =>
  state.sessionUI.selectedSession;

export const selectSessionsLoading = (state: RootState) =>
  state.sessionUI.sessionsLoading;
