import { useCallback, useEffect } from "react";
import { useAppDispatch } from "@/app/hooks";
import {
  setCurrentRequest,
  setSelectedCategories,
  setSelectedDurations,
} from "../slices/eventUISlice";
import { fetchEventsAction } from "../thunks/eventThunks";
import { FetchEventsRequest } from "@/api/fetchEvents/types";
import { useToastError } from "../../EventManagement/hooks/useToastError";
import { EventFilters } from "../components/EventDirectoryFilters/EventDirectoryFilters";
import { DEFAULTS, PRICE_RANGE } from "../constants/eventDirectoryConstants";
import {
  toggleFavoriteThunk,
  fetchFavoritesAction,
} from "../slices/favoritesSlice";

export const useEventDirectoryActions = (
  currentRequest: FetchEventsRequest,
) => {
  const dispatch = useAppDispatch();
  const { executeToastError } = useToastError();

  const handlePaginationChange = useCallback(
    async (pageIndex: number, pageSize: number) => {
      return executeToastError(async () => {
        const page = pageIndex + 1;

        dispatch(
          setCurrentRequest({
            ...currentRequest,
            page,
            pageSize: pageSize || DEFAULTS.PAGE_SIZE,
          }),
        );

        await dispatch(fetchEventsAction());
      }, "Failed to change page");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handlePageChange = useCallback(
    async (newPage: number, newPageSize: number) => {
      dispatch(
        setCurrentRequest({
          page: newPage + 1,
          pageSize: newPageSize,
        }),
      );
      await handlePaginationChange(newPage, newPageSize);
    },
    [dispatch, handlePaginationChange],
  );

  const handleSearchChange = useCallback(
    (searchTerm: string) => {
      dispatch(
        setCurrentRequest({
          ...currentRequest,
          searchTerm,
          page: 1,
        }),
      );
      dispatch(fetchEventsAction());
    },
    [dispatch, currentRequest],
  );

  const handleFiltersChange = useCallback(
    (filters: EventFilters) => {
      dispatch(
        setCurrentRequest({
          ...currentRequest,
          ...filters,
          page: 1,
        }),
      );
      dispatch(fetchEventsAction());
    },
    [dispatch, currentRequest],
  );

  const triggerFilterChange = useCallback(
    (onFilterChange: (filters: EventFilters) => void) => {
      return (categories: string[], durations: string[]) => {
        onFilterChange({
          categories,
          durations,
          minPrice: PRICE_RANGE.MIN,
          maxPrice: PRICE_RANGE.MAX,
        });
      };
    },
    [],
  );

  const handleToggleFilter = useCallback(
    (
      selectedCategories: string[],
      selectedDurations: string[],
      filterChangeHandler: (categories: string[], durations: string[]) => void,
    ) => {
      return (type: string, value: string) => {
        if (type === "categories") {
          const newCategories = selectedCategories.includes(value)
            ? selectedCategories.filter((cat: string) => cat !== value)
            : [...selectedCategories, value];
          dispatch(setSelectedCategories(newCategories));
          filterChangeHandler(newCategories, selectedDurations);
        } else if (type === "durations") {
          const newDurations = selectedDurations.includes(value)
            ? selectedDurations.filter((dur: string) => dur !== value)
            : [...selectedDurations, value];
          dispatch(setSelectedDurations(newDurations));
          filterChangeHandler(selectedCategories, newDurations);
        }
      };
    },
    [dispatch],
  );

  const handleClearFilters = useCallback(
    (
      filterChangeHandler: (categories: string[], durations: string[]) => void,
    ) => {
      return () => {
        dispatch(setSelectedCategories([]));
        dispatch(setSelectedDurations([]));
        filterChangeHandler([], []);
      };
    },
    [dispatch],
  );

  const handleImageError = useCallback(
    (setImageError: React.Dispatch<React.SetStateAction<boolean>>) => {
      return () => {
        setImageError(true);
      };
    },
    [],
  );

  const handleFavoriteClick = useCallback(
    (eventId: number, onFavoriteToggle: (eventId: number) => void) => {
      return () => {
        onFavoriteToggle(eventId);
      };
    },
    [],
  );

  const handleFavoriteToggle = useCallback(
    async (eventId: number) => {
      await dispatch(toggleFavoriteThunk(eventId));
    },
    [dispatch],
  );

  const loadFavorites = useCallback(() => {
    dispatch(fetchFavoritesAction());
  }, [dispatch]);

  return {
    handlePaginationChange,
    handleSearchChange,
    handleFiltersChange,
    handlePageChange,
    handleImageError,
    handleFavoriteClick,
    triggerFilterChange,
    handleToggleFilter,
    handleClearFilters,
    handleFavoriteToggle,
    loadFavorites,
  };
};

export const useInitializeFavorites = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchFavoritesAction());
  }, [dispatch]);
};
