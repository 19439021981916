import { Button } from "../../../../../../components/ui/button";
import { useAppSelector } from "../../../../../../app/hooks";
import EventDirectoryCard from "../EventDirectoryCard/EventDirectoryCard";
import { selectFavoriteIds } from "../../selectors/eventDirectorySelectors";
import { ApiEvent } from "@/api/fetchEvents/types";
import { Loader2 } from "lucide-react";
import {
  useEventDirectoryActions,
  useInitializeFavorites,
} from "../../hooks/useEventDirectoryActions";

export interface EventDirectoryGridProps {
  events: ApiEvent[];
  searchQuery?: string;
  categories?: string[];
  durations?: string[];
  minPrice?: number;
  maxPrice?: number;
  onFavoriteToggle?: (eventId: number) => void;
  showOnlyFavorites?: boolean;
  currentPage?: number;
  itemsPerPage?: number;
  loading?: boolean;
  error?: string | null;
}

const EventDirectoryGrid: React.FC<EventDirectoryGridProps> = ({
  events,
  loading,
  error,
}) => {
  const favorites = useAppSelector(selectFavoriteIds);
  const { handleFavoriteToggle } = useEventDirectoryActions({
    page: 1,
    pageSize: 10,
  });

  // Initialize favorites on component mount
  useInitializeFavorites();

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[400px] gap-4">
        <p className="text-destructive font-medium">{error}</p>
        <Button variant="outline">Retry</Button>
      </div>
    );
  }

  if (!events || events.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <p className="text-muted-foreground">No events found</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      {events.map((event) => (
        <EventDirectoryCard
          event={event}
          isFavorited={favorites.includes(event.id)}
          key={event.id}
          onFavoriteToggle={handleFavoriteToggle}
        />
      ))}
    </div>
  );
};

export default EventDirectoryGrid;
