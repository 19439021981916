import { useMemo } from "react";
import { ApiEvent } from "@/api/fetchEvents/types";
import { Checkbox } from "@/components/ui/checkbox";
import { EventManagementActionMenu } from "../components/EventManagementActionMenu/EventManagementActionMenu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

// Function to truncate text with ellipsis
const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

export interface UseTableColumnsProps {
  selectedEvents: number[];
  onSelectEvent: (eventId: number, checked: boolean) => void;
  onEditEvent: (eventId: number) => void;
  onDuplicateEvent: (event: ApiEvent) => void;
  onDeleteEvent: (eventId: number) => void;
  onSelectAll: (checked: boolean) => void;
  isAllSelected: boolean;
  onShowSessions: (eventId: number) => void;
}

export interface Column {
  id?: string;
  accessorKey?: keyof ApiEvent | string;
  header?: string | (() => React.ReactNode);
  cell?: (props: { row: { original: ApiEvent } }) => React.ReactNode;
}

/**
 * Custom hook to create table columns configuration based on the provided props
 */
export const useTableColumns = (props: UseTableColumnsProps): Column[] => {
  const {
    selectedEvents,
    onSelectEvent,
    onEditEvent,
    onDuplicateEvent,
    onDeleteEvent,
    onSelectAll,
    isAllSelected,
    onShowSessions,
  } = props;

  return useMemo(
    () => [
      {
        id: "select",
        header: () => (
          <div className="px-1">
            <Checkbox
              checked={isAllSelected}
              className="text-white"
              onCheckedChange={onSelectAll}
            />
          </div>
        ),
        cell: ({
          row: { original: event },
        }: {
          row: { original: ApiEvent };
        }) => (
          <div className="px-1">
            <Checkbox
              checked={selectedEvents.includes(event.id)}
              className="text-white"
              onCheckedChange={(checked) => onSelectEvent(event.id, !!checked)}
            />
          </div>
        ),
      },
      {
        accessorKey: "eventName",
        header: "Event Name",
      },
      {
        accessorKey: "eventCode",
        header: "Event Code",
      },
      {
        accessorKey: "eventType",
        header: "Event Type",
        cell: ({
          row: { original: event },
        }: {
          row: { original: ApiEvent };
        }) => {
          if (event.eventType) {
            return <span>{event.eventType}</span>;
          }

          if (event.eventTypeId) {
            return <span>Type ID: {event.eventTypeId}</span>;
          }

          return <span>&#8212;</span>;
        },
      },
      {
        accessorKey: "eventCategory",
        header: "Event Category",
        cell: ({
          row: { original: event },
        }: {
          row: { original: ApiEvent };
        }) => {
          if (event.eventCategory) {
            return <span>{event.eventCategory}</span>;
          }

          if (event.eventCategoryId) {
            return <span>Category ID: {event.eventCategoryId}</span>;
          }

          return <span>&#8212;</span>;
        },
      },
      {
        accessorKey: "eventInstructor",
        header: "Event Instructor",
        cell: ({
          row: { original: event },
        }: {
          row: { original: ApiEvent };
        }) => {
          if (!event.eventInstructor || event.eventInstructor.trim() === "") {
            return <span>&#8212;</span>;
          }
          return <span>{event.eventInstructor}</span>;
        },
      },
      {
        accessorKey: "eventDescription",
        header: "Description",
        cell: ({
          row: { original: event },
        }: {
          row: { original: ApiEvent };
        }) => {
          const maxLength = 100;
          const isDescriptionTruncated =
            event.eventDescription.length > maxLength;
          const truncatedDescription = truncateText(
            event.eventDescription,
            maxLength,
          );

          if (isDescriptionTruncated) {
            return (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="cursor-help hover:text-primary transition-colors">
                      {truncatedDescription}
                    </span>
                  </TooltipTrigger>
                  <TooltipContent
                    className="max-w-md p-4 text-sm text-white bg-primary border-primary shadow-lg rounded-md"
                    side="top"
                    sideOffset={5}
                  >
                    <div className="space-y-2">
                      <h4 className="font-medium">Full Description</h4>
                      <p className="leading-relaxed">
                        {event.eventDescription}
                      </p>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            );
          }

          return <span>{event.eventDescription}</span>;
        },
      },
      {
        accessorKey: "eventPrice",
        header: "Price",
      },
      {
        id: "actions",
        cell: ({
          row: { original: event },
        }: {
          row: { original: ApiEvent };
        }) => (
          <EventManagementActionMenu
            event={event}
            onDelete={() => onDeleteEvent(event.id)}
            onDuplicate={() => onDuplicateEvent(event)}
            onEdit={() => onEditEvent(event.id)}
            onShowSessions={() => onShowSessions(event.id)}
          />
        ),
      },
    ],
    [
      selectedEvents,
      onSelectEvent,
      onEditEvent,
      onDuplicateEvent,
      onDeleteEvent,
      onShowSessions,
      onSelectAll,
      isAllSelected,
    ],
  );
};
