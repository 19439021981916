import { useSelector } from "react-redux";
import TPFilters from "@/modules/eventRegistration/features/shared/components/ERFilters";
import { selectCurrentRequestState } from "../../selectors/sessionManagementSelectors";
import { selectSelectedFilters } from "../../selectors/sessionManagementSelectors";
import { FilterType } from "../../utils/typeGuards";
import { useSessionManagementActions } from "../../hooks/useSessionManagementActions";
import {
  FilterOption,
  FilterChangeParams,
} from "../../hooks/useSessionManagementActions";
import { useFilterSections } from "../../hooks/useFilterSections";

export interface FilterSection {
  title: string;
  type: FilterType;
  options: FilterOption[];
}

export interface SessionManagementFiltersProps {
  onFilterChange: (filters: FilterChangeParams) => void;
}

export const SessionManagementFilters: React.FC<
  SessionManagementFiltersProps
> = ({ onFilterChange }) => {
  const currentRequest = useSelector(selectCurrentRequestState);
  const selectedFilters = useSelector(selectSelectedFilters);

  const { handleToggleFilter, handleClearFilters } =
    useSessionManagementActions({
      currentRequest,
      eventId: currentRequest.eventId,
      sessionToEdit: null,
      onFilterChange, // Pass the onFilterChange prop to the hook
    });

  const sections = useFilterSections();

  return (
    <TPFilters
      buttonClassName="h-10 flex-1 sm:flex-none sm:min-w-[120px] px-4 py-2 hover:bg-gray-200"
      onClearFilters={handleClearFilters}
      onToggleFilter={handleToggleFilter}
      sections={sections}
      selectedFilters={selectedFilters}
    />
  );
};
