// React and form imports
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useEventManagementActions } from "@/modules/eventRegistration/features/EventManagement/hooks/useEventManagementActions";
import { useDialogFilesCleaner } from "@/modules/eventRegistration/features/EventManagement/hooks/useDialogFilesCleaner";
import { useEventDialogForm } from "@/modules/eventRegistration/features/EventManagement/hooks/useEventDialogForm";
import { useDialogReset } from "@/modules/eventRegistration/features/EventManagement/hooks/useDialogReset";

// UI Components
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

// Shared Components
import { EntityDialog } from "@/modules/eventRegistration/features/shared/components/EntityDialog";

// Icons
import { Pencil, Plus, Trash2 } from "lucide-react";

// Feature imports
import { EventFormField } from "@/modules/eventRegistration/features/EventManagement/components/EventFormField/EventFormField";
import {
  eventSchema,
  EventFormData,
} from "@/modules/eventRegistration/features/EventManagement/schemas/eventSchema";
import { EVENT_FORM } from "@/modules/eventRegistration/features/EventManagement/constants/eventManagementConstants";
import {
  selectEventDialogState,
  selectShowAddEventType,
  selectEditingEventType,
  selectShowAddEventInstructor,
  selectEditingEventInstructor,
  selectShowAddEventCategory,
  selectEditingEventCategory,
} from "@/modules/eventRegistration/features/EventManagement/selectors/eventManagementSelectors";

import {
  setShowAddEventType,
  setEditingEventType,
  setShowAddEventInstructor,
  setEditingEventInstructor,
  setShowAddEventCategory,
  setEditingEventCategory,
  setShowDeleteTypeDialog,
  setShowDeleteInstructorDialog,
  setShowDeleteCategoryDialog,
} from "@/modules/eventRegistration/features/EventManagement/slices/eventManagementUISlice";

import {
  fetchEventTypesAction,
  fetchEventInstructorsAction,
  fetchEventCategoriesAction,
} from "@/modules/eventRegistration/features/EventManagement/thunks/eventManagementThunks";

// API Types
import { ApiEventType } from "@/api/fetchEventTypes/types";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";
import { ApiEventFile } from "@/api/fetchEventFiles/types";

import { EventTypeDialog } from "../EventTypeDialog/EventTypeDialog";
import { EventInstructorDialog } from "../EventInstructorDialog/EventInstructorDialog";
import { EventCategoryDialog } from "../EventCategoryDialog/EventCategoryDialog";
import { EventDialogAlerts } from "../EventDialogAlerts/EventDialogAlerts";

export interface EventDialogProps {
  initialData?: EventFormData;
  mode: "create" | "edit";
  onOpenChange: (open: boolean) => void;
  onSubmit: (data: EventFormData) => Promise<void>;
  open: boolean;
  isSubmitting?: boolean;
  onSuccess?: () => void;
}

const EventDialog = ({
  open,
  onOpenChange,
  initialData,
  mode,
  onSuccess,
}: EventDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const dialogState = useAppSelector(selectEventDialogState);
  const {
    isSubmitting,
    submitError,
    eventTypes,
    eventInstructors,
    eventCategories = [],
    isLoadingEventTypes,
    isLoadingEventCategories,
    isLoadingEventInstructors,
    selectedFiles,
    existingFiles,
  } = dialogState;
  const showAddEventType = useAppSelector(selectShowAddEventType);
  const editingEventType = useAppSelector(selectEditingEventType);
  const showAddEventInstructor = useAppSelector(selectShowAddEventInstructor);
  const editingEventInstructor = useAppSelector(selectEditingEventInstructor);
  const showAddEventCategory = useAppSelector(selectShowAddEventCategory);
  const editingEventCategory = useAppSelector(selectEditingEventCategory);

  const { handleClearDialogFiles } = useEventManagementActions({
    page: 1,
    pageSize: 10,
  });

  useDialogFilesCleaner(open);

  const {
    handleEventSubmit,
    handleDialogClose,
    handleDialogConfirmClose,
    handleDialogReset,
    handleDialogConfirmReset,
    handleDeleteEventType,
    handleDeleteEventCategory,
    handleDeleteEventInstructor,
    handleFileChange,
    handleRemoveFile,
    handleRemoveExistingFile,
  } = useEventManagementActions({
    page: 1,
    pageSize: 10,
  });

  useDialogReset(open);

  const form = useForm<EventFormData>({
    resolver: zodResolver(eventSchema),
    defaultValues: mode === "create" ? EVENT_FORM.DEFAULT_VALUES : initialData,
  });

  const {
    isInitializing,
    isDirty,
    handleEventTypeChange,
    handleEventCategoryChange,
    handleEventInstructorChange,
  } = useEventDialogForm({
    form,
    open,
    mode,
    initialData,
  });

  return (
    <>
      <EventDialogAlerts
        onDeleteCategoryConfirm={() => {
          const selectedCategoryId = Number(form.watch("eventCategoryId"));
          if (selectedCategoryId) {
            handleDeleteEventCategory(selectedCategoryId, form);
          }
        }}
        onDeleteInstructorConfirm={() => {
          const selectedInstructorId = Number(form.watch("eventInstructorId"));
          if (selectedInstructorId) {
            handleDeleteEventInstructor(selectedInstructorId, form);
          }
        }}
        onDeleteTypeConfirm={() => {
          const selectedTypeId = Number(form.watch("eventTypeId"));
          if (selectedTypeId) {
            handleDeleteEventType(selectedTypeId, form);
          }
        }}
        onResetConfirm={() => {
          if (form) {
            handleDialogConfirmReset(
              mode,
              form,
              initialData || EVENT_FORM.DEFAULT_VALUES,
            );
          }
        }}
        onUnsavedChangesConfirm={() => {
          form.reset();
          handleDialogConfirmClose(onOpenChange);
        }}
      />

      <EntityDialog
        cancelButtonText={EVENT_FORM.BUTTON_TEXT.cancel}
        clearFiles={handleClearDialogFiles}
        errorTitle={EVENT_FORM.ERROR_MESSAGES.title}
        form={form}
        isDirty={isDirty}
        isInitializing={isInitializing}
        isSubmitting={isSubmitting}
        mode={mode}
        onClose={() =>
          handleDialogClose(
            form.formState,
            onOpenChange,
            editingEventType,
            editingEventInstructor,
            editingEventCategory,
          )
        }
        onOpenChange={onOpenChange}
        onReset={() => handleDialogReset()}
        onSubmit={(data: EventFormData) =>
          handleEventSubmit(
            mode,
            data,
            selectedFiles,
            initialData?.id,
            onOpenChange,
            onSuccess,
          )
        }
        open={open}
        preventInteractOutside={
          !editingEventType && !editingEventInstructor && !editingEventCategory
        }
        resetButtonText={EVENT_FORM.BUTTON_TEXT.reset}
        submitButtonText={{
          create: "Create",
          edit: "Update",
          creating: "Creating...",
          updating: "Updating...",
        }}
        submitError={submitError}
        title={
          mode === "create" ? EVENT_FORM.TITLES.create : EVENT_FORM.TITLES.edit
        }
      >
        <EventFormField
          control={form.control}
          label={EVENT_FORM.LABELS.eventName}
          name="eventName"
          placeholder={EVENT_FORM.PLACEHOLDERS.eventName}
          type="text"
        />

        <EventFormField
          control={form.control}
          label={EVENT_FORM.LABELS.eventCode}
          name="eventCode"
          placeholder={EVENT_FORM.PLACEHOLDERS.eventCode}
          type="text"
        />

        <EventFormField
          control={form.control}
          label={EVENT_FORM.LABELS.eventDescription}
          name="eventDescription"
          placeholder={EVENT_FORM.PLACEHOLDERS.eventDescription}
          type="textarea"
        />

        <EventFormField
          control={form.control}
          label={EVENT_FORM.LABELS.eventPrice}
          name="eventPrice"
          placeholder={EVENT_FORM.PLACEHOLDERS.eventPrice}
          type="number"
        />

        <div className="flex gap-2 items-start">
          <div className="flex-1">
            <EventFormField
              control={form.control}
              disabled={isLoadingEventTypes}
              label={EVENT_FORM.LABELS.eventType}
              name="eventTypeId"
              onValueChange={handleEventTypeChange}
              options={[
                ...eventTypes.map((type: ApiEventType) => ({
                  value: type.id.toString(),
                  label: type.name,
                })),
                {
                  value: "add-new",
                  label: (
                    <div className="flex items-center text-green-600">
                      <Plus className="mr-2 h-4 w-4" />
                      Add new event type
                    </div>
                  ),
                },
              ]}
              placeholder={
                isLoadingEventTypes
                  ? "Loading event types..."
                  : "Select an event type"
              }
              type="select"
            />
          </div>
          <Button
            className="mt-8"
            disabled={!form.watch("eventTypeId")}
            onClick={() => {
              const selectedTypeId = Number(form.watch("eventTypeId"));
              const selectedType = eventTypes.find(
                (type: ApiEventType) => type.id === selectedTypeId,
              );
              if (selectedType) {
                dispatch(setEditingEventType(selectedType));
              }
            }}
            size="icon"
            type="button"
            variant="outline"
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            className="mt-8"
            disabled={!form.watch("eventTypeId")}
            onClick={() => {
              dispatch(setShowDeleteTypeDialog(true));
            }}
            size="icon"
            type="button"
            variant="outline"
          >
            <Trash2 className="h-4 w-4 text-red-500" />
          </Button>
        </div>
        <div className="flex gap-2 items-start">
          <div className="flex-1">
            <EventFormField
              control={form.control}
              disabled={isLoadingEventInstructors}
              label={EVENT_FORM.LABELS.eventInstructor}
              name="eventInstructorId"
              onValueChange={handleEventInstructorChange}
              options={[
                ...eventInstructors.map((instructor: ApiEventInstructor) => ({
                  value: instructor.id.toString(),
                  label: instructor.firstName
                    ? `${instructor.firstName} ${instructor.lastName}`
                    : instructor.id.toString(),
                })),
                {
                  value: "add-new",
                  label: (
                    <div className="flex items-center text-green-600">
                      <Plus className="mr-2 h-4 w-4" />
                      Add new event instructor
                    </div>
                  ),
                },
              ]}
              placeholder={
                isLoadingEventInstructors
                  ? "Loading event instructors..."
                  : "Select an event instructor"
              }
              type="select"
            />
          </div>
          <Button
            className="mt-8"
            disabled={!form.watch("eventInstructorId")}
            onClick={() => {
              const selectedInstructorId = Number(
                form.watch("eventInstructorId"),
              );
              const selectedInstructor = eventInstructors.find(
                (instructor: ApiEventInstructor) =>
                  instructor.id === selectedInstructorId,
              );
              if (selectedInstructor) {
                dispatch(setEditingEventInstructor(selectedInstructor));
              }
            }}
            size="icon"
            type="button"
            variant="outline"
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            className="mt-8"
            disabled={!form.watch("eventInstructorId")}
            onClick={() => {
              dispatch(setShowDeleteInstructorDialog(true));
            }}
            size="icon"
            type="button"
            variant="outline"
          >
            <Trash2 className="h-4 w-4 text-red-500" />
          </Button>
        </div>
        <div className="flex gap-2 items-start">
          <div className="flex-1">
            <EventFormField
              control={form.control}
              disabled={isLoadingEventCategories}
              label={EVENT_FORM.LABELS.eventCategory}
              name="eventCategoryId"
              onValueChange={handleEventCategoryChange}
              options={[
                ...eventCategories.map((category: ApiEventCategory) => ({
                  value: category.id.toString(),
                  label: category.name,
                })),
                {
                  value: "add-new",
                  label: (
                    <div className="flex items-center text-green-600">
                      <Plus className="mr-2 h-4 w-4" />
                      Add new event category
                    </div>
                  ),
                },
              ]}
              placeholder={
                isLoadingEventCategories
                  ? "Loading event categories..."
                  : "Select an event category"
              }
              type="select"
            />
          </div>
          <Button
            className="mt-8"
            disabled={!form.watch("eventCategoryId")}
            onClick={() => {
              const selectedCategoryId = Number(form.watch("eventCategoryId"));
              const selectedCategory = eventCategories.find(
                (category: ApiEventCategory) =>
                  category.id === selectedCategoryId,
              );
              if (selectedCategory) {
                dispatch(setEditingEventCategory(selectedCategory));
              }
            }}
            size="icon"
            type="button"
            variant="outline"
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            className="mt-8"
            disabled={!form.watch("eventCategoryId")}
            onClick={() => {
              dispatch(setShowDeleteCategoryDialog(true));
            }}
            size="icon"
            type="button"
            variant="outline"
          >
            <Trash2 className="h-4 w-4 text-red-500" />
          </Button>
        </div>

        <div className="grid w-full gap-1.5">
          <Label htmlFor="eventFiles">Event Files</Label>
          <div className="grid gap-2">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-center w-full">
                <label
                  className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:border-primary hover:bg-gray-100 transition-colors duration-200"
                  htmlFor="dropzone-file"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 mb-4 text-gray-500"
                      fill="none"
                      viewBox="0 0 20 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Click to upload</span>
                      {" or drag and drop"}
                    </p>
                    <p className="text-xs text-gray-500">
                      PDF, DOC, DOCX, or PPT (MAX. 10MB)
                    </p>
                  </div>
                  <Input
                    accept=".pdf,.doc,.docx,.ppt,.pptx"
                    className="hidden"
                    id="dropzone-file"
                    multiple
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const files = Array.from(event.target.files || []);
                      handleFileChange(files);
                    }}
                    type="file"
                  />
                </label>
              </div>

              {existingFiles.length > 0 && mode === "edit" && (
                <div className="space-y-2">
                  <Label>Existing Files</Label>
                  {existingFiles.map((file: ApiEventFile) => (
                    <div
                      className="flex items-center justify-between p-2 bg-gray-50 rounded-md"
                      key={file.id}
                    >
                      <span className="text-sm truncate max-w-[80%]">
                        {file.fileName}
                      </span>
                      <Button
                        onClick={() => handleRemoveExistingFile(file)}
                        size="sm"
                        type="button"
                        variant="ghost"
                      >
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </Button>
                    </div>
                  ))}
                </div>
              )}

              {selectedFiles.length > 0 && (
                <div className="space-y-2">
                  <Label>New Files</Label>
                  {selectedFiles.map((file: File, index: number) => (
                    <div
                      className="flex items-center justify-between p-2 bg-gray-50 rounded-md"
                      key={index}
                    >
                      <span className="text-sm truncate max-w-[80%]">
                        {file.name}
                      </span>
                      <Button
                        onClick={() => handleRemoveFile(file)}
                        size="sm"
                        type="button"
                        variant="ghost"
                      >
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </EntityDialog>

      {editingEventType && (
        <EventTypeDialog
          eventType={editingEventType as ApiEventType | null}
          onOpenChange={(open: boolean) => {
            if (!open) dispatch(setEditingEventType(null));
          }}
          onSuccess={() => {
            dispatch(fetchEventTypesAction());
          }}
          open={!!editingEventType}
        />
      )}

      {showAddEventType && (
        <EventTypeDialog
          eventType={null}
          onOpenChange={(open: boolean) => {
            if (!open) dispatch(setShowAddEventType(false));
          }}
          onSuccess={() => {
            dispatch(fetchEventTypesAction());
          }}
          open={showAddEventType}
        />
      )}

      {showAddEventInstructor && (
        <EventInstructorDialog
          eventInstructor={null}
          onOpenChange={(open: boolean) => {
            if (!open) dispatch(setShowAddEventInstructor(false));
          }}
          onSuccess={() => {
            dispatch(fetchEventInstructorsAction());
          }}
          open={showAddEventInstructor}
        />
      )}

      {editingEventInstructor && (
        <EventInstructorDialog
          eventInstructor={editingEventInstructor as ApiEventInstructor | null}
          onOpenChange={(open: boolean) => {
            if (!open) dispatch(setEditingEventInstructor(null));
          }}
          onSuccess={() => {
            dispatch(fetchEventInstructorsAction());
          }}
          open={!!editingEventInstructor}
        />
      )}

      {editingEventCategory && (
        <EventCategoryDialog
          eventCategory={editingEventCategory as ApiEventCategory | null}
          onOpenChange={(open: boolean) => {
            if (!open) dispatch(setEditingEventCategory(null));
          }}
          onSuccess={() => {
            dispatch(fetchEventCategoriesAction());
          }}
          open={!!editingEventCategory}
        />
      )}

      {showAddEventCategory && (
        <EventCategoryDialog
          eventCategory={null}
          onOpenChange={(open: boolean) => {
            if (!open) dispatch(setShowAddEventCategory(false));
          }}
          onSuccess={() => {
            dispatch(fetchEventCategoriesAction());
          }}
          open={showAddEventCategory}
        />
      )}
    </>
  );
};

export default EventDialog;
