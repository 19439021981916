import React, { useCallback, useRef } from "react";
import { Button } from "@/components/ui/button";
import { ContentBlockList } from "@/modules/hub/features/ResourceLibrary/components/ContentBlockList/ContentBlockList";
import { ContentBlockEditorProps } from "@/modules/hub/features/ResourceLibrary/components/ContentBlockEditor/types";
import {
  BlockType,
  ContentBlockBase,
} from "@/modules/hub/features/ResourceLibrary/components/SortableBlock/types";

/**
 * A lightweight wrapper around ContentBlockList that allows adding new blocks.
 * On add, we scroll both the local container (if it's scrollable) and the entire page.
 */
export function ContentBlockEditor({
  blocks,
  onChange,
}: ContentBlockEditorProps) {
  const listContainerRef = useRef<HTMLDivElement>(null);

  const handleAddBlock = useCallback(
    (blockType: BlockType) => {
      if (blockType !== "text" && blockType !== "image") return;

      const newBlock: ContentBlockBase = {
        id: crypto.randomUUID(),
        type: blockType,
        content: "",
        order_number: blocks.length,
      };

      const updated = [...blocks, newBlock];
      onChange(updated);

      setTimeout(() => {
        if (listContainerRef.current) {
          listContainerRef.current.scrollTo({
            top: listContainerRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 0);
    },
    [blocks, onChange],
  );

  const handleBlocksChange = useCallback(
    (updatedBlocks: ContentBlockBase[]) => {
      onChange(updatedBlocks);
    },
    [onChange],
  );

  return (
    <div className="space-y-4">
      <div className="flex gap-2">
        <Button
          onClick={() => handleAddBlock("text")}
          size="sm"
          type="button"
          variant="outline"
        >
          Add Text
        </Button>
        <Button
          onClick={() => handleAddBlock("image")}
          size="sm"
          type="button"
          variant="outline"
        >
          Add Image
        </Button>
      </div>
      <ContentBlockList
        blocks={blocks}
        onChange={handleBlocksChange}
        ref={listContainerRef}
      />
    </div>
  );
}
