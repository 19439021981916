import React from "react";
import { EventManagementTableSection } from "../EventManagementTableSection/EventManagementTableSection";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/app/hooks";
import EventDialog from "../EventDialog/EventDialog";
import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary";
import { useEventForm } from "../../hooks/useEventForm";
import { fetchEventsAction } from "../../thunks/eventManagementThunks";
import { updateEventDialog } from "../../slices/eventManagementUISlice";
import {
  selectEventsWithLoadingState,
  selectEventDialogState,
  selectEventManagementCurrentRequest,
} from "../../selectors/eventManagementSelectors";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useEventManagementActions } from "../../hooks/useEventManagementActions";
import { EventFormData } from "../../schemas/eventSchema";
import { EventManagementToolbar } from "../EventManagementToolbar/EventManagementToolbar";
import { DEFAULTS } from "../../constants/eventManagementConstants";
import { EventManagementContext } from "../../context/EventManagementContext";
import useInitialEventsFetch from "../../hooks/useInitialEventsFetch"; // Changed to default import

const EventManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formState, form } = useEventForm();
  const { events, isLoadingEvents, eventsError, totalCount } = useSelector(
    selectEventsWithLoadingState,
  );
  const { showEventDialog, eventToEdit } = useSelector(selectEventDialogState);
  const currentRequest = useSelector(selectEventManagementCurrentRequest);
  const {
    handlePaginationChange,
    handleSortingChange,
    handleFiltersChange,
    handleDialogSubmit,
    handleAddEvent,
    handleEditEvent,
    handleSearchChange,
  } = useEventManagementActions(currentRequest);

  useInitialEventsFetch();

  const contextValue = {
    currentRequest,
    isLoadingEvents,
    handlePaginationChange,
    handleSortingChange,
    handleFiltersChange,
    handleSearchChange,
    handleEditEvent: (eventId: number) => handleEditEvent(eventId, events),
  };

  if (eventsError) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{eventsError}</AlertDescription>
      </Alert>
    );
  }

  return (
    <ErrorBoundary>
      <EventManagementContext.Provider value={contextValue}>
        <div className="space-y-4">
          <EventManagementToolbar
            isLoading={isLoadingEvents}
            onAddEvent={handleAddEvent}
            onFilterChange={handleFiltersChange}
            onSearch={handleSearchChange}
          />
          <EventManagementTableSection
            currentRequest={currentRequest}
            defaultPageSize={DEFAULTS.PAGE_SIZE}
            events={events}
            isLoading={isLoadingEvents}
            onEditEvent={(eventId: number) => handleEditEvent(eventId, events)}
            onPaginationChange={handlePaginationChange}
            onSortingChange={handleSortingChange}
            totalCount={totalCount}
          />
        </div>

        {showEventDialog && (
          <EventDialog
            initialData={
              eventToEdit
                ? {
                    id: eventToEdit.id,
                    eventName: eventToEdit.eventName,
                    eventCode: eventToEdit.eventCode,
                    eventDescription: eventToEdit.eventDescription,
                    eventPrice: Number(eventToEdit.eventPrice),
                    eventTypeId: eventToEdit.eventTypeId || null,
                    eventInstructorId: eventToEdit.eventInstructorId || null,
                    eventCategoryId: eventToEdit.eventCategoryId || null,
                  }
                : undefined
            }
            isSubmitting={formState.isSubmitting}
            mode={eventToEdit ? "edit" : "create"}
            onOpenChange={(open) => {
              if (!open) {
                dispatch(updateEventDialog({ show: false, event: null }));
              }
            }}
            onSubmit={(data: EventFormData) =>
              handleDialogSubmit(
                data,
                formState,
                form,
                eventToEdit ? "edit" : "create",
                eventToEdit?.id,
              )
            }
            onSuccess={() => {
              dispatch(fetchEventsAction());
            }}
            open={showEventDialog}
          />
        )}
      </EventManagementContext.Provider>
    </ErrorBoundary>
  );
};

export default EventManagement;
