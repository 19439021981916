import React from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useAlertDialogActions } from "../hooks/useAlertDialogActions";

export interface AlertDialogComponentProps {
  cancelText?: string;
  cancelButtonClassName?: string;
  confirmText?: string;
  confirmButtonClassName?: string;
  description?: string;
  isOpen: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  onOpenChange: (open: boolean) => void;
  title?: string;
  isDestructive?: boolean;
}

export const AlertDialogComponent: React.FC<AlertDialogComponentProps> = ({
  cancelText = "Cancel",
  cancelButtonClassName = "",
  confirmText = "Confirm",
  confirmButtonClassName = "",
  description,
  isOpen,
  onCancel,
  onConfirm,
  onOpenChange,
  title = "Confirmation",
  isDestructive = false,
}) => {
  const { handleConfirm, handleCancel } = useAlertDialogActions({
    onConfirm,
    onCancel,
    onOpenChange,
  });

  const destructiveClassName = isDestructive
    ? "bg-red-600 hover:bg-red-700 focus:ring-red-600xxxx"
    : "";
  const finalConfirmClassName =
    `${confirmButtonClassName} ${destructiveClassName}`.trim();

  return (
    <AlertDialog onOpenChange={onOpenChange} open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          {description && (
            <AlertDialogDescription>{description}</AlertDialogDescription>
          )}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button
            className={`mt-2 sm:mt-0 ${cancelButtonClassName}`}
            onClick={handleCancel}
            variant="outline"
          >
            {cancelText}
          </Button>
          <Button
            className={finalConfirmClassName}
            onClick={handleConfirm}
            variant={isDestructive ? "destructive" : "default"}
          >
            {confirmText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
