import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "@/app/store";
import { getResourceCategories } from "@/api/getResourceCategories/getResourceCategoriesApi";
import { createResourceCategory } from "@/api/createResourceCategory/createResourceCategoryApi";
import { editResourceCategory } from "@/api/editResourceCategory/editResourceCategoryApi";
import { deleteResourceCategory } from "@/api/deleteResourceCategory/deleteResourceCategoryApi";

interface ResourceCategory {
  id: number;
  name: string;
}

interface ResourceCategoryState {
  categories: ResourceCategory[];
  fetchLoading: boolean;
  fetchError: string | null;
  createLoading: boolean;
  createError: string | null;
  updateLoading: boolean;
  updateError: string | null;
  deleteLoading: boolean;
  deleteError: string | null;
}

const initialState: ResourceCategoryState = {
  categories: [],

  fetchLoading: false,
  fetchError: null,

  createLoading: false,
  createError: null,

  updateLoading: false,
  updateError: null,

  deleteLoading: false,
  deleteError: null,
};

const resourceCategorySlice = createSlice({
  name: "resourceCategory",
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<ResourceCategory[]>) {
      state.categories = action.payload;
    },
    addCategory(state, action: PayloadAction<ResourceCategory>) {
      state.categories.push(action.payload);
    },
    updateCategory(state, action: PayloadAction<ResourceCategory>) {
      const updated = action.payload;
      const index = state.categories.findIndex((c) => c.id === updated.id);
      if (index !== -1) {
        state.categories[index] = updated;
      }
    },
    removeCategory(state, action: PayloadAction<number>) {
      state.categories = state.categories.filter(
        (c) => c.id !== action.payload,
      );
    },
    setFetchLoading(state, action: PayloadAction<boolean>) {
      state.fetchLoading = action.payload;
    },
    setFetchError(state, action: PayloadAction<string | null>) {
      state.fetchError = action.payload;
    },
    setCreateLoading(state, action: PayloadAction<boolean>) {
      state.createLoading = action.payload;
    },
    setCreateError(state, action: PayloadAction<string | null>) {
      state.createError = action.payload;
    },
    setUpdateLoading(state, action: PayloadAction<boolean>) {
      state.updateLoading = action.payload;
    },
    setUpdateError(state, action: PayloadAction<string | null>) {
      state.updateError = action.payload;
    },
    setDeleteLoading(state, action: PayloadAction<boolean>) {
      state.deleteLoading = action.payload;
    },
    setDeleteError(state, action: PayloadAction<string | null>) {
      state.deleteError = action.payload;
    },
  },
});

export const {
  setCategories,
  addCategory,
  updateCategory,
  removeCategory,
  setFetchLoading,
  setFetchError,
  setCreateLoading,
  setCreateError,
  setUpdateLoading,
  setUpdateError,
  setDeleteLoading,
  setDeleteError,
} = resourceCategorySlice.actions;

export default resourceCategorySlice.reducer;

export const fetchResourceCategoriesAction =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setFetchLoading(true));
    dispatch(setFetchError(null));

    try {
      const response = await getResourceCategories({});
      dispatch(setCategories(response.data.categories));
    } catch (error) {
      dispatch(
        setFetchError(
          error instanceof Error
            ? error.message
            : "Failed to fetch resource categories.",
        ),
      );
    } finally {
      dispatch(setFetchLoading(false));
    }
  };

export const createResourceCategoryAction =
  (name: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setCreateLoading(true));
    dispatch(setCreateError(null));

    try {
      const response = await createResourceCategory({ name });
      dispatch(
        addCategory({
          id: response.data.id ?? 0,
          name: response.data.name ?? "",
        }),
      );
    } catch (error) {
      dispatch(
        setCreateError(
          error instanceof Error
            ? error.message
            : "Failed to create resource category.",
        ),
      );
    } finally {
      dispatch(setCreateLoading(false));
    }
  };

export const editResourceCategoryAction =
  (categoryId: number, name: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setUpdateLoading(true));
    dispatch(setUpdateError(null));

    try {
      const response = await editResourceCategory(categoryId, { name });
      dispatch(
        updateCategory({
          id: response.data.id ?? 0,
          name: response.data.name ?? "",
        }),
      );
    } catch (error) {
      dispatch(
        setUpdateError(
          error instanceof Error
            ? error.message
            : "Failed to edit resource category.",
        ),
      );
    } finally {
      dispatch(setUpdateLoading(false));
    }
  };

export const deleteResourceCategoryAction =
  (categoryId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setDeleteLoading(true));
    dispatch(setDeleteError(null));

    try {
      await deleteResourceCategory(categoryId);
      dispatch(removeCategory(categoryId));
    } catch (error) {
      dispatch(
        setDeleteError(
          error instanceof Error
            ? error.message
            : "Failed to delete resource category.",
        ),
      );
    } finally {
      dispatch(setDeleteLoading(false));
    }
  };
