import {
  ERPagination,
  ERPaginationProps,
} from "@/modules/eventRegistration/features/shared/components/ERPagination";
import { PAGE_SIZE_OPTIONS } from "../../constants/eventDirectoryConstants";

const EventDirectoryPagination: React.FC<ERPaginationProps> = (props) => (
  <ERPagination
    {...props}
    itemName="events"
    pageSizeOptions={[...PAGE_SIZE_OPTIONS]}
  />
);

export default EventDirectoryPagination;
