import { useMemo } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { COLUMN_HEADERS } from "../constants/sessionManagementConstants";
import { formatDateForDisplay } from "../utils/dateUtils";
import { SessionManagementActionMenu } from "../components/SessionManagementActionMenu/SessionManagementActionMenu";
import { ApiEventSession } from "@/api/fetchEventSessions/types";

export interface UseTableColumnsProps {
  selectedSessions: number[];
  onSelectSession: (sessionId: number, checked: boolean) => void;
  onEditSession: (session: ApiEventSession) => void;
  onDuplicateSession: (session: ApiEventSession) => void;
  onDeleteSession: (sessionId: number) => void;
  onSelectAll: (checked: boolean) => void;
  isAllSelected: boolean;
}

export interface Column {
  id?: string;
  accessorKey?: keyof ApiEventSession | string;
  header?: string | (() => React.ReactNode);
  cell?: (props: { row: { original: ApiEventSession } }) => React.ReactNode;
}

export const useTableColumns = ({
  selectedSessions,
  onSelectSession,
  onEditSession,
  onDuplicateSession,
  onDeleteSession,
  onSelectAll,
  isAllSelected,
}: UseTableColumnsProps) => {
  const columns = useMemo<Column[]>(
    () => [
      {
        id: "select",
        header: () => (
          <Checkbox
            checked={isAllSelected}
            onCheckedChange={(checked) => onSelectAll(!!checked)}
          />
        ),
        cell: ({ row: { original: session } }) => (
          <Checkbox
            checked={selectedSessions.includes(session.id)}
            onCheckedChange={(checked) =>
              onSelectSession(session.id, !!checked)
            }
          />
        ),
      },
      {
        accessorKey: "eventName",
        header: COLUMN_HEADERS.EVENT_NAME,
        cell: ({ row: { original: session } }) => (
          <span>{session.eventName || "—"}</span>
        ),
      },
      {
        accessorKey: "startDate",
        header: COLUMN_HEADERS.START_DATE,
        cell: ({ row: { original: session } }) => (
          <div>{formatDateForDisplay(session.startDate)}</div>
        ),
      },
      {
        accessorKey: "endDate",
        header: COLUMN_HEADERS.END_DATE,
        cell: ({ row: { original: session } }) => (
          <div>{formatDateForDisplay(session.endDate)}</div>
        ),
      },
      {
        accessorKey: "virtualLink",
        header: COLUMN_HEADERS.VIRTUAL_LINK,
        cell: ({ row: { original: session } }) => {
          const link =
            session.virtualLink === "NULL" ? null : session.virtualLink;
          return <span>{link || "—"}</span>;
        },
      },
      {
        accessorKey: "maxEnrollments",
        header: COLUMN_HEADERS.MAX_ENROLLMENTS,
        cell: ({ row: { original: session } }) => (
          <span>{session.maxEnrollments}</span>
        ),
      },
      {
        accessorKey: "status",
        header: COLUMN_HEADERS.STATUS,
        cell: ({ row: { original: session } }) => (
          <div className="capitalize">{session.status.toLowerCase()}</div>
        ),
      },
      {
        id: "actions",
        header: COLUMN_HEADERS.ACTIONS,
        cell: ({ row: { original: session } }) => (
          <SessionManagementActionMenu
            onDelete={onDeleteSession}
            onDuplicate={onDuplicateSession}
            onEdit={onEditSession}
            session={session}
          />
        ),
      },
    ],
    [
      selectedSessions,
      onSelectSession,
      onEditSession,
      onDuplicateSession,
      onDeleteSession,
      onSelectAll,
      isAllSelected,
    ],
  );

  return columns;
};

export default useTableColumns;
