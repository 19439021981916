import { StochasticCustomer } from "../../../../../../api/fetchStochasticCustomers/types";
import { formatCurrency } from "@/utils/formatCurrency";
import { Column } from "@/components/Datatable/Datatable";

export function createCustomersColumns(): Column<StochasticCustomer>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Has Installation",
      accessorKey: "hasInstallation",
      cell: ({ row }) => (row.original.hasInstallation ? "Yes" : "No"),
    },
    {
      header: "Has Subscription",
      accessorKey: "hasSubscription",
      cell: ({ row }) => (row.original.hasSubscription ? "Yes" : "No"),
    },
    {
      header: "Postal Code",
      // Using 'id' here, or you could omit it entirely.
      id: "postalCode",
      cell: ({ row }) => {
        const postalCode = row.original.address?.postalCode;
        if (!postalCode) {
          return "N/A";
        }
        if (postalCode.length <= 5) {
          return postalCode;
        }
        return postalCode.slice(0, 5) + "-" + postalCode.slice(5);
      },
    },
    {
      header: "Count Invoices",
      accessorKey: "countInvoices",
    },
    {
      header: "Invoice Total",
      accessorKey: "invoiceTotal",
      cell: ({ row }) => formatCurrency(row.original.invoiceTotal),
    },
    {
      header: "Lifetime Value",
      accessorKey: "lifetimeValue",
      cell: ({ row }) => formatCurrency(row.original.lifetimeValue),
    },
    {
      header: "First Invoiced At",
      accessorKey: "firstInvoicedAt",
      cell: ({ row }) =>
        row.original.firstInvoicedAt
          ? new Date(row.original.firstInvoicedAt).toLocaleDateString()
          : "N/A",
    },
    {
      header: "Last Invoiced At",
      accessorKey: "lastInvoicedAt",
      cell: ({ row }) =>
        row.original.lastInvoicedAt
          ? new Date(row.original.lastInvoicedAt).toLocaleDateString()
          : "N/A",
    },
  ];
}
