import axios from "../axiosInstance";
import { CopyEventRequest, CopyEventResponse } from "./types";

export const copyEvent = async (
  eventId: number,
  requestData: CopyEventRequest,
): Promise<CopyEventResponse> => {
  const response = await axios.post<CopyEventResponse>(
    `/api/private/events/${eventId}/duplicate`,
    requestData,
  );
  return response.data;
};
