import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFavorites } from "../../../../../api/favoriteEvent/favoritesApi";
import { RootState } from "../../../../../app/rootReducer";
import { AppDispatch } from "../../../../../app/store";

interface FavoritesState {
  favorites: number[];
  loading: boolean;
  error: string | null;
  initialized: boolean; // Add initialization tracking
}

// Get initial favorites from localStorage
const getStoredFavorites = (): number[] => {
  try {
    const stored = localStorage.getItem("eventFavorites");
    return stored ? JSON.parse(stored) : [];
  } catch (error) {
    console.error("Error reading favorites from localStorage:", error);
    return [];
  }
};

const initialState: FavoritesState = {
  favorites: getStoredFavorites(),
  loading: false,
  error: null,
  initialized: false, // Track initialization state
};

export const fetchFavoritesAction = createAsyncThunk(
  "favorites/fetchFavorites",
  async (_, { getState }) => {
    const state = getState() as RootState;
    if (state.favorites.initialized) {
      return state.favorites.favorites; // Return current favorites if already initialized
    }

    const favorites = await fetchFavorites();
    const storedFavorites = getStoredFavorites();
    const mergedFavorites = Array.from(
      new Set([...storedFavorites, ...(favorites || [])]),
    );
    localStorage.setItem("eventFavorites", JSON.stringify(mergedFavorites));
    return mergedFavorites;
  },
);

export const toggleFavoriteThunk = createAsyncThunk(
  "favorites/toggle",
  async (eventId: number, { getState }) => {
    const state = getState() as RootState;
    const isFavorited = state.favorites.favorites.includes(eventId);

    const favorites = JSON.parse(
      localStorage.getItem("eventFavorites") || "[]",
    );
    let newFavorites;

    if (isFavorited) {
      newFavorites = favorites.filter((id: number) => id !== eventId);
    } else {
      newFavorites = [...favorites, eventId];
    }

    localStorage.setItem("eventFavorites", JSON.stringify(newFavorites));

    return { eventId, isFavorited: !isFavorited };
  },
);

export const initializeFavoritesAction = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("favorites/initializeFavorites", async (_, { dispatch, rejectWithValue }) => {
  try {
    await dispatch(fetchFavoritesAction()).unwrap();
  } catch {
    return rejectWithValue(
      "Failed to load your favorited events. Please try again later.",
    );
  }
});

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavoritesAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFavoritesAction.fulfilled, (state, action) => {
        state.loading = false;
        state.favorites = action.payload;
        state.error = null;
        state.initialized = true;
      })
      .addCase(fetchFavoritesAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch favorites";
        // Don't reset favorites on error, keep what's in localStorage
        state.favorites = getStoredFavorites();
      })
      .addCase(toggleFavoriteThunk.fulfilled, (state, action) => {
        // action.payload contains { eventId, isFavorited }
        const { eventId, isFavorited } = action.payload;
        if (isFavorited) {
          if (!state.favorites.includes(eventId)) {
            state.favorites.push(eventId);
          }
        } else {
          state.favorites = state.favorites.filter((id) => id !== eventId);
        }
      })
      .addCase(toggleFavoriteThunk.rejected, (state, action) => {
        state.error = action.error.message || "Failed to toggle favorite";
        // Revert to localStorage state on error
        state.favorites = getStoredFavorites();
      });
  },
});

export const { setLoading, setError } = favoritesSlice.actions;

export default favoritesSlice.reducer;
