import { Control } from "react-hook-form";
import { inputTypeSchema } from "../schemas/eventSchema";

export type InputType =
  | "text"
  | "number"
  | "textarea"
  | "checkbox"
  | "datetime-local";

export interface UseEventFormFieldReturn {
  validatedType: InputType;
}

export interface UseEventFormFieldProps<T extends Record<string, unknown>> {
  control: Control<T>;
  name: keyof T;
  type?: InputType;
}

export const useEventFormField = <T extends Record<string, unknown>>({
  type = "text",
}: UseEventFormFieldProps<T>): UseEventFormFieldReturn => {
  // Validate type prop
  const validatedType = inputTypeSchema.parse(type);

  return {
    validatedType,
  };
};
