import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityState,
} from "@reduxjs/toolkit";
import { ApiEvent } from "@/api/fetchEvents/types";
import { ApiEventType } from "@/api/fetchEventTypes/types";
import { RootState } from "@/app/rootReducer";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";
import { EventFormData } from "@/modules/eventRegistration/features/EventManagement/schemas/eventSchema";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";

// Create entity adapter for events
const eventsAdapter = createEntityAdapter<ApiEvent>();

// create entity adapter for event types
const eventTypesAdapter = createEntityAdapter<ApiEventType>();

// create entity adapter for event instructors
const eventInstructorsAdapter = createEntityAdapter<ApiEventInstructor>();

// create entity adapter for event categories
const eventCategoriesAdapter = createEntityAdapter<ApiEventCategory>();

// Get the selectors
const selectors = eventsAdapter.getSelectors<RootState>(
  (state) => state.eventManagementEvents,
);

// Define the state shape for event data
interface EventManagementEventsState {
  loading: boolean;
  error: string | null;
  totalCount: number;
  selectedIds: number[];
  dialog: {
    isSubmitting: boolean;
    submitError: string | null;
    showUnsavedChangesDialog: boolean;
    showResetDialog: boolean;
    eventTypes: ApiEventType[];
    isLoadingEventTypes: boolean;
    eventInstructors: ApiEventInstructor[];
    isLoadingEventInstructors: boolean;
    eventCategories: ApiEventCategory[];
    isLoadingEventCategories: boolean;
    selectedFiles: File[];
    existingFiles: ApiEventFile[];
    formData?: EventFormData;
  };
  eventTypes: EntityState<ApiEventType, number>;
  eventInstructors: EntityState<ApiEventInstructor, number>;
  eventCategories: EntityState<ApiEventCategory, number>;
  isLoadingEventCategories: boolean;
  showDeleteCategoryDialog: boolean;
  eventDetails: {
    isLoading: boolean;
    error: string | null;
  };
}

// Initial state using the adapter
const initialState = eventsAdapter.getInitialState<EventManagementEventsState>({
  loading: false,
  error: null,
  totalCount: 0,
  selectedIds: [],
  dialog: {
    isSubmitting: false,
    submitError: null,
    showUnsavedChangesDialog: false,
    showResetDialog: false,
    eventTypes: [],
    isLoadingEventTypes: false,
    eventInstructors: [],
    isLoadingEventInstructors: false,
    eventCategories: [],
    isLoadingEventCategories: false,
    selectedFiles: [],
    existingFiles: [],
  },
  eventTypes: eventTypesAdapter.getInitialState(),
  eventInstructors: eventInstructorsAdapter.getInitialState(),
  eventCategories: eventCategoriesAdapter.getInitialState(),
  isLoadingEventCategories: false,
  showDeleteCategoryDialog: false,
  eventDetails: {
    isLoading: false,
    error: null,
  },
});

// Create the slice
const eventManagementEventsSlice = createSlice({
  name: "eventManagementEvents",
  initialState,
  reducers: {
    toggleEventSelection(state, action: PayloadAction<number>) {
      const id = action.payload;
      const index = state.selectedIds.indexOf(id);
      if (index === -1) {
        state.selectedIds.push(id);
      } else {
        state.selectedIds.splice(index, 1);
      }
    },
    setSelectedEvents(state, action: PayloadAction<number[]>) {
      state.selectedIds = action.payload;
    },
    clearSelectedEvents(state) {
      state.selectedIds = [];
    },
    setDialogSubmitting(state, action: PayloadAction<boolean>) {
      state.dialog.isSubmitting = action.payload;
    },
    setDialogSubmitError(state, action: PayloadAction<string | null>) {
      state.dialog.submitError = action.payload;
    },
    setDialogUnsavedChanges(state, action: PayloadAction<boolean>) {
      state.dialog.showUnsavedChangesDialog = action.payload;
    },
    setDialogReset(state, action: PayloadAction<boolean>) {
      state.dialog.showResetDialog = action.payload;
    },
    setDialogEventTypes(state, action: PayloadAction<ApiEventType[]>) {
      state.dialog.eventTypes = action.payload;
    },
    setDialogLoadingEventTypes(state, action: PayloadAction<boolean>) {
      state.dialog.isLoadingEventTypes = action.payload;
    },
    setDialogEventInstructors(
      state,
      action: PayloadAction<ApiEventInstructor[]>,
    ) {
      state.dialog.eventInstructors = action.payload;
    },
    setDialogLoadingEventInstructors(state, action: PayloadAction<boolean>) {
      state.dialog.isLoadingEventInstructors = action.payload;
    },
    setDialogEventCategories(state, action: PayloadAction<ApiEventCategory[]>) {
      state.dialog.eventCategories = action.payload;
    },
    setDialogLoadingEventCategories(state, action: PayloadAction<boolean>) {
      state.dialog.isLoadingEventCategories = action.payload;
    },
    setDialogSelectedFiles(state, action: PayloadAction<File[]>) {
      state.dialog.selectedFiles = action.payload;
    },
    setDialogExistingFiles(state, action: PayloadAction<ApiEventFile[]>) {
      state.dialog.existingFiles = action.payload;
    },
    addDialogSelectedFiles(state, action: PayloadAction<File[]>) {
      state.dialog.selectedFiles = [
        ...state.dialog.selectedFiles,
        ...action.payload,
      ];
    },
    removeDialogSelectedFile(state, action: PayloadAction<File>) {
      state.dialog.selectedFiles = state.dialog.selectedFiles.filter(
        (file) => file !== action.payload,
      );
    },
    removeDialogExistingFile(state, action: PayloadAction<ApiEventFile>) {
      state.dialog.existingFiles = state.dialog.existingFiles.filter(
        (file) => file.id !== action.payload.id,
      );
    },
    clearDialogFiles(state) {
      state.dialog.selectedFiles = [];
      state.dialog.existingFiles = [];
    },
    setDialogFormData(state, action: PayloadAction<EventFormData>) {
      state.dialog.formData = action.payload;
    },
    setShowDeleteCategoryDialog(state, action: PayloadAction<boolean>) {
      state.showDeleteCategoryDialog = action.payload;
    },
    // These will be replaced by the extraReducers when we implement the thunks
    setEvents(state, action: PayloadAction<ApiEvent[]>) {
      eventsAdapter.setAll(state, action.payload);
    },
    addEvent(state, action: PayloadAction<ApiEvent>) {
      eventsAdapter.addOne(state, action.payload);
    },
    updateEvent(state, action: PayloadAction<ApiEvent>) {
      eventsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeEvent(state, action: PayloadAction<number>) {
      eventsAdapter.removeOne(state, action.payload);
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setTotalCount(state, action: PayloadAction<number>) {
      state.totalCount = action.payload;
    },
  },
  // extraReducers will be added when we implement the thunks
});

// Export actions
export const {
  toggleEventSelection,
  setSelectedEvents,
  clearSelectedEvents,
  setDialogSubmitting,
  setDialogSubmitError,
  setDialogUnsavedChanges,
  setDialogReset,
  setDialogEventTypes,
  setDialogLoadingEventTypes,
  setDialogEventInstructors,
  setDialogLoadingEventInstructors,
  setDialogEventCategories,
  setDialogLoadingEventCategories,
  setDialogSelectedFiles,
  setDialogExistingFiles,
  addDialogSelectedFiles,
  removeDialogSelectedFile,
  removeDialogExistingFile,
  clearDialogFiles,
  setDialogFormData,
  setShowDeleteCategoryDialog,
  setEvents,
  addEvent,
  updateEvent,
  removeEvent,
  setLoading,
  setError,
  setTotalCount,
} = eventManagementEventsSlice.actions;

// Export selectors
export const {
  selectAll: selectAllEvents,
  selectById: selectEventById,
  selectIds: selectEventIds,
} = selectors;

// Note: The selectEventDialogState selector has been moved to eventManagementSelectors.ts
// for centralized selector management and to avoid duplication

export const selectEventDetailsLoading = (state: RootState) =>
  state.eventManagementEvents.eventDetails.isLoading;

export const selectTotalCount = (state: RootState) =>
  state.eventManagementEvents.totalCount;

export const selectEventFiles = (state: RootState) => ({
  selectedFiles: state.eventManagementEvents.dialog.selectedFiles,
  existingFiles: state.eventManagementEvents.dialog.existingFiles,
});

export default eventManagementEventsSlice.reducer;
