import React from "react";
import { Button } from "@/components/ui/button";
import { Star } from "lucide-react";
import { Link } from "react-router-dom";
import EventDirectorySearch from "../EventDirectorySearch/EventDirectorySearch";
import EventDirectoryFilters from "../EventDirectoryFilters/EventDirectoryFilters";
import { EventFilters } from "../EventDirectoryFilters/EventDirectoryFilters";

interface EventDirectoryToolbarProps {
  onSearch: (query: string) => void;
  onFilterChange: (filters: EventFilters) => void;
  isLoading: boolean;
}

const EventDirectoryToolbar: React.FC<EventDirectoryToolbarProps> = ({
  onSearch,
  onFilterChange,
}) => (
  <div className="flex items-center gap-4">
    <div className="flex-1">
      <EventDirectorySearch onSearch={onSearch} />
    </div>
    <div className="flex items-center gap-2">
      <EventDirectoryFilters onFilterChange={onFilterChange} />
      <Button
        asChild
        className="bg-primary hover:bg-primary/90 text-white"
        variant="outline"
      >
        <Link
          className="flex items-center gap-2"
          to="/event-registration/favorited-events"
        >
          <Star className="h-4 w-4" />
          <span className="hidden sm:inline">Favorited Events</span>
        </Link>
      </Button>
    </div>
  </div>
);

export default EventDirectoryToolbar;
