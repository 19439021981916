import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchEventsRequest } from "@/api/fetchEvents/types";
import { ApiEvent } from "@/api/fetchEvents/types";
import { ApiEventType } from "@/api/fetchEventTypes/types";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { EventFormData } from "@/modules/eventRegistration/features/EventManagement/schemas/eventSchema";
import { ApiEventSession } from "@/api/fetchEventSessions/types";

// Define the state shape for UI-related state
interface EventUIState {
  currentRequest: FetchEventsRequest;
  selectedCategories: string[];
  selectedDurations: string[];
  selectedCompany: string | null;
  priceRange: {
    min: number;
    max: number;
  };
  showEventDialog: boolean;
  eventToEdit: ApiEvent | null;
  showDeleteTypeDialog: boolean;
  showAddEventType: boolean;
  editingEventType: ApiEventType | null;
  showAddEventInstructor: boolean;
  editingEventInstructor: ApiEventInstructor | null;
  showDeleteInstructorDialog: boolean;
  showAddEventCategory: boolean;
  editingEventCategory: ApiEventCategory | null;
  showDeleteCategoryDialog: boolean;
  isSubmitting: boolean;
  submitError: string | null;
  showUnsavedChangesDialog: boolean;
  showResetDialog: boolean;
  eventTypes: ApiEventType[];
  isLoadingEventTypes: boolean;
  eventInstructors: ApiEventInstructor[];
  isLoadingEventInstructors: boolean;
  eventCategories: ApiEventCategory[];
  isLoadingEventCategories: boolean;
  selectedFiles: File[];
  existingFiles: ApiEventFile[];
  formData?: EventFormData;
  selectedSession: ApiEventSession | null;
}

const initialState: EventUIState = {
  currentRequest: {
    page: 1,
    pageSize: 10,
    searchTerm: "",
    sortBy: undefined,
    sortOrder: undefined,
  },
  selectedCategories: [],
  selectedDurations: [],
  selectedCompany: null,
  priceRange: {
    min: 0,
    max: 1000,
  },
  showEventDialog: false,
  eventToEdit: null,
  showDeleteTypeDialog: false,
  showAddEventType: false,
  editingEventType: null,
  showAddEventInstructor: false,
  editingEventInstructor: null,
  showDeleteInstructorDialog: false,
  showAddEventCategory: false,
  editingEventCategory: null,
  showDeleteCategoryDialog: false,
  isSubmitting: false,
  submitError: null,
  showUnsavedChangesDialog: false,
  showResetDialog: false,
  eventTypes: [],
  isLoadingEventTypes: false,
  eventInstructors: [],
  isLoadingEventInstructors: false,
  eventCategories: [],
  isLoadingEventCategories: false,
  selectedFiles: [],
  existingFiles: [],
  formData: undefined,
  selectedSession: null,
};

const eventUISlice = createSlice({
  name: "eventUI",
  initialState,
  reducers: {
    setCurrentRequest: (
      state,
      action: PayloadAction<Partial<FetchEventsRequest>>,
    ) => {
      state.currentRequest = {
        ...state.currentRequest,
        ...action.payload,
      };
    },
    resetPagination: (state) => {
      state.currentRequest.page = 1;
      state.currentRequest.pageSize = 10;
    },
    setSelectedCategories: (state, action: PayloadAction<string[]>) => {
      state.selectedCategories = action.payload;
      state.currentRequest.page = 1;
    },
    setSelectedDurations: (state, action: PayloadAction<string[]>) => {
      state.selectedDurations = action.payload;
      state.currentRequest.page = 1;
    },
    setSelectedCompany: (state, action: PayloadAction<string | null>) => {
      state.selectedCompany = action.payload;
      state.currentRequest.page = 1;
    },
    setPriceRange: (
      state,
      action: PayloadAction<{ min: number; max: number }>,
    ) => {
      state.priceRange = action.payload;
      state.currentRequest.page = 1;
    },
    updateEventDialog: (
      state,
      action: PayloadAction<{
        show: boolean;
        event: ApiEvent | null;
      }>,
    ) => {
      state.showEventDialog = action.payload.show;
      state.eventToEdit = action.payload.event;
    },
    clearAllFilters: (state) => {
      state.selectedCategories = [];
      state.selectedCompany = null;
      state.currentRequest = {
        ...state.currentRequest,
        eventCategoryId: undefined,
        isPublished: undefined,
        page: 1,
      };
    },
    setShowDeleteTypeDialog: (state, action: PayloadAction<boolean>) => {
      state.showDeleteTypeDialog = action.payload;
    },
    setShowAddEventType: (state, action: PayloadAction<boolean>) => {
      state.showAddEventType = action.payload;
    },
    setEditingEventType: (
      state,
      action: PayloadAction<ApiEventType | null>,
    ) => {
      state.editingEventType = action.payload;
    },
    setShowAddEventInstructor: (state, action: PayloadAction<boolean>) => {
      state.showAddEventInstructor = action.payload;
    },
    setEditingEventInstructor: (
      state,
      action: PayloadAction<ApiEventInstructor | null>,
    ) => {
      state.editingEventInstructor = action.payload;
    },
    setShowDeleteInstructorDialog: (state, action: PayloadAction<boolean>) => {
      state.showDeleteInstructorDialog = action.payload;
    },
    setShowAddEventCategory: (state, action: PayloadAction<boolean>) => {
      state.showAddEventCategory = action.payload;
    },
    setEditingEventCategory: (
      state,
      action: PayloadAction<ApiEventCategory | null>,
    ) => {
      state.editingEventCategory = action.payload;
    },
    setShowDeleteCategoryDialog: (state, action: PayloadAction<boolean>) => {
      state.showDeleteCategoryDialog = action.payload;
    },
    setSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    setSubmitError: (state, action: PayloadAction<string | null>) => {
      state.submitError = action.payload;
    },
    setUnsavedChangesDialog: (state, action: PayloadAction<boolean>) => {
      state.showUnsavedChangesDialog = action.payload;
    },
    setResetDialog: (state, action: PayloadAction<boolean>) => {
      state.showResetDialog = action.payload;
    },
    setEventTypes: (state, action: PayloadAction<ApiEventType[]>) => {
      state.eventTypes = action.payload;
    },
    setLoadingEventTypes: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEventTypes = action.payload;
    },
  },
});

// Export actions
export const {
  setCurrentRequest,
  resetPagination,
  setSelectedCategories,
  setSelectedDurations,
  setSelectedCompany,
  setPriceRange,
  updateEventDialog,
  clearAllFilters,
  setShowDeleteTypeDialog,
  setShowAddEventType,
  setEditingEventType,
  setShowAddEventInstructor,
  setEditingEventInstructor,
  setShowDeleteInstructorDialog,
  setShowAddEventCategory,
  setEditingEventCategory,
  setShowDeleteCategoryDialog,
  setSubmitting,
  setSubmitError,
  setUnsavedChangesDialog,
  setResetDialog,
  setEventTypes,
  setLoadingEventTypes,
} = eventUISlice.actions;

export default eventUISlice.reducer;
