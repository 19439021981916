import { useCallback } from "react";
import { useAppDispatch } from "@/app/hooks";
import {
  fetchSessionsAction,
  duplicateSessionAction,
  deleteSessionAction,
} from "../thunks/sessionThunks";
import {
  setCurrentRequest,
  updateSessionDialog,
  setSelectedStatus,
  clearAllFilters,
  setSessionsLoading,
} from "../slices/sessionUISlice";
import { useToastError } from "@/modules/eventRegistration/features/EventManagement/hooks/useToastError";
import { ApiEventSession } from "@/api/fetchEventSessions/types";
import { EventSessionFormData } from "../schemas/eventSessionSchema";
import { FilterType, isFilterType, isStatusValue } from "../utils/typeGuards";
import {
  toggleSessionSelection,
  setSelectedSessions,
} from "../slices/sessionsSlice";
import { useToastSuccess } from "@/modules/eventRegistration/features/EventManagement/hooks/useToastSuccess";
import { UseFormReturn } from "react-hook-form";

export interface SortingParams {
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
}

export interface SessionFilters {
  sessionInstructorId?: number | null;
  status?: string | null;
}

export type SessionRequest = {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
  searchTerm?: string;
  status?: string;
  eventId?: number;
};

export type FilterChangeParams = {
  status: string | null;
};

export type FilterOption = {
  label: string;
  value: string;
};

export interface FilterSection {
  title: string;
  type: FilterType;
  options: FilterOption[];
}

export type SearchChangeHandler = (query: string) => void;

interface UseSessionManagementActionsProps {
  currentRequest: SessionRequest;
  eventId?: number | string;
  sessionToEdit: ApiEventSession | null;
  handleSubmit?: (
    data: EventSessionFormData,
    session: ApiEventSession | null,
  ) => Promise<void>;
  onFilterChange?: (filters: FilterChangeParams) => void;
  onSearch?: (query: string) => Promise<void> | void;
  sessions?: ApiEventSession[];
}

export const useSessionManagementActions = ({
  currentRequest,
  eventId,
  sessionToEdit,
  handleSubmit,
  onFilterChange,
  onSearch,
  sessions,
}: UseSessionManagementActionsProps) => {
  const dispatch = useAppDispatch();
  const { executeToastError } = useToastError();
  const { executeToastSuccess } = useToastSuccess();

  const handlePaginationChange = useCallback(
    async (pageIndex: number, pageSize: number) => {
      return executeToastError(async () => {
        const updatedRequest = {
          ...currentRequest,
          page: pageIndex + 1,
          pageSize,
        };

        dispatch(setCurrentRequest(updatedRequest));

        // Set sessionsLoading to false to prevent double loading state
        dispatch(setSessionsLoading(false));
        await dispatch(
          fetchSessionsAction({
            eventId: currentRequest.eventId!,
            page: pageIndex + 1,
            pageSize,
            searchTerm: currentRequest.searchTerm,
            sortBy: currentRequest.sortBy,
            sortOrder: currentRequest.sortOrder,
          }),
        ).unwrap();
      }, "Failed to change page");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleSortingChange = useCallback(
    async (params: SortingParams) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            sortBy: params.sortBy,
            sortOrder: params.sortOrder,
            page: 1,
          }),
        );
        // Set sessionsLoading to false to prevent double loading state
        dispatch(setSessionsLoading(false));
        await dispatch(
          fetchSessionsAction({
            eventId: currentRequest.eventId!,
            sortBy: params.sortBy,
            sortOrder: params.sortOrder,
            searchTerm: currentRequest.searchTerm,
          }),
        ).unwrap();
      }, "Failed to sort sessions");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleTableSectionSortingChange = useCallback(
    (sortBy?: string, sortOrder?: "ASC" | "DESC") => {
      return handleSortingChange({ sortBy, sortOrder });
    },
    [handleSortingChange],
  );

  const handleSearchChange = useCallback(
    async (query: string): Promise<void> => {
      dispatch(
        setCurrentRequest({
          ...currentRequest,
          page: 1,
          searchTerm: query,
        }),
      );

      // Fetch sessions with the search term
      try {
        // Set sessionsLoading to false to prevent double loading state
        dispatch(setSessionsLoading(false));
        await dispatch(
          fetchSessionsAction({
            eventId: currentRequest.eventId!,
            searchTerm: query,
            sortBy: currentRequest.sortBy,
            sortOrder: currentRequest.sortOrder,
          }),
        ).unwrap();
      } catch (error) {
        console.error("Failed to search sessions:", error);
      }

      if (onSearch) {
        const result = onSearch(query);
        // If onSearch returns a Promise, await it
        if (result instanceof Promise) {
          await result;
        }
      }
      return Promise.resolve();
    },
    [dispatch, currentRequest, onSearch],
  );

  const handleFiltersChange = useCallback(
    async (filters: SessionFilters) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            status: filters.status ?? undefined,
            page: 1,
          }),
        );
        // Set sessionsLoading to false to prevent double loading state
        dispatch(setSessionsLoading(false));
        await dispatch(
          fetchSessionsAction({
            eventId: currentRequest.eventId!,
            status: filters.status ?? undefined,
            searchTerm: currentRequest.searchTerm,
            sortBy: currentRequest.sortBy,
            sortOrder: currentRequest.sortOrder,
          }),
        ).unwrap();
      }, "Failed to filter sessions");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleToggleFilter = useCallback(
    (type: string, value: string) => {
      if (!isFilterType(type)) return;
      if (type === "status") {
        const statusValue = isStatusValue(value) ? value : "SCHEDULED";
        dispatch(setSelectedStatus(statusValue));

        // Call the onFilterChange prop if provided
        if (onFilterChange) {
          onFilterChange({
            status: statusValue,
          });
        } else {
          // Otherwise use the internal handler
          handleFiltersChange({
            status: statusValue,
          });
        }
      }
    },
    [dispatch, handleFiltersChange, onFilterChange],
  );

  const handleClearFilters = useCallback(() => {
    dispatch(clearAllFilters());

    // Call the onFilterChange prop if provided
    if (onFilterChange) {
      onFilterChange({
        status: null,
      });
    } else {
      // Otherwise use the internal handler
      handleFiltersChange({
        status: null,
      });
    }
  }, [dispatch, handleFiltersChange, onFilterChange]);

  const handleDialogSubmit = useCallback(
    async (
      data: EventSessionFormData,
      form?: UseFormReturn<EventSessionFormData> | null,
      mode: "create" | "edit" = "create",
      onSuccess?: () => void,
      onClose?: () => void,
    ) => {
      try {
        if (handleSubmit) {
          await handleSubmit(data, sessionToEdit);

          // Reset form if creating a new session
          if (mode === "create" && form) {
            form.reset();
          }

          // Call onSuccess callback if provided
          if (onSuccess) {
            onSuccess();
          }

          // Close the dialog
          if (onClose) {
            onClose();
          }
        }
      } catch (error) {
        console.error("Form submission error:", error);
      }
    },
    [handleSubmit, sessionToEdit],
  );

  const handleAddSession = useCallback(() => {
    dispatch(updateSessionDialog({ show: true, session: null }));
  }, [dispatch]);

  const handleEditSession = useCallback(
    (session: ApiEventSession) => {
      dispatch(updateSessionDialog({ show: true, session }));
    },
    [dispatch],
  );

  const handleDialogClose = useCallback(() => {
    dispatch(updateSessionDialog({ show: false, session: null }));
  }, [dispatch]);

  const handleDialogSuccess = useCallback(() => {
    if (eventId) {
      dispatch(setSessionsLoading(false));
      dispatch(
        fetchSessionsAction({
          eventId: typeof eventId === "string" ? Number(eventId) : eventId,
          page: currentRequest.page,
          pageSize: currentRequest.pageSize,
          searchTerm: currentRequest.searchTerm,
          sortBy: currentRequest.sortBy,
          sortOrder: currentRequest.sortOrder,
          status: currentRequest.status,
        }),
      );
    }
  }, [dispatch, eventId, currentRequest]);

  // New handlers from SessionManagementTable
  const handleSelectSession = useCallback(
    (sessionId: number) => {
      dispatch(toggleSessionSelection(sessionId));
    },
    [dispatch],
  );

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked && sessions) {
        const sessionIds = sessions.map((session) => session.id);
        dispatch(setSelectedSessions(sessionIds));
      } else {
        dispatch(setSelectedSessions([]));
      }
    },
    [dispatch, sessions],
  );

  const handleDuplicateSession = useCallback(
    async (session: ApiEventSession) => {
      await executeToastSuccess(
        async () => {
          const result = await dispatch(
            duplicateSessionAction({
              sessionId: session.id,
              eventId: session.eventId,
              eventName: session.eventName,
              startDate: session.startDate,
              endDate: session.endDate,
              maxEnrollments: session.maxEnrollments,
              virtualLink: session.virtualLink,
              notes: session.notes,
              status: session.status,
            }),
          ).unwrap();
          if (eventId) {
            dispatch(setSessionsLoading(false));

            dispatch(
              fetchSessionsAction({
                eventId:
                  typeof eventId === "string" ? Number(eventId) : eventId,
                page: currentRequest.page,
                pageSize: currentRequest.pageSize,
                searchTerm: currentRequest.searchTerm,
                sortBy: currentRequest.sortBy,
                sortOrder: currentRequest.sortOrder,
                status: currentRequest.status,
              }),
            );
          }
          return result;
        },
        {
          title: "Success",
          description: "Session duplicated successfully",
        },
      );
    },
    [dispatch, eventId, executeToastSuccess, currentRequest],
  );

  const handleDeleteSession = useCallback(
    async (sessionId: number) => {
      await executeToastSuccess(
        async () => {
          // First, get the current total count before deletion
          const currentTotalCount = sessions?.length || 0;
          const itemsPerPage = currentRequest.pageSize || 10;
          const currentPage = currentRequest.page || 1;

          // Delete the session
          const result = await dispatch(
            deleteSessionAction(sessionId),
          ).unwrap();

          if (eventId) {
            // Calculate the new total count after deletion
            const newTotalCount = currentTotalCount - 1;
            const totalPages = Math.ceil(newTotalCount / itemsPerPage);

            // If the current page is now beyond the total pages, go to the last page
            let targetPage = currentPage;
            if (currentPage > totalPages && totalPages > 0) {
              targetPage = totalPages;
            }

            // If we're on the last page and it's now empty (but not page 1), go to previous page
            if (sessions && sessions.length === 1 && currentPage > 1) {
              targetPage = currentPage - 1;
            }

            // Update the UI state to reflect the new page
            dispatch(
              setCurrentRequest({
                ...currentRequest,
                page: targetPage,
              }),
            );

            // Set sessionsLoading to false to prevent double loading state
            dispatch(setSessionsLoading(false));

            // Fetch sessions with the adjusted page
            dispatch(
              fetchSessionsAction({
                eventId:
                  typeof eventId === "string" ? Number(eventId) : eventId,
                page: targetPage,
                pageSize: currentRequest.pageSize,
                searchTerm: currentRequest.searchTerm,
                sortBy: currentRequest.sortBy,
                sortOrder: currentRequest.sortOrder,
                status: currentRequest.status,
              }),
            );
          }
          return result;
        },
        {
          title: "Success",
          description: "Session deleted successfully",
        },
      );
    },
    [dispatch, eventId, executeToastSuccess, currentRequest, sessions],
  );

  return {
    handlePaginationChange,
    handleSortingChange,
    handleTableSectionSortingChange,
    handleSearchChange,
    handleFiltersChange,
    handleToggleFilter,
    handleClearFilters,
    handleDialogSubmit,
    handleAddSession,
    handleEditSession,
    handleDialogClose,
    handleDialogSuccess,
    handleSelectSession,
    handleSelectAll,
    handleDuplicateSession,
    handleDeleteSession,
  };
};
