import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectSessionDialogStateFromSlice } from "../selectors/sessionManagementSelectors";
import {
  setDialogUnsavedChanges,
  setDialogReset,
} from "../slices/sessionsSlice";

interface UseSessionDialogAlertsProps {
  onResetConfirm: () => void;
  onUnsavedChangesConfirm: () => void;
}

export const useSessionDialogAlerts = ({
  onResetConfirm,
  onUnsavedChangesConfirm,
}: UseSessionDialogAlertsProps) => {
  const dispatch = useAppDispatch();
  const dialogState = useAppSelector(selectSessionDialogStateFromSlice);

  const showUnsavedChangesDialog = dialogState.showUnsavedChangesDialog;
  const showResetDialog = dialogState.showResetDialog;

  const handleCancelClose = () => {
    dispatch(setDialogUnsavedChanges(false));
  };

  const handleConfirmClose = () => {
    dispatch(setDialogUnsavedChanges(false));
    onUnsavedChangesConfirm();
  };

  const handleCancelReset = () => {
    dispatch(setDialogReset(false));
  };

  const handleConfirmReset = () => {
    dispatch(setDialogReset(false));
    onResetConfirm();
  };

  const setUnsavedChangesDialog = (open: boolean) => {
    dispatch(setDialogUnsavedChanges(open));
  };

  const setResetDialog = (open: boolean) => {
    dispatch(setDialogReset(open));
  };

  return {
    showUnsavedChangesDialog,
    showResetDialog,
    handleCancelClose,
    handleConfirmClose,
    handleCancelReset,
    handleConfirmReset,
    setUnsavedChangesDialog,
    setResetDialog,
  };
};
