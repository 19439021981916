import { SessionCard } from "../SessionCard/SessionCard";
import { SessionPagination } from "../SessionPagination/SessionPagination";
import { Loader2 } from "lucide-react";
import { ApiEventSession } from "@/api/fetchEventSessions/types";

export interface SessionListProps {
  sessions: ApiEventSession[];
  selectedSession: number | null;
  totalSessionsCount: number;
  sessionsLoading: boolean;
  onSessionClick: (sessionId: number) => void;
  pageIndex: number;
  pageSize: number;
  onPageChange: (newPageIndex: number, newPageSize: number) => void;
}

export const SessionList: React.FC<SessionListProps> = ({
  sessions,
  selectedSession,
  totalSessionsCount,
  sessionsLoading,
  onSessionClick,
  pageIndex,
  pageSize,
  onPageChange,
}) => {
  return (
    <div className="space-y-4 mt-8">
      <h2 className="text-xl font-semibold">Available Sessions</h2>
      {sessionsLoading ? (
        <div className="flex justify-center items-center h-32">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
        </div>
      ) : (
        <div className="grid gap-4">
          {sessions && sessions.length > 0 ? (
            sessions.map((session) => (
              <SessionCard
                isSelected={session.id === selectedSession}
                key={session.id}
                onClick={() => onSessionClick(session.id)}
                session={session}
              />
            ))
          ) : (
            <div className="p-10 text-center text-muted-foreground">
              No sessions available
            </div>
          )}
        </div>
      )}
      {totalSessionsCount > 0 && (
        <SessionPagination
          onPageChange={onPageChange}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalCount={totalSessionsCount}
        />
      )}
    </div>
  );
};
