import { useEffect } from "react";
import { useAppDispatch } from "@/app/hooks";
import { useToast } from "@/components/ui/use-toast";
import { fetchEventsAction } from "../thunks/eventManagementThunks";
import { setCurrentRequest } from "../slices/eventManagementUISlice";
import {
  DEFAULTS,
  TOAST_MESSAGES,
} from "../constants/eventManagementConstants";

/**
 * Hook to handle the initial fetching of events when the component mounts
 */
const useInitialEventsFetch = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Set the current request parameters
        dispatch(setCurrentRequest({ page: 1, pageSize: DEFAULTS.PAGE_SIZE }));

        // Fetch events and update the event management state
        await dispatch(fetchEventsAction()).unwrap();
      } catch {
        toast({
          title: TOAST_MESSAGES.FETCH_ERROR.title,
          description: TOAST_MESSAGES.FETCH_ERROR.description,
          variant: "destructive",
        });
      }
    };

    // Call the fetch function immediately when the component mounts
    fetchEvents();
  }, [dispatch, toast]);
};

export default useInitialEventsFetch;
