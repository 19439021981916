import React, { useCallback, useMemo } from "react";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import ProspectListFilters from "../ProspectListFilters/ProspectListFilters";
import { useStochasticProspects } from "../../hooks/useStochasticProspects";
import { createStochasticProspectsColumns } from "../ProspectsColumns/ProspectsColumns";
import { StochasticProspect } from "../../../../../../api/fetchStochasticProspects/types";
import DataTable from "@/components/Datatable/Datatable";

const ProspectList: React.FC = () => {
  const {
    prospects,
    totalCount,
    loading,
    error,
    pagination,
    handlePaginationChange,
    handleFilterChange,
    filters,
  } = useStochasticProspects();

  const columns = useMemo(() => createStochasticProspectsColumns(), []);

  const onFilterChange = useCallback(
    (searchTerm: string) => {
      handleFilterChange(searchTerm);
      handlePaginationChange({
        pageIndex: 0,
        pageSize: pagination.pageSize,
      });
    },
    [handleFilterChange, handlePaginationChange, pagination.pageSize],
  );

  return (
    <MainPageWrapper error={error} loading={loading} title="Prospects">
      <ProspectListFilters filters={filters} onFilterChange={onFilterChange} />

      <div className="relative">
        <DataTable<StochasticProspect>
          columns={columns}
          data={prospects}
          error={error}
          loading={loading}
          noDataMessage="No prospects found"
          onPageChange={(newPageIndex, newPageSize) =>
            handlePaginationChange({
              pageIndex: newPageIndex,
              pageSize: newPageSize,
            })
          }
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          rowKeyExtractor={(item) => item.id}
          totalCount={totalCount}
        />
      </div>
    </MainPageWrapper>
  );
};

export default ProspectList;
