import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { EventFormField } from "@/modules/eventRegistration/features/EventManagement/components/EventFormField/EventFormField";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { useEntitySubmit } from "@/modules/eventRegistration/features/EventManagement/hooks/useEntitySubmit";
import { useEntityDialogActions } from "../hooks/useEntityDialogActions";

export interface EntityModalProps<T extends FieldValues> {
  entity?: T;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
  title: string;
  form: UseFormReturn<T>;
  handleSubmit: (data: T) => Promise<boolean>;
  isSubmitting: boolean;
  fields: Array<{
    name: keyof T;
    label: string;
    placeholder?: string;
    type?: "text" | "textarea" | "checkbox" | "select" | "datetime-local";
    options?: Array<{ value: string; label: string }>;
  }>;
}

export function EntityModal<T extends FieldValues>({
  entity,
  open,
  onOpenChange,
  onSuccess,
  title,
  form,
  handleSubmit,
  isSubmitting,
  fields,
}: EntityModalProps<T>): JSX.Element {
  const { onSubmit } = useEntitySubmit({
    entity,
    title,
    form,
    handleSubmit,
    onOpenChange,
    onSuccess,
  });

  const { handleOpenChange } = useEntityDialogActions({
    form,
    onOpenChange,
  });

  return (
    <Dialog onOpenChange={handleOpenChange} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{entity ? `Edit ${title}` : `Add ${title}`}</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
            {fields.map((field) => (
              <EventFormField
                control={form.control}
                key={String(field.name)}
                label={field.label}
                name={field.name as Path<T>}
                options={field.options}
                placeholder={field.placeholder}
                type={field.type || "text"}
              />
            ))}

            <div className="flex justify-end space-x-4 pt-4">
              <Button
                onClick={() => onOpenChange(false)}
                type="button"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                className="min-w-[100px]"
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Saving...
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
