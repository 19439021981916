import axios from "../axiosInstance";
import { DeleteEventResponse } from "./types";

export const deleteEvent = async (
  eventId: number,
): Promise<DeleteEventResponse> => {
  const response = await axios.delete<DeleteEventResponse>(
    `/api/private/events/${eventId}`,
  );
  return response.data;
};
