import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import { useBatchProspects } from "../../hooks/useBatchProspects";
import { createBatchProspectsColumns } from "../BatchProspectColumns/BatchProspectColumns";
import { Prospect } from "../../../../../../api/getBatchProspects/types";
import DataTable from "@/components/Datatable/Datatable";

const BatchProspectList: React.FC = () => {
  const { batchId } = useParams<{ campaignId: string; batchId: string }>();
  const numericBatchId = useMemo(() => Number(batchId), [batchId]);

  const {
    prospects,
    totalCount,
    loading,
    error,
    pagination,
    handlePaginationChange,
  } = useBatchProspects(numericBatchId);

  const columns = useMemo(() => createBatchProspectsColumns(), []);

  return (
    <MainPageWrapper error={error} loading={loading} title="Batch Prospects">
      <DataTable<Prospect>
        columns={columns}
        data={prospects}
        error={error}
        loading={loading}
        noDataMessage="No prospects found"
        onPageChange={(newPageIndex, newPageSize) =>
          handlePaginationChange({
            pageIndex: newPageIndex,
            pageSize: newPageSize,
          })
        }
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        rowKeyExtractor={(item) => item.id}
        totalCount={totalCount}
      />
    </MainPageWrapper>
  );
};

export default BatchProspectList;
