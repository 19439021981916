import React, { useCallback, useMemo, useState } from "react";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import { useStochasticMailingData } from "../../hooks/useStochasticMailingData";
import { createStochasticMailingColumns } from "../StochasticMailingListColumns/StochasticMailingListColumns";
import StochasticMailingListFilters from "../StochasticMailingListFilters/StochasticMailingListFilters";
import { useDownloadBatchesProspectsCsv } from "@/modules/stochastic/features/StochasticMailing/hooks/useDownloadBatchesProspectsCsv";
import { useBulkUpdateBatchesStatus } from "@/modules/stochastic/features/StochasticMailing/hooks/useBulkUpdateBatchesStatus";
import UpdateBatchStatusModal from "@/modules/stochastic/features/StochasticMailing/components/UpdateBatchStatusModal/UpdateBatchStatusModal";
import { StochasticClientMailDataRow } from "../../../../../../api/fetchStochasticClientMailData/types";
import DataTable from "@/components/Datatable/Datatable";

const StochasticMailingList: React.FC = () => {
  const {
    mailDataRows,
    totalCount,
    mailDataRowsLoading,
    mailDataRowsError,
    pagination,
    bulkBatchStatusDetailsMetadata,
    bulkBatchStatusDetailsMetadataLoading,
    filters,
    handlePaginationChange,
    handleFilterChange,
  } = useStochasticMailingData();

  const { loading: isDownloading, downloadCsv } =
    useDownloadBatchesProspectsCsv();
  const { loading: isBulkUpdating, bulkUpdateBatchesStatus } =
    useBulkUpdateBatchesStatus();

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedStatus: "new",
  });

  const handleUpdateBatchStatusButtonClick = () =>
    setModalState({
      isOpen: true,
      selectedStatus: bulkBatchStatusDetailsMetadata?.currentStatus || "new",
    });

  const handleModalCloseButtonClicked = () =>
    setModalState((prev) => ({ ...prev, isOpen: false }));

  const handleUpdateStatus = (newStatus: string) =>
    setModalState((prev) => ({ ...prev, selectedStatus: newStatus }));

  const handleDownloadCsv = useCallback(() => {
    if (filters.week && filters.year) {
      downloadCsv(filters.week as number, filters.year as number);
    }
  }, [downloadCsv, filters]);

  const handleSaveButtonClicked = useCallback(async () => {
    if (!filters.week || !filters.year) return;
    await bulkUpdateBatchesStatus(
      filters.year as number,
      filters.week as number,
      modalState.selectedStatus,
    );
    handleModalCloseButtonClicked();
    handleFilterChange("week", filters.week);
  }, [
    bulkUpdateBatchesStatus,
    filters,
    modalState.selectedStatus,
    handleModalCloseButtonClicked,
    handleFilterChange,
  ]);

  const columns = useMemo(() => createStochasticMailingColumns(), []);

  const batchStatusOptions =
    bulkBatchStatusDetailsMetadata?.bulkBatchStatusOptions || [];
  const isModalSaveButtonDisabled =
    modalState.selectedStatus ===
      bulkBatchStatusDetailsMetadata?.currentStatus || isBulkUpdating;

  return (
    <MainPageWrapper
      error={mailDataRowsError}
      loading={mailDataRowsLoading || bulkBatchStatusDetailsMetadataLoading}
      title="Campaign Status"
    >
      <StochasticMailingListFilters
        filters={filters}
        isDownloading={isDownloading}
        onDownloadCLIFileButtonClick={handleDownloadCsv}
        onFilterChange={handleFilterChange}
        onUpdateBatchStatusButtonClick={handleUpdateBatchStatusButtonClick}
      />

      <DataTable<StochasticClientMailDataRow>
        columns={columns}
        data={mailDataRows}
        error={mailDataRowsError}
        loading={mailDataRowsLoading || bulkBatchStatusDetailsMetadataLoading}
        noDataMessage="No campaign rows found"
        onPageChange={(newPageIndex, newPageSize) =>
          handlePaginationChange({
            pageIndex: newPageIndex,
            pageSize: newPageSize,
          })
        }
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        rowKeyExtractor={(row) =>
          row.id ?? `${row.campaignId}-${row.batchNumber}`
        }
        totalCount={totalCount}
      />

      <UpdateBatchStatusModal
        batchStatusOptions={batchStatusOptions}
        isModalOpen={modalState.isOpen}
        isSaveButtonDisabled={isModalSaveButtonDisabled}
        isSaving={isBulkUpdating}
        onCloseButtonClick={handleModalCloseButtonClicked}
        onSaveButtonClick={handleSaveButtonClicked}
        onStatusChange={handleUpdateStatus}
        selectedStatus={modalState.selectedStatus}
      />
    </MainPageWrapper>
  );
};

export default StochasticMailingList;
