import { StochasticProspect } from "../../../../../../api/fetchStochasticProspects/types";
import { Column } from "@/components/Datatable/Datatable";

export function createStochasticProspectsColumns(): Column<StochasticProspect>[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Full Name",
      accessorKey: "fullName",
    },
    {
      header: "Address 1",
      cell: ({ row }) => row.original.address?.address1 ?? "N/A",
    },
    {
      header: "City",
      cell: ({ row }) => row.original.address?.city ?? "N/A",
    },
    {
      header: "State",
      cell: ({ row }) => row.original.address?.stateCode ?? "N/A",
    },
    {
      header: "Postal Code",
      cell: ({ row }) => {
        const postalCode = row.original.address?.postalCode;
        if (!postalCode) {
          return "N/A";
        }
        return postalCode.length <= 5
          ? postalCode
          : postalCode.slice(0, 5) + "-" + postalCode.slice(5);
      },
    },
    {
      header: "Preferred",
      cell: ({ row }) => (row.original.isPreferred ? "Yes" : "No"),
    },
    {
      header: "Do Not Mail",
      cell: ({ row }) => (row.original.doNotMail ? "Yes" : "No"),
    },
    {
      header: "Do Not Contact",
      cell: ({ row }) => (row.original.doNotContact ? "Yes" : "No"),
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) =>
        row.original.createdAt
          ? new Date(row.original.createdAt).toLocaleDateString()
          : "N/A",
    },
    {
      header: "Updated At",
      accessorKey: "updatedAt",
      cell: ({ row }) =>
        row.original.updatedAt
          ? new Date(row.original.updatedAt).toLocaleDateString()
          : "N/A",
    },
  ];
}
