import { createContext, useContext, ReactNode } from "react";
import { useAppSelector, useAppDispatch } from "@/app/hooks";
import {
  selectCurrentRequestState,
  selectSessionsWithLoadingState,
} from "../selectors/sessionManagementSelectors";
import {
  SortingParams,
  useSessionManagementActions,
  SessionFilters,
} from "../hooks/useSessionManagementActions";
import {
  ApiEventSession,
  FetchEventSessionsRequest,
} from "@/api/fetchEventSessions/types";
import { setCurrentRequest } from "../slices/sessionUISlice";
import { editSessionAction } from "../thunks/sessionThunks";

export interface SessionManagementContextType {
  currentRequest: FetchEventSessionsRequest;
  isLoadingSessions: boolean;
  handlePaginationChange: (
    pageIndex: number,
    pageSize: number,
  ) => Promise<void>;
  handleSortingChange: (params: SortingParams) => Promise<void>;
  handleSearchChange: (searchTerm: string) => Promise<void>;
  handleFiltersChange: (filters: SessionFilters) => Promise<void>;
  handleEditSession: (session: ApiEventSession) => void;
}

export const SessionManagementContext = createContext<
  SessionManagementContextType | undefined
>(undefined);

export const SessionManagementProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const currentRequest = useAppSelector(selectCurrentRequestState);
  const { isLoadingSessions } = useAppSelector(selectSessionsWithLoadingState);
  const {
    handlePaginationChange,
    handleSearchChange: asyncHandleSearchChange,
    handleFiltersChange,
    handleSortingChange,
  } = useSessionManagementActions({
    currentRequest,
    eventId: currentRequest.eventId,
    sessionToEdit: null,
  });
  const dispatch = useAppDispatch();

  return (
    <SessionManagementContext.Provider
      value={{
        currentRequest,
        isLoadingSessions,
        handlePaginationChange,
        handleSearchChange: (searchTerm: string) =>
          Promise.resolve(asyncHandleSearchChange(searchTerm)),
        handleFiltersChange: (filters: SessionFilters) =>
          Promise.resolve(handleFiltersChange(filters)),
        handleSortingChange,
        handleEditSession: (session: ApiEventSession) => {
          dispatch(
            setCurrentRequest({
              ...currentRequest,
              eventId: session.eventId,
            }),
          );
          dispatch(
            editSessionAction({
              sessionId: session.id,
              editSessionDTO: {
                eventId: session.eventId,
                eventName: session.eventName,
                startDate: session.startDate,
                endDate: session.endDate,
                maxEnrollments: session.maxEnrollments,
                virtualLink: session.virtualLink,
                notes: session.notes,
                status: session.status,
              },
            }),
          );
        },
      }}
    >
      {children}
    </SessionManagementContext.Provider>
  );
};

export const useSessionManagementContext = () => {
  const context = useContext(SessionManagementContext);
  if (context === undefined) {
    throw new Error(
      "useSessionManagementContext must be used within a SessionManagementProvider",
    );
  }
  return context;
};
