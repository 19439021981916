import { useState, useCallback, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "@/app/hooks";
import { useDebouncedValue } from "../../../../../hooks/useDebouncedValue";
import { selectCurrentRequestState } from "../selectors/eventDirectorySelectors";
import { setCurrentRequest } from "../slices/eventUISlice";

/**
 * Hook to handle search functionality with debouncing
 * @param onSearch Function to call when search is triggered
 * @param debounceMs Debounce time in milliseconds
 * @returns Object containing search query, handler function, and debounced query
 */
export const useEventSearch = (
  onSearch: (query: string) => Promise<void> | void,
  debounceMs: number = 300,
) => {
  const currentRequest = useAppSelector(selectCurrentRequestState);
  const dispatch = useAppDispatch();

  // Use a ref to track the previous search term to avoid infinite loops
  const prevSearchTermRef = useRef<string | null | undefined>(
    currentRequest.searchTerm,
  );

  // Initialize local search query with current search term from state
  const [localSearchQuery, setLocalSearchQuery] = useState(
    currentRequest.searchTerm ?? "",
  );

  // Create debounced version of the search query
  const debouncedQuery = useDebouncedValue(localSearchQuery, debounceMs);

  // Handle changes to the search input
  const handleSearchChange = useCallback((query: string) => {
    setLocalSearchQuery(query);
  }, []);

  // Clear search term when component mounts
  useEffect(() => {
    // Only clear if there's a search term to clear
    if (currentRequest.searchTerm) {
      const newRequest = {
        ...currentRequest,
        searchTerm: "",
      };
      dispatch(setCurrentRequest(newRequest));
      setLocalSearchQuery("");
    }
  }, []);

  // Effect to handle debounced search
  useEffect(() => {
    // Only update if the debounced query is different from the previous search term
    if (debouncedQuery !== prevSearchTermRef.current) {
      prevSearchTermRef.current = debouncedQuery;

      // Create a new request object with updated search term
      const newRequest = {
        ...currentRequest,
        page: 1,
        searchTerm: debouncedQuery,
      };

      dispatch(setCurrentRequest(newRequest));
      onSearch(debouncedQuery);
    }
  }, [debouncedQuery, dispatch, onSearch, currentRequest]);

  return {
    searchQuery: localSearchQuery,
    handleSearchChange,
    debouncedQuery,
  };
};
