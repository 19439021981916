import { useEffect, useState, useCallback, useRef } from "react";
import { UseFormReturn } from "react-hook-form";
import { useAppDispatch } from "@/app/hooks";
import {
  setSubmitting,
  setSubmitError,
} from "@/modules/eventRegistration/features/EventManagement/slices/eventManagementUISlice";
import {
  fetchEventTypesAction,
  fetchEventInstructorsAction,
  fetchEventCategoriesAction,
} from "@/modules/eventRegistration/features/EventManagement/thunks/eventManagementThunks";
import {
  setShowAddEventType,
  setShowAddEventCategory,
  setShowAddEventInstructor,
} from "@/modules/eventRegistration/features/EventManagement/slices/eventManagementUISlice";
import { EventFormData } from "../schemas/eventSchema";
import { EVENT_FORM } from "../constants/eventManagementConstants";

interface UseEventDialogFormProps {
  form: UseFormReturn<EventFormData>;
  open: boolean;
  mode: "create" | "edit";
  initialData?: EventFormData;
}

export const useEventDialogForm = ({
  form,
  open,
  mode,
  initialData,
}: UseEventDialogFormProps) => {
  const dispatch = useAppDispatch();
  const [isInitializing, setIsInitializing] = useState(false);
  const initialized = useRef(false);
  const openRef = useRef(open);

  // Effect to fetch data when dialog opens
  useEffect(() => {
    const shouldFetchData = open && !initialized.current;

    if (shouldFetchData) {
      setIsInitializing(true);

      // Fetch the necessary data for the dialog
      const fetchData = async () => {
        try {
          // Set initialized first to prevent multiple fetches
          initialized.current = true;

          // Dispatch all fetch actions in parallel
          await Promise.all([
            dispatch(fetchEventTypesAction()),
            dispatch(fetchEventInstructorsAction()),
            dispatch(fetchEventCategoriesAction()),
          ]);

          // Initialize the form
          if (mode === "edit" && initialData) {
            form.reset(initialData);
          } else {
            form.reset(EVENT_FORM.DEFAULT_VALUES);
          }
        } catch (error) {
          console.error("Failed to initialize dialog:", error);
          // Reset initialized if fetching fails to allow retry
          initialized.current = false;
        } finally {
          setIsInitializing(false);
        }
      };

      fetchData();
    }
  }, [open, dispatch, form, mode, initialData]);

  // Effect to handle dialog open/close state
  useEffect(() => {
    // Only run this effect if the open state actually changes
    if (openRef.current !== open) {
      openRef.current = open;
      if (!open) {
        // Reset the dialog state when closing
        dispatch(setSubmitting(false));
        dispatch(setSubmitError(null));
        form.reset(EVENT_FORM.DEFAULT_VALUES);
        setIsInitializing(false);
        initialized.current = false;
      }
    }
  }, [open, dispatch, form]);

  const isDirty = form.formState.isDirty;

  const handleEventTypeChange = useCallback(
    (newValue: string) => {
      if (newValue === "add-new") {
        dispatch(setShowAddEventType(true));
        const previousValue = form.getValues("eventTypeId");
        form.setValue("eventTypeId", previousValue);
      }
    },
    [dispatch, form],
  );

  const handleEventCategoryChange = useCallback(
    (newValue: string) => {
      if (newValue === "add-new") {
        dispatch(setShowAddEventCategory(true));
        const previousValue = form.getValues("eventCategoryId");
        form.setValue("eventCategoryId", previousValue);
      }
    },
    [dispatch, form],
  );

  const handleEventInstructorChange = useCallback(
    (newValue: string) => {
      if (newValue === "add-new") {
        dispatch(setShowAddEventInstructor(true));
        const previousValue = form.getValues("eventInstructorId");
        form.setValue("eventInstructorId", previousValue);
      }
    },
    [dispatch, form],
  );

  return {
    isInitializing,
    isDirty,
    handleEventTypeChange,
    handleEventCategoryChange,
    handleEventInstructorChange,
  };
};
