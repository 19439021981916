import { NavigationItem } from "@/navigation/types";
import hubConfig from "@/navigation/hubNavigation";
import adminConfig from "@/navigation/adminNavigation";
import stochasticConfig from "@/navigation/stochasticNavigation";
import trainingPortalConfig from "@/navigation/eventRegistrationNavigation";
import coachingConfig from "@/navigation/coachingNavigation";
import { HomeIcon, Squares2X2Icon } from "@heroicons/react/24/outline";

export function getUnifiedNavigation(): NavigationItem[] {
  return [
    {
      name: "Hub",
      internalName: "hub",
      permissions: [],
      href: "#",
      icon: HomeIcon,
      current: false,
      children: hubConfig.navigation,
    },
    {
      name: "Coaching",
      internalName: "coaching",
      permissions: [],
      href: "#",
      icon: Squares2X2Icon,
      current: false,
      children: coachingConfig.navigation,
    },
    {
      name: "Training Portal",
      internalName: "training_portal",
      permissions: [],
      href: "#",
      icon: Squares2X2Icon,
      current: false,
      children: trainingPortalConfig.navigation,
    },
    {
      name: "Stochastic",
      internalName: "stochastic",
      permissions: [],
      href: "#",
      icon: Squares2X2Icon,
      current: false,
      children: stochasticConfig.navigation,
    },
    {
      name: "Admin",
      internalName: "admin",
      permissions: [],
      href: "#",
      icon: Squares2X2Icon,
      current: false,
      roles: ["ROLE_SUPER_ADMIN"],
      children: adminConfig.navigation,
      isCertainPathOnly: true,
    },
  ];
}
