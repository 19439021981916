import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/app/hooks";
import { useEffect, useRef } from "react";
import {
  SessionFormData,
  DEFAULT_SESSION_FORM_VALUES,
  sessionSchema,
} from "../schemas/sessionSchema";
import {
  setDialogUnsavedChanges,
  setDialogReset,
} from "../slices/sessionsSlice";

type UseSessionDialogFormProps = {
  onClose: () => void;
  initialValues?: SessionFormData;
};

type UseSessionDialogFormReturn = {
  form: UseFormReturn<SessionFormData>;
  handleClose: () => void;
  handleReset: () => void;
  confirmReset: () => void;
  confirmClose: () => void;
};

export const useSessionDialogForm = ({
  onClose,
  initialValues = DEFAULT_SESSION_FORM_VALUES,
}: UseSessionDialogFormProps): UseSessionDialogFormReturn => {
  const dispatch = useAppDispatch();
  const initialValuesRef = useRef(initialValues);

  const form = useForm<SessionFormData>({
    resolver: zodResolver(sessionSchema),
    defaultValues: initialValues,
  });

  // Reset form when initialValues change
  useEffect(() => {
    if (initialValues && initialValues !== initialValuesRef.current) {
      initialValuesRef.current = initialValues;
      form.reset(initialValues);
    }
  }, [initialValues, form]);

  const handleClose = () => {
    const isDirty = form.formState.isDirty;

    if (isDirty) {
      dispatch(setDialogUnsavedChanges(true));
    } else {
      onClose();
    }
  };

  const handleReset = () => {
    const isDirty = form.formState.isDirty;

    if (isDirty) {
      dispatch(setDialogReset(true));
    } else {
      form.reset(initialValues);
    }
  };

  const confirmReset = () => {
    form.reset(initialValues);
    dispatch(setDialogReset(false));
  };

  const confirmClose = () => {
    dispatch(setDialogUnsavedChanges(false));
    onClose();
  };

  return {
    form,
    handleClose,
    handleReset,
    confirmReset,
    confirmClose,
  };
};
