import { ReactNode } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Loader2 } from "lucide-react";

export interface EntityDialogProps<TFormData extends FieldValues> {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  isSubmitting?: boolean;
  isInitializing?: boolean;
  submitError?: string | null;
  errorTitle?: string;
  form: UseFormReturn<TFormData>;
  onSubmit: (data: TFormData) => Promise<void> | void;
  onClose: () => void;
  onReset: () => void;
  isDirty?: boolean;
  mode: "create" | "edit";
  submitButtonText?: {
    create: string;
    edit: string;
    creating?: string;
    updating?: string;
  };
  resetButtonText?: string;
  cancelButtonText?: string;
  loadingText?: string;
  children: ReactNode;
  preventInteractOutside?: boolean;
  clearFiles?: () => void;
}

export const EntityDialog = <TFormData extends FieldValues>(
  props: EntityDialogProps<TFormData>,
): JSX.Element => {
  const {
    open,
    onOpenChange,
    title,
    isSubmitting = false,
    isInitializing = false,
    submitError = null,
    errorTitle = "Error",
    form,
    onSubmit,
    onReset,
    isDirty = false,
    mode,
    submitButtonText = {
      create: "Create",
      edit: "Update",
      creating: "Creating...",
      updating: "Updating...",
    },
    resetButtonText = "Reset",
    cancelButtonText = "Cancel",
    loadingText = "Loading details...",
    children,
    preventInteractOutside = true,
    clearFiles,
  } = props;

  return (
    <Sheet
      onOpenChange={(isOpen) => {
        // Always allow the dialog to close
        if (!isOpen) {
          onOpenChange(false);
          clearFiles?.(); // Call clearFiles when dialog is closed
        } else {
          onOpenChange(isOpen);
        }
      }}
      open={open}
    >
      <SheetContent
        className="sm:max-w-[600px] w-full overflow-y-auto max-h-screen px-4 md:px-6"
        onInteractOutside={(e) => {
          // Only prevent interaction outside if it's not a click on the close button
          const target = e.target as HTMLElement;
          const isCloseButton =
            target.closest("[data-radix-collection-item]") !== null;

          if (preventInteractOutside && isDirty && !isCloseButton) {
            e.preventDefault();
          }
        }}
      >
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>

        {isInitializing ? (
          <div className="h-[80vh] flex items-center justify-center">
            <div className="flex flex-col items-center gap-2">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <p className="text-sm text-muted-foreground">{loadingText}</p>
            </div>
          </div>
        ) : (
          <Form {...form}>
            <form
              className="space-y-4 py-4"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              {children}

              {submitError && (
                <Alert variant="destructive">
                  <AlertTitle>{errorTitle}</AlertTitle>
                  <AlertDescription>{submitError}</AlertDescription>
                </Alert>
              )}

              <div className="flex flex-col sm:flex-row sm:justify-between space-y-4 sm:space-y-0 sm:space-x-4 pt-4">
                <Button
                  className="w-full sm:w-auto"
                  disabled={isSubmitting}
                  onClick={() => {
                    // Only call onReset to show the confirmation dialog
                    onReset();
                  }}
                  type="button"
                  variant="outline"
                >
                  {resetButtonText}
                </Button>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
                  <Button
                    className="w-full sm:w-auto"
                    disabled={isSubmitting}
                    onClick={() => {
                      // Always close the dialog when cancel is clicked
                      onOpenChange(false);
                      clearFiles?.(); // Call clearFiles if it exists
                    }}
                    type="button"
                    variant="outline"
                  >
                    {cancelButtonText}
                  </Button>
                  <Button
                    className="w-full sm:w-auto min-w-[100px]"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        {mode === "create"
                          ? submitButtonText.creating || "Creating..."
                          : submitButtonText.updating || "Updating..."}
                      </>
                    ) : mode === "create" ? (
                      submitButtonText.create
                    ) : (
                      submitButtonText.edit
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        )}
      </SheetContent>
    </Sheet>
  );
};
