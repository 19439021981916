// src/modules/hub/features/ResourceLibrary/components/ContentBlockList/ContentBlockList.tsx

import React, { useCallback, useMemo, forwardRef } from "react";
import {
  DndContext,
  DragEndEvent,
  closestCenter,
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { UniqueIdentifier } from "@dnd-kit/core";
import { SortableBlock } from "@/modules/hub/features/ResourceLibrary/components/SortableBlock/SortableBlock";
import { ContentBlockListProps } from "@/modules/hub/features/ResourceLibrary/components/ContentBlockList/types";
import { ContentBlockBase } from "@/modules/hub/features/ResourceLibrary/components/SortableBlock/types";

/**
 * Displays a list of SortableBlock items (text, image, etc.)
 * and handles drag-and-drop reordering via DnDKit.
 *
 * We forwardRef to expose the container for scrolling.
 */
export const ContentBlockList = forwardRef<
  HTMLDivElement,
  ContentBlockListProps
>(function ContentBlockList({ blocks, onChange }, ref) {
  // Filter out any unsupported blocks
  const filteredBlocks = useMemo(
    () => blocks.filter((b) => b.type === "text" || b.type === "image"),
    [blocks],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Reorder after dragging
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over || active.id === over.id) return;

      const oldIndex = filteredBlocks.findIndex((b) => b.id === active.id);
      const newIndex = filteredBlocks.findIndex((b) => b.id === over.id);
      if (oldIndex < 0 || newIndex < 0) return;

      const newBlocks = arrayMove(filteredBlocks, oldIndex, newIndex).map(
        (block, i) => ({ ...block, order_number: i }),
      );
      onChange(newBlocks);
    },
    [filteredBlocks, onChange],
  );

  // Remove a block
  const handleRemoveBlock = useCallback(
    (blockId: string) => {
      onChange(filteredBlocks.filter((b) => b.id !== blockId));
    },
    [filteredBlocks, onChange],
  );

  // Update a block
  const handleUpdateBlock = useCallback(
    (blockId: string, updates: Partial<ContentBlockBase>) => {
      const newBlocks = filteredBlocks.map((b) =>
        b.id === blockId ? { ...b, ...updates } : b,
      );
      onChange(newBlocks);
    },
    [filteredBlocks, onChange],
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext
        items={filteredBlocks.map((b) => b.id as UniqueIdentifier)}
        strategy={verticalListSortingStrategy}
      >
        {/*
            Container with ref + scroll styling.
            Adjust max-h and overflow as needed.
          */}
        <div className="space-y-4 max-h-[600px] overflow-y-auto" ref={ref}>
          {filteredBlocks.map((block, i) => (
            <SortableBlock
              key={block.id || `temp-${i}`}
              {...block}
              onChange={(updates) => handleUpdateBlock(block.id || "", updates)}
              onRemove={() => handleRemoveBlock(block.id || "")}
            />
          ))}

          {filteredBlocks.length === 0 && (
            <div className="text-center py-8 text-gray-500 border border-dashed rounded-lg">
              No content blocks yet. Add blocks below.
            </div>
          )}
        </div>
      </SortableContext>
    </DndContext>
  );
});
