// src/modules/hub/features/ResourceLibrary/components/ResourceFilters/ResourceFilters.tsx

import React, { useState, useEffect } from "react";
import { useDebouncedValue } from "@/hooks/useDebouncedValue";
import { MultiSelect } from "@/components/MultiSelect/MultiSelect";

interface FilterMetaItem {
  id: number;
  name: string;
}

interface ResourceFiltersProps {
  filters: {
    searchTerm: string;
    tradeIds: string[];
    resourceTypeIds: string[];
    employeeRoleIds: string[];
  };
  onFilterChange: (filterKey: string, value: string | string[]) => void;
  resourceTypes: FilterMetaItem[];
  trades: FilterMetaItem[];
  employeeRoles: FilterMetaItem[];
}

const ResourceFilters: React.FC<ResourceFiltersProps> = ({
  filters,
  onFilterChange,
  resourceTypes,
  trades,
  employeeRoles,
}) => {
  const [searchInput, setSearchInput] = useState(filters.searchTerm);
  const debouncedSearch = useDebouncedValue(searchInput, 500);

  // Only call onFilterChange if the new search is different from the old one
  useEffect(() => {
    if (debouncedSearch !== filters.searchTerm) {
      onFilterChange("searchTerm", debouncedSearch);
    }
  }, [debouncedSearch, filters.searchTerm, onFilterChange]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const clearSearch = () => {
    setSearchInput("");
    onFilterChange("searchTerm", "");
  };

  // Build multi-select options
  const resourceTypeOptions = resourceTypes.map((rt) => ({
    label: rt.name,
    value: String(rt.id),
  }));
  const tradeOptions = trades.map((t) => ({
    label: t.name,
    value: String(t.id),
  }));
  const roleOptions = employeeRoles.map((er) => ({
    label: er.name,
    value: String(er.id),
  }));

  return (
    <div className="mb-4 space-y-4">
      {/* Search row (alone on its line) */}
      <div className="relative w-full">
        <input
          className="px-3 py-2 pr-10 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none"
          onChange={handleSearchChange}
          placeholder="Search by title..."
          type="text"
          value={searchInput}
        />
        {searchInput && (
          <button
            className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
            onClick={clearSearch}
          >
            ✕
          </button>
        )}
      </div>

      {/* Multi-select row */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Resource Types */}
        <MultiSelect
          onChange={(newVals) => onFilterChange("resourceTypeIds", newVals)}
          options={resourceTypeOptions}
          placeholder="Select resource types..."
          value={filters.resourceTypeIds}
        />

        {/* Trades */}
        <MultiSelect
          onChange={(newVals) => onFilterChange("tradeIds", newVals)}
          options={tradeOptions}
          placeholder="Select trades..."
          value={filters.tradeIds}
        />

        {/* Employee Roles */}
        <MultiSelect
          onChange={(newVals) => onFilterChange("employeeRoleIds", newVals)}
          options={roleOptions}
          placeholder="Select roles..."
          value={filters.employeeRoleIds}
        />
      </div>
    </div>
  );
};

export default ResourceFilters;
