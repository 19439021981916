import { useCallback } from "react";

interface UseAlertDialogActionsProps {
  onConfirm: () => void;
  onCancel?: () => void;
  onOpenChange: (open: boolean) => void;
}

export const useAlertDialogActions = ({
  onConfirm,
  onCancel,
  onOpenChange,
}: UseAlertDialogActionsProps) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
    onOpenChange(false);
  }, [onConfirm, onOpenChange]);

  const forceClose = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    forceClose();
  }, [onCancel, forceClose]);

  return {
    handleConfirm,
    forceClose,
    handleCancel,
  };
};
