import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@/hooks/useToast";
import { CreateEventSessionRequest } from "@/api/createEventSession/types";
import { EditEventSessionDTO } from "@/api/editEventSession/types";
import { ApiEventSession } from "@/api/fetchEventSessions/types";
import {
  createSessionAction,
  editSessionAction,
} from "@/modules/eventRegistration/features/SessionManagement/thunks/sessionThunks";
import { setCurrentRequest } from "@/modules/eventRegistration/features/SessionManagement/slices/sessionUISlice";
import { RootState } from "@/app/rootReducer";
import { EventSessionFormData } from "../schemas/eventSessionSchema";
import { DEFAULTS } from "../constants/sessionManagementConstants";
import { AppDispatch } from "@/app/store";

export const useSessionForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { totalCount } = useSelector((state: RootState) => state.sessions);
  const currentRequest = useSelector(
    (state: RootState) => state.sessionUI.currentRequest,
  );

  const handleSubmit = async (
    data: EventSessionFormData,
    sessionToEdit: ApiEventSession | null,
  ): Promise<void> => {
    setIsSubmitting(true);

    try {
      if (sessionToEdit) {
        const editSessionDTO: EditEventSessionDTO = {
          eventId: sessionToEdit.eventId,
          eventName: sessionToEdit.eventName,
          startDate: data.startDate,
          endDate: data.endDate,
          maxEnrollments: data.maxEnrollments,
          virtualLink: data.virtualLink,
          notes: data.notes,
          status: data.status,
        };

        await dispatch(
          editSessionAction({
            sessionId: sessionToEdit.id,
            editSessionDTO,
          }),
        ).unwrap();

        toast({
          title: "Success",
          description: "Session updated successfully",
        });
      } else {
        // Create session logic with pagination handling
        const sessionData: Omit<CreateEventSessionRequest, "eventId"> = {
          eventName: data.eventName || "",
          startDate: data.startDate,
          endDate: data.endDate,
          maxEnrollments: data.maxEnrollments,
          virtualLink: data.virtualLink,
          notes: data.notes,
          status: "SCHEDULED",
        };

        await dispatch(
          createSessionAction({
            createSessionDTO: {
              ...sessionData,
              eventId: currentRequest.eventId!,
            },
          }),
        ).unwrap();

        // Calculate the last page
        const lastPage = Math.ceil(
          (totalCount + 1) / (currentRequest?.pageSize ?? DEFAULTS.PAGE_SIZE),
        );

        // Update pagination to the last page
        await dispatch(
          setCurrentRequest({
            ...currentRequest,
            page: lastPage,
            pageSize: currentRequest?.pageSize ?? DEFAULTS.PAGE_SIZE,
          }),
        );

        toast({
          title: "Success",
          description: "Session created successfully",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to save session",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit,
    isSubmitting,
  };
};
