import { FILTER_SECTIONS } from "../constants/eventManagementConstants";

export enum FilterType {
  category = "category",
  status = "status",
}

export type FilterOption = {
  label: string;
  value: string;
};

export interface FilterSection {
  title: string;
  type: FilterType;
  options: FilterOption[];
}

/**
 * Creates filter sections with the provided category options
 * @param categoryOptions - The dynamic category options to include
 * @returns Array of filter sections
 */
export const createFilterSections = (
  categoryOptions: FilterOption[],
): FilterSection[] => {
  return [
    {
      ...FILTER_SECTIONS.CATEGORIES,
      type: FilterType.category,
      options: categoryOptions,
    },
    {
      ...FILTER_SECTIONS.STATUS,
      type: FilterType.status,
    },
  ];
};
