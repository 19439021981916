import { EntityModal } from "@/modules/eventRegistration/features/shared/components/EntityModal";
import { ApiEventCategory } from "@/api/fetchEventCategories/types";
import { useEventCategoryDialogForm } from "../../hooks/useEventCategoryDialogForm";
import { EventCategoryFormData } from "../../schemas/eventSchema";
import { categoryFields } from "../../constants/eventManagementConstants";

export interface EditEventCategoryDialogProps {
  eventCategory: ApiEventCategory | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
}

export function EventCategoryDialog({
  eventCategory,
  open,
  onOpenChange,
  onSuccess,
}: EditEventCategoryDialogProps): JSX.Element {
  const { form, handleSubmit, isSubmitting } = useEventCategoryDialogForm({
    eventCategory,
    onSuccess,
    onOpenChange,
  });

  return (
    <EntityModal<EventCategoryFormData>
      entity={eventCategory ?? undefined}
      fields={categoryFields}
      form={form}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      onOpenChange={onOpenChange}
      onSuccess={onSuccess}
      open={open}
      title="Event Category"
    />
  );
}
