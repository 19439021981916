import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/rootReducer";
import { selectAllEvents } from "../slices/eventsSlice";
import { DEFAULTS } from "../constants/eventDirectoryConstants";
import { ApiEvent } from "@/api/fetchEvents/types";

export type FilterOption = {
  label: string;
  value: string;
};

export type FilterType = "category" | "status";

export const selectEventDirectoryState = (state: RootState) => state.events;
export const selectEventUIState = (state: RootState) => state.eventUI;
export const selectFavoritesState = (state: RootState) => state.favorites;

export const selectEventsWithLoadingState = createSelector(
  [selectAllEvents, selectEventDirectoryState],
  (events, eventState) => ({
    events,
    isLoadingEvents: eventState.loading,
    eventsError: eventState.error,
    totalCount: eventState.totalCount,
  }),
);

export const selectCurrentRequestState = (state: RootState) =>
  state.eventUI.currentRequest;

// Dialog and details selectors
export const selectEventDialogState = (state: RootState) => state.events.dialog;

export const selectEventDetailsLoading = (state: RootState) =>
  state.events.eventDetails.isLoading;

export const selectTotalCount = (state: RootState) => state.events.totalCount;

export const selectEventFiles = (state: RootState) =>
  state.events.dialog.existingFiles;

export const selectCategoryOptions = createSelector(
  [selectAllEvents],
  (events) => {
    const categories = Array.from(
      new Set(
        events
          .map((event) => event.eventCategoryId)
          .filter((id): id is number => id !== null && id !== undefined),
      ),
    );

    return categories.map(
      (categoryId): FilterOption => ({
        label: `Category ${categoryId}`,
        value: String(categoryId),
      }),
    );
  },
);

export const selectSelectedFilters = createSelector(
  [selectCurrentRequestState],
  (currentRequest): Record<FilterType, string[]> => ({
    category: currentRequest.eventCategoryId
      ? [String(currentRequest.eventCategoryId)]
      : [],
    status: currentRequest.isPublished ? ["Published"] : [],
  }),
);

export const selectFilteredAndPaginatedEvents = createSelector(
  [
    selectAllEvents,
    (state: RootState) => state.favorites.favorites,
    (state: RootState, showOnlyFavorites: boolean) => showOnlyFavorites,
    (state: RootState, showOnlyFavorites: boolean, currentPage: number) =>
      currentPage,
    (itemsPerPage: number) => itemsPerPage,
  ],
  (events, favorites, showOnlyFavorites, currentPage, itemsPerPage) => {
    const filtered = showOnlyFavorites
      ? events.filter((event) => favorites.includes(event.id))
      : events;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return filtered.slice(startIndex, endIndex);
  },
);

export const selectCurrentPage = createSelector(
  [selectCurrentRequestState],
  (currentRequest) => currentRequest.page || 1,
);

export const selectPageSize = createSelector(
  [selectCurrentRequestState],
  (currentRequest) => currentRequest.pageSize || DEFAULTS.PAGE_SIZE,
);

export const selectTotalPages = createSelector(
  [selectTotalCount, selectPageSize],
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize),
);

// Favorites Selectors
export const selectFavoriteIds = createSelector(
  [selectFavoritesState],
  (favoritesState) => favoritesState?.favorites || [],
);

export const selectFavoritesLoading = createSelector(
  [selectFavoritesState],
  (favoritesState) => favoritesState?.loading || false,
);

// Selector to check if an event is favorited
export const selectIsFavorited = (state: RootState, eventId: number) =>
  state.favorites.favorites.includes(eventId);

// Selector to get favorited events data
export const selectFavoritedEventsData = createSelector(
  [selectAllEvents, selectFavoriteIds, selectEventDirectoryState],
  (allEvents, favorites, eventsState) => ({
    favoritedEvents: allEvents.filter((event: ApiEvent) =>
      favorites.includes(event.id),
    ),
    favorites,
    loading: eventsState.loading,
    error: eventsState.error,
  }),
);

// UI State Selectors
export const selectCurrentRequest = (state: RootState) =>
  state.eventUI.currentRequest;
export const selectSelectedCategories = (state: RootState) =>
  state.eventUI.selectedCategories;
export const selectSelectedDurations = (state: RootState) =>
  state.eventUI.selectedDurations;
export const selectSelectedCompany = (state: RootState) =>
  state.eventUI.selectedCompany;
export const selectSelectedSession = (state: RootState) =>
  state.eventUI.selectedSession;
export const selectPriceRange = (state: RootState) => state.eventUI.priceRange;
export const selectShowEventDialog = (state: RootState) =>
  state.eventUI.showEventDialog;
export const selectEventToEdit = (state: RootState) =>
  state.eventUI.eventToEdit;
export const selectShowDeleteTypeDialog = (state: RootState) =>
  state.eventUI.showDeleteTypeDialog;
export const selectShowAddEventType = (state: RootState) =>
  state.eventUI.showAddEventType;
export const selectEditingEventType = (state: RootState) =>
  state.eventUI.editingEventType;
export const selectShowAddEventInstructor = (state: RootState) =>
  state.eventUI.showAddEventInstructor;
export const selectEditingEventInstructor = (state: RootState) =>
  state.eventUI.editingEventInstructor;
export const selectShowDeleteInstructorDialog = (state: RootState) =>
  state.eventUI.showDeleteInstructorDialog;
export const selectShowAddEventCategory = (state: RootState) =>
  state.eventUI.showAddEventCategory;
export const selectEditingEventCategory = (state: RootState) =>
  state.eventUI.editingEventCategory;
export const selectShowDeleteCategoryDialog = (state: RootState) =>
  state.eventUI.showDeleteCategoryDialog;
export const selectIsSubmitting = (state: RootState) =>
  state.eventUI.isSubmitting;
export const selectSubmitError = (state: RootState) =>
  state.eventUI.submitError;
export const selectShowUnsavedChangesDialog = (state: RootState) =>
  state.eventUI.showUnsavedChangesDialog;
export const selectShowResetDialog = (state: RootState) =>
  state.eventUI.showResetDialog;
export const selectEventTypes = (state: RootState) => state.eventUI.eventTypes;
export const selectIsLoadingEventTypes = (state: RootState) =>
  state.eventUI.isLoadingEventTypes;
