import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchEventSessionsRequest } from "@/api/fetchEventSessions/types";
import { RootState } from "@/app/rootReducer";
import { ApiEventSession } from "@/api/fetchEventSessions/types";

interface SessionUIState {
  currentRequest: FetchEventSessionsRequest;
  showSessionDialog: boolean;
  sessionToEdit: ApiEventSession | null;
  selectedSession: string | null;
  showDeleteSessionDialog: boolean;
  isSubmitting: boolean;
  submitError: string | null;
  showUnsavedChangesDialog: boolean;
  showResetDialog: boolean;
  status: string | undefined;
  pageIndex: number;
  pageSize: number;
  sessionsLoading: boolean;
}

const initialState: SessionUIState = {
  currentRequest: {
    page: 1,
    pageSize: 5,
    searchTerm: "",
    sortBy: undefined,
    sortOrder: undefined,
    eventId: undefined,
  },
  showSessionDialog: false,
  sessionToEdit: null,
  showDeleteSessionDialog: false,
  isSubmitting: false,
  submitError: null,
  showUnsavedChangesDialog: false,
  showResetDialog: false,
  status: undefined,
  selectedSession: null,
  pageIndex: 0,
  pageSize: 5,
  sessionsLoading: false,
};

const sessionUISlice = createSlice({
  name: "sessionUI",
  initialState,
  reducers: {
    setCurrentRequest: (
      state,
      action: PayloadAction<Partial<FetchEventSessionsRequest>>,
    ) => {
      state.currentRequest = {
        ...state.currentRequest,
        ...action.payload,
      };
    },
    resetPagination: (state) => {
      state.currentRequest.page = 1;
      state.currentRequest.pageSize = 5;
      state.pageIndex = 0;
      state.pageSize = 5;
    },
    updateSessionDialog: (
      state,
      action: PayloadAction<{
        show: boolean;
        session: ApiEventSession | null;
      }>,
    ) => {
      state.showSessionDialog = action.payload.show;
      state.sessionToEdit = action.payload.session;
    },
    setShowDeleteSessionDialog: (state, action: PayloadAction<boolean>) => {
      state.showDeleteSessionDialog = action.payload;
    },
    setSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    setSubmitError: (state, action: PayloadAction<string | null>) => {
      state.submitError = action.payload;
    },
    setUnsavedChangesDialog: (state, action: PayloadAction<boolean>) => {
      state.showUnsavedChangesDialog = action.payload;
    },
    setResetDialog: (state, action: PayloadAction<boolean>) => {
      state.showResetDialog = action.payload;
    },
    setSelectedStatus: (state, action: PayloadAction<string>) => {
      state.currentRequest.status = action.payload;
    },
    clearAllFilters: (state) => {
      state.currentRequest = {
        ...state.currentRequest,
        status: undefined,
      };
    },
    setSelectedSession: (state, action: PayloadAction<string | null>) => {
      state.selectedSession = action.payload;
    },
    setPageIndex: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
      // Reset to first page when changing page size
      state.pageIndex = 0;
    },
    setSessionsLoading: (state, action: PayloadAction<boolean>) => {
      state.sessionsLoading = action.payload;
    },
  },
});

// Export actions
export const {
  setCurrentRequest,
  resetPagination,
  updateSessionDialog,
  setShowDeleteSessionDialog,
  setSubmitting,
  setSubmitError,
  setUnsavedChangesDialog,
  setResetDialog,
  setSelectedStatus,
  clearAllFilters,
  setSelectedSession,
  setPageIndex,
  setPageSize,
  setSessionsLoading,
} = sessionUISlice.actions;

// Update these selectors to use the direct properties
export const selectPageIndex = (state: RootState) => state.sessionUI.pageIndex;
export const selectPageSize = (state: RootState) => state.sessionUI.pageSize;

export default sessionUISlice.reducer;
