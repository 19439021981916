import React from "react";
import { Campaign } from "../../../../../../api/fetchCompanyCampaigns/types";
import CampaignManagementActionMenu from "../CampaignManagementActionMenu/CampaignManagementActionMenu";
import { Column } from "@/components/Datatable/Datatable";

interface CreateCampaignsColumnsProps {
  handleViewBatches: (id: number) => void;
  handleViewFiles: (id: number) => void;
  handleEditCampaign: (id: number) => void;
}

export function createCampaignsColumns({
  handleViewBatches,
  handleViewFiles,
  handleEditCampaign,
}: CreateCampaignsColumnsProps): Column<Campaign>[] {
  return [
    {
      header: "Campaign ID",
      accessorKey: "id",
    },
    {
      header: "Campaign Name",
      accessorKey: "name",
    },
    {
      header: "Phone Number",
      accessorKey: "phoneNumber",
      cell: ({ row }) => row.original.phoneNumber || "N/A",
    },
    {
      header: "Start Date",
      accessorKey: "startDate",
      cell: ({ row }) => row.original.startDate || "N/A",
    },
    {
      header: "End Date",
      accessorKey: "endDate",
      cell: ({ row }) => row.original.endDate || "N/A",
    },
    {
      header: "Status",
      accessorKey: "campaignStatus",
      cell: ({ row }) =>
        row.original.campaignStatus?.name
          ? row.original.campaignStatus.name.charAt(0).toUpperCase() +
            row.original.campaignStatus.name.slice(1)
          : "N/A",
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <CampaignManagementActionMenu
          campaignId={row.original.id}
          onEditCampaign={handleEditCampaign}
          onViewBatches={handleViewBatches}
          onViewFiles={handleViewFiles}
        />
      ),
    },
  ];
}
