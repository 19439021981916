import { FC } from "react";
import ERSearch from "../../../shared/components/ERSearch";
import { useEventSearch } from "../../hooks/useEventSearch";

export interface EventDirectorySearchProps {
  onSearch: (query: string) => Promise<void> | void;
}

const EventDirectorySearch: FC<EventDirectorySearchProps> = ({ onSearch }) => {
  const { searchQuery, handleSearchChange } = useEventSearch(onSearch);

  return (
    <ERSearch
      className="w-full"
      onSearchChange={handleSearchChange}
      placeholder="Search events..."
      searchQuery={searchQuery}
    />
  );
};

export default EventDirectorySearch;
