import axios from "../axiosInstance";
import { DeleteEventTypeResponse } from "./types";

export const deleteEventType = async (
  id: number,
): Promise<DeleteEventTypeResponse> => {
  const response = await axios.delete<DeleteEventTypeResponse>(
    `/api/private/event-types/${id}`,
  );
  return response.data;
};
