import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  setDialogUnsavedChanges,
  setDialogSubmitError,
  setSelectedEvents,
  toggleEventSelection,
  clearDialogFiles,
} from "../slices/eventManagementEventsSlice";
import {
  setShowDeleteTypeDialog,
  setShowDeleteInstructorDialog,
  setShowDeleteCategoryDialog,
  setResetDialog,
  setCurrentRequest,
  setSelectedFiles,
  setExistingFiles,
  updateEventDialog,
} from "../slices/eventManagementUISlice";
import {
  selectSelectedFiles,
  selectExistingFiles,
} from "../selectors/eventManagementSelectors";
import { useToastError } from "./useToastError";
import { useToastSuccess } from "./useToastSuccess";
import { EventFormData } from "../schemas/eventSchema";
import {
  setSelectedCategories,
  setSelectedCompany,
  clearAllFilters,
} from "@/modules/eventRegistration/features/EventDirectory/slices/eventUISlice";
import {
  fetchEventsAction,
  handleEventSubmission,
  deleteEventAction,
  duplicateEventAction,
  deleteEventFileAction,
  deleteEventTypeAction,
  fetchEventTypesAction,
  deleteEventCategoryAction,
  fetchEventCategoriesAction,
  deleteEventInstructorAction,
  fetchEventInstructorsAction,
  fetchEventFilesAction,
} from "../thunks/eventManagementThunks";
import { FormState, UseFormReturn } from "react-hook-form";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { ApiEvent } from "@/api/fetchEvents/types";
import { isFilterType, isStatusValue } from "../utils/typeGuards";
import { FilterChangeParams } from "../components/EventManagementFilters/EventManagementFilters";
import { EVENT_FORM } from "../constants/eventManagementConstants";

export type EventRequest = {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
  searchTerm?: string;
  eventCategoryId?: number;
  isPublished?: boolean;
};

export type SortOrder = "ASC" | "DESC";

export interface SortingParams {
  sortBy?: string;
  sortOrder?: SortOrder;
}

export interface EventFilters {
  eventCategoryId?: number | null;
  isPublished?: boolean | null;
  hideFromCatalog?: boolean | null;
}

export const useEventManagementActions = (currentRequest: EventRequest) => {
  const dispatch = useAppDispatch();
  const { executeToastError } = useToastError();
  const { executeToastSuccess } = useToastSuccess();
  const navigate = useNavigate();
  const selectedFiles = useAppSelector(selectSelectedFiles);
  const existingFiles = useAppSelector(selectExistingFiles);

  const handlePaginationChange = useCallback(
    async (pageIndex: number, pageSize: number) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            page: pageIndex + 1,
            pageSize,
          }),
        );
        await dispatch(fetchEventsAction()).unwrap();
      }, "Failed to change page");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleSortingChange = useCallback(
    async (params: SortingParams) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            sortBy: params.sortBy,
            sortOrder: params.sortOrder,
            page: 1,
          }),
        );
        await dispatch(fetchEventsAction()).unwrap();
      }, "Failed to sort events");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleSearchChange = useCallback(
    async (searchTerm: string, callback?: (query: string) => void) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            searchTerm,
            page: 1,
          }),
        );
        await dispatch(fetchEventsAction()).unwrap();
        // Call the callback if provided
        if (callback) {
          callback(searchTerm);
        }
      }, "Failed to search events");
    },
    [dispatch, currentRequest, executeToastError],
  );

  const handleFiltersChange = useCallback(
    async (filters: EventFilters) => {
      return executeToastError(async () => {
        dispatch(
          setCurrentRequest({
            ...currentRequest,
            eventCategoryId: filters.eventCategoryId ?? undefined,
            isPublished: filters.isPublished ?? undefined,
            page: 1,
          }),
        );
        await dispatch(fetchEventsAction()).unwrap();
      }, "Failed to filter events");
    },
    [dispatch, currentRequest, executeToastError],
  );

  /**
   * Handles dialog submit for event form
   */
  const handleDialogSubmit = useCallback(
    async (
      data: EventFormData,
      formState: FormState<EventFormData>,
      form: UseFormReturn<EventFormData>,
      mode: "edit" | "create",
      eventId?: number,
    ) => {
      try {
        // Check if there are any errors in the form
        if (Object.keys(formState.errors).length > 0) {
          dispatch(
            setDialogSubmitError(
              "Please fix all form errors before submitting.",
            ),
          );
          return;
        }

        // Set loading state
        dispatch(setDialogSubmitError(null));

        // Handle event submission
        const result = await dispatch(
          handleEventSubmission({
            mode,
            data,
            eventId,
            selectedFiles,
          }),
        ).unwrap();

        if (result) {
          // Clear selected files after successful submission
          dispatch(setSelectedFiles([]));

          // Clear existing files after successful submission
          dispatch(setExistingFiles([]));

          // Close the dialog
          dispatch(
            updateEventDialog({
              show: false,
              event: null,
            }),
          );

          // Show success toast
          executeToastSuccess(() => Promise.resolve(), {
            title: mode === "edit" ? "Event Updated" : "Event Created",
            description:
              mode === "edit"
                ? `Event ${data.eventName} has been updated successfully.`
                : `Event ${data.eventName} has been created successfully.`,
          });
        }
      } catch {
        // Handle error
        executeToastError(
          () => Promise.resolve(),
          `Failed to ${mode === "edit" ? "update" : "create"} event`,
        );
      }
    },
    [dispatch, executeToastError, executeToastSuccess, selectedFiles],
  );

  const handleAddEvent = useCallback(() => {
    dispatch(updateEventDialog({ show: true, event: null }));
  }, [dispatch]);

  const handleEditEvent = useCallback(
    async (eventId: number, events: ApiEvent[]) => {
      const event = events.find((e) => e.id === eventId);
      if (event) {
        // Show the dialog with the event data
        dispatch(updateEventDialog({ show: true, event }));

        // Fetch the event files
        try {
          const files = await dispatch(
            fetchEventFilesAction(event.id),
          ).unwrap();
          dispatch(setExistingFiles(files));
        } catch {
          executeToastError(
            () => Promise.resolve(),
            "Failed to fetch event files",
          );
        }
      }
    },
    [dispatch, executeToastError],
  );

  const handleEventSubmit = useCallback(
    async (
      mode: "create" | "edit",
      data: EventFormData,
      selectedFiles: File[],
      eventId?: number,
      onOpenChange?: (open: boolean) => void,
      onSuccess?: () => void,
    ): Promise<void> => {
      dispatch(setDialogSubmitError(null));

      await executeToastSuccess(
        async () => {
          await dispatch(
            handleEventSubmission({
              mode,
              data,
              selectedFiles,
              eventId,
            }),
          ).unwrap();

          // Clear selected files after successful submission
          dispatch(setSelectedFiles([]));

          // Clear existing files after successful submission
          dispatch(setExistingFiles([]));

          onOpenChange?.(false);
          onSuccess?.();
        },
        {
          title: "Success",
          description:
            mode === "create"
              ? "Event created successfully"
              : "Event updated successfully",
        },
      );
    },
    [dispatch, executeToastSuccess],
  );

  const handleDialogClose = useCallback(
    (
      formState: FormState<EventFormData>,
      onOpenChange: (open: boolean) => void,
      editingEventType: unknown,
      editingEventInstructor: unknown,
      editingEventCategory: unknown,
    ) => {
      if (
        formState.isDirty &&
        !editingEventType &&
        !editingEventInstructor &&
        !editingEventCategory
      ) {
        dispatch(setDialogUnsavedChanges(true));
      } else {
        onOpenChange(false);
      }
    },
    [dispatch],
  );

  const handleDialogConfirmClose = useCallback(
    (onOpenChange: (open: boolean) => void) => {
      dispatch(setDialogUnsavedChanges(false));
      onOpenChange(false);
    },
    [dispatch],
  );

  const handleDialogReset = useCallback(() => {
    // Always allow reset regardless of form state
    dispatch(setResetDialog(true));
  }, [dispatch]);

  const handleDialogConfirmReset = useCallback(
    (
      mode: string,
      form: UseFormReturn<EventFormData>,
      initialData: EventFormData,
    ) => {
      if (mode === "create") {
        form.reset(EVENT_FORM.DEFAULT_VALUES);
      } else if (initialData) {
        form.reset(initialData);
      }
      dispatch(setResetDialog(false));
    },
    [dispatch],
  );

  const handleDeleteEventType = useCallback(
    async (
      selectedTypeId: number,
      form: UseFormReturn<EventFormData>,
      onSuccess?: () => Promise<void>,
    ) => {
      return executeToastSuccess(
        async () => {
          const result = await dispatch(
            deleteEventTypeAction(selectedTypeId),
          ).unwrap();

          dispatch(setShowDeleteTypeDialog(false));

          if (form.watch("eventTypeId") === selectedTypeId) {
            form.setValue("eventTypeId", null, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }

          await Promise.all([dispatch(fetchEventTypesAction()), onSuccess?.()]);

          return result;
        },
        {
          title: "Success",
          description:
            "Event type deleted successfully. Associated events have been updated.",
        },
      );
    },
    [dispatch, executeToastSuccess],
  );

  const handleDeleteEventCategory = useCallback(
    async (
      selectedCategoryId: number,
      form: UseFormReturn<EventFormData>,
      onSuccess?: () => Promise<void>,
    ) => {
      return executeToastSuccess(
        async () => {
          const result = await dispatch(
            deleteEventCategoryAction(selectedCategoryId),
          ).unwrap();

          dispatch(setShowDeleteCategoryDialog(false));

          if (form.watch("eventCategoryId") === selectedCategoryId) {
            form.setValue("eventCategoryId", null, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }

          await Promise.all([
            dispatch(fetchEventCategoriesAction()),
            onSuccess?.(),
          ]);

          return result;
        },
        {
          title: "Success",
          description:
            "Event category deleted successfully. Associated events have been updated.",
        },
      );
    },
    [dispatch, executeToastSuccess],
  );

  const handleDeleteEventInstructor = useCallback(
    async (
      selectedInstructorId: number,
      form: UseFormReturn<EventFormData>,
      onSuccess?: () => Promise<void>,
    ) => {
      return executeToastSuccess(
        async () => {
          const result = await dispatch(
            deleteEventInstructorAction(selectedInstructorId),
          ).unwrap();

          dispatch(setShowDeleteInstructorDialog(false));

          if (form.watch("eventInstructorId") === selectedInstructorId) {
            form.setValue("eventInstructorId", null, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }

          await Promise.all([
            dispatch(fetchEventInstructorsAction()),
            onSuccess?.(),
          ]);

          return result;
        },
        {
          title: "Success",
          description:
            "Event instructor deleted successfully. Associated events have been updated.",
        },
      );
    },
    [dispatch, executeToastSuccess],
  );

  const handleFileChange = useCallback(
    (files: File[]) => {
      // Get current selected files from state
      const updatedFiles = [...selectedFiles, ...files];
      // Update the state with the combined files
      dispatch(setSelectedFiles(updatedFiles));
    },
    [dispatch, selectedFiles],
  );

  const handleClearDialogFiles = useCallback(() => {
    dispatch(clearDialogFiles());
    dispatch(setSelectedFiles([]));
    dispatch(setExistingFiles([]));
  }, [dispatch]);

  const handleRemoveFile = useCallback(
    (file: File) => {
      // Get current selected files from state
      const updatedFiles = selectedFiles.filter((f) => f !== file);
      // Update the state with the filtered files
      dispatch(setSelectedFiles(updatedFiles));
    },
    [dispatch, selectedFiles],
  );

  const handleRemoveExistingFile = useCallback(
    async (file: ApiEventFile) => {
      try {
        // Delete the file from the database
        await dispatch(deleteEventFileAction(file.id)).unwrap();

        // Update the existingFiles state by filtering out the deleted file
        const updatedExistingFiles = existingFiles.filter(
          (f) => f.id !== file.id,
        );
        dispatch(setExistingFiles(updatedExistingFiles));

        // Show success toast
        executeToastSuccess(() => Promise.resolve(), {
          title: "File Deleted",
          description: `File ${file.fileName} has been deleted successfully.`,
        });
      } catch {
        executeToastError(() => Promise.resolve(), "Failed to delete file");
      }
    },
    [dispatch, executeToastError, executeToastSuccess, existingFiles],
  );

  const handleDialogCloseAction = useCallback(
    (action: ActionCreatorWithPayload<boolean>) => {
      return () => {
        dispatch(action(false));
        dispatch(setDialogSubmitError(null));
        dispatch(setSelectedFiles([])); // Clear selected files
        dispatch(setExistingFiles([])); // Clear existing files
      };
    },
    [dispatch],
  );

  const handleShowSessions = useCallback(
    (eventId: number) => {
      navigate(`/event-registration/events/${eventId}/sessions`);
    },
    [navigate],
  );

  const handleSelectAllEvents = useCallback(
    (checked: boolean, events: ApiEvent[]) => {
      if (checked) {
        dispatch(setSelectedEvents(events.map((event) => event.id)));
      } else {
        dispatch(setSelectedEvents([]));
      }
    },
    [dispatch],
  );

  const handleSelectEvent = useCallback(
    (eventId: number) => {
      dispatch(toggleEventSelection(eventId));
    },
    [dispatch],
  );

  const handleCreateEventSuccess = useCallback(
    async (
      totalCount: number,
      pageSize: number,
      onPaginationChange?: (pageIndex: number, pageSize: number) => void,
    ) => {
      // Calculate the last page
      const lastPage = Math.ceil((totalCount + 1) / pageSize);
      // Update pagination to the last page
      if (onPaginationChange) {
        onPaginationChange(lastPage - 1, pageSize);
      }

      // Then fetch the events
      return dispatch(fetchEventsAction()).unwrap();
    },
    [dispatch],
  );

  const handleDuplicateEvent = useCallback(
    async (
      event: ApiEvent,
      totalCount: number,
      pageSize: number,
      onPaginationChange?: (pageIndex: number, pageSize: number) => void,
    ) => {
      await executeToastSuccess(
        async () => {
          await dispatch(
            duplicateEventAction({
              eventId: event.id,
              eventCode: `${event.eventCode}-copy`,
              eventName: `${event.eventName} (Copy)`,
              eventTypeId: event.eventTypeId || null,
              eventInstructorId: event.eventInstructorId || null,
              eventCategoryId: event.eventCategoryId || null,
            }),
          ).unwrap();
          return handleCreateEventSuccess(
            totalCount,
            pageSize,
            onPaginationChange,
          );
        },
        {
          title: "Event duplicated",
          description: "The event has been successfully duplicated.",
        },
      );
    },
    [dispatch, executeToastSuccess, handleCreateEventSuccess],
  );

  const handleDeleteEvent = useCallback(
    async (eventId: number) => {
      await executeToastSuccess(
        async () => {
          await dispatch(deleteEventAction(eventId)).unwrap();
          await dispatch(fetchEventsAction()).unwrap();
        },
        {
          title: "Event deleted",
          description: "The event has been successfully deleted.",
        },
      );
    },
    [dispatch, executeToastSuccess],
  );

  const handleToggleFilter = useCallback(
    (type: string, value: string) => {
      if (!isFilterType(type)) return;
      if (type === "category") {
        const categoryId = Number(value);
        dispatch(setSelectedCategories([value]));
        return {
          ...currentRequest,
          eventCategoryId: categoryId,
          isPublished: currentRequest.isPublished ?? null,
        };
      }

      if (type === "status") {
        const statusValue = isStatusValue(value) ? value : "Draft";
        dispatch(
          setSelectedCompany(statusValue === "Published" ? statusValue : null),
        );
        return {
          ...currentRequest,
          eventCategoryId: currentRequest.eventCategoryId ?? null,
          isPublished: statusValue === "Published",
        };
      }

      return currentRequest;
    },
    [dispatch, currentRequest],
  );

  const handleClearFilters = useCallback(() => {
    dispatch(clearAllFilters());
    return {
      ...currentRequest,
      eventCategoryId: null,
      isPublished: null,
    };
  }, [dispatch, currentRequest]);

  const handleFilterToggle = useCallback(
    (
      type: string,
      value: string,
      callback?: (params: FilterChangeParams) => void,
    ) => {
      const updatedRequest = handleToggleFilter(type, value);
      if (updatedRequest) {
        // Convert EventRequest to FilterChangeParams
        const filterParams: FilterChangeParams = {
          eventCategoryId: updatedRequest.eventCategoryId ?? null,
          isPublished: updatedRequest.isPublished ?? null,
        };
        // Call the callback if provided
        if (callback) {
          callback(filterParams);
        }
        return filterParams;
      }
      return null;
    },
    [handleToggleFilter],
  );

  const handleClear = useCallback(
    (callback?: (params: FilterChangeParams) => void) => {
      const updatedRequest = handleClearFilters();
      // Convert EventRequest to FilterChangeParams
      const filterParams: FilterChangeParams = {
        eventCategoryId: updatedRequest.eventCategoryId ?? null,
        isPublished: updatedRequest.isPublished ?? null,
      };
      // Call the callback if provided
      if (callback) {
        callback(filterParams);
      }
      return filterParams;
    },
    [handleClearFilters],
  );

  return {
    handlePaginationChange,
    handleSortingChange,
    handleSearchChange,
    handleFiltersChange,
    handleEventSubmit,
    handleDialogClose,
    handleDialogConfirmClose,
    handleDialogReset,
    handleDialogConfirmReset,
    handleDeleteEventType,
    handleDeleteEventCategory,
    handleDeleteEventInstructor,
    handleFileChange,
    handleRemoveFile,
    handleRemoveExistingFile,
    handleDialogCloseAction,
    handleDialogSubmit,
    handleAddEvent,
    handleEditEvent,
    handleToggleFilter,
    handleClearFilters,
    handleFilterToggle,
    handleClear,
    handleShowSessions,
    handleSelectAllEvents,
    handleSelectEvent,
    handleCreateEventSuccess,
    handleDuplicateEvent,
    handleDeleteEvent,
    handleClearDialogFiles,
  };
};
