import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/app/hooks";
import {
  editEventInstructorAction,
  createEventInstructorAction,
} from "@/modules/eventRegistration/features/EventDirectory/thunks/eventThunks";
import {
  EventInstructorFormData,
  eventInstructorSchema,
} from "../schemas/eventSchema";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";

export const useEditEventInstructorForm = (
  eventInstructor: ApiEventInstructor | null,
) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<EventInstructorFormData>({
    resolver: zodResolver(eventInstructorSchema),
    defaultValues: {
      firstName: eventInstructor?.firstName ?? "",
      lastName: eventInstructor?.lastName ?? "",
      email: eventInstructor?.email ?? "",
      isActive: eventInstructor?.isActive ?? true,
    },
  });

  const handleSubmit = async (
    data: EventInstructorFormData,
  ): Promise<boolean> => {
    setIsSubmitting(true);
    try {
      if (eventInstructor) {
        await dispatch(
          editEventInstructorAction({
            instructorId: eventInstructor.id,
            editEventInstructorDTO: data,
          }),
        ).unwrap();
      } else {
        await dispatch(
          createEventInstructorAction({
            createEventInstructorDTO: data,
          }),
        ).unwrap();
      }
      return true;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    form,
    handleSubmit,
    isSubmitting,
  };
};
