export const EVENT_FORM = {
  BUTTON_TEXT: {
    create: "Create Event",
    edit: "Save Changes",
    creating: "Adding...",
    updating: "Saving...",
    cancel: "Cancel",
    discard: "Discard Changes",
    reset: "Reset Form",
    delete: "Delete",
  },
  DEFAULT_VALUES: {
    eventName: "",
    eventCode: "",
    eventDescription: "",
    eventPrice: 0,
    eventTypeId: null,
    eventInstructorId: null,
    eventCategoryId: null,
  },
  LABELS: {
    eventName: "Event Name",
    eventCode: "Event Code",
    eventDescription: "Description",
    eventPrice: "Price",
    eventType: "Event Type",
    eventInstructor: "Event Instructor",
    eventCategory: "Event Category",
  },
  PLACEHOLDERS: {
    eventName: "Enter event name",
    eventCode: "Enter event code",
    eventDescription: "Enter event description",
    eventPrice: "Enter event price",
    eventType: "Select event type",
    eventInstructor: "Select event instructor",
    eventCategory: "Select event category",
  },
  TITLES: {
    create: "Create New Event",
    edit: "Edit Event",
    unsavedChanges: "Unsaved Changes",
    resetForm: "Reset Form",
    deleteType: "Delete Event Type",
    deleteInstructor: "Delete Event Instructor",
    deleteCategory: "Delete Event Category",
  },
  ERROR_MESSAGES: {
    title: "Error",
    default: "An error occurred while saving the event",
  },
  MESSAGES: {
    unsavedChanges:
      "You have unsaved changes. Are you sure you want to discard them?",
    resetForm:
      "Are you sure you want to reset the form? All changes will be lost.",
    deleteCategory: "Are you sure you want to delete this event category?",
    deleteCategoryInUse:
      "This event category cannot be deleted because it is currently being used by one or more events. Please remove this category from all events before deleting it.",
    deleteInstructor: "Are you sure you want to delete this instructor?",
    deleteType: "Are you sure you want to delete this event type?",
  },
  SHEET_WIDTH: "sm:max-w-[425px]",
} as const;

export const TOAST_MESSAGES = {
  FETCH_ERROR: {
    title: "Error",
    description: "Failed to fetch events",
  },
  SUBMIT_ERROR: {
    title: "Error",
    description: "Failed to submit event",
  },
} as const;

export const DEFAULTS = {
  PAGE_SIZE: 10,
} as const;

export const FILTER_SECTIONS = {
  CATEGORIES: {
    title: "Category",
    type: "category",
  },
  STATUS: {
    title: "Status",
    type: "status",
    options: [
      { label: "Published", value: "Published" },
      { label: "Draft", value: "Draft" },
    ],
  },
};

export const instructorFields = [
  {
    name: "firstName" as const,
    label: "First Name",
    placeholder: "Enter instructor's first name",
  },
  {
    name: "lastName" as const,
    label: "Last Name",
    placeholder: "Enter instructor's last name",
  },
  {
    name: "email" as const,
    label: "Email",
    placeholder: "Enter instructor's email",
  },
  {
    name: "isActive" as const,
    label: "Active",
    type: "checkbox" as const,
  },
];

export const categoryFields = [
  {
    name: "name" as const,
    label: "Name",
    placeholder: "Enter event category name",
  },
  {
    name: "description" as const,
    label: "Description",
    placeholder: "Enter event category description",
    type: "textarea" as const,
  },
  {
    name: "isActive" as const,
    label: "Active",
    type: "checkbox" as const,
  },
];

export const eventTypeFields = [
  {
    name: "name" as const,
    label: "Name",
    placeholder: "Enter event type name",
  },
  {
    name: "description" as const,
    label: "Description",
    placeholder: "Enter event type description",
    type: "textarea" as const,
  },
  {
    name: "isActive" as const,
    label: "Active",
    type: "checkbox" as const,
  },
];

export const DEFAULT_PAGE_SIZE = 10;
