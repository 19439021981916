import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import ShowIfHasAccess from "@/components/ShowIfHasAccess/ShowIfHasAccess";

interface CampaignManagementActionMenuProps {
  campaignId: number;
  onViewBatches: (id: number) => void;
  onViewFiles: (id: number) => void;
  onEditCampaign: (id: number) => void;
}

const CampaignManagementActionMenu: React.FC<
  CampaignManagementActionMenuProps
> = ({ campaignId, onViewBatches, onViewFiles, onEditCampaign }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="h-8 w-8 p-0 hover:bg-gray-200 transition-colors"
          variant="ghost"
        >
          <span className="sr-only">Open menu</span>
          <EllipsisVerticalIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px] p-2 bg-white">
        <ShowIfHasAccess requiredPermissions={["CAN_MANAGE_CAMPAIGN_BATCHES"]}>
          <DropdownMenuItem
            className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
            onClick={() => onViewBatches(campaignId)}
          >
            View Batches
          </DropdownMenuItem>
        </ShowIfHasAccess>

        <DropdownMenuItem
          className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
          onClick={() => onViewFiles(campaignId)}
        >
          View Files
        </DropdownMenuItem>

        <ShowIfHasAccess requiredPermissions={["CAN_EDIT_CAMPAIGNS"]}>
          <DropdownMenuItem
            className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
            onClick={() => onEditCampaign(campaignId)}
          >
            Edit
          </DropdownMenuItem>
        </ShowIfHasAccess>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default CampaignManagementActionMenu;
