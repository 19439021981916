import { Route } from "react-router-dom";
import MainLayout from "../../components/MainLayout/MainLayout";
import { AuthenticationGuard } from "../../components/AuthenticationGuard/AuthenticationGuard";
import EventManagement from "@/modules/eventRegistration/features/EventManagement/components/EventManagement/EventManagement";
import EventDirectory from "@/modules/eventRegistration/features/EventDirectory/components/EventDirectory/EventDirectory";
import FavoritedEvents from "@/modules/eventRegistration/features/EventDirectory/components/FavoritedEvents/FavoritedEvents";
import { SessionManagement } from "@/modules/eventRegistration/features/SessionManagement/components/SessionManagement/SessionManagement";
import EventDetails from "@/modules/eventRegistration/features/EventDetails/components/EventDetails/EventDetails";
import PermissionGuard from "@/components/PermissionGuard/PermissionGuard";

const EventRegistrationRoutes = (
  <Route
    element={
      <AuthenticationGuard
        component={() => <MainLayout section="event-registration" />}
      />
    }
    path="/event-registration"
  >
    <Route
      element={
        <PermissionGuard requiredRoles={["ROLE_SUPER_ADMIN"]}>
          <EventManagement />
        </PermissionGuard>
      }
      path="admin/event-management"
    />

    <Route element={<EventDirectory />} path="event-directory" />
    <Route element={<FavoritedEvents />} path="favorited-events" />
    <Route element={<SessionManagement />} path="events/:eventId/sessions" />
    <Route element={<EventDetails />} path="events/:eventId" />
  </Route>
);

export default EventRegistrationRoutes;
