import { useAppSelector, useAppDispatch } from "@/app/hooks";
import { selectEventsWithLoadingState } from "../../selectors/eventDirectorySelectors";
import { useEventDirectoryActions } from "../../hooks/useEventDirectoryActions";
import { EventDirectoryProvider } from "../../context/EventDirectoryContext";
import { selectCurrentRequest } from "../../selectors/eventDirectorySelectors";
import EventDirectoryPagination from "../EventDirectoryPagination/EventDirectoryPagination";
import EventDirectoryToolbar from "../EventDirectoryToolbar/EventDirectoryToolbar";
import EventDirectoryGrid from "../EventDirectoryGrid/EventDirectoryGrid";
import { useInitializeEvents } from "../../../shared/hooks/useInitializeEvents";
import { DEFAULTS } from "../../constants/eventDirectoryConstants";
import React from "react";
import { useEventSearch } from "../../hooks/useEventSearch";
import { fetchEventsAction } from "../../thunks/eventThunks";

const EventDirectory: React.FC = () => {
  const currentRequest = useAppSelector(selectCurrentRequest);
  const dispatch = useAppDispatch();

  useInitializeEvents({ pageSize: DEFAULTS.PAGE_SIZE });

  const { events, isLoadingEvents, eventsError, totalCount } = useAppSelector(
    selectEventsWithLoadingState,
  );

  const { handleSearchChange } = useEventSearch(async () => {
    await dispatch(fetchEventsAction());
  });

  const { handleFiltersChange, handlePageChange } = useEventDirectoryActions({
    page: currentRequest.page,
    pageSize: currentRequest.pageSize,
    searchTerm: currentRequest.searchTerm || "",
  });

  return (
    <EventDirectoryProvider>
      <div className="min-h-screen bg-gradient-to-b from-background to-muted/20">
        <div className="container space-y-8">
          <div className="space-y-4">
            <EventDirectoryToolbar
              isLoading={isLoadingEvents}
              onFilterChange={handleFiltersChange}
              onSearch={handleSearchChange}
            />
            <EventDirectoryGrid
              error={eventsError}
              events={events}
              itemsPerPage={currentRequest.pageSize}
              loading={isLoadingEvents}
            />
            {totalCount > 0 && (
              <EventDirectoryPagination
                onPageChange={handlePageChange}
                pageIndex={(currentRequest.page || 0) - 1}
                pageSize={currentRequest.pageSize || 0}
                totalCount={totalCount}
              />
            )}
          </div>
        </div>
      </div>
    </EventDirectoryProvider>
  );
};

export default EventDirectory;
