import React from "react";
import { EventManagementTable } from "../EventManagementTable/EventManagementTable";
import { ApiEvent } from "@/api/fetchEvents/types";
import { FetchEventsRequest } from "@/api/fetchEvents/types";
import {
  SortingParams,
  SortOrder,
} from "../../hooks/useEventManagementActions";

export interface EventManagementTableSectionProps {
  events: ApiEvent[];
  isLoading: boolean;
  currentRequest: Omit<FetchEventsRequest, "sortOrder"> & {
    sortOrder?: SortOrder;
  };
  totalCount: number;
  onEditEvent: (eventId: number) => void;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
  onSortingChange: (params: SortingParams) => Promise<void>;
  defaultPageSize: number;
}

export const EventManagementTableSection: React.FC<
  EventManagementTableSectionProps
> = ({ onEditEvent, onPaginationChange }) => {
  return (
    <EventManagementTable
      onEditEvent={onEditEvent}
      onPaginationChange={onPaginationChange}
    />
  );
};

export default React.memo(EventManagementTableSection);
