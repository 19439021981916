import React from "react";
import { useSelector } from "react-redux";
import { ApiEvent } from "@/api/fetchEvents/types";
import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator";
import { EventManagementTableHeader } from "../EventManagementTableHeader/EventManagementTableHeader";
import { EventManagementPagination } from "../EventManagementPagination/EventManagementPagination";
import { useTableColumns } from "../../hooks/useTableColumns";
import {
  selectAllEvents,
  selectIsLoading,
  selectEventManagementCurrentRequest,
  selectTotalCount,
  selectSelectedEventIds,
} from "../../selectors/eventManagementSelectors";
import { useEventManagementActions } from "../../hooks/useEventManagementActions";
import { createPaginationFromRequest } from "../../utils/paginationUtils";
import { useAllEventsSelected } from "../../hooks/useAllEventsSelected";

interface EventManagementTableProps {
  onEditEvent: (eventId: number) => void;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
}

export const EventManagementTable: React.FC<EventManagementTableProps> = ({
  onEditEvent,
  onPaginationChange,
}) => {
  const events = useSelector(selectAllEvents) as ApiEvent[];
  const isLoading = useSelector(selectIsLoading);
  const currentRequest = useSelector(selectEventManagementCurrentRequest);
  const totalCount = useSelector(selectTotalCount);
  const selectedEvents = useSelector(selectSelectedEventIds);

  const {
    handleShowSessions,
    handleSelectEvent,
    handleSelectAllEvents,
    handleDuplicateEvent,
    handleDeleteEvent,
  } = useEventManagementActions(currentRequest);

  const pagination = createPaginationFromRequest(currentRequest, totalCount);

  const isAllSelected = useAllEventsSelected(events, selectedEvents);

  const columns = useTableColumns({
    selectedEvents,
    onSelectEvent: handleSelectEvent,
    onEditEvent,
    onDuplicateEvent: (event) =>
      handleDuplicateEvent(
        event,
        totalCount as number,
        pagination.pageSize,
        onPaginationChange,
      ),
    onDeleteEvent: handleDeleteEvent,
    onSelectAll: (checked) => handleSelectAllEvents(checked, events),
    isAllSelected,
    onShowSessions: handleShowSessions,
  });

  if (isLoading && events.length === 0) {
    return <LoadingIndicator />;
  }

  return (
    <div className="relative space-y-4">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
          <LoadingIndicator />
        </div>
      )}
      <div className="rounded-md border shadow-sm">
        <div className="relative w-full overflow-auto">
          <table className="w-full caption-bottom text-sm">
            <EventManagementTableHeader columns={columns} />
            <tbody className="[&_tr:last-child]:border-0">
              {events.length === 0 ? (
                <tr className="border-b transition-colors hover:bg-muted/50">
                  <td
                    className="p-10 text-center text-muted-foreground"
                    colSpan={columns.length + 1}
                  >
                    No events found
                  </td>
                </tr>
              ) : (
                <>
                  {events.map((event: ApiEvent) => (
                    <tr
                      className="border-b transition-colors hover:bg-muted/50"
                      key={event.id}
                    >
                      {columns.map((column) => (
                        <td
                          className="p-4 align-middle [&:has([role=checkbox])]:pr-0"
                          key={column.id || column.accessorKey}
                        >
                          {column.cell
                            ? column.cell({ row: { original: event } })
                            : !Array.isArray(
                                  event[column.accessorKey as keyof ApiEvent],
                                ) &&
                                (typeof event[
                                  column.accessorKey as keyof ApiEvent
                                ] === "string" ||
                                  typeof event[
                                    column.accessorKey as keyof ApiEvent
                                  ] === "number")
                              ? String(
                                  event[column.accessorKey as keyof ApiEvent],
                                )
                              : null}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {pagination.pageIndex <
                    Math.ceil(pagination.totalCount / pagination.pageSize) -
                      1 &&
                    Array.from({
                      length: pagination.pageSize - events.length,
                    }).map((_, index) => (
                      <tr className="border-b" key={`empty-${index}`}>
                        {columns.map((column, colIndex) => (
                          <td
                            className="p-4 align-middle"
                            key={`empty-${index}-${colIndex}`}
                          >
                            <div className="h-6" />
                          </td>
                        ))}
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <EventManagementPagination
        onPageChange={onPaginationChange}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        totalCount={pagination.totalCount}
      />
    </div>
  );
};
