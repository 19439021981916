import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserAppSettings } from "../../modules/hub/features/UserAppSettings/selectors/userAppSettingsSelectors";
import IncompleteAccountSetup from "../../modules/hub/features/Auth/components/IncompleteAccountSetup/IncompleteAccountSetup";
import WideSidebar from "../WideSidebar/WideSidebar";
import TopNavBar from "../TopNavBar/TopNavBar";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import MainContent from "../MainContent/MainContent";
import { SidebarProvider } from "@/components/ui/sidebar";

interface MainLayoutProps {
  section: string | null;
}

const MainLayout: React.FC<MainLayoutProps> = () => {
  const [sidebarOpenWide, setSidebarOpenWide] = useState(true);
  const userAppSettings = useSelector(selectUserAppSettings);
  const isAccountSetupIncomplete = userAppSettings && !userAppSettings.roleName;

  return (
    <SidebarProvider onOpenChange={setSidebarOpenWide} open={sidebarOpenWide}>
      <WideSidebar />
      <div className="flex flex-1 flex-col min-w-0">
        <TopNavBar
          onSidebarToggle={() => setSidebarOpenWide((prev) => !prev)}
        />
        <Breadcrumbs />
        <MainContent>
          {isAccountSetupIncomplete ? <IncompleteAccountSetup /> : <Outlet />}
        </MainContent>
      </div>
    </SidebarProvider>
  );
};

export default MainLayout;
