import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/app/store";
import { fetchSessionsAction } from "../../SessionManagement/thunks/sessionThunks";

export const useLoadSessions = (
  eventId: string | undefined,
  eventIdNumber: number,
  pageIndex: number,
  pageSize: number,
) => {
  const dispatch = useDispatch<AppDispatch>();
  const [sessionsLoading, setSessionsLoading] = useState(false);

  useEffect(() => {
    if (eventId) {
      setSessionsLoading(true);
      dispatch(
        fetchSessionsAction({
          eventId: eventIdNumber,
          page: pageIndex + 1,
          pageSize,
        }),
      ).finally(() => {
        setSessionsLoading(false);
      });
    }
  }, [dispatch, eventId, eventIdNumber, pageIndex, pageSize]);

  return { sessionsLoading };
};
