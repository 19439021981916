import { createContext, useContext } from "react";
import { FetchEventsRequest } from "@/api/fetchEvents/types";
import {
  EventFilters,
  SortingParams,
} from "../hooks/useEventManagementActions";

export interface EventManagementContextType {
  currentRequest: FetchEventsRequest;
  isLoadingEvents: boolean;
  handlePaginationChange: (
    pageIndex: number,
    pageSize: number,
  ) => Promise<void>;
  handleSortingChange: (params: SortingParams) => Promise<void>;
  handleSearchChange: (searchTerm: string) => Promise<void>;
  handleFiltersChange: (filters: EventFilters) => Promise<void>;
  handleEditEvent: (eventId: number) => void;
}

export const EventManagementContext = createContext<
  EventManagementContextType | undefined
>(undefined);

export const useEventManagement = () => {
  const context = useContext(EventManagementContext);
  if (!context) {
    throw new Error(
      "useEventManagement must be used within EventManagementProvider",
    );
  }
  return context;
};
