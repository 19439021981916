import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/rootReducer";
import { selectEventById } from "../../EventDirectory/slices/eventsSlice";
import {
  selectSelectedSession,
  selectSessionsByEventId,
} from "../../SessionManagement/selectors/sessionManagementSelectors";
import { selectSelectedUsers } from "../../../../hub/features/UserManagement/slices/usersSlice";
import { ApiEvent } from "@/api/fetchEvents/types";
import { ApiEventFile } from "@/api/fetchEventFiles/types";
import { ApiEventSession } from "@/api/fetchEventSessions/types";
import { useSelector } from "react-redux";
import {
  selectEventDetailsLoading,
  selectEventFiles,
} from "../../EventDirectory/selectors/eventDirectorySelectors";

/**
 * Selects the page index for event details
 * @param state The Redux state
 * @returns The page index
 */
export const selectEventDetailsPageIndex = (state: RootState) =>
  state.sessionUI.pageIndex;

/**
 * Hook to select the page index for event details
 * @returns The page index
 */
export const useEventDetailsPageIndex = (): number => {
  return useSelector(selectEventDetailsPageIndex);
};

/**
 * Selects the page size for event details
 * @param state The Redux state
 * @returns The page size
 */
export const selectEventDetailsPageSize = (state: RootState) =>
  state.sessionUI.pageSize;

/**
 * Hook to select the page size for event details
 * @returns The page size
 */
export const useEventDetailsPageSize = (): number => {
  return useSelector(selectEventDetailsPageSize);
};

/**
 * Hook to check if event details are loading
 * @returns True if loading, false otherwise
 */
export const useEventDetailsLoading = (): boolean => {
  return useSelector(selectEventDetailsLoading);
};

/**
 * Selects the event by ID
 * @param state The Redux state
 * @param eventIdNumber The event ID as a number
 * @returns The event or null if not found
 */
export const selectEventDetailsEvent = (
  state: RootState,
  eventIdNumber: number,
): ApiEvent | null =>
  eventIdNumber ? selectEventById(state, eventIdNumber) : null;

/**
 * Hook to select event by ID number
 * @param eventIdNumber The event ID as a number
 * @returns The event or null if not found
 */
export const useEventDetailsEvent = (
  eventIdNumber: number,
): ApiEvent | null => {
  return useSelector((state: RootState) =>
    selectEventDetailsEvent(state, eventIdNumber),
  );
};

/**
 * Selects the event files
 * @param state The Redux state
 * @returns Array of event files
 */
export const selectEventDetailsFiles = (state: RootState): ApiEventFile[] =>
  selectEventFiles(state);

/**
 * Hook to select event files
 * @returns Array of event files
 */
export const useEventDetailsFiles = (): ApiEventFile[] => {
  return useSelector(selectEventDetailsFiles);
};

/**
 * Hook to select the selected session ID
 * @returns The selected session ID or null
 */
export const useEventDetailsSelectedSession = (): string | null => {
  return useSelector(selectSelectedSession);
};

/**
 * Selects sessions by event ID
 * @param state The Redux state
 * @param eventId The event ID string
 * @param eventIdNumber The event ID as a number
 * @returns Array of event sessions
 */
export const selectEventDetailsSessions = (
  state: RootState,
  eventId: string | undefined,
  eventIdNumber: number,
): ApiEventSession[] =>
  eventId ? selectSessionsByEventId(state, eventIdNumber) : [];

/**
 * Hook to select sessions by event ID
 * @param eventId The event ID string
 * @param eventIdNumber The event ID as a number
 * @returns Array of event sessions
 */
export const useEventDetailsSessions = (
  eventId: string | undefined,
  eventIdNumber: number,
): ApiEventSession[] => {
  return useSelector((state: RootState) =>
    selectEventDetailsSessions(state, eventId, eventIdNumber),
  );
};

/**
 * Selects the total count of sessions
 * @param state The Redux state
 * @returns The total count of sessions
 */
export const selectTotalSessionsCount = (state: RootState) =>
  state.sessions.totalCount;

/**
 * Hook to select the total count of sessions
 * @returns The total count of sessions
 */
export const useEventDetailsTotalSessionsCount = (): number => {
  return useSelector(selectTotalSessionsCount);
};

export const selectEventDetailsData = createSelector(
  [
    (state: RootState, eventId: number) => selectEventById(state, eventId),
    selectEventFiles,
    selectSelectedSession,
    selectSelectedUsers,
    (state: RootState, eventId: number) =>
      selectSessionsByEventId(state, eventId),
  ],
  (event, files, selectedSession, selectedUsers, sessions) => ({
    event,
    files,
    selectedSession,
    selectedUsers,
    sessions,
  }),
);

export { selectEventFiles };
