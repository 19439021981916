export interface TimeOption {
  value: string;
  label: string;
}

export const generateTimeOptions = (): TimeOption[] => {
  const options: TimeOption[] = [];
  for (let hour = 0; hour < 24; hour++) {
    const hourFormatted = hour.toString().padStart(2, "0");
    const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    const period = hour >= 12 ? "PM" : "AM";
    options.push({
      value: `${hourFormatted}:00`,
      label: `${hour12}:00 ${period}`,
    });
    options.push({
      value: `${hourFormatted}:30`,
      label: `${hour12}:30 ${period}`,
    });
  }
  return options;
};

export const TIME_OPTIONS = generateTimeOptions();

export const getAvailableTimeOptions = (
  date: Date | null,
  timeOptions: TimeOption[],
): TimeOption[] => {
  if (!date) return timeOptions;

  const now = new Date();
  const isToday =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();

  if (!isToday) return timeOptions;

  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  return timeOptions.filter((option) => {
    const [hours, minutes] = option.value.split(":").map(Number);
    if (hours > currentHour) return true;
    if (hours === currentHour) return minutes > currentMinute;
    return false;
  });
};

export const getTimeDisplayLabel = (
  time: string,
  timeOptions: TimeOption[],
): string => {
  const option = timeOptions.find((opt) => opt.value === time);
  return option ? option.label : time;
};
