import { ApiEvent } from "@/api/fetchEvents/types";

/**
 * Checks if all events are selected
 * @param events - Array of events to check
 * @param selectedEventIds - Array of selected event IDs
 * @returns boolean indicating if all events are selected
 */
export const areAllEventsSelected = (
  events: ApiEvent[],
  selectedEventIds: number[],
): boolean => {
  return (
    events.length > 0 &&
    events.every((event) => selectedEventIds.includes(event.id))
  );
};
