import { Company } from "../../slices/companiesSlice";
import React from "react";
import CompanyManagementActionMenu from "../CompanyManagementActionMenu/CompanyManagementActionMenu";

export interface Column {
  id?: string;
  accessorKey?: keyof Company | string;
  header?: string | (() => React.ReactNode);
  cell?: (props: { row: { original: Company } }) => React.ReactNode;
}

interface CreateCompaniesColumnsProps {
  handleEdit: (uuid: string) => void;
}

export function createCompaniesColumns({
  handleEdit,
}: CreateCompaniesColumnsProps): Column[] {
  return [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Company Name",
      accessorKey: "companyName",
    },
    {
      header: "Salesforce ID",
      accessorKey: "salesforceId",
    },
    {
      header: "Intacct ID",
      accessorKey: "intacctId",
    },
    {
      header: "Stochastic Client",
      accessorKey: "marketingEnabled",
      cell: ({ row }) => (row.original.marketingEnabled ? "Yes" : "No"),
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const uuid = row.original.uuid || "";
        return (
          <CompanyManagementActionMenu companyUuid={uuid} onEdit={handleEdit} />
        );
      },
    },
  ];
}
