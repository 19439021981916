import { axiosInstance } from "../axiosInstance";
import { EditEventInstructorResponse, EditEventInstructorDTO } from "./types";

export const editEventInstructor = async (
  id: number,
  editEventInstructorDTO: EditEventInstructorDTO,
): Promise<EditEventInstructorResponse> => {
  const response = await axiosInstance.put<EditEventInstructorResponse>(
    `/api/private/event-instructors/${id}/edit`,
    editEventInstructorDTO,
  );
  return response.data;
};
