import { createContext, useContext, ReactNode } from "react";
import { useAppSelector } from "@/app/hooks";
import {
  selectCurrentRequestState,
  selectEventsWithLoadingState,
} from "../selectors/eventDirectorySelectors";
import { FetchEventsRequest } from "@/api/fetchEvents/types";
import { useEventDirectoryActions } from "../hooks/useEventDirectoryActions";
import { EventFilters } from "../components/EventDirectoryFilters/EventDirectoryFilters";

export interface EventDirectoryContextType {
  currentRequest: FetchEventsRequest;
  isLoadingEvents: boolean;
  handlePaginationChange: (
    pageIndex: number,
    pageSize: number,
  ) => Promise<void | undefined>;
  handleSearchChange: (searchTerm: string) => Promise<void | undefined>;
  handleFiltersChange: (
    filters: Record<string, string[]>,
  ) => Promise<void | undefined>;
}

export const EventDirectoryContext = createContext<
  EventDirectoryContextType | undefined
>(undefined);

export const EventDirectoryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const currentRequest = useAppSelector(selectCurrentRequestState);
  const { isLoadingEvents } = useAppSelector(selectEventsWithLoadingState);
  const {
    handlePaginationChange,
    handleSearchChange: asyncHandleSearchChange,
    handleFiltersChange,
  } = useEventDirectoryActions(currentRequest);

  return (
    <EventDirectoryContext.Provider
      value={{
        currentRequest,
        isLoadingEvents,
        handlePaginationChange,
        handleSearchChange: (searchTerm: string) =>
          Promise.resolve(asyncHandleSearchChange(searchTerm)),
        handleFiltersChange: (filters: Record<string, string[]>) =>
          Promise.resolve(
            handleFiltersChange(filters as unknown as EventFilters),
          ),
      }}
    >
      {children}
    </EventDirectoryContext.Provider>
  );
};

export const useEventDirectoryContext = () => {
  const context = useContext(EventDirectoryContext);
  if (context === undefined) {
    throw new Error(
      "useEventDirectoryContext must be used within a EventDirectoryProvider",
    );
  }
  return context;
};
