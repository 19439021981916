import React, { useRef, useCallback, useState } from "react";
import { useSortable, UseSortableArguments } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { RichTextEditor } from "@/modules/hub/features/ResourceLibrary/components/RichTextEditor/RichTextEditor";
import { Button } from "@/components/ui/button";
import { SortableBlockProps } from "./types";
import { uploadTmpFile } from "@/api/uploadTmpFile/uploadTmpFileApi";
import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator";

export function SortableBlock({
  id,
  type,
  content,
  onRemove,
  onChange,
}: SortableBlockProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id as UseSortableArguments["id"] });

  const [isUploading, setIsUploading] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleUploadImageClick = useCallback(() => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }, []);

  const handleFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      setIsUploading(true);

      try {
        const response = await uploadTmpFile(file);
        const { fileId: newFileId, fileUrl } = response.data;
        onChange({
          content: fileUrl,
          fileId: newFileId,
        });
      } catch (error) {
        console.error("Error uploading file", error);
      } finally {
        if (hiddenFileInput.current) {
          hiddenFileInput.current.value = "";
        }
        setIsUploading(false);
      }
    },
    [onChange],
  );

  const handleRichTextChange = useCallback(
    (newContent: string) => {
      onChange({ content: newContent });
    },
    [onChange],
  );

  const renderBlockContent = () => {
    switch (type) {
      case "text":
        return (
          <div className="mt-2">
            <RichTextEditor
              id={id || ""}
              initialContent={content}
              onChange={handleRichTextChange}
              onRemove={onRemove}
            />
          </div>
        );

      case "image":
        return (
          <div className="mt-2 space-y-4 relative min-h-[100px]">
            {content && (
              <img
                alt="Content block"
                src={content}
                style={{ width: "100%", borderRadius: 8 }}
              />
            )}
            <div>
              {isUploading ? (
                <Button disabled type="button" variant="outline">
                  Uploading Image...
                </Button>
              ) : (
                <Button
                  onClick={handleUploadImageClick}
                  type="button"
                  variant="outline"
                >
                  Upload Image
                </Button>
              )}
            </div>

            {isUploading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <LoadingIndicator isFullScreen={false} />
              </div>
            )}
            <input
              accept="image/*"
              className="hidden"
              onChange={handleFileChange}
              ref={hiddenFileInput}
              type="file"
            />
          </div>
        );

      default:
        return <p>Unknown block type: {type}</p>;
    }
  };

  return (
    <div
      className="border rounded-lg p-4 bg-white mb-6"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <button
            className="cursor-grab hover:bg-gray-100 p-1 rounded"
            type="button"
            {...listeners}
            aria-label="Drag block"
          >
            ⋮⋮
          </button>
          <span className="font-medium">{type} Block</span>
        </div>
        <Button
          className="text-red-500 hover:text-red-700"
          onClick={onRemove}
          size="sm"
          type="button"
          variant="ghost"
        >
          Remove
        </Button>
      </div>

      {renderBlockContent()}
    </div>
  );
}
