import React from "react";
import { Input } from "../../../../../components/ui/input";
import { Search } from "lucide-react";
import { useERSearchActions } from "../hooks/useERSearchActions";

export interface ERSearchProps {
  className?: string;
  onSearchChange: (value: string) => void;
  placeholder?: string;
  searchQuery: string;
}

const ERSearch: React.FC<ERSearchProps> = ({
  className = "",
  onSearchChange,
  placeholder = "Search...",
  searchQuery,
}) => {
  const { handleSearchChange } = useERSearchActions({ onSearchChange });

  return (
    <div className={`relative ${className}`}>
      <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        className="pl-8"
        onChange={handleSearchChange}
        placeholder={placeholder}
        value={searchQuery}
      />
    </div>
  );
};

export default ERSearch;
