import { useEffect, useState, useCallback, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useToast } from "@/components/ui/use-toast";
import {
  ResourceFormData,
  resourceFormSchema,
} from "@/modules/hub/features/ResourceLibrary/hooks/useCreateResource/types";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { getCreateUpdateResourceMetadataAction } from "@/modules/hub/features/ResourceLibrary/slices/resourceLibraryMetadataSlice";
import {
  getResourceAction,
  updateResourceAction,
} from "@/modules/hub/features/ResourceLibrary/slices/createUpdateResourceSlice";
import { useNavigate } from "react-router-dom";
import { useNotification } from "@/context/NotificationContext";

export function useEditResource() {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const {
    resourceTags,
    resourceCategories,
    employeeRoles,
    trades,
    resourceTypes,
    loading: metadataLoading,
    error: metadataError,
  } = useAppSelector((state) => state.resourceLibraryMetadata);

  const { fetchedResource, loadingUpdate } = useAppSelector(
    (state) => state.createUpdateResource,
  );

  useEffect(() => {
    dispatch(getCreateUpdateResourceMetadataAction());
  }, [dispatch]);

  /**
   * Note: Our new form schema has:
   * tags:  { id: number, name?: string }[]
   * categories: { id: number, name?: string }[]
   * trades, roles remain as IDs/strings
   */
  const defaultValues: ResourceFormData = {
    title: "",
    tagline: "",
    description: "",
    type: null,
    content_url: "",
    thumbnail_url: "",
    thumbnailFileId: null,
    publish_start_date: null,
    publish_end_date: null,
    is_published: false,
    tags: [],
    categories: [],
    tradeIds: [],
    roleIds: [],
    contentBlocks: [],
  };

  const form = useForm<ResourceFormData>({
    resolver: zodResolver(resourceFormSchema),
    defaultValues,
    mode: "onChange",
  });

  /**
   * When we have `fetchedResource`, populate the form.
   * Suppose your backend returns:
   *   fetchedResource.tags = [{id:4,name:"Foo"},{id:5,name:"Bar"}]
   *   fetchedResource.categories = [{id:10,name:"Cat 1"},...]
   *   etc.
   * If the old server data only had tagIds or categoryIds, you must adapt:
   *   fetchedResource.tags or fetchedResource.categories might not exist.
   * If it does exist, we map them directly to form fields.
   */
  useEffect(() => {
    if (fetchedResource) {
      form.reset({
        title: fetchedResource.title || "",
        tagline: fetchedResource.tagline || "",
        description: fetchedResource.description || "",
        type: fetchedResource.typeId || 0,
        content_url: fetchedResource.contentUrl || "",
        thumbnail_url: fetchedResource.thumbnailUrl || "",
        thumbnailFileId: fetchedResource.thumbnailFileId || null,
        publish_start_date: fetchedResource.publishStartDate
          ? new Date(fetchedResource.publishStartDate)
          : null,
        publish_end_date: fetchedResource.publishEndDate
          ? new Date(fetchedResource.publishEndDate)
          : null,
        is_published: fetchedResource.isPublished ?? false,

        // If your backend returns "tags" as array of {id, name}, we can store them directly:
        tags:
          fetchedResource.tags?.map((t) => ({
            id: t.id,
            name: t.name,
          })) || [],

        // Same for categories:
        categories:
          fetchedResource.categories?.map((c) => ({
            id: c.id,
            name: c.name,
          })) || [],

        // trades and roles remain numeric/string arrays
        tradeIds: fetchedResource.tradeIds?.map(String) || [],
        roleIds: fetchedResource.roleIds?.map(String) || [],

        contentBlocks:
          fetchedResource.contentBlocks?.map((block) => ({
            id: block.id,
            type: block.type,
            content: block.content,
            order_number: block.order_number,
            metadata: {},
            fileId: block.fileId || null,
          })) || [],
      });
    }
  }, [fetchedResource, form]);

  const resourceTypeId = form.watch("type");

  const selectedResourceType = useMemo(() => {
    if (!resourceTypeId) return null;
    return resourceTypes.find((rt) => rt.id === resourceTypeId) || null;
  }, [resourceTypeId, resourceTypes]);

  const requiresContentUrl = selectedResourceType?.requiresContentUrl || false;

  /**
   * Updating flow: we have to convert arrays of {id,name} for "tags" & "categories"
   * into arrays of numeric IDs for the server, e.g. "tagIds" or "categoryIds"
   */
  const submitForm = useCallback(
    async (values: ResourceFormData) => {
      try {
        setIsLoading(true);
        if (!fetchedResource) {
          throw new Error("No resource loaded to update");
        }

        const numericType = values.type ?? 0;

        const filteredBlocks =
          values.contentBlocks?.filter(
            (b) => b.type === "text" || b.type === "image",
          ) || [];

        const publishStart = values.publish_start_date
          ? values.publish_start_date.toISOString()
          : null;
        const publishEnd = values.publish_end_date
          ? values.publish_end_date.toISOString()
          : null;

        const finalBlocks = filteredBlocks.map((b) => ({
          ...b,
          order_number: b.order_number ?? 0,
        }));

        // Convert "tags" -> array of numeric IDs
        const tagIds = values.tags?.map((t) => t.id) || [];
        const categoryIds = values.categories?.map((c) => c.id) || [];

        const finalData = {
          title: values.title,
          tagline: values.tagline || null,
          description: values.description,
          type: numericType,
          content_url: values.content_url || null,
          thumbnail_url: values.thumbnail_url || null,
          thumbnailFileId: values.thumbnailFileId ?? null,
          publish_start_date: publishStart,
          publish_end_date: publishEnd,
          is_published: values.is_published ?? false,

          // trades and roles remain ID arrays
          tradeIds: values.tradeIds || [],
          roleIds: values.roleIds || [],

          // Now our "tags" and "categories" become ID arrays for the server
          tagIds,
          categoryIds,
          contentBlocks: finalBlocks,
        };

        const resourceId = fetchedResource.id;
        if (!resourceId) {
          throw new Error("No resource ID found to update");
        }

        await dispatch(
          updateResourceAction(resourceId, finalData, () => {
            showNotification(
              "Success!",
              "Resource has been successfully updated!",
              "success",
            );
            navigate(`/admin/resources`);
          }),
        );
      } catch (error) {
        console.error("Error updating resource:", error);
        if (error instanceof Error) {
          toast({
            title: "Error",
            description: error.message || "Failed to update",
            variant: "destructive",
          });
        } else {
          toast({
            title: "Error",
            description: "An unknown error occurred while updating.",
            variant: "destructive",
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, fetchedResource, toast],
  );

  const handleCancel = useCallback(() => {
    navigate(`/admin/resources`);
  }, []);

  const fetchResource = useCallback(
    (uuidParam: string) => {
      dispatch(getResourceAction(uuidParam));
    },
    [dispatch],
  );

  return {
    form,
    isLoading: isLoading || loadingUpdate,
    tags: resourceTags,
    trades,
    categories: resourceCategories,
    roles: employeeRoles,
    resourceTypes,
    metadataLoading,
    metadataError,
    resourceTypeId,
    selectedResourceType,
    requiresContentUrl,
    submitForm,
    handleCancel,
    fetchResource,
  };
}
