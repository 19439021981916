import { useParams } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useLoadEventDetails } from "../../hooks/useLoadEventDetails";
import {
  useEventDetailsPageIndex,
  useEventDetailsPageSize,
  useEventDetailsEvent,
  useEventDetailsFiles,
  useEventDetailsSessions,
  useEventDetailsSelectedSession,
  useEventDetailsTotalSessionsCount,
  useEventDetailsLoading,
} from "../../selectors/eventDetailsSelectors";
import { useEventDetailsActions } from "../../hooks/useEventDetailsActions";
import { EventDetailsFiles } from "../EventDetailsFiles/EventDetailsFiles";
import { EventHeader } from "../EventHeader/EventHeader";
import { EventDescription } from "../EventDescription/EventDescription";
import { SessionList } from "../SessionList/SessionList";
import { useLoadSessions } from "../../hooks/useLoadSessions";
import { EventDetailsSkeleton } from "../EventDetailsSkeleton/EventDetailsSkeleton";
import { getEventIdNumber } from "../../utils/eventDetailsUtils";
import { ApiEventFile } from "@/api/fetchEventFiles/types";

export interface EventDetailsFilesProps {
  files: ApiEventFile[];
  onFileDownload: (file: ApiEventFile) => void;
  downloadingFileId: number | null;
  isLoading?: boolean;
}

const EventDetails: React.FC = () => {
  // Router hooks
  const { eventId } = useParams<{ eventId: string }>();

  // Selectors for pagination
  const pageIndex = useEventDetailsPageIndex();
  const pageSize = useEventDetailsPageSize();

  // Get event ID as number
  const eventIdNumber = getEventIdNumber(eventId);

  // Load sessions and event details
  const { sessionsLoading } = useLoadSessions(
    eventId,
    eventIdNumber,
    pageIndex,
    pageSize,
  );
  useLoadEventDetails(eventId);

  // UI state selectors
  const isLoading = useEventDetailsLoading();
  const {
    handleFileDownload,
    downloadingFileId,
    handleSessionClick,
    handlePageChange,
    handleBackNavigation,
    handleAttendeesNavigation,
  } = useEventDetailsActions();

  // Data selectors
  const event = useEventDetailsEvent(eventIdNumber);
  const files = useEventDetailsFiles();
  const selectedSession = useEventDetailsSelectedSession();
  const sessions = useEventDetailsSessions(eventId, eventIdNumber);
  const totalSessionsCount = useEventDetailsTotalSessionsCount();

  if (isLoading) {
    return <EventDetailsSkeleton />;
  }

  if (!event) {
    return <div>Event not found</div>;
  }

  return (
    <div className="container mx-auto">
      <div className="mb-4">
        <Button
          className="flex items-center gap-2"
          onClick={handleBackNavigation}
          variant="ghost"
        >
          <ArrowLeft className="h-4 w-4" />
          Back
        </Button>
      </div>

      <EventHeader event={event} />
      <EventDescription event={event} />
      <EventDetailsFiles
        downloadingFileId={downloadingFileId}
        files={files}
        isLoading={isLoading}
        onFileDownload={handleFileDownload}
      />
      <SessionList
        onPageChange={handlePageChange}
        onSessionClick={handleSessionClick}
        pageIndex={pageIndex}
        pageSize={pageSize}
        selectedSession={selectedSession ? Number(selectedSession) : null}
        sessions={sessions}
        sessionsLoading={sessionsLoading}
        totalSessionsCount={totalSessionsCount}
      />

      <div className="mt-8 flex justify-end">
        <Button
          className="w-full md:w-auto bg-primary hover:bg-primary/90 text-white font-semibold px-8 py-2 rounded-lg shadow-sm transition-colors text-md"
          disabled={!selectedSession}
          onClick={() => handleAttendeesNavigation(eventId)}
          size="lg"
          variant="default"
        >
          Select Attendees
        </Button>
      </div>
    </div>
  );
};

export default EventDetails;
