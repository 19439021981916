import { useEffect } from "react";
import { useEventManagementActions } from "./useEventManagementActions";

/**
 * A custom hook that cleans up files when a dialog opens
 * @param isOpen Whether the dialog is open
 */
export const useDialogFilesCleaner = (isOpen: boolean) => {
  const { handleClearDialogFiles } = useEventManagementActions({
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    if (isOpen) {
      handleClearDialogFiles();
    }
  }, [isOpen, handleClearDialogFiles]);
};
