import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "@/app/store";
import { getResourceTags } from "@/api/getResourceTags/getResourceTagsApi";
import { createResourceTag } from "@/api/createResourceTag/createResourceTagApi";
import { editResourceTag } from "@/api/editResourceTag/editResourceTagApi";
import { deleteResourceTag } from "@/api/deleteResourceTag/deleteResourceTagApi";

interface ResourceTag {
  id: number;
  name: string;
}

interface ResourceTagState {
  tags: ResourceTag[];

  fetchLoading: boolean;
  fetchError: string | null;

  createLoading: boolean;
  createError: string | null;

  updateLoading: boolean;
  updateError: string | null;

  deleteLoading: boolean;
  deleteError: string | null;
}

const initialState: ResourceTagState = {
  tags: [],
  fetchLoading: false,
  fetchError: null,
  createLoading: false,
  createError: null,
  updateLoading: false,
  updateError: null,
  deleteLoading: false,
  deleteError: null,
};

const resourceTagSlice = createSlice({
  name: "resourceTag",
  initialState,
  reducers: {
    setTags(state, action: PayloadAction<ResourceTag[]>) {
      state.tags = action.payload;
    },
    addTag(state, action: PayloadAction<ResourceTag>) {
      state.tags.push(action.payload);
    },
    updateTag(state, action: PayloadAction<ResourceTag>) {
      const updated = action.payload;
      const index = state.tags.findIndex((t) => t.id === updated.id);
      if (index !== -1) {
        state.tags[index] = updated;
      }
    },
    removeTag(state, action: PayloadAction<number>) {
      state.tags = state.tags.filter((t) => t.id !== action.payload);
    },
    setFetchLoading(state, action: PayloadAction<boolean>) {
      state.fetchLoading = action.payload;
    },
    setFetchError(state, action: PayloadAction<string | null>) {
      state.fetchError = action.payload;
    },
    setCreateLoading(state, action: PayloadAction<boolean>) {
      state.createLoading = action.payload;
    },
    setCreateError(state, action: PayloadAction<string | null>) {
      state.createError = action.payload;
    },
    setUpdateLoading(state, action: PayloadAction<boolean>) {
      state.updateLoading = action.payload;
    },
    setUpdateError(state, action: PayloadAction<string | null>) {
      state.updateError = action.payload;
    },
    setDeleteLoading(state, action: PayloadAction<boolean>) {
      state.deleteLoading = action.payload;
    },
    setDeleteError(state, action: PayloadAction<string | null>) {
      state.deleteError = action.payload;
    },
  },
});

export const {
  setTags,
  addTag,
  updateTag,
  removeTag,
  setFetchLoading,
  setFetchError,
  setCreateLoading,
  setCreateError,
  setUpdateLoading,
  setUpdateError,
  setDeleteLoading,
  setDeleteError,
} = resourceTagSlice.actions;

export default resourceTagSlice.reducer;

export const fetchResourceTagsAction =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setFetchLoading(true));
    dispatch(setFetchError(null));

    try {
      const response = await getResourceTags({});
      dispatch(setTags(response.data.tags));
    } catch (error) {
      dispatch(
        setFetchError(
          error instanceof Error
            ? error.message
            : "Failed to fetch resource tags.",
        ),
      );
    } finally {
      dispatch(setFetchLoading(false));
    }
  };

export const createResourceTagAction =
  (name: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setCreateLoading(true));
    dispatch(setCreateError(null));

    try {
      const response = await createResourceTag({ name });
      dispatch(
        addTag({
          id: response.data.id ?? 0,
          name: response.data.name ?? "",
        }),
      );
    } catch (error) {
      dispatch(
        setCreateError(
          error instanceof Error
            ? error.message
            : "Failed to create resource tag.",
        ),
      );
    } finally {
      dispatch(setCreateLoading(false));
    }
  };

export const editResourceTagAction =
  (tagId: number, name: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setUpdateLoading(true));
    dispatch(setUpdateError(null));

    try {
      const response = await editResourceTag(tagId, { name });
      dispatch(
        updateTag({
          id: response.data.id ?? 0,
          name: response.data.name ?? "",
        }),
      );
    } catch (error) {
      dispatch(
        setUpdateError(
          error instanceof Error
            ? error.message
            : "Failed to edit resource tag.",
        ),
      );
    } finally {
      dispatch(setUpdateLoading(false));
    }
  };

export const deleteResourceTagAction =
  (tagId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setDeleteLoading(true));
    dispatch(setDeleteError(null));

    try {
      await deleteResourceTag(tagId);
      dispatch(removeTag(tagId));
    } catch (error) {
      dispatch(
        setDeleteError(
          error instanceof Error
            ? error.message
            : "Failed to delete resource tag.",
        ),
      );
    } finally {
      dispatch(setDeleteLoading(false));
    }
  };
