import { EventRequest } from "../hooks/useEventManagementActions";

/**
 * Interface for pagination parameters
 */
export interface PaginationParams {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
}

/**
 * Creates pagination parameters from the current request and total count
 *
 * @param currentRequest - The current event request object
 * @param totalCount - The total count of events
 * @returns PaginationParams object with pageIndex, pageSize, and totalCount
 */
export const createPaginationFromRequest = (
  currentRequest: EventRequest | null | undefined,
  totalCount: number,
): PaginationParams => {
  return {
    pageIndex: (currentRequest?.page ?? 1) - 1,
    pageSize: currentRequest?.pageSize ?? 10,
    totalCount: totalCount,
  };
};
