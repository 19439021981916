import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/Card/Card";
import { Badge } from "@/components/Badge/Badge";
import { Button } from "@/components/Button/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/Command/Command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Popover/Popover";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/utils/utils";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@/context/ThemeContext";
import { FilterOptions } from "@/api/fetchTotalSalesAgeDistributionChartData/types";

type MultiSelectProps = {
  onChange: (selected: string[]) => void;
  options: string[];
  placeholder: string;
  selected: string[];
};

type ChartDataItem = {
  days?: string;
  month?: string;
  salesPercentage?: number;
  totalSales?: number;
  [key: string]: string | number | undefined;
};

type AlphaChartProps = {
  chartId?: string;
  initialData: ChartDataItem[];
  filterOptions: FilterOptions;
  filters: FilterOptions;
  handleFilterChange: (
    filterType: "cities" | "years" | "trades",
    values: string[],
  ) => void;
};

export default function TotalSalesAgeDistributionChart({
  initialData,
  filterOptions,
  filters,
  handleFilterChange,
}: AlphaChartProps) {
  const { theme } = useTheme();

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercentage = (value: number) => {
    return `${value}%`;
  };

  return (
    <Card className="w-full bg-white dark:bg-secondary-dark">
      <CardHeader>
        <CardTitle className="text-fontColor dark:text-light">
          Total Sales Age Distribution Chart
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-wrap gap-4 mb-4">
          <MultiSelect
            onChange={(values) => handleFilterChange("cities", values)}
            options={filterOptions.cities}
            placeholder="Select Cities"
            selected={filters.cities}
          />
          <MultiSelect
            onChange={(values) => handleFilterChange("years", values)}
            options={filterOptions.years}
            placeholder="Select Years"
            selected={filters.years}
          />
          <MultiSelect
            onChange={(values) => handleFilterChange("trades", values)}
            options={filterOptions.trades}
            placeholder="Select Trades"
            selected={filters.trades}
          />
        </div>
        <div className="flex flex-wrap gap-2 mb-4">
          {filters.cities.map((city) => (
            <Badge key={city} variant="secondary">
              {city}
            </Badge>
          ))}
          {filters.years.map((year) => (
            <Badge key={year} variant="secondary">
              {year}
            </Badge>
          ))}
          {filters.trades.map((trade) => (
            <Badge key={trade} variant="secondary">
              {trade}
            </Badge>
          ))}
        </div>
        <ResponsiveContainer height={400} width="100%">
          <ComposedChart
            data={initialData}
            margin={{
              top: 5,
              right: 30,
              left: 45,
              bottom: 5,
            }}
          >
            <CartesianGrid
              stroke={theme === "dark" ? "#374151" : "#e5e7eb"}
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey="salesPeriod"
              stroke={theme === "dark" ? "#9CA3AF" : "#4B5563"}
            />
            <YAxis
              domain={[0, "dataMax"]}
              stroke={theme === "dark" ? "#9CA3AF" : "#4B5563"}
              tickFormatter={formatCurrency}
              yAxisId="left"
            />
            <YAxis
              domain={[0, 100]}
              orientation="right"
              stroke={theme === "dark" ? "#9CA3AF" : "#4B5563"}
              tickFormatter={formatPercentage}
              yAxisId="right"
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme === "dark" ? "#1F2937" : "#FFFFFF",
                borderColor: theme === "dark" ? "#374151" : "#E5E7EB",
                color: theme === "dark" ? "#F3F4F6" : "#1F2937",
              }}
              formatter={(value, name) => {
                if (name === "Total Sales")
                  return formatCurrency(value as number);
                if (name === "Sales %")
                  return formatPercentage(value as number);
                return value;
              }}
            />
            <Legend
              wrapperStyle={{
                color: theme === "dark" ? "#F3F4F6" : "#1F2937",
              }}
            />
            <Bar
              dataKey="totalSales"
              fill={theme === "dark" ? "#60A5FA" : "#2E8BC0"}
              name="Total Sales"
              yAxisId="left"
            />
            <Line
              dataKey="salesPercentage"
              dot={{ fill: theme === "dark" ? "#F97316" : "#FF7300", r: 4 }}
              name="Sales %"
              stroke={theme === "dark" ? "#F97316" : "#FF7300"}
              strokeWidth={2}
              type="monotone"
              yAxisId="right"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}

function MultiSelect({
  options,
  selected = [],
  onChange,
  placeholder,
}: MultiSelectProps) {
  const [open, setOpen] = useState(false);
  const safeSelected = Array.isArray(selected) ? selected : [];

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <Button
          aria-expanded={open}
          className="w-[200px] justify-between bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600"
          role="combobox"
          variant="outline"
        >
          {safeSelected.length > 0
            ? `${safeSelected.length} selected`
            : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-600 max-h-80 overflow-y-auto">
        <Command className="bg-transparent" shouldFilter={true}>
          <CommandInput
            className="text-gray-900 dark:text-gray-100"
            placeholder={`Search ${placeholder.toLowerCase()}...`}
          />
          <CommandEmpty className="text-gray-500 dark:text-gray-400">
            No {placeholder.toLowerCase()} found.
          </CommandEmpty>
          <CommandGroup>
            {options.map((option) => (
              <CommandItem
                className="text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700"
                key={option}
                onSelect={() => {
                  const newSelected = safeSelected.includes(option)
                    ? safeSelected.filter((item) => item !== option)
                    : [...safeSelected, option];
                  onChange(newSelected);
                }}
                value={option}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    safeSelected.includes(option) ? "opacity-100" : "opacity-0",
                  )}
                />
                {option}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
