import { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { MoreHorizontal, Edit, Copy, Trash2, Eye } from "lucide-react";
import { ApiEvent } from "@/api/fetchEvents/types";
import { DeleteConfirmationDialog } from "@/modules/eventRegistration/features/shared/components/DeleteConfirmationDialog";

interface EventManagementActionMenuProps {
  event: ApiEvent;
  onDelete: (eventId: number) => void;
  onDuplicate: (eventId: number) => void;
  onEdit: (eventId: number) => void;
  onShowSessions: (eventId: number) => void;
}

export const EventManagementActionMenu: React.FC<
  EventManagementActionMenuProps
> = ({ event, onDelete, onDuplicate, onEdit, onShowSessions }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="h-8 w-8 p-0 hover:bg-gray-200 transition-colors"
            variant="ghost"
          >
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px] p-2 bg-white">
          <DropdownMenuItem
            className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
            onClick={() => onEdit(event.id)}
          >
            <Edit className="mr-2 h-4 w-4" />
            <span>Edit</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
            onClick={() => onDuplicate(event.id)}
          >
            <Copy className="mr-2 h-4 w-4" />
            <span>Duplicate</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center px-3 py-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors"
            onClick={() => onShowSessions(event.id)}
          >
            <Eye className="mr-2 h-4 w-4" />
            <span>Show Sessions</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center px-3 py-2 text-sm cursor-pointer text-red-600 hover:bg-gray-100 transition-colors"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            <Trash2 className="mr-2 h-4 w-4" />
            <span>Delete</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteConfirmationDialog
        description={`Are you sure you want to delete the course "${event.eventName}"?`}
        isOpen={showDeleteConfirmation}
        onConfirm={() => onDelete(event.id)}
        onOpenChange={setShowDeleteConfirmation}
        title="Delete Course"
      />
    </>
  );
};
