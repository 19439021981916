import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/rootReducer";
import { selectAllEvents as selectAllEventsFromSlice } from "@/modules/eventRegistration/features/EventDirectory/slices/eventsSlice";
import { FilterOption } from "../utils/filterUtils";
import { ApiEvent } from "@/api/fetchEvents/types";

export const selectEventManagementState = (state: RootState) => state.events;

export const selectEventUIState = (state: RootState) => state.eventUI;

export const selectEventManagementUIState = (state: RootState) =>
  state.eventManagementUI;

// Individual UI state selectors
export const selectShowDeleteTypeDialog = (state: RootState) =>
  state.eventManagementUI.showDeleteTypeDialog;

export const selectShowAddEventType = (state: RootState) =>
  state.eventManagementUI.showAddEventType;

export const selectEditingEventType = (state: RootState) =>
  state.eventManagementUI.editingEventType;

export const selectShowAddEventInstructor = (state: RootState) =>
  state.eventManagementUI.showAddEventInstructor;

export const selectEditingEventInstructor = (state: RootState) =>
  state.eventManagementUI.editingEventInstructor;

export const selectShowDeleteInstructorDialog = (state: RootState) =>
  state.eventManagementUI.showDeleteInstructorDialog;

export const selectShowAddEventCategory = (state: RootState) =>
  state.eventManagementUI.showAddEventCategory;

export const selectEditingEventCategory = (state: RootState) =>
  state.eventManagementUI.editingEventCategory;

export const selectShowDeleteCategoryDialog = (state: RootState) =>
  state.eventManagementUI.showDeleteCategoryDialog;

export const selectIsSubmitting = (state: RootState) =>
  state.eventManagementUI.isSubmitting;

export const selectSubmitError = (state: RootState) =>
  state.eventManagementUI.submitError;

export const selectShowUnsavedChangesDialog = (state: RootState) =>
  state.eventManagementUI.showUnsavedChangesDialog;

export const selectShowResetDialog = (state: RootState) =>
  state.eventManagementUI.showResetDialog;

export const selectEventTypes = (state: RootState) =>
  state.eventManagementUI.eventTypes;

export const selectIsLoadingEventTypes = (state: RootState) =>
  state.eventManagementUI.isLoadingEventTypes;

export const selectEventInstructors = (state: RootState) =>
  state.eventManagementUI.eventInstructors;

export const selectIsLoadingEventInstructors = (state: RootState) =>
  state.eventManagementUI.isLoadingEventInstructors;

export const selectEventCategories = (state: RootState) =>
  state.eventManagementUI.eventCategories;

export const selectIsLoadingEventCategories = (state: RootState) =>
  state.eventManagementUI.isLoadingEventCategories;

export const selectSelectedFiles = (state: RootState) =>
  state.eventManagementUI.selectedFiles;

export const selectExistingFiles = (state: RootState) =>
  state.eventManagementUI.existingFiles;

export const selectShowEventDialog = (state: RootState) =>
  state.eventManagementUI.showEventDialog;

export const selectEventToEdit = (state: RootState) =>
  state.eventManagementUI.eventToEdit;

// Re-export the selectAllEvents selector from the eventsSlice

export const selectAllEvents = selectAllEventsFromSlice;

// Selectors for EventManagementTable

export const selectIsLoading = (state: RootState) =>
  state.eventManagementEvents.loading;

export const selectTotalCount = (state: RootState) => state.events.totalCount;

export const selectSelectedEventIds = (state: RootState) =>
  state.events.selectedIds;

export const selectSelectedEvents = createSelector(
  [selectAllEvents, selectSelectedEventIds],
  (events, selectedIds) =>
    events.filter((event) => selectedIds.includes(event.id)),
);

// Selectors for EventManagementFilters

export const selectFilters = (state: RootState) => ({
  categories: state.eventManagementUI.currentRequest.filters?.categories || [],
  status: state.eventManagementUI.currentRequest.filters?.status || [],
});

// Selector for selected filters

export const selectSelectedFilters = createSelector(
  [(state: RootState) => state.eventManagementUI.currentRequest],
  (currentRequest) => {
    const selectedFilters: Record<string, string[]> = {
      category: [],
      status: [],
    };

    // Add category filter if selected
    if (currentRequest.eventCategoryId) {
      selectedFilters.category.push(currentRequest.eventCategoryId.toString());
    }

    // Add status filter if selected
    if (
      currentRequest.isPublished !== null &&
      currentRequest.isPublished !== undefined
    ) {
      selectedFilters.status.push(
        currentRequest.isPublished ? "Published" : "Draft",
      );
    }

    return selectedFilters;
  },
);

// Selector for current request state

export const selectEventManagementCurrentRequest = (state: RootState) =>
  state.eventManagementUI.currentRequest;

// Comprehensive selector for dialog state that combines all dialog-related state
// This selector combines data from multiple slices, so createSelector is appropriate here

export const selectEventDialogState = createSelector(
  [
    // Dialog visibility and event to edit from eventManagementUI slice
    (state: RootState) => state.eventManagementUI.showEventDialog,
    (state: RootState) => state.eventManagementUI.eventToEdit,
    // Form submission state from eventManagementUI slice
    (state: RootState) => state.eventManagementUI.isSubmitting,
    (state: RootState) => state.eventManagementUI.submitError,
    // Event types, instructors, and categories from eventManagementUI slice
    (state: RootState) => state.eventManagementUI.eventTypes,
    (state: RootState) => state.eventManagementUI.eventInstructors,
    (state: RootState) => state.eventManagementUI.eventCategories,
    // Loading states from eventManagementUI slice
    (state: RootState) => state.eventManagementUI.isLoadingEventTypes,
    (state: RootState) => state.eventManagementUI.isLoadingEventInstructors,
    (state: RootState) => state.eventManagementUI.isLoadingEventCategories,
    // Files from eventManagementUI slice
    (state: RootState) => state.eventManagementUI.selectedFiles,
    (state: RootState) => state.eventManagementUI.existingFiles,
  ],
  (
    showEventDialog,
    eventToEdit,
    isSubmitting,
    submitError,
    eventTypes,
    eventInstructors,
    eventCategories,
    isLoadingEventTypes,
    isLoadingEventInstructors,
    isLoadingEventCategories,
    selectedFiles,
    existingFiles,
  ) => {
    // Using a stable reference for the returned object
    return {
      showEventDialog,
      eventToEdit,
      isSubmitting,
      submitError,
      eventTypes,
      eventInstructors,
      eventCategories,
      isLoadingEventTypes,
      isLoadingEventInstructors,
      isLoadingEventCategories,
      selectedFiles,
      existingFiles,
    };
  },
);

// Selector for dialog alerts state

export const selectDialogAlertsState = createSelector(
  [
    selectShowUnsavedChangesDialog,
    selectShowResetDialog,
    selectShowDeleteTypeDialog,
    selectShowDeleteInstructorDialog,
    selectShowDeleteCategoryDialog,
  ],
  (
    showUnsavedChangesDialog,
    showResetDialog,
    showDeleteTypeDialog,
    showDeleteInstructorDialog,
    showDeleteCategoryDialog,
  ) => {
    // Using a stable reference for the returned object
    return {
      showUnsavedChangesDialog,
      showResetDialog,
      showDeleteTypeDialog,
      showDeleteInstructorDialog,
      showDeleteCategoryDialog,
    };
  },
);

// Selector for events with loading state

export const selectEventsWithLoadingState = createSelector(
  [
    selectAllEvents,
    selectIsLoading,
    (state: RootState) => state.events.error,
    selectTotalCount,
  ],
  (events, isLoadingEvents, eventsError, totalCount) => ({
    events,
    isLoadingEvents,
    eventsError,
    totalCount,
  }),
);

// Helper function to get filter options from events

const getFilterOptions = (
  events: ApiEvent[],
  filterType: keyof Pick<ApiEvent, "eventCategory" | "eventType">,
): FilterOption[] => {
  const uniqueValues = new Set<string>();

  events.forEach((event) => {
    if (filterType === "eventCategory" && event.eventCategory) {
      uniqueValues.add(event.eventCategory);
    } else if (filterType === "eventType" && event.eventType) {
      uniqueValues.add(event.eventType);
    }
  });

  return Array.from(uniqueValues)
    .sort()
    .map((value) => ({
      label: value,
      value,
    }));
};

// Selector for filter options

export const selectFilterOptions = createSelector(
  [selectAllEvents],
  (events) => ({
    categories: getFilterOptions(events, "eventCategory"),
    status: getFilterOptions(events, "eventType"),
  }),
);

// Selector for event files

export const selectEventFiles = createSelector(
  [
    (state: RootState) => state.eventManagementUI.selectedFiles,
    (state: RootState) => state.eventManagementUI.existingFiles,
  ],
  (selectedFiles, existingFiles) => {
    // Using a stable reference for the returned object
    return {
      selectedFiles,
      existingFiles,
    };
  },
);
