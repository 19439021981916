import { ApiEvent } from "@/api/fetchEvents/types";

export interface EventDescriptionProps {
  event: ApiEvent;
}

export const EventDescription: React.FC<EventDescriptionProps> = ({
  event,
}) => (
  <div>
    <h2 className="text-xl font-semibold mb-2">Description</h2>
    <p className="text-gray-600">{event.eventDescription}</p>
  </div>
);
