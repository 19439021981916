export interface BreadcrumbInfo {
  name: string;
  clickable: boolean;
}

export type BreadcrumbNameMap = {
  [key: string]: BreadcrumbInfo;
};

export const breadcrumbNameMap: BreadcrumbNameMap = {
  "/hub": { name: "Hub", clickable: true },
  "/hub/courses": { name: "Courses", clickable: true },
  "/hub/users": { name: "Users", clickable: true },
  "/hub/document-library": { name: "Document Library", clickable: false },
  "/event-registration": { name: "Event Registration", clickable: true },
  "/event-registration/events": { name: "Events", clickable: true },
};
