import { CampaignProduct } from "@/api/fetchCampaignProducts/types";
import { Column } from "@/components/Datatable/Datatable";

export function createStochasticCampaignProductsColumns(): Column<CampaignProduct>[] {
  return [
    {
      header: "Name",
      accessorKey: "name",
      cell: ({ row }) => (
        <div className="whitespace-normal">{row.original.name}</div>
      ),
    },
    {
      header: "Description",
      accessorKey: "description",
      cell: ({ row }) => (
        <div className="whitespace-normal">{row.original.description}</div>
      ),
    },
    {
      header: "Prospect Price",
      accessorKey: "prospectPrice",
      cell: ({ row }) => row.original.prospectPrice || "",
    },
    {
      header: "Customer Price",
      accessorKey: "customerPrice",
      cell: ({ row }) => row.original.customerPrice || "",
    },
    {
      header: "Status",
      accessorKey: "isActive",
      cell: () => (
        <span
          className={
            "px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-gray-800"
          }
        >
          Active
        </span>
      ),
    },
    {
      header: "Actions",
      id: "actions",
    },
  ];
}
