import {
  ERPagination,
  ERPaginationProps,
} from "@/modules/eventRegistration/features/shared/components/ERPagination";

export const SessionPagination: React.FC<ERPaginationProps> = (props) => (
  <ERPagination
    {...props}
    itemName="sessions"
    pageSizeOptions={[5, 10, 15, 20]}
  />
);
