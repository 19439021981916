import * as z from "zod";

// Base schema for event fields
const eventBaseSchema = {
  eventName: z
    .string()
    .min(1, "Event name is required")
    .max(100, "Event name cannot exceed 100 characters"),
  eventCode: z
    .string()
    .min(1, "Event code is required")
    .max(20, "Event code cannot exceed 20 characters")
    .regex(
      /^[A-Za-z0-9-]+$/,
      "Event code can only contain letters, numbers, and hyphens",
    ),
  eventDescription: z
    .string()
    .min(1, "Description is required")
    .max(1000, "Description cannot exceed 1000 characters"),
  eventPrice: z.coerce
    .number({ invalid_type_error: "Price must be a valid number" })
    .min(0, "Price must be greater than or equal to 0")
    .max(99999.99, "Price cannot exceed 99,999.99")
    .transform((val) => Number(val.toFixed(2))),
  eventTypeId: z
    .number()
    .nullable()
    .transform((val) => {
      if (typeof val === "string") {
        return Number(val);
      }
      if (typeof val === "number") {
        return val;
      }
      return null;
    }),
  eventInstructorId: z
    .number()
    .nullable()
    .transform((val) => {
      if (typeof val === "string") {
        return Number(val);
      }
      if (typeof val === "number") {
        return val;
      }
      return null;
    }),
  eventCategoryId: z
    .number()
    .nullable()
    .transform((val) => {
      if (typeof val === "string") {
        return Number(val);
      }
      if (typeof val === "number") {
        return val;
      }
      return null;
    }),
  isPublished: z.boolean().optional().default(false),
  imageUrl: z.string().nullable().optional(),
} as const;

// Schema for creating a new event
export const eventSchema = z.object(eventBaseSchema);

// Schema for editing an existing event
export const eventEditSchema = z
  .object({
    ...eventBaseSchema,
  })
  .strict(); // Ensures no extra fields

export const inputTypeSchema = z.enum([
  "text",
  "number",
  "textarea",
  "checkbox",
  "datetime-local",
]);

export const eventTypeSchema = z.object({
  name: z.string().min(1, "Name is required"),
  description: z.string().nullable(),
  isActive: z.boolean(),
  id: z.number().optional(),
});

export type EventTypeFormData = z.infer<typeof eventTypeSchema>;

export const eventInstructorSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Invalid email address"),
  isActive: z.boolean(),
  id: z.number().optional(),
});

export type EventInstructorFormData = z.infer<typeof eventInstructorSchema>;

export const eventCategorySchema = z.object({
  name: z.string().min(1, "Name is required"),
  description: z.string().nullable(),
  isActive: z.boolean(),
  id: z.number().optional(),
});

export type EventCategoryFormData = z.infer<typeof eventCategorySchema>;

// Type for form data
export type EventFormData = {
  id?: number;
  eventName: string;
  eventCode: string;
  eventDescription: string;
  eventPrice: number;
  eventTypeId: number | null;
  eventInstructorId: number | null;
  eventCategoryId: number | null;
  companyUuid?: string;
  companyId?: string | number;
  hideFromCalendar?: boolean;
  hideFromCatalog?: boolean;
  isPublished?: boolean;
  imageUrl?: string | null;
};

// Type for validated event data
export type ValidatedEventData = Readonly<EventFormData>;

// Type for form field names
export type EventFormFields = keyof EventFormData;

// Type for form errors
export type EventFormErrors = Partial<Record<EventFormFields, string>>;
