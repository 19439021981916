import React from "react";

/**
 * A column definition for a generic table row of type T.
 * - accessorKey: property name in T
 * - header: label or function that returns a React node
 * - cell: if you need a custom cell renderer (like a menu)
 */
export interface Column<T> {
  id?: string;
  accessorKey?: keyof T | string;
  header?: string | (() => React.ReactNode);
  cell?: (props: { row: { original: T } }) => React.ReactNode;
}

interface DataTableHeaderProps<T> {
  columns: Column<T>[];
}

/**
 * Renders the <thead> and a single <tr> with <th> for each column.
 */
function DataTableHeader<T>({ columns }: DataTableHeaderProps<T>) {
  return (
    <thead className="[&_tr]:border-b">
      <tr className="bg-gray-50/75">
        {columns.map((column) => {
          // Convert any symbol or undefined to a string
          const rawHeaderKey = column.id ?? column.accessorKey;
          const safeHeaderKey = String(rawHeaderKey);

          // Determine the "content" for the header
          let rawContent: unknown;
          if (typeof column.header === "function") {
            rawContent = column.header();
          } else if (typeof column.header !== "undefined") {
            rawContent = column.header;
          } else {
            // Fallback to showing the accessorKey itself
            rawContent = column.accessorKey ?? "";
          }

          // If rawContent is a symbol, convert it to a string
          if (typeof rawContent === "symbol") {
            rawContent = rawContent.toString();
          }

          // Now we can safely treat it as a ReactNode
          const content = rawContent as React.ReactNode;

          return (
            <th
              className="h-11 px-4 text-left align-middle font-semibold text-muted-foreground"
              key={safeHeaderKey}
            >
              {content}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default DataTableHeader;
