import { combineReducers } from "@reduxjs/toolkit";
import stochasticCustomersReducer from "../modules/stochastic/features/CustomerList/slices/stochasticCustomersSlice";
import stochasticProspectsReducer from "../modules/stochastic/features/ProspectList/slices/stochasticProspectsSlice";
import stochasticCampaignProductsReducer from "../modules/stochastic/features/CampaignProductList/slices/stochasticCampaignProductsSlice";
import userAppSettingsReducer from "../modules/hub/features/UserAppSettings/slices/userAppSettingsSlice";
import quickBooksReportsReducer from "../modules/hub/features/DocumentLibrary/slices/quickBooksReportsSlice";
import usersReducer from "../modules/hub/features/UserManagement/slices/usersSlice";
import companiesReducer from "../modules/hub/features/CompanyManagement/slices/companiesSlice";
import editUserDetailsReducer from "../modules/hub/features/UserManagement/slices/editUserDetailsSlice";
import companyProfileReducer from "../modules/hub/features/UserAppSettings/slices/companyProfileSlice";
import userProfileReducer from "../modules/hub/features/UserAppSettings/slices/userProfileSlice";
import campaignListReducer from "../modules/stochastic/features/CampaignManagement/slices/campaignListSlice";
import campaignReducer from "../modules/stochastic/features/CampaignManagement/slices/campaignSlice";
import campaignBatchListReducer from "../modules/stochastic/features/CampaignBatchManagement/slices/campaignBatchListSlice";
import batchProspectReducer from "../modules/stochastic/features/BatchProspectManagement/slices/batchProspectSlice";
import campaignFilesReducer from "../modules/stochastic/features/CampaignFileManagement/slices/campaignFilesSlice";
import createCampaignReducer from "../modules/stochastic/features/CampaignManagement/slices/createCampaignSlice";
import editRolesAndPermissionsReducer from "../modules/hub/features/UserManagement/slices/editRolesAndPermissionsSlice";
import eventsReducer from "../modules/eventRegistration/features/EventDirectory/slices/eventsSlice";
import eventUIReducer from "../modules/eventRegistration/features/EventDirectory/slices/eventUISlice";
import sessionsReducer from "../modules/eventRegistration/features/SessionManagement/slices/sessionsSlice";
import stochasticMailingReducer from "../modules/stochastic/features/StochasticMailing/slices/stochasticMailingSlice";
import favoritesReducer from "../modules/eventRegistration/features/EventDirectory/slices/favoritesSlice";
import fetchRestrictedAddressesReducer from "@/modules/stochastic/features/DoNotMailManagement/slices/fetchRestrictedAddressesSlice";
import createRestrictedAddressReducer from "@/modules/stochastic/features/DoNotMailManagement/slices/createRestrictedAddressSlice";
import updateRestrictedAddressReducer from "@/modules/stochastic/features/DoNotMailManagement/slices/updateRestrictedAddressSlice";
import deleteRestrictedAddressReducer from "@/modules/stochastic/features/DoNotMailManagement/slices/deleteRestrictedAddressSlice";
import fetchSingleRestrictedAddressReducer from "@/modules/stochastic/features/DoNotMailManagement/slices/fetchSingleRestrictedAddressSlice";
import sessionUIReducer from "../modules/eventRegistration/features/SessionManagement/slices/sessionUISlice";
import resourceLibraryMetadataReducer from "@/modules/hub/features/ResourceLibrary/slices/resourceLibraryMetadataSlice";
import createUpdateResourceReducer from "@/modules/hub/features/ResourceLibrary/slices/createUpdateResourceSlice";
import resourceCategoryReducer from "@/modules/hub/features/ResourceLibrary/slices/resourceCategorySlice";
import resourceTagReducer from "@/modules/hub/features/ResourceLibrary/slices/resourceTagSlice";
import bulkBatchStatusesDetailsMetadataReducer from "../modules/stochastic/features/StochasticMailing/slices/bulkBatchStatusDetailsMetadataSlice";
import resourceListReducer from "@/modules/hub/features/ResourceLibrary/slices/resourceListSlice";
import eventManagementUIReducer from "../modules/eventRegistration/features/EventManagement/slices/eventManagementUISlice";
import eventManagementEventsReducer from "../modules/eventRegistration/features/EventManagement/slices/eventManagementEventsSlice";

const rootReducer = combineReducers({
  stochasticCustomers: stochasticCustomersReducer,
  stochasticProspects: stochasticProspectsReducer,
  stochasticCampaignProducts: stochasticCampaignProductsReducer,
  userAppSettings: userAppSettingsReducer,
  quickBooksReports: quickBooksReportsReducer,
  users: usersReducer,
  editUserDetails: editUserDetailsReducer,
  companies: companiesReducer,
  companyProfile: companyProfileReducer,
  userProfile: userProfileReducer,
  campaignList: campaignListReducer,
  campaign: campaignReducer,
  campaignBatchList: campaignBatchListReducer,
  batchProspect: batchProspectReducer,
  campaignFiles: campaignFilesReducer,
  createCampaign: createCampaignReducer,
  editRolesAndPermissions: editRolesAndPermissionsReducer,
  events: eventsReducer,
  eventUI: eventUIReducer,
  eventManagementUI: eventManagementUIReducer,
  eventManagementEvents: eventManagementEventsReducer,
  sessions: sessionsReducer,
  stochasticMailing: stochasticMailingReducer,
  favorites: favoritesReducer,
  sessionUI: sessionUIReducer,
  fetchRestrictedAddresses: fetchRestrictedAddressesReducer,
  createRestrictedAddress: createRestrictedAddressReducer,
  updateRestrictedAddress: updateRestrictedAddressReducer,
  deleteRestrictedAddress: deleteRestrictedAddressReducer,
  fetchSingleRestrictedAddress: fetchSingleRestrictedAddressReducer,
  resourceLibraryMetadata: resourceLibraryMetadataReducer,
  createUpdateResource: createUpdateResourceReducer,
  resourceCategory: resourceCategoryReducer,
  resourceTag: resourceTagReducer,
  bulkBatchStatusDetailsMetadata: bulkBatchStatusesDetailsMetadataReducer,
  resourceList: resourceListReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
