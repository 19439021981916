import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { SessionFormData } from "../schemas/sessionSchema";

interface UseFormResetProps {
  form: UseFormReturn<SessionFormData>;
  open: boolean;
  initialData?: SessionFormData;
  mode: "create" | "edit";
  defaultValues: SessionFormData;
}

/**
 * Hook to handle form reset logic when dialog is opened or closed
 * Resets the form to default values when dialog is closed
 * or when opened for creation without initial data
 */
export const useFormReset = ({
  form,
  open,
  initialData,
  mode,
  defaultValues,
}: UseFormResetProps): void => {
  useEffect(() => {
    if (!open) {
      // Reset form when dialog is closed
      form.reset(defaultValues, { keepDefaultValues: true });
    } else if (open && !initialData && mode === "create") {
      // Reset form when dialog is opened for creation
      form.reset(defaultValues, { keepDefaultValues: true });
    }
  }, [open, initialData, mode, form, defaultValues]);
};
