import { useEffect, useState, useCallback, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useToast } from "@/components/ui/use-toast";
import {
  ResourceFormData,
  resourceFormSchema,
} from "@/modules/hub/features/ResourceLibrary/hooks/useCreateResource/types";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { getCreateUpdateResourceMetadataAction } from "@/modules/hub/features/ResourceLibrary/slices/resourceLibraryMetadataSlice";
import { createResourceAction } from "@/modules/hub/features/ResourceLibrary/slices/createUpdateResourceSlice";
import { useNavigate } from "react-router-dom";
import { useNotification } from "@/context/NotificationContext";

export function useCreateResource() {
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotification();

  const {
    resourceTags,
    resourceCategories,
    employeeRoles,
    trades,
    resourceTypes,
    loading: metadataLoading,
    error: metadataError,
  } = useAppSelector((state) => state.resourceLibraryMetadata);

  useEffect(() => {
    dispatch(getCreateUpdateResourceMetadataAction());
  }, [dispatch]);

  // Default form values
  const defaultValues: ResourceFormData = {
    title: "",
    tagline: "",
    description: "",
    type: null,
    content_url: "",
    thumbnail_url: "",
    thumbnailFileId: null,
    publish_start_date: null,
    publish_end_date: null,
    is_published: false,
    tags: [], // <-- array of objects { id, name }
    categories: [], // <-- array of objects { id, name }
    tradeIds: [],
    roleIds: [],
    contentBlocks: [],
  };

  const form = useForm<ResourceFormData>({
    resolver: zodResolver(resourceFormSchema),
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    const defaultType = resourceTypes.find((rt) => rt.isDefault === true);
    if (defaultType) {
      const currentType = form.getValues("type");
      if (currentType === null) {
        form.setValue("type", defaultType.id, { shouldDirty: false });
      }
    }
  }, [resourceTypes, form]);

  const resourceTypeId = form.watch("type");

  const selectedResourceType = useMemo(() => {
    if (!resourceTypeId) return null;
    return resourceTypes.find((rt) => rt.id === resourceTypeId) || null;
  }, [resourceTypeId, resourceTypes]);

  const requiresContentUrl = selectedResourceType?.requiresContentUrl || false;

  const submitForm = useCallback(
    async (values: ResourceFormData) => {
      try {
        setIsLoading(true);

        const numericType = values.type ?? 0;

        const filteredBlocks =
          values.contentBlocks?.filter(
            (b) => b.type === "text" || b.type === "image",
          ) || [];

        const publishStart = values.publish_start_date
          ? values.publish_start_date.toISOString()
          : null;

        const publishEnd = values.publish_end_date
          ? values.publish_end_date.toISOString()
          : null;

        const finalBlocks = filteredBlocks.map((b) => ({
          ...b,
          order_number: b.order_number ?? 0,
        }));

        // Transform tags/categories from array of objects to array of IDs
        const tagIds = (values.tags || []).map((t) => t.id);
        const categoryIds = (values.categories || []).map((c) => c.id);

        const finalData = {
          title: values.title,
          tagline: values.tagline || null,
          description: values.description,
          type: numericType,
          content_url: values.content_url || null,
          thumbnail_url: values.thumbnail_url || null,
          thumbnailFileId: values.thumbnailFileId ?? null,
          publish_start_date: publishStart,
          publish_end_date: publishEnd,
          is_published: values.is_published ?? false,
          tagIds, // server expects array of IDs
          categoryIds, // server expects array of IDs
          tradeIds: values.tradeIds || [],
          roleIds: values.roleIds || [],
          contentBlocks: finalBlocks,
        };

        await dispatch(
          createResourceAction(finalData, () => {
            showNotification(
              "Success!",
              "Resource has been successfully created!",
              "success",
            );
            navigate(`/admin/resources`);
          }),
        );
      } catch (error) {
        console.error("Error saving resource:", error);
        if (error instanceof Error) {
          toast({
            title: "Error",
            description: error.message || "Failed to save",
            variant: "destructive",
          });
        } else {
          toast({
            title: "Error",
            description: "An unknown error occurred while saving.",
            variant: "destructive",
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, toast, navigate, showNotification],
  );

  const handleCancel = useCallback(() => {
    navigate(`/admin/resources`);
  }, []);

  return {
    form,
    isLoading,
    tags: resourceTags,
    trades,
    categories: resourceCategories,
    roles: employeeRoles,
    resourceTypes,
    metadataLoading,
    metadataError,
    resourceTypeId,
    selectedResourceType,
    requiresContentUrl,
    submitForm,
    handleCancel,
  };
}
