import React from "react";
import { AlertDialogComponent } from "../../shared/components/AlertDialogComponent";

interface DeleteConfirmationDialogProps {
  cancelText?: string;
  confirmText?: string;
  description?: string;
  isOpen: boolean;
  onConfirm: () => void;
  onOpenChange: (open: boolean) => void;
  title?: string;
}

export const DeleteConfirmationDialog: React.FC<
  DeleteConfirmationDialogProps
> = ({
  cancelText = "Cancel",
  confirmText = "Delete",
  description = "Are you sure you want to delete this course?",
  isOpen,
  onConfirm,
  onOpenChange,
  title = "Delete Confirmation",
}) => {
  return (
    <AlertDialogComponent
      cancelText={cancelText}
      confirmText={confirmText}
      description={description}
      isDestructive={true}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onOpenChange={onOpenChange}
      title={title}
    />
  );
};
