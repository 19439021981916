import { EntityModal } from "@/modules/eventRegistration/features/shared/components/EntityModal";
import { useEditEventInstructorForm } from "../../hooks/useEditEventInstructorForm";
import { EventInstructorFormData } from "../../schemas/eventSchema";
import { instructorFields } from "../../constants/eventManagementConstants";
import { ApiEventInstructor } from "@/api/fetchEventInstructors/types";

export interface EditEventInstructorDialogProps {
  eventInstructor: ApiEventInstructor | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
}

export function EventInstructorDialog({
  eventInstructor,
  open,
  onOpenChange,
  onSuccess,
}: EditEventInstructorDialogProps): JSX.Element {
  const { form, handleSubmit, isSubmitting } =
    useEditEventInstructorForm(eventInstructor);

  return (
    <EntityModal<EventInstructorFormData>
      entity={eventInstructor ?? undefined}
      fields={instructorFields}
      form={form}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      onOpenChange={onOpenChange}
      onSuccess={onSuccess}
      open={open}
      title="Event Instructor"
    />
  );
}
