import { useMemo } from "react";
import { FILTER_SECTIONS } from "../constants/sessionManagementConstants";
import { FilterType } from "../utils/typeGuards";
import { FilterSection } from "../components/SessionManagementFilters/SessionManagementFilters";

/**
 * Hook that returns the filter sections for the session management filters
 * @returns An array of filter sections
 */
export const useFilterSections = (): FilterSection[] => {
  return useMemo(
    () => [
      {
        ...FILTER_SECTIONS.STATUS,
        type: "status" as FilterType,
      },
    ],
    [],
  );
};
