import {
  UsersIcon,
  EyeIcon,
  ChartBarIcon,
  MegaphoneIcon,
  PlusIcon,
  ArrowUpTrayIcon,
  EnvelopeIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import { NavigationItem } from "./types";

const navigation: NavigationItem[] = [
  {
    name: "Dashboard",
    href: "/stochastic/dashboard",
    icon: ChartBarIcon,
    current: false,
    internalName: "dashboard",
    permissions: [],
  },
  {
    name: "Prospects",
    href: "/stochastic/prospects",
    icon: EyeIcon,
    current: false,
    internalName: "prospects",
    permissions: ["CAN_MANAGE_PROSPECTS"],
  },
  {
    name: "Customers",
    href: "/stochastic/customers",
    icon: UsersIcon,
    current: false,
    internalName: "customers",
    permissions: ["CAN_MANAGE_CUSTOMERS"],
  },
  {
    name: "Campaign Management",
    href: "/stochastic/campaigns",
    icon: MegaphoneIcon,
    current: false,
    internalName: "campaigns",
    permissions: ["CAN_MANAGE_CAMPAIGNS"],
  },
  {
    name: "Uploads",
    href: "#",
    icon: ArrowUpTrayIcon,
    current: false,
    internalName: "dashboards",
    permissions: [],
    roles: ["ROLE_SUPER_ADMIN", "ROLE_MARKETING"],
    children: [
      {
        name: "Field Service Import",
        href: "/stochastic/field-service-import",
        icon: PlusIcon,
        current: false,
        internalName: "field_service_import",
        permissions: [],
        roles: ["ROLE_SUPER_ADMIN", "ROLE_MARKETING"],
      },
      {
        name: "Prospect Source Import",
        href: "/stochastic/prospect-source-import",
        icon: PlusIcon,
        current: false,
        internalName: "prospect_source_import",
        permissions: [],
        roles: ["ROLE_SUPER_ADMIN", "ROLE_MARKETING"],
      },
      {
        name: "Import Status",
        href: "/stochastic/import-status",
        icon: PlusIcon,
        current: false,
        internalName: "import_status",
        permissions: [],
        roles: ["ROLE_SUPER_ADMIN", "ROLE_MARKETING"],
      },
    ],
  },
  {
    name: "Admin",
    href: "#",
    icon: Cog6ToothIcon,
    current: false,
    internalName: "admin",
    permissions: [],
    roles: ["ROLE_SUPER_ADMIN", "ROLE_MARKETING"],
    isCertainPathOnly: true,
    children: [
      {
        name: "Campaign Status",
        href: "/stochastic/mailing",
        icon: EnvelopeIcon,
        current: false,
        internalName: "stochastic_mailing",
        permissions: ["CAN_VIEW_STOCHASTIC_MAILING"],
      },
      {
        name: "Do Not Mail",
        href: "/stochastic/do-not-mail",
        icon: EyeIcon,
        current: false,
        internalName: "doNotMail",
        permissions: [],
        roles: ["ROLE_SUPER_ADMIN", "ROLE_MARKETING"],
      },
      {
        name: "Manage Products",
        href: "/stochastic/campaign-products",
        icon: EyeIcon,
        current: false,
        internalName: "campaignProducts",
        permissions: [],
        roles: ["ROLE_SUPER_ADMIN"],
      },
    ],
  },
];

const stochasticConfig = {
  sectionName: "stochastic",
  defaultRoute: "/stochastic/dashboard",
  navigation,
};

export default stochasticConfig;
