import { useState, useCallback } from "react";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

interface UseEventFormFieldHandlersProps<T extends FieldValues> {
  field: ControllerRenderProps<T, Path<T>>;
  disabled?: boolean;
  readonly?: boolean;
}

export const useEventFormFieldHandlers = <T extends FieldValues>({
  field,
  disabled = false,
  readonly = false,
}: UseEventFormFieldHandlersProps<T>) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback(() => {
    if (!disabled && !readonly) {
      setIsFocused(true);
    }
  }, [disabled, readonly]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    field.onBlur();
  }, [field]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!disabled && !readonly) {
        field.onChange(e);
      }
    },
    [disabled, readonly, field],
  );

  return {
    isFocused,
    handleFocus,
    handleBlur,
    handleChange,
  };
};
