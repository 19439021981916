import { useCallback } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { useToast } from "@/hooks/useToast";

interface UseEntitySubmitProps<T extends FieldValues> {
  entity?: T;
  title: string;
  form: UseFormReturn<T>;
  handleSubmit: (data: T) => Promise<boolean>;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
}

export const useEntitySubmit = <T extends FieldValues>({
  entity,
  title,
  form,
  handleSubmit,
  onOpenChange,
  onSuccess,
}: UseEntitySubmitProps<T>) => {
  const { toast } = useToast();

  const onSubmit = useCallback(
    async (data: T) => {
      try {
        const success = await handleSubmit(data);
        if (success) {
          form.reset();
          toast({
            title: "Success",
            description: `${title} ${entity ? "updated" : "created"} successfully`,
          });
          onOpenChange(false);
          onSuccess();
        }
      } catch (error: unknown) {
        toast({
          variant: "destructive",
          title: "Error",
          description:
            error instanceof Error
              ? error.message
              : "An unexpected error occurred",
        });
      }
    },
    [entity, handleSubmit, onOpenChange, onSuccess, toast, form, title],
  );

  return { onSubmit };
};
