import { Button } from "@/components/ui/button";
import { Card, CardFooter, CardHeader } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Star } from "lucide-react";
import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ApiEvent } from "@/api/fetchEvents/types";
import { useEventDirectoryActions } from "../../hooks/useEventDirectoryActions";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { truncateText } from "../../utils/textUtils";

interface EventDirectoryCardProps {
  event: ApiEvent;
  isFavorited: boolean;
  onFavoriteToggle: (eventId: number) => void;
}

const EventDirectoryCard: React.FC<EventDirectoryCardProps> = ({
  event,
  isFavorited,
  onFavoriteToggle,
}) => {
  const [imageError, setImageError] = useState(false);
  const { handleImageError, handleFavoriteClick } = useEventDirectoryActions({
    page: 1,
    pageSize: 10,
  });

  // Truncate description to 150 characters
  const truncatedDescription = truncateText(event.eventDescription, 150);
  const isDescriptionTruncated = event.eventDescription.length > 150;

  return (
    <Card className="flex flex-col group shadow-md hover:shadow-lg transition-all duration-200 hover:-translate-y-1">
      <div className="aspect-video relative overflow-hidden rounded-t-lg">
        <img
          alt={event.eventName}
          className="object-cover w-full h-full transform transition-transform duration-200 group-hover:scale-105"
          loading="lazy"
          onError={handleImageError(setImageError)}
          src={
            imageError || !event.imageUrl
              ? "/placeholder-event.jpg"
              : event.imageUrl
          }
        />
        <Button
          className={clsx(
            "absolute top-2 right-2 h-8 w-8 bg-white/80 hover:bg-white/90 transition-colors",
            isFavorited && "text-yellow-500",
          )}
          onClick={handleFavoriteClick(event.id, onFavoriteToggle)}
          size="icon"
          variant="ghost"
        >
          <Star
            className="h-4 w-4"
            fill={isFavorited ? "currentColor" : "none"}
          />
        </Button>
      </div>
      <CardHeader>
        <div className="flex items-center gap-2">
          {event.eventCategory && (
            <Badge className="text-white font-medium bg-primary/90 hover:bg-primary">
              {event.eventCategory}
            </Badge>
          )}
          {event.eventType && (
            <Badge className="text-white font-medium bg-primary/90 hover:bg-primary">
              {event.eventType}
            </Badge>
          )}
        </div>
        <h3 className="font-semibold text-lg mt-2">{event.eventName}</h3>
        {isDescriptionTruncated ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <p className="text-muted-foreground text-sm cursor-help hover:text-primary transition-colors">
                  {truncatedDescription}
                </p>
              </TooltipTrigger>
              <TooltipContent
                className="max-w-md p-4 text-sm text-white bg-primary border-primary shadow-lg rounded-md"
                side="top"
                sideOffset={5}
              >
                <div className="space-y-2">
                  <h4 className="font-medium">Full Description</h4>
                  <p className="leading-relaxed">{event.eventDescription}</p>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <p className="text-muted-foreground text-sm">
            {truncatedDescription}
          </p>
        )}
      </CardHeader>
      <CardFooter className="mt-auto">
        <div className="flex flex-wrap items-center justify-between w-full gap-3">
          <div className="flex items-center">
            <span className="font-semibold">${event.eventPrice}</span>
          </div>
          <Link
            className="flex-shrink-0"
            to={`/event-registration/events/${event.id}`}
          >
            <Button className="text-white whitespace-nowrap">
              Event Details
            </Button>
          </Link>
        </div>
      </CardFooter>
    </Card>
  );
};

export default EventDirectoryCard;
