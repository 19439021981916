import React, { useMemo } from "react";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import { useCampaignProducts } from "../../hooks/useStochasticCampaignProducts";
import { createStochasticCampaignProductsColumns } from "../CampaignProductColumns/CampaignProductsColumns";
import CampaignProductListFilters from "../CampaignProductListFilters/CampaignProductListFilters";
import { CampaignProduct } from "@/api/fetchCampaignProducts/types";

const CampaignProductList: React.FC = () => {
  const {
    campaignProducts,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    filters,
    handlePaginationChange,
    handleFilterChange,
  } = useCampaignProducts();

  const columns = useMemo(() => createStochasticCampaignProductsColumns(), []);

  const sortedData = useMemo(() => {
    if (sorting.length === 0) return campaignProducts;

    const { id, desc } = sorting[0];

    return [...campaignProducts].sort((a, b) => {
      const aValue = a[id as keyof typeof a];
      const bValue = b[id as keyof typeof b];

      if (aValue == null && bValue == null) return 0;
      if (aValue == null) return desc ? -1 : 1;
      if (bValue == null) return desc ? 1 : -1;

      if (typeof aValue === "string" && typeof bValue === "string") {
        return desc
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return desc ? bValue - aValue : aValue - bValue;
      }

      if (typeof aValue === "boolean" && typeof bValue === "boolean") {
        if (aValue === bValue) return 0;
        return desc ? (aValue ? -1 : 1) : aValue ? 1 : -1;
      }

      return 0;
    });
  }, [campaignProducts, sorting]);

  return (
    <MainPageWrapper error={error} loading={loading} title="Campaign Products">
      <CampaignProductListFilters
        filters={filters}
        onFilterChange={handleFilterChange}
      />

      <div className="relative">
        <DataTable<CampaignProduct>
          columns={columns}
          data={sortedData}
          error={error}
          loading={loading}
          noDataMessage="No prospects found"
          onPageChange={(newPageIndex, newPageSize) =>
            handlePaginationChange({
              pageIndex: newPageIndex,
              pageSize: newPageSize,
            })
          }
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          rowKeyExtractor={(item) => item.id}
          totalCount={totalCount}
        />
      </div>
    </MainPageWrapper>
  );
};

export default CampaignProductList;
