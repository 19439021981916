import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  StochasticCustomer,
  FetchStochasticCustomersRequest,
  FetchStochasticCustomersResponse,
} from "../../../../../api/fetchStochasticCustomers/types";
import { AppDispatch, AppThunk } from "../../../../../app/store";
import { fetchStochasticCustomers } from "../../../../../api/fetchStochasticCustomers/fetchStochasticCustomersApi";

interface StochasticCustomersState {
  customers: StochasticCustomer[];
  totalCount: number;
  loading: boolean;
  error: string | null;
}

const initialState: StochasticCustomersState = {
  customers: [],
  totalCount: 0,
  loading: false,
  error: null,
};

const stochasticCustomersSlice = createSlice({
  name: "stochasticCustomers",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setCustomersData: (
      state,
      action: PayloadAction<{
        customers: StochasticCustomer[];
        totalCount: number;
      }>,
    ) => {
      state.customers = action.payload.customers;
      state.totalCount = action.payload.totalCount;
    },
    resetStochasticCustomers: () => initialState,
  },
});

export const {
  setLoading,
  setError,
  setCustomersData,
  resetStochasticCustomers,
} = stochasticCustomersSlice.actions;

export const fetchStochasticCustomersAction =
  (requestData: FetchStochasticCustomersRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: FetchStochasticCustomersResponse =
        await fetchStochasticCustomers(requestData);

      dispatch(
        setCustomersData({
          customers: response.data,
          totalCount: response.meta?.totalCount || 0,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError("Failed to fetch customers"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export default stochasticCustomersSlice.reducer;
