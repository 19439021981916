import {
  ERPagination,
  ERPaginationProps,
} from "@/modules/eventRegistration/features/shared/components/ERPagination";

export interface TablePaginationProps {
  totalCount: number;
  pageIndex: number;
  pageSize: number;
  onPageChange: (pageIndex: number, pageSize: number) => void;
}

export const EventManagementPagination: React.FC<ERPaginationProps> = (
  props,
) => (
  <ERPagination
    {...props}
    itemName="events"
    pageSizeOptions={[10, 20, 30, 40, 50]}
  />
);
