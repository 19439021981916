import axios from "../axiosInstance";
import {
  CreateEventInstructorRequest,
  CreateEventInstructorResponse,
} from "./types";

export const createEventInstructor = async (
  requestData: CreateEventInstructorRequest,
): Promise<CreateEventInstructorResponse> => {
  const response = await axios.post<CreateEventInstructorResponse>(
    `/api/private/event-instructors/create`,
    requestData,
  );
  return response.data;
};
